import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef, AfterViewChecked, ViewChildren } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
// import { TemplateService } from 'src/app/services/template.service';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { SharedService } from 'src/app/shared/services/shared.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { ConfirmModalComponent } from '../../layout/confirm-modal';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { TemplateModel } from 'src/app/models/template.model';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { Subscription, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-shared-simple-question-edit-view',
  templateUrl: './template-shared-simple-question-edit-view.component.html',
  styleUrls: ['./template-shared-simple-question-edit-view.component.scss']
})
export class TemplateSharedSimpleQuestionEditViewComponent implements OnInit, AfterViewInit, OnDestroy,AfterViewChecked {
  private subscription: Subscription;
  private subscriptionSave: Subscription;
  private saveSubscription: Subscription;
  // private spinnerSubscription: Subscription;
  @Input() childInput;
  appConstants = AppConfig.settings;
  SERVER_URL:any;
  checkServerURL: any;
  // subscription: any = {};
  isEdit: boolean = false;
  choicesharedListId: number;
  spinnerTemplate: boolean = false;
  checkedArr:any = [];
  deleteButton:boolean = false;
  getQuestionSharedListByIdURL:any;
  templateSharedlistquestionUpdateURL:any;
  deleteChoiceURL:any;
  deleteSharedListURL: any;
  editTempSingleQuesForm: UntypedFormGroup;
  sharedQuestionDetail:any = [];
  columnsToDisplay: string[];
  columnsToDisplayPunchCode: any = [];
  columns: any[];
  dataSource: any = [];
  isNew: boolean = false;
  dataNotFound: any;
  questionType:any='Simple';
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('div') viewEdit: ElementRef;
  @ViewChild('table') table: MatTable<any>;
  @ViewChildren('ref') ref;
  sharedQuestionId: any;
  constructor(
  //  private templateService: TemplateService,
  //  private commonService: CommonService,
  private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    public dialog:MatDialog,
    private cdr:ChangeDetectorRef

  ) { 
    this.SERVER_URL = `${this.appConstants.serverUrl}`;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`;
      this.templateSharedlistquestionUpdateURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`;
      this.deleteChoiceURL = `${this.SERVER_URL}/${this.appConstants.templateURL}/${this.appConstants.deleteChoiceURL}`;
      this.deleteSharedListURL = `${this.SERVER_URL}/${this.appConstants.templateURL}/${this.appConstants.deleteTemplateSharedQuestionURL}`;
    } else {
      this.getQuestionSharedListByIdURL = this.appConstants.getTemplateQuestionSharedListByIdLocalURL;
    }
  }
  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.editTempSingleQuesForm = this.formBuilder.group({
      templateDescription: new UntypedFormControl(''),
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
      ])),
      sharedListDescription: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
      ])),
      questionSharedListId: new UntypedFormControl(''),
      punchCodes: this.formBuilder.array([])
    });

    this.sharedService.templateData.subscribe( data => {
      this.questionType = data.isGrid;
    });

    this.saveSubscription =this.sharedService.templateSaveEvent$.subscribe(data => {
      if(data.isSave==true && this.questionType=='Simple'){
        this.editTempSingleQuesForm.controls['templateDescription'].setValue(data.templateDescription)
        this.sharedListSingleQuestionSave();
      }
    })
    
  }

 ngOnChanges(){
  this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
/*
  this.spinnerSubscription = this.sharedService.spinnerEvent$.subscribe(data => {
    this.spinnerTemplate = data;
  }); */
 }

 ngAfterViewInit(){
  if(this.questionType === 'Simple'){
    this.subscription = this.sharedService.templateEditEvent$.subscribe(data => {
      this.isEdit = data.isEdit;
      if(this.isEdit === true){
        this.viewEdit.nativeElement.classList.add('editOnly');
      }
      if(this.isEdit === false){
        this.viewEdit.nativeElement.classList.remove('editOnly');
      }
      if(data.isEdit === false && data.isCancel === true){
        this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
        /* this.editTempSingleQuesForm.patchValue({
          sharedListName:this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListName,
          sharedListDescription:(this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions.length>0)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions[0].questionLongText : '',
        });
        this.checkedArr=[];
        const len= this.ref.length;
        const arr=this.ref._results;
        for(let i=0;i<len;i++){
          arr[i]._checked=false;          
        }
       
        this.ref._checked = false; */
      }
    });
  }

  this.subscriptionSave  = this.sharedService.templateSaveEvent$.subscribe(data => {
    if(data.isSave === true){
      this.isNew = data.isNew;
      this.sharedListSingleQuestionSave();
    }
  })
  if(this.subscriptionSave){ this.subscriptionSave.unsubscribe() }
}

  getQuestionSharedListById(templateId, sharedQuestionId){
    let choices;
    this.spinnerTemplate=true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.templateURL}/${templateId}/${this.appConstants.templateSharedQuestions}/${sharedQuestionId}`;
    }
    this.commonService.getRequest(`${this.getQuestionSharedListByIdURL}`, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe( 
        data => {
          this.spinnerTemplate=false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          if(data){
          this.sharedQuestionDetail = data['templateQuestionChoice'][0];
          if(this.sharedQuestionDetail.templateQuestionChoiceId){
            this.sharedService.templateQuesChoiceEmit(this.sharedQuestionDetail.templateQuestionChoiceId);
              }
        if(this.sharedQuestionDetail){
          this.editTempSingleQuesForm.patchValue({
            questionSharedListId: data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.questionSharedListId, 
            sharedListName:data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.sharedListName,
            sharedListDescription:(data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.sharedListQuestions.length>0)? data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.sharedListQuestions[0].questionLongText : '',
           })
          this.choicesharedListId =  (data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList)? data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList.choiceSharedListId: null ;
          choices = data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList;
        } else {
          this.dataNotFound = 'There is no sharedList';
        }

        if(this.isEdit === true){
          this.viewEdit.nativeElement.classList.add('editOnly');
        } else {
          this.viewEdit.nativeElement.classList.remove('editOnly');
        }
          
        this.columns = [];
        this.columnsToDisplay = [];
        this.columnsToDisplayPunchCode = [];
        this.dataSource = [];
        const punchList = this.editTempSingleQuesForm.controls.punchCodes as UntypedFormArray;
        punchList.clear();
        if (choices) {
         let displayedElementWithRows: any[] = [];
         let parentColumn = [
          { columnDef: 'action', header: 'Action', punchCode: '', punchCol: 'actions', choiceId: '' },
          { columnDef: 'name', header: 'Choices', punchCode: '', punchCol: 'questions', choiceId: '' },
          { columnDef: 'punchCode', header: '', punchCode: '', punchCol: 'questionss', choiceId: '' }
        ];
         if (choices.sharedListChoices.length > 0) {
          choices.sharedListChoices.sort((a, b) => parseFloat(a.choiceSequenceNumber) - parseFloat(b.choiceSequenceNumber));
           choices.sharedListChoices.map(
             item => {
               punchList.push(this.formBuilder.group({
                sharedListChoiceId: [item.sharedListChoiceId, Validators.required],
                  choiceLibraryId: [item.choiceLibrary.choiceLibraryId],
                punchCode: [item.punchCode, Validators.required],
                choiceSequenceNumber: [item.choiceSequenceNumber],
                activeYn:[item.choiceLibrary.activeYn]
               }));
             }
           );
         }
     
         this.columns = [...parentColumn];
         this.columnsToDisplay = this.columns.map(c => c.columnDef);
         this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
         this.dataSource = choices.sharedListChoices;
          }
        }
        }, 
        error => {
          this.spinnerTemplate=false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
        }
      );
    }
  
  public hasError = (controlName: string, errorName: string) => {
    return this.editTempSingleQuesForm.controls[controlName].hasError(errorName);
  }

sharedListSingleQuestionSave(){
  if (this.editTempSingleQuesForm.invalid) {
    return false;
  } else {
      this.templateSave();
  }
}

templateSave(){
  this.spinnerTemplate = true;
  // this.sharedService.spinnerEvent(this.spinnerTemplate);
  let choiceSharedListData=null;
  let punchdata = null;
  const punchList = this.editTempSingleQuesForm.controls.punchCodes as UntypedFormArray; 
    if(punchList.length>0){
      punchdata=null;
      punchdata = punchList.controls.map(item => {
        return {
          sharedListChoiceId: item.get('sharedListChoiceId').value,
          punchCode: item.get('punchCode').value,
          choiceLibrary: {
            choiceLibraryId: item.get('choiceLibraryId').value,
          },
          activeYn:item.get('activeYn').value,
          choiceSequenceNumber: item.value.choiceSequenceNumber
        }
      })
      choiceSharedListData = {
        choiceSharedListId:this.choicesharedListId,
        sharedListChoices: punchdata
      }
    }
    let inputData:TemplateModel = {
      surveyTemplateId: this.childInput.templateId,
      templateDescription: this.editTempSingleQuesForm.controls['templateDescription'].value,
      templateQuestionChoice: [{
        templateQuestionChoiceId: this.sharedQuestionDetail.templateQuestionChoiceId,
        sharedQuestionChoice: {
          sharedQuestionsChoiceId: this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId,
          questionSharedList: {
            questionSharedListId: this.editTempSingleQuesForm.controls['questionSharedListId'].value,
            sharedListName: this.editTempSingleQuesForm.controls['sharedListName'].value,
            sharedListDescription:this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListDescription,
            source:this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.source,​
            activeYn: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.activeYn,
            questionType: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.questionType,
            variableYN: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.variableYN,
            sharedListQuestions: [
              {
                questionLibrary : {
                  questionLibraryId : (this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions.length>0)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions[0].questionLibrary.questionLibraryId: null,
                },
                sharedListQuestionsId: (this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions.length>0)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions[0].sharedListQuestionsId:null,
                questionLongText: this.editTempSingleQuesForm.controls['sharedListDescription'].value,
                questionSequenceNumber: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions[0].questionSequenceNumber,
              }
            ]
          },
            choiceSharedList: choiceSharedListData
          }
    }]}
  this.commonService.putRequest(this.templateSharedlistquestionUpdateURL,'PUT', inputData)
  .pipe(takeUntil(this.destroy$))
  .subscribe( 
    (result) => {
      this.sharedQuestionId=result;
      if(result){
      this.spinnerTemplate = false;
      // this.sharedService.spinnerEvent(this.spinnerTemplate);
      this.toastorService.success('Template Updated Successfully!');
      let templateData = { surveyTemplateId: this.childInput.templateId, id:result, isGrid: this.questionType } 
      const reload={reload:true, response:templateData, isEdit: false}
      this.sharedService.templateDataEmit(reload);    
   /*   let questionSharedListData = {
        id:result, 
        isGrid: this.questionType
      } 
      this.sharedService.sharedListDataEmit(questionSharedListData);
      */
      }
    },
    (error) => {
      this.spinnerTemplate = false;
      // this.sharedService.spinnerEvent(this.spinnerTemplate);
      if(error.status==409){
        error.message = error.error;
      }
      this.toastorService.warn(error.message);
      const editCancelData: any = { isEdit: true };
      this.sharedService.announceEvent(editCancelData);
    },
    () => {
    });
  }

editSharedQuestion() {}

selectRow($event, dataSource) {
  if ($event.checked) {
    this.checkedArr.push(dataSource.sharedListChoiceId);
  }
  else {
    let i = this.checkedArr.indexOf(dataSource.id);
    this.checkedArr.splice(i, 1);
  }
  if (this.checkedArr.length>0) {
    this.deleteButton = true;
  } else {
    this.deleteButton = false;
  }
}


  // Confirmation Popup
  openconfirmModal(type, id): void {
    const dialogRef = this.dialog.open((ConfirmModalComponent), { data: { msg: `delete the ${type}` }, panelClass: 'confirmModal', disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        if (type === 'choice') {
          this.deleteChoice();
        }
        else if (type === 'Shared List') {
          this.deleteSharedList();
        }
      }
    });
  }
  
  deleteChoice() {
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
  //  const options = this.checkedArr ? { params: new HttpParams().set('choiceId', this.checkedArr).set('squestionId', this.childInput.sharedQuestionId).set('templateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId)  } : {};
  //  const options = this.checkedArr ? { params: new HttpParams().set('sharedListChoicesId', this.checkedArr).set('sharedQuestionsChoiceId', this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId).set('surveyTemplateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId) } : {};
    const options = this.checkedArr ? {'sharedListChoicesId': this.checkedArr, 'sharedQuestionsChoiceId': this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId, 'surveyTemplateId': this.childInput.templateId, 'templateQuestionChoiceId': this.sharedQuestionDetail.templateQuestionChoiceId } : {};
    this.commonService.deleteRequest(this.deleteChoiceURL,'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result:any) => {
          this.checkedArr=[];
          this.deleteButton = false;
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate); 
          this.toastorService.success('Choice Deleted Successfully!');
        const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
        this.sharedService.templateEmitEvent(editCancelData);
          let questionSharedListData = {
          //  id:result.questionSharedListId, 
            id:result, 
            isGrid: this.questionType
          } 
          this.sharedService.sharedListDataEmit(questionSharedListData);
        //  this.childInput.sharedQuestionId = result.questionSharedListId;
        //  this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
        },
        error => {
          this.checkedArr=[];
          this.deleteButton = false;
          this.toastorService.warn('Error ' + error.message);
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
        },
        () => {
        }
      );
  }

  deleteSharedList() {
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
  //  const options = this.childInput.sharedQuestionId ? { params: new HttpParams().set('squestionId', this.childInput.sharedQuestionId).set('templateId', this.childInput.templateId) } : {};
  // const options = this.childInput.sharedQuestionId ? { params: new HttpParams().set('questionSharedListId', this.childInput.sharedQuestionId).set('surveyTemplateId', this.childInput.templateId) } : {};
  // const options = this.childInput.sharedQuestionId ? { params: new HttpParams().set('questionSharedListId', this.childInput.sharedQuestionId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId).set('surveyTemplateId', this.childInput.templateId) } : {};
  const options = this.childInput.sharedQuestionId ? { 'questionSharedListId':this.childInput.sharedQuestionId,'templateQuestionChoiceId':this.sharedQuestionDetail.templateQuestionChoiceId,'surveyTemplateId':this.childInput.templateId} : {};
  
  this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
  this.commonService.deleteRequest(this.deleteSharedListURL, 'DELETE', options, options)
  .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result:any) => {
          this.checkedArr = [];
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          this.toastorService.success('Shared Question Deleted Successfully!');
          const editCancelData: any  = { isEdit: false, sideNavReload: true, type:'sharedListDEL' }
          this.sharedService.templateEmitEvent(editCancelData);
        },
        error => {
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          this.toastorService.warn('Error' + error.message);
        }
      );
  }

  //ADD CHOICE 
 openAddChoice(): void {
    let dialogRef = this.dialog.open((AddChoiceComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { sharedListId: this.childInput.sharedQuestionId, choicesharedListId: this.choicesharedListId, surveyTemplateId: this.childInput.templateId ,templateQuestionChoiceId:this.sharedQuestionDetail.templateQuestionChoiceId}
    });
      dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
        this.sharedService.templateEmitEvent(editCancelData);
        let questionSharedListData = {
        //  id:data.questionSharedListId, 
          id:data, 
          isGrid: this.questionType
        } 
        this.sharedService.sharedListDataEmit(questionSharedListData);
      //  this.childInput.sharedQuestionId =  data.questionSharedListId;
     //   this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
      } 
    });
  }
  refreshTable(choices) {
    let displayedElementWithRows: any[];
    let parentColumn = [
      { columnDef: 'action', header: 'Action', punchCode: '', punchCol: 'actions', choiceId: '' },
      { columnDef: 'name', header: 'Choices', punchCode: '', punchCol: 'questions', choiceId: '' },
      { columnDef: 'punchCode', header: '', punchCode: '', punchCol: 'questionss', choiceId: '' }
    ];
    this.columns = [...parentColumn];
    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
    this.dataSource = choices;
    const punchList = this.editTempSingleQuesForm.controls.punchCodes as UntypedFormArray;
    punchList.clear();
    if (choices.length > 0) {
      choices.map(
        item => {
          
          punchList.push(this.formBuilder.group({
            sharedListChoiceId: item.sharedListChoiceId,
            punchCode: item.punchCode,
            choiceLibraryId:item.choiceLibraryId,
            choiceSequenceNumber:item.choiceSequenceNumber
          }));
     //     console.log(item);
          // return {
          //   'columnDef': 'choice' + item.id,
          //   'header': item.name,
          //   'punchCode': item.punchCode,
          //   'punchCol': 'choices' + item.id,
          //   'choiceId': item.id
          // }
        });
    }
   // this.isEdit = false;
  }
  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource,prevIndex, event.currentIndex);
    moveItemInArray(this.editTempSingleQuesForm.get('punchCodes')['controls'],prevIndex, event.currentIndex);
    moveItemInArray(this.editTempSingleQuesForm.get('punchCodes').value,prevIndex, event.currentIndex);
 
 const len=this.dataSource.length;
 const punchList = this.editTempSingleQuesForm.controls.punchCodes as UntypedFormArray;

    const arr =punchList.value;
    for(let i=0;i<arr.length;i++){
      arr[i].choiceSequenceNumber =i
       }
 

  //  this.table1.renderRows();
  this.table.renderRows();

  }

ngOnDestroy() {
  this.subscription.unsubscribe();
  this.saveSubscription.unsubscribe();
 // this.spinnerSubscription.unsubscribe();
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
}


}
