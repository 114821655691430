<div class="container primeNewModal addChoice-sharedlist">
    <!-- <h4 mat-dialog-title id="myModalLabel2">Create Question</h4> -->
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="addChoiceForm" autocomplete="off" class="example-form" novalidate>
            <mat-card-content>
                <mat-form-field class="searchTextWrap example-full-width align-autocomplete selection-placeholder" floatLabel=never>
                    <label class="font-13 required float-left"> Select from Shared Choice</label>
                    <div class="autoSelect hastag clearfix clear" floatLabel=never>
                        <span *ngFor="let item of selectedChoices;let i=index">
                            <mat-list-item> {{ item.sharedListName }}
                                <button mat-icon-button (click)="onRemove(item)">
                                    <mat-icon class="mat-24 hastagDelete">x</mat-icon>
                                </button>
                            </mat-list-item>
                        </span>
                    </div>
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search" name="searchChoiModel" [(ngModel)]="searchChoiModel" [ngModelOptions]="{standalone: true}">
                        <!-- <span>{{searchChoiModel}}</span>  -->
                        <i class="searchicon align-autosearchIcon"></i>
                    </div>


                    <!-- <mat-form-field class="align-autocomplete" floatLabel=never>     -->

                    <mat-selection-list matInput #multiSelect class="marginT10 form-control align-autocomplete addChoice-searchChoice" formControlName="sharedChoiceId">
                        <!--  <mat-list-option *ngFor="let option of sharedChoiceList | searchFilter: searchChoiModel ;let i=index" 
                        [selected]="option.selected" (click)="onAreaListControlChanged(sharedChoices,option)" [value]="option.choiceSharedListId"> -->
                        <mat-list-option title="{{option.sharedListName}}" *ngFor="let option of sharedChoiceList | searchFilter: searchChoiModel ;let i=index" [ngClass]="getSomeClass(option)" [selected]="option.isselected === 'true'" (click)="onAreaListControlChanged(option)"
                            [value]="option.sharedChoiceId">
                            <span>{{option.sharedListName}}</span>
                        </mat-list-option>
                    </mat-selection-list>
                </mat-form-field>

                <!-- <div *ngIf="loading">Choices are loading...</div> -->


                <!-- </mat-form-field>   -->


                <input type="hidden" formControlName="choiceSharedListId">
                <input type="hidden" formControlName="sharedListId">

            </mat-card-content>
            <div class="spinner" *ngIf="isLoadingSave">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="addChoiceForm.invalid" (click)="addSharedChoice()">ADD</button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close (click)="openLink($event)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>