<div class="basic-container question-dialog-container">
  <span *ngIf="spinnerSurveyChoice">
    <div class="spinnerdialogBox">
      <div class="bounceone"></div>
      <div class="bouncetwo"></div>
      <div class="bouncethree"></div>
      <div class="bouncefour"></div>
      <div class="bouncefive"></div>
      <div class="bouncesix"></div>
    </div>
  </span>
  <div *ngIf="!spinnerSurveyChoice">
    <div class="center-modalcloseWrapper">
      <span class="center-modalClose" mat-dialog-close>x</span>
    </div>
    <table mat-table [dataSource]="surveyTableSource" class="mat-elevation-z8">

      <ng-container matColumnDef='surveyId'>
        <th mat-header-cell *matHeaderCellDef> {{surveyHeader.surveyCode | uppercase}} </th>
        <td mat-cell *matCellDef="let element"> <a class="surveyLink" (click)="surveyEdit(element)">{{element.surveyCode}}</a> </td>
        <td mat-footer-cell *matFooterCellDef>

          {{dataTableErrorBlock}} </td>
      </ng-container>

      <ng-container matColumnDef="surveyCode">
        <th mat-header-cell *matHeaderCellDef> {{surveyHeader.showTitle | uppercase}}</th>
        <td mat-cell *matCellDef="let element"> {{element.showTitle}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="createdDt">
        <th mat-header-cell *matHeaderCellDef>{{surveyHeader.surveyDate | uppercase }}</th>
        <td mat-cell *matCellDef="let element"> {{element.surveyDate | date: 'MM/dd/yyyy' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="surveyColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: surveyColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="surveyColumns;" class="table-footer-section"></tr>
    </table>
  </div>
</div>