import {
  Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, HostListener, AfterViewInit, OnChanges
} from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { QuestionService } from 'src/app/services/question.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { QuestiondashboardDatatable } from '../../../../models/questiondashboard-datatable';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';
import { QuestionsModel } from 'src/app/models/questions.model';
import { UntypedFormControl, FormGroup, FormBuilder } from '@angular/forms';
import { QuestionSurveydialogComponent } from './question-surveydialog.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import { SatDatepicker } from 'saturn-datepicker';
import { Sort } from '@angular/material/sort';
import { map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { AuthManager } from 'src/app/helpers/auth-manager';

@Component({
  selector: 'app-question-dashboard',
  templateUrl: './question-dashboard.component.html',
  styleUrls: ['./question-dashboard.component.scss'],
  providers: [QuestiondashboardDatatableService]
})
export class QuestiondashboardComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Output() editEvent = new EventEmitter();
  @Output() filterExcelExport = new EventEmitter();
  public globalResponse: any;
  appConstants = AppConfig.settings;
  DASHBOARDSSERVER_URL: any;
  // tslint:disable-next-line: variable-name
  dashboardSurvey_URL: string;
  checkServerURL: any;
  questionConstants: any;
  dataTableHeader: any;
  dataTableColumns: any;
  dataTableFillterColumns: any;
  questionText: any;
  dataTableGrid: any = [];
  dataTableGridTotalCount: number;
  dots = '...';
  // formQuestionDashboard: FormGroup;
  categoryControl = new UntypedFormControl('');
  genericTextControl = new UntypedFormControl('');
  connotationControl = new UntypedFormControl('');
  percentileYnControl = new UntypedFormControl('');
  summRptYnControl = new UntypedFormControl('');
  activeYnControl = new UntypedFormControl('');
  createdDtControl = new UntypedFormControl('');
  lastUpdatedDtControl = new UntypedFormControl('');
  filterValues = {
    genericText: '',
    questionPosNegFlag: '',
    questionCategoryDesc: '',
    percentileYn: '',
    summRptYn: '',
    activeYn: '',
    fromCreatedDate: '',
    toCreatedDate: '',
    fromUpdatedDate: '',
    toUpdatedDate: ''
  };

  connotationList: any[];
  percentileList: any[];
  summaryReportList: any[];
  statusList: any[];
  categoryList: any;
  public url = 'question';
  pipe: DatePipe;
  events: string[] = [];
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /* @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>; */
  destroy$: Subject<boolean> = new Subject<boolean>();
  now: Date = new Date();
  filterdata: any;
  filterReturnData: any;
  dataTableErrorBlock: any;
  dataTableNoDataFound: any;
  spinnerQuestion: boolean;
  dataTableGridTemp: any;
  lastdate: string;
  createdate: string;
  isLoading = false;
  firstClear: boolean;
  message: any;
  subscription: Subscription;
  isAdmin: boolean = true;
  constructor(
    private appconfig: AppConfig,
    private commonService: CommonService,
    private toastorService: NotificationService,
    private questionservice: QuestiondashboardDatatableService,
    private surveyDialog: MatDialog,
    private quesService: QuestionService,
    private notificationService: NotificationService,
    private awsCommonService: AwsCommonService,
    private authManager: AuthManager
  ) {
    this.spinnerQuestion = true;
    this.quesService.tooltip.emit(true);
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.DASHBOARDSSERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.questionDashboardParam}`;
      this.dashboardSurvey_URL = `${this.appConstants.serverUrl}/${this.appConstants.questionDashboardSurveyParam}`;
    } else {
      this.DASHBOARDSSERVER_URL = this.appConstants.questionDashboardLocalURL;
      this.dashboardSurvey_URL = this.appConstants.questionDashboardSurveyLocalURL;
    }
    this.dataTableHeader = this.appConstants.questiondashboardHeader;
    this.dataTableColumns = Object.values(this.appConstants.questionDashboardTableColumns).slice();
    this.dataTableFillterColumns = Object.values(this.appConstants.questionDashboardFillterTableColumns);
    this.connotationList = Object.values(this.appConstants.connotationList);
    this.percentileList = Object.values(this.appConstants.percentileList);
    this.summaryReportList = Object.values(this.appConstants.summaryReportList);
    this.statusList = Object.values(this.appConstants.statusList);
    this.categoryList = this.categoryList,
      this.pipe = new DatePipe('en');
    this.dataTableErrorBlock = 'Loading Data...';
    this.dataTableNoDataFound = 'No Records Found';
  }
  ngOnInit() {
    this.getMultipleFilter();
    // console.log(this.authManager.userRole)
    if (this.authManager.userRole != 'ADMIN') {
      this.isAdmin = false;
    }
    // this.isAdmin = true;
    // this.dataTableGrid.sort = this.sort;
    // window.addEventListener('scroll', this.scroll, true);

    this.getCategory();
  }
  // scroll = (): void => {
  //     //console.log("------",this.dataTableGrid);

  // };
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  ngOnChanges() {
    setTimeout(() => {
      this.dataTableGrid.sort = this.sort;
    });
  }


  clearQuestionTable() {
    // this.isLoading = true;
    //  this.spinnerQuestion=true;
    //  setTimeout(() => {
    //   //console.log('hide');
    //   this.spinnerQuestion = false;
    // }, 1000);

    this.dataTableGrid.filter = '';
    this.percentileYnControl.setValue('');
    this.categoryControl.setValue('');
    this.genericTextControl.setValue('');
    this.connotationControl.setValue('');
    this.summRptYnControl.setValue('');
    this.activeYnControl.setValue('');
    this.lastUpdatedDtControl.setValue('');
    this.filterValues.fromCreatedDate = '';
    this.filterValues.toCreatedDate = '';
    this.filterValues.fromUpdatedDate = '';
    this.filterValues.toUpdatedDate = '';
    this.questionservice.clearDate.emit(true);
    this.dataTableGrid.filter = JSON.stringify(this.filterValues);
    this.dataTableGrid.filter = JSON.stringify(this.filterValues);
    this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
    //  this.spinnerQuestion = false;
  }
  getquestion() {
    this.spinnerQuestion = true;
    // this.questionservice.getQuestionDashboard(this.DASHBOARDSSERVER_URL)
    this.awsCommonService.getRequest(this.DASHBOARDSSERVER_URL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {

          this.spinnerQuestion = false,
            this.quesService.tooltip.emit(false);
          this.dataTableGrid = dataSource,
            this.dataTableGridTemp = this.dataTableGrid.content,
            this.dataTableGrid = new MatTableDataSource<QuestiondashboardDatatable>(this.dataTableGrid.content),
            setTimeout(() => {
              // //console.log(this.sort);
              this.dataTableGrid.sort = this.sort;

            }),
            // this.dataTableGrid.sort = this.sort,
            this.dataTableGrid.filterPredicate = this.customFilterPredicate(),
            this.dataTableGridTotalCount = this.dataTableGrid.numberOfElements,
            this.clearQuestionTable(),
            this.firstClear = true;
          // this.isLoading = false;
          // this.sortedData = this.dataTableGrid.slice();
        },
        error => {
          this.spinnerQuestion = false,
            this.notificationService.warn('Oops!!! The Server was unable to complete your request'),
            this.dataTableErrorBlock = '';
        }
      );
  }
  getMultipleFilter() {
    this.categoryControl.valueChanges
      .subscribe(
        (questionCategoryDesc) => {
          this.filterValues.questionCategoryDesc = questionCategoryDesc;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          // //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );

    this.genericTextControl.valueChanges
      .subscribe(
        genericText => {
          this.filterValues.genericText = genericText;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          // //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.connotationControl.valueChanges
      .subscribe(
        questionPosNegFlag => {
          this.filterValues.questionPosNegFlag = questionPosNegFlag;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          // //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.percentileYnControl.valueChanges
      .subscribe(
        percentileYn => {
          this.filterValues.percentileYn = percentileYn;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          //  //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.summRptYnControl.valueChanges
      .subscribe(
        summRptYn => {
          this.filterValues.summRptYn = summRptYn;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          // //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.activeYnControl.valueChanges
      .subscribe(
        activeYn => {
          this.filterValues.activeYn = activeYn;
          this.dataTableGrid.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
          // //console.log('loading data');
          // this.isLoading = true;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    // this.isLoading = false;
  }
  customFilterPredicate() {
    // this.isLoading = false;
    return (data: QuestiondashboardDatatable, filter: string): boolean => {

      const searchString = JSON.parse(filter);
      const genericText = data.genericText.toString().trim().toLowerCase().indexOf(searchString.genericText.toLowerCase()) !== -1;
      const questionPosNegFlag = data.questionPosNegFlag.toString().trim().indexOf(searchString.questionPosNegFlag) !== -1;
      const questionCategoryDesc = data.questionCategoryDesc.toString().trim().indexOf(searchString.questionCategoryDesc) !== -1;
      const percentileYn = data.percentileYn.toString().trim().indexOf(searchString.percentileYn) !== -1;
      const summRptYn = data.summRptYn.toString().trim().indexOf(searchString.summRptYn) !== -1;
      const activeYn = data.activeYn.toString().trim().indexOf(searchString.activeYn) !== -1;

      const createdDt = (
        (data.createdDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (data.createdDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const lastUpdatedDt = (
        (data.lastUpdatedDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (data.lastUpdatedDt.toString() <= (searchString.toUpdatedDate).toString())
      );

      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.filterReturnData = genericText && questionPosNegFlag && questionCategoryDesc && percentileYn && summRptYn && activeYn;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          genericText && questionPosNegFlag && questionCategoryDesc && percentileYn && summRptYn && activeYn && createdDt;
      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.filterReturnData =
          genericText && questionPosNegFlag && questionCategoryDesc && percentileYn && summRptYn && activeYn && lastUpdatedDt;
      } else {
        this.filterReturnData =
          genericText && questionPosNegFlag && questionCategoryDesc && percentileYn && summRptYn && activeYn && createdDt && lastUpdatedDt;
      }

      return this.filterReturnData;
    };
    //   return myFilterPredicate;
  }
  ngAfterViewInit() {
    this.getquestion();
    this.dataTableGrid.sort = this.sort;
  }
  ViewSurveyData(data) {
    if (data) {
      if (data.length > 1) {
        return data[0] + '   +' + data.length + '   More';
      } else {
        return data;
      }
    }
    // else {
    //  return 'No Data';
    // }
  }
  startEdit(data: any) {
    let inputQuestion: any;
    inputQuestion = {
      questionLibraryId: data.questionLibraryId,
      genericText: data.genericText,
      activeYn: data.activeYn,
      percentileYn: data.percentileYn,
      questionPosNegFlag: data.questionPosNegFlag,
      summRptYn: data.summRptYn,
      questionCategory: {
        questionCategoryId: data.questionCategoryId,
        questionCategoryDesc: data.questionCategoryDesc
      }
    };
    this.editEvent.emit(inputQuestion);
  }
  getCategory(): void {
    // this.quesService.getCategoryList()
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/categories?page=1`, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.categoryList = data;
      });
  }
  openDialog(questionData: any) {
    const dialog = this.surveyDialog.open(QuestionSurveydialogComponent, {
      disableClose: true,
      data: '',
      id: questionData,
      panelClass: 'surveyModal'
    });
  }
  questionOpenCalendarClosed(picker: MatDatepicker<Date>) {
    picker.open();
  }
  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.filterValues.fromCreatedDate = fromDate;
      this.filterValues.toCreatedDate = toDate;
      this.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.filterValues.fromUpdatedDate = fromDate;
      this.filterValues.toUpdatedDate = toDate;
      this.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }

    this.dataTableGrid.filter = JSON.stringify(this.filterValues);
    this.dataTableGrid.filter = JSON.stringify(this.filterValues);
    this.dataTableGridTotalCount = this.dataTableGrid.filteredData.length;
  }
  sortData(sort: Sort) {
    const data = this.dataTableGridTemp.slice();
    if (!sort.active || sort.direction === '') {
      this.dataTableGrid = data;
      return;
    }
    this.dataTableGrid = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'questionCategoryDesc': return compare(a.questionCategoryDesc, b.questionCategoryDesc, isAsc);
        case 'genericText': return compare(a.genericText, b.genericText, isAsc);
        case 'questionPosNegFlag': return compare(a.questionPosNegFlag, b.questionPosNegFlag, isAsc);
        case 'percentileYn': return compare(a.percentileYn, b.percentileYn, isAsc);
        case 'summRptYn': return compare(a.summRptYn, b.summRptYn, isAsc);
        case 'activeYn': return compare(a.activeYn, b.activeYn, isAsc);
        case 'createdDt': return compare(a.createdDt, b.createdDt, isAsc);
        case 'lastUpdatedDt': return compare(a.lastUpdatedDt, b.lastUpdatedDt, isAsc);
        default: return 0;
      }
    });
    this.dataTableGrid = this.dataTableGrid;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

