import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { MatSort } from '@angular/material/sort';
import { ChoicedashboardDatatableService } from '../../../../services/choicedashboard-datatable.service';
import { ChoicedashboardDatatable } from '../../../../models/choicedashboard-datatable';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ChoiceSurveydialogComponent } from './choice-surveydialog.component';
import { DatePipe } from '@angular/common';
import { Subject, throwError, of } from 'rxjs';
import { takeUntil, catchError, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { Sort } from '@angular/material/sort';
import { ChoiceService } from 'src/app/services/choice.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { AuthManager } from 'src/app/helpers/auth-manager';

@Component({
  selector: 'app-choice-dashboard',
  templateUrl: './choice-dashboard.component.html',
  styleUrls: ['./choice-dashboard.component.scss']
})
export class ChoiceDashboardComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  appConstants = AppConfig.settings;
  SERVER_URL: any;
  checkServerURL: any;
  dashboardSurveyURL: string;
  dataTableHeader: any;
  dataTableColumns: any;
  dataTableFillterColumns: any;
  dataTableSource: any = [];
  choicePosNegFlagList: any[];
  choiceactiveYnList: any[];
  pipe: DatePipe;
  dataTableGridTotalCount: any;
  choicePosNegFlagControl = new UntypedFormControl('');
  choiceactiveYnControl = new UntypedFormControl('');
  choiceLibraryDescControl = new UntypedFormControl('');
  createdByControl = new UntypedFormControl('');
  filterReturnData: any;
  isAdmin:boolean = true;
  filterValues = {
    choiceLibraryDesc: '',
    posNegFlag: '',
    activeYn: '',
    createdBy: '',
    fromCreatedDate: '',
    toCreatedDate: '',
    fromUpdatedDate: '',
    toUpdatedDate: ''
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() editEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  dataTableErrorBlock: any;
  dataTableNoDataFound: any;
  isLoading = true;
  spinnerChoice: boolean;
  dataTableGridTemp: any;
  lastdate: string;
  createdate: string;
  constructor(
    private appconfig: AppConfig,
    private choiceservice: ChoicedashboardDatatableService,
    private surveyDialog: MatDialog,
    private notificationService: NotificationService,
    private choiceService: ChoiceService,
    private awsCommonService: AwsCommonService,
    private authManager: AuthManager
  ) {
    this.spinnerChoice = true;
    this.choiceService.tooltip.emit(true);
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardParam}`;
      this.dashboardSurveyURL = `${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardSurveyParam}`;
    } else {
      this.SERVER_URL = this.appConstants.choiceDashboardLocalURL;
    }
    this.dataTableHeader = this.appConstants.choiceDashboardHeader;
    this.dataTableColumns = Object.values(this.appConstants.choiceDashboardTableColumns).slice();
    this.dataTableFillterColumns = Object.values(this.appConstants.choiceDashboardFillterTableColumns);
    this.choicePosNegFlagList = Object.values(this.appConstants.choicePosNegFlagList);
    this.choiceactiveYnList = Object.values(this.appConstants.choiceActiveYn);
    this.pipe = new DatePipe('en');
    this.dataTableErrorBlock = 'Loading Data...';
    this.dataTableNoDataFound = 'No Records Found';
  }

  ngOnInit() {

    this.getMultipleFilter();
    // this.dataTableSource.sort = this.sort;
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  ngOnChanges() {
    // this.getquestion();
    setTimeout(() => {
      //console.log(this.sort);
      this.dataTableSource.sort = this.sort;
    });
  }
  getDashboard() {
    this.spinnerChoice = true;
    // this.choiceservice.getChoiceDashboard(this.SERVER_URL).pipe(takeUntil(this.destroy$))
    this.awsCommonService.getRequest(this.SERVER_URL,'GET').pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          this.spinnerChoice = false;
          this.choiceService.tooltip.emit(false);
          // this.isLoading = false,
          this.dataTableSource = dataSource,
            this.dataTableGridTemp = this.dataTableSource.content,
            this.dataTableSource = new MatTableDataSource<ChoicedashboardDatatable>(this.dataTableSource.content),
            setTimeout(() => {
              //console.log(this.sort);
              this.dataTableSource.sort = this.sort;
            }),
            //console.log(this.dataTableSource),
            this.dataTableGridTotalCount = this.dataTableSource.numberOfElements,
            this.dataTableSource.filterPredicate = this.customFilterPredicate(),
            this.clearChoiceTable(),
            this.spinnerChoice = false;
        },
        error => {
          this.notificationService.warn(error.message);
          this.dataTableErrorBlock = '';
          // this.isLoading = false;
        }
      );
  }
  ngAfterViewInit() {
    this.getDashboard();
    //console.log(this.sort);
    //console.log(this.dataTableSource.sort);
    this.dataTableSource.sort = this.sort;
  }
  clearChoiceTable() {
  //   this.spinnerChoice=true;
  //   setTimeout(() => {
  //    //console.log('hide');
  //    this.spinnerChoice = false;
  //  }, 1000);

    // this.dataTableGrid.filterPredicate = '';
    // setTimeout(() => {
    //   //console.log('hide');
    //   this.spinnerChoice = false;
    //   this.dataTableSource.sort = this.sort;
    // });
    this.dataTableSource.filter = '';
    // this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
    this.choiceLibraryDescControl.setValue('');
    this.choicePosNegFlagControl.setValue('');
    this.choiceactiveYnControl.setValue('');
    this.createdByControl.setValue('');
    this.filterValues.fromCreatedDate = '';
    this.filterValues.toCreatedDate = '';
    this.filterValues.fromUpdatedDate = '';
    this.filterValues.toUpdatedDate = '';
    this.lastdate = '';
    this.createdate = '';
    this.choiceservice.clearDate.emit(true);
    this.dataTableSource.filter = JSON.stringify(this.filterValues);
    this.dataTableSource.filter = JSON.stringify(this.filterValues);
    this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
  }

  ViewSurveyData(data) {

    if (data) {
      if (data.length > 1) {
        return data[0] + '   +' + data.length + '   More';
      } else {
        return data;
      }
    }
    // else {
    //  return 'No Data';
    // }
  }
  getMultipleFilter() {
    this.choiceLibraryDescControl.valueChanges
      .subscribe(
        choiceLibraryDesc => {
          this.filterValues.choiceLibraryDesc = choiceLibraryDesc;
          this.dataTableSource.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.choicePosNegFlagControl.valueChanges
      .subscribe(
        posNegFlag => {
          this.filterValues.posNegFlag = posNegFlag;
          this.dataTableSource.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.choiceactiveYnControl.valueChanges
      .subscribe(
        activeYn => {
          this.filterValues.activeYn = activeYn;
          this.dataTableSource.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
    this.createdByControl.valueChanges
      .subscribe(
        createdBy => {
          this.filterValues.createdBy = createdBy;
          this.dataTableSource.filter = JSON.stringify(this.filterValues);
          this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
          this.dataTableErrorBlock = (this.dataTableGridTotalCount === 0) ? this.dataTableNoDataFound : this.dataTableErrorBlock;
        }
      );
  }
  customFilterPredicate() {

    return (data: ChoicedashboardDatatable, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      const choiceLibraryDesc =
        data.choiceLibraryDesc.toString().trim().toLowerCase().indexOf(searchString.choiceLibraryDesc.toLowerCase()) !== -1;
      const posNegFlag = data.posNegFlag.toString().trim().indexOf(searchString.posNegFlag) !== -1;
      const activeYn = data.activeYn.toString().trim().indexOf(searchString.activeYn) !== -1;
      const createdBy = data.createdBy.toString().trim().toLowerCase().indexOf(searchString.createdBy.toLowerCase()) !== -1;
      const createdDt = (
        (data.createdDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (data.createdDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const lastUpdatedDt = (
        (data.lastUpdatedDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (data.lastUpdatedDt.toString() <= (searchString.toUpdatedDate).toString())
      );

      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.filterReturnData = choiceLibraryDesc && posNegFlag && activeYn && createdBy;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.filterReturnData = choiceLibraryDesc && posNegFlag && activeYn && createdBy && createdDt;
      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.filterReturnData = choiceLibraryDesc && posNegFlag && activeYn && createdBy && lastUpdatedDt;
      } else {
        this.filterReturnData = choiceLibraryDesc && posNegFlag && activeYn && createdBy && createdDt && lastUpdatedDt;
      }

      return this.filterReturnData;
    };
  }
  startEdit(data: any) {
    let inputChoice: any;
    inputChoice = {
      choiceLibraryId: data.choiceLibraryId,
      choiceName: data.choiceLibraryDesc,
      choiceLibraryDesc: data.choiceLibraryDesc,
      activeYn: data.activeYn,
      posNegFlag: data.posNegFlag,
      sharedList: {
        sharedListId: '',
        sharedListDesc: ''
      }
    };
    this.editEvent.emit(inputChoice);
  }
  openChoiceDialog(choiceData) {
    const dialog = this.surveyDialog.open(ChoiceSurveydialogComponent, {
      disableClose: true,
      data: '',
      id: choiceData,
      panelClass: 'surveyModal'
    });
  }
  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.filterValues.fromCreatedDate = fromDate;
      this.filterValues.toCreatedDate = toDate;
      this.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.filterValues.fromUpdatedDate = fromDate;
      this.filterValues.toUpdatedDate = toDate;
      this.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }
    this.dataTableSource.filter = JSON.stringify(this.filterValues);
    this.dataTableGridTotalCount = this.dataTableSource.filteredData.length;
  }
  sortData(sort: Sort) {
    const data = this.dataTableGridTemp.slice();
    if (!sort.active || sort.direction === '') {
      this.dataTableSource = data;
      return;
    }

    this.dataTableSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'choiceLibraryDesc': return compare(a.choiceLibraryDesc, b.choiceLibraryDesc, isAsc);
        case 'posNegFlag': return compare(a.posNegFlag, b.posNegFlag, isAsc);
        case 'activeYn': return compare(a.activeYn, b.activeYn, isAsc);
        case 'createdBy': return compare(a.createdBy, b.createdBy, isAsc);
        case 'createdDt': return compare(a.createdDt, b.createdDt, isAsc);
        case 'lastUpdatedDt': return compare(a.lastUpdatedDt, b.lastUpdatedDt, isAsc);
        default: return 0;
      }
    });
    this.dataTableSource = this.dataTableSource;
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
