

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { Inputsheetprintads, InputsheetprintCreateHeader, InputsheetPrintCreate, InputsheetPrintEdit, InputsheetprintEditHeader, InputsheetPrintQuesSection, InputSheetPrintSrAwarenessSection } from 'src/app/models/inputsheetprintads';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpParams } from '@angular/common/http';
import { InputService } from 'src/app/services/inputsheet.service';
import { ConditionalExpr } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import { AuthManager } from 'src/app/helpers/auth-manager';
import data from '../../../../../assets/json/vendorData';

@Component({
  selector: 'app-print-edit',
  templateUrl: './print-edit.component.html',
  styleUrls: ['./print-edit.component.scss'],
  providers: [DatePipe]
})
export class PrintEditComponent implements OnInit {
  selectedGenre: any = [];
  appConstants = AppConfig.settings;
  destroy$: Subject<boolean> = new Subject<boolean>();
  printDec: InputsheetPrintEdit;
  pilotDateVal: string;
  pilotDate = new UntypedFormControl();
  vendor=new UntypedFormControl();
  notSaved: boolean;
  printAddTableRow: UntypedFormGroup;
  dataSource;
  indexpostion: UntypedFormGroup;
  createInputPrint: InputsheetPrintCreate;
  genreList: any = [];
  selected: any = [];
  disButtons: boolean = false;
  disDelete: boolean = false;
  readonlyFlag: boolean = false;
  disableSelect: boolean = false;
  showTitleEmpty: boolean = false;
  isAdmin:boolean = true;

  valueFound: any;
  inputSheetId: any;
  showTitleList: any[] = [];
  Section1PromoSet: any = [];
  sectionData: any[] = [];
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any = [];
  printInfoIds: any = [];
  editData: any;
  dataValue: any = [];
  typeid: any = [];
  TotalAds: number = 0;
  statusOfPrint: any;
  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  toggle: boolean = true;
  datasection1HeaderList: any[] = [];
  vendorData: { "vendorId": number; "vendorName": string; }[];

  public form: UntypedFormGroup;
  public formAfterINDIVpAds: UntypedFormGroup;
  public formAfterGroupAds: UntypedFormGroup;
  public formAfterPromoAds: UntypedFormGroup;
  public formTag: UntypedFormGroup;
  public formShowDesc: UntypedFormGroup;
  public formVideotoShow: UntypedFormGroup;

  srAwarenessList: any = [];
  questionstimList: any = [];

  srawarenessSave: boolean = false;
  quessSave: boolean = false;

  srAwareData = []
  quesStimData = []

  submittedForm8: boolean = false;
  submittedForm9: boolean = false;
  adTitleButton: boolean = false;
  section8Form: UntypedFormGroup;
  onAddsec8: boolean = false;
  dataSource8;
  displayedColumns8: string[] = ['possraware', 'srawareness', 'columndelete'];

  section9Form: UntypedFormGroup;
  onAddsec9: boolean = false;
  dataSource9;
  displayedColumns9: string[] = ['posques', 'quesnstim', 'columndelete'];
  showSelected: boolean = false;
  spinnerMTemplate: boolean = false;
  vendorSelected: string;
  selectedVendor: any;
  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private commonService: AwsCommonService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private toastorService: NotificationService,
    private route: ActivatedRoute,
    private inputService: InputService,
    private datePipe: DatePipe,
    private authManager: AuthManager
  ) {

    this.form = fb.group({ adTitle: fb.array([]) });
    this.formAfterINDIVpAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formAfterGroupAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formAfterPromoAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formTag = fb.group({ inputSheetSection: fb.array([]) });
    this.formShowDesc = fb.group({ inputSheetSection: fb.array([]) });
    this.formVideotoShow = fb.group({ inputSheetSection: fb.array([]) });

    this.section8Form = fb.group({
      section8Array: fb.array([])
    });

    this.section9Form = fb.group({
      section9Array: fb.array([])
    });
    if (this.form.value.invalid) {
      this.adTitleButton = true;
    }
    this.printDec = {
      editTitle: this.appConstants.inputsheetHeader.editTitle,
      printAddHeader: ['genre', 'show Title', 'episode #', 'no of print ads'],
      printAddColumns: ['genreDesc', 'showTitle', 'episode', 'noOfPrints'],
      editAllData: [],
      editShowtitleData: [],
      regenrateData: []
    };
    this.printDec.printaddData = [];
    // this.printDec.printaddData = [{ genreId: '', showTitleID: '', episode: '', noofprintads: '' }];
    // this.dataSource(this.printDec.printaddData);
    // this.dataSource = new MatTableDataSource(this.printDec.printaddData);

    if (this.commonService.editPrintData) {
      this.editData = this.commonService.editPrintData;
      if(this.authManager.userRole != 'ADMIN'){
        this.isAdmin = false;
        this.disButtons = true;
        this.readonlyFlag = true;
        this.disableSelect = true;
      }
      //console.log(editstorage);

    } else {
      this.router.navigate(['../print'], { relativeTo: this.route });
    }


    this.printDec.printAddCreateForm = this.fb.group({
      inputSheetId: new UntypedFormControl(null),
      inputSheetType: this.fb.group({
        inputSheetType: 'Print',
        activeYn: 'Y'
      }),
      surveyAnalyst: ['', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      programmerNotes: ['', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      testDate: [''],
      vendor:[''],
      activeYn: 'Y',
      inputSheetStatus: this.fb.group({
        inputSheetStatus: 'Draft'
      }),
      inputSheetPrintInfo: this.fb.array([this.createItem()]),
    });

    // this.printAddTableRow = this.fb.group({
    //   rows: this.fb.array([])
    // });


    // this.printAddTableRow = this.fb.group({
    //   rows: this.fb.array([])
    // });
    this.sendMessage();
    this.initGroup();
  }
  createItem(): UntypedFormGroup {
    return this.fb.group({
      showTitleSequenceNumber: 1,
      printInfoId: new UntypedFormControl(null),
      primeShowTitle: this.fb.group({
        primeShowTitleId: [''],
        projectType: this.fb.group({
          c2027ProjectTypeId: [''],
        }),
      }),
      noOfPrints: [''],
      episodeSerialNumber: [''],
    });
  }
  createItemSection(): UntypedFormGroup {
    return this.fb.group({
      printInfoId: [],
      showTitle: [''],
      noOfPrints: [''],
      inputsheetId: [''],
      promoSet: this.fb.array([this.section1()])
    })

  }

  section1(): UntypedFormGroup {
    return this.fb.group({
      promoSpotPrintTitle: [''],
    });
  }
  initGroup() {
    this.createItem();
  }

  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../print'], { relativeTo: this.route });
  }

  ngOnInit() {
    this.vendorData=data;

    this.dataSource8 = new MatTableDataSource<InputSheetPrintSrAwarenessSection>(this.srAwarenessList);
    this.dataSource9 = new MatTableDataSource<InputsheetPrintQuesSection>(this.questionstimList);

    const tableData = [];

    let tableEditData;
    this.inputSheetId = this.editData.inputsheetId;
    //this.inputSheetId = 1492;
    // const options = this.inputSheetId ?
    //   {
    //     params: new HttpParams().set('inputsheetId', this.inputSheetId)
    //       .set('inputsheetType', 'PRINT')
    //       .set('id', this.inputSheetId)
    //   } : {};
    const options = this.inputSheetId ? { 'inputsheetId': this.inputSheetId, 'inputsheetType': 'PRINT', 'id': this.inputSheetId } : {};
    //const localURL = 'assets/json/printads.json';
    this.spinnerMTemplate = true;
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.editInputSheetParams}`,
      'GET', options)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
        
          // this.srAwareData = data.Section_Data.srAwareness;
          // this.quesStimData = data.Section_Data.questionStimChanges;
          //  //console.log('data of edit', data);
          //console.log(data);
          this.statusOfPrint = data.inputSheetStatus.inputSheetStatus;
          // console.log('status of print',this.statusOfPrint);
          if (data.inputSheetStatus.inputSheetStatus === 'Submitted') {
            //console.log('submitted print');
            this.adTitleButton = true;
            this.disButtons = true;
            this.disDelete = true;
            this.readonlyFlag = true;
            this.disableSelect = true;
            // console.log('readonly flag');
          }

          this.pilotDate.setValue(data.testDate);
          if(data.vendorId){
            const index=this.vendorData.findIndex(item=>item.vendorId===data.vendorId);
           
            if(index>-1){
              this.vendorSelected=this.vendorData[index].vendorName;
              
              this.vendor.setValue(this.vendorData[index].vendorName);
            }
          }
          this.printDec.printAddCreateForm = this.fb.group({
            inputSheetId: data.inputSheetId,
            inputSheetType: this.fb.group({
              inputSheetType: data.inputSheetType.inputSheetType,
              activeYn: data.inputSheetType.activeYn
            }),
            surveyAnalyst: [data.surveyAnalyst],
            programmerNotes: [data.programmerNotes],
            testDate: [data.testDate],
            vendor:[this.vendorSelected],
            activeYn: 'Y',
            inputSheetStatus: this.fb.group({
              inputSheetStatus: 'Draft'
            }),
            inputSheetPrintInfo: this.fb.array([])
          });
          //console.log(data.inputSheetPrintInfoTemp);
          data.inputSheetPrintInfoTemp.forEach((itemPE, indexPE) => {

            this.printDec.printaddData.push([
              {
                genreId: itemPE.primeShowTitle.projectType.c2027ProjectTypeId,
                showTitleID: itemPE.primeShowTitle.primeShowTitleId,
                episode: itemPE.episodeSerialNumber,
                noofprintads: itemPE.noOfPrints
              }
            ]);
            //console.log(this.printDec.printaddData);
            const dataHeader = this.fb.group({
              showTitleSequenceNumber: parseInt(indexPE + 1),
              printInfoId: [itemPE.printInfoId],
              primeShowTitle: this.fb.group({
                primeShowTitleId: [itemPE.primeShowTitle.primeShowTitleId],
                showTitle: [itemPE.primeShowTitle.showTitle],
                projectType: this.fb.group({
                  c2027ProjectTypeId: [itemPE.primeShowTitle.projectType.c2027ProjectTypeId],

                  c2027ProjectTypeDesc: [itemPE.primeShowTitle.projectType.c2027ProjectTypeDesc]
                }),
              }),
              noOfPrints: [itemPE.noOfPrints],
              episodeSerialNumber: [itemPE.episodeSerialNumber]
            });
            this.dataSource = new MatTableDataSource<InputsheetprintEditHeader>(this.printDec.printaddData);
            (this.printDec.printAddCreateForm.controls.inputSheetPrintInfo as UntypedFormArray).push(dataHeader);
            this.typeid = itemPE.primeShowTitle.primeShowTitleId
            {
              this.commonService.getRequest(`
            ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
                'GET')
                .subscribe(
                  data => {
                    //console.log(data);
                    this.genreList = data;
                  },
                  err => {
                    this.toastorService.warn(err);
                  }
                );
            }

          });

          this.getTotalAds();
          this.spinnerMTemplate = false;
        },
        err => {
          //console.log('show project genres');
          this.toastorService.warn(err);
        }
      )


    this.sectionsData(this.inputSheetId);

    // this.getSection1(this.editData.inputsheetId)
  }
  printaddRow() {
    //console.log('Add Print Row');
    this.showTitleEmpty = false;
    let rows = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    rows.push(this.createItem());
    this.printDec.printaddData.push([
      { genreId: '', showTitleID: '', episode: '', noofprintads: '' }
    ]);
    this.dataSource = new MatTableDataSource(this.printDec.printaddData);
    //console.log(rows.value);
    //console.log(this.printDec.printAddCreateForm.value);
    //console.log('datasource', this.dataSource);
  }
  onSelectedVendor(e){
    this.selectedVendor=e.value;
  }
  onSelectedGenre(e, index) {
    let selectedGenre = e;
    //console.log(selectedGenre);
    //console.log(index);
    this.spinnerMTemplate = true;
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .subscribe(data => {
        //console.log(data);
        this.showTitleList[index] = data;
        this.spinnerMTemplate = false;
      }, error => this.toastorService.warn(error)
      );
  }
  loadSrAware(sraware) {
    if (sraware == null) {
      this.onAddsrawareRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as UntypedFormArray).clear();
      sraware = sraware.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          srawareness: mapdata.description,
          possraware: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          srawareness: new UntypedFormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section8Form.get('section8Array') as UntypedFormArray).push(ArrayData);
      })

      this.srAwarenessList = data;
      //console.log(this.srAwarenessList);
      this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    }
  }

  loadQuesStim(quesstim) {
    if (quesstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section9Form.controls.section9Array as UntypedFormArray).clear();
      quesstim = quesstim.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          quesnstim: mapdata.description,
          posques: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          quesnstim: new UntypedFormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section9Form.get('section9Array') as UntypedFormArray).push(ArrayData);
      })

      this.questionstimList = data;
      //console.log(this.questionstimList);
      this.dataSource9 = new MatTableDataSource(this.questionstimList);
    }

  }

  sectionsData(inputSheetId) {
    // const localURL = 'assets/json/printAllSectionedit.json';

    this.printDec.editAllData = [];
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};
    const options = inputSheetId ? { 'inputSheetId': inputSheetId } : {};
    this.spinnerMTemplate = true;
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAllSection}`,
      'GET', options)
      // this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(data => {

        this.srAwareData = data.Section_Data.section8;
        this.quesStimData = data.Section_Data.section9;
        this.loadSrAware(this.srAwareData);
        this.loadQuesStim(this.quesStimData);
        this.printDec.editAllData = data;
        this.spinnerMTemplate = false;

        //console.log(this.printDec.editAllData);


        // data.Section_Data.addTitle.map(item  => {
        //   this.selected.push(item.promoSpotPrintTitle);
        //   //console.log('item',item);
        //   this.quesStimData = data.Section_Data.questionStimChanges;
        // });

        this.getSection1(this.editData.inputsheetId)
        // if (this.quesStimData == null) {
        //   this.questionstimList = [{ rowid: null, posques: 1, quesnstim: '' }];
        //   //console.log(this.questionstimList)
        //   this.dataSource8 = new MatTableDataSource(this.questionstimList);
        // } else {
        //   this.loadQuesStim(this.quesStimData)
        // }
        // this.spinnerMTemplate = false;
      }, error => this.toastorService.warn(error)
      );

  }


  section1Generate(dataArray) {
    //console.log(dataArray);
    //console.log(this.datasection1HeaderList);
    if (this.datasection1HeaderList) {
      this.spinnerMTemplate = true;

      this.datasection1HeaderList.forEach(printAdsData => {
        //console.log('printAdsData', printAdsData)
        this.spinnerMTemplate = false;

        // this.addAdTitleGroup(printAdsData);
      });
    } else {
      // this.addAdTitleGroup();
    }


  }


  addInputSheetPrintInfo(tableDataIndex: number, data?: any) {

  }
  disableAddTitleButton(formValue, ele, event) {
    if ((formValue.invalid) || (this.statusOfPrint === 'Submitted')) {
      this.adTitleButton = true;
      this.disDelete = true;
      //console.log('hsusauysauyau')
    } else if ((formValue.valid) && (this.statusOfPrint === 'Draft')) {
      this.adTitleButton = false;
      this.disDelete = false;
      ele.setValue(event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));

    }
  }
  onSubmit(formValue, sectionType) {
    // this.putAdTitleGroup();
    // return false;

    if (formValue.invalid) {
      return false;
    } else {
      // this.adTitleButton=false;
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSection}/${sectionType}`,
        'PUT', formValue.value)
        .subscribe(result => {
          this.spinnerMTemplate = false;
          this.toastorService.success('Updated Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        });
    }
  }

  onSectionSubmit(formValue, sectionNumber) {
    if (formValue.invalid) {
      return false;
    } else {
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`,
        'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);
            if (sectionNumber = 'section2') { this.putAfterINDIVadsGroup(result); }
            if (sectionNumber = 'section3') { this.putAfterGroupAds(result); }
            if (sectionNumber = 'section4') { this.putAfterPromoAds(result); }
            if (sectionNumber = 'section5') { this.putaddTag(result); }
            if (sectionNumber = 'section6') { this.putShowDesc(result); }
            if (sectionNumber = 'section7') { this.putVideotoShow(result); }
            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }
  }

  getTotalAds() {
    this.TotalAds = 0;
    const inputSheetPrintInfoArray = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    let indexVal: any = 0;
    inputSheetPrintInfoArray.value.map(
      t => {
        if (t.noOfPrints != 'undefined' && t.noOfPrints != '') {
          this.TotalAds = this.TotalAds + parseInt(t.noOfPrints);
        }
        indexVal = parseInt(indexVal + 1);
        t.showTitleSequenceNumber = indexVal;
      }
    )
  }

  printGenerate() {
    if (this.printDec.printAddCreateForm.invalid) {
      return false;
    } else {
      console.log(this.selectedVendor);
      console.log(this.printDec.printAddCreateForm)
      this.spinnerMTemplate = true;
      this.printDec.printAddCreateForm.get('testDate').setValue(this.datePipe.transform(this.pilotDate.value, 'yyyy/MM/dd'));
      this.printDec.printAddCreateForm.get('vendor').setValue(this.vendor.value);
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result: any) => {
            this.printDec.regenrateData = [];
            this.printDec.regenrateData.push(result);
            while ((this.form.get('adTitle') as UntypedFormArray).length !== 0) {
              (this.form.get('adTitle') as UntypedFormArray).removeAt(0);
            }
            while ((this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }

            while ((this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }

            while ((this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }
            while ((this.formTag.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formTag.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }
            while ((this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }
            while ((this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length !== 0) {
              (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            }

            this.getSection1(result.inputSheetId);
            this.toastorService.success('Updated Successfully');
          },
          err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }
  }

  openconfirmModal(type: any, id: any): void {
    const dialogRef =
      this.dialog.open(ConfirmModalComponent,
        {
          panelClass: 'confirmModal',
          disableClose: true,
          data: {
            msg: (type === 'cancel') ? 'cancel the changes' : (type == 'Submit') ? 'submit? All the unsaved changes will be lost' : ''
          }
        });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === 'edit') {
        this.router.navigate(['../print'], { relativeTo: this.route });
      }
      if (dialogResult === 'Submit') {
        this.createNewPrint(type);
      }
    });
  }
  // printGenerate() {
  //   //console.log('generate');
  //   //console.log(this.printDec.printAddCreateForm.value);
  //   if (this.printDec.printAddCreateForm.invalid) {
  //     return false;
  //   } else {
  //     this.commonService.putRequest(`
  //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
  //       .subscribe(
  //         result => {
  //           //console.log(result);
  //           this.getSection1(result);
  //           this.toastorService.success('Update Successfully');
  //         },
  //         err => {
  //           this.toastorService.warn('Oops!!! The Server was unable to complete your request');
  //         });
  //   }
  // }
  createNewPrint(status) {
    status == 'Draft' ?
      this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Draft' :
      this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Submitted';
    this.spinnerMTemplate = true;
    this.commonService.putRequest(`
          ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.spinnerMTemplate = false;
          this.toastorService.success('Print Ad Submitted Successfully');
          this.router.navigate(['../print'], { relativeTo: this.route });
        },
        err => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        });

  }


  getSection1(inputSheetId) {
    const datasection1ColumnsList: any[] = [];
    this.datasection1HeaderList = [];
    this.printDec.editShowtitleData = [];
    const options = inputSheetId ? { 'inputSheetId': inputSheetId } : {};
    this.spinnerMTemplate = true;
    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      .subscribe(
        datasection => {
          this.printDec.editShowtitleData = datasection;
          this.sectionData = datasection;
          this.showSelected = true;
          this.spinnerMTemplate = false;
        },
        (err) => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        },
        () => {
          this.printEditSectionDisplay();
        }
      );
  }
  printEditSectionDisplay() {
    //console.log(this.printDec.editShowtitleData);
    //console.log(this.printDec.editAllData);
    this.displayedColumns = [];
    if (this.printDec.editShowtitleData) {
      // this.datasection1HeaderList.forEach((data)=>{
      //   this.editAdTitleGroup(data);
      // })
      this.printDec.editShowtitleData.forEach((printAdsDataitem, adindex) => {

        this.displayedColumns.push(printAdsDataitem.primeShowTitle.showTitle);
        //console.log('--------------------------------------------------------');
        //console.log(this.displayedColumns);
        //console.log(printAdsDataitem);

        //console.log(adindex);
        // if(printAdsDataitem.printInfoId  ===printAdsDataitem.printInfoId ) 
        this.editAdTitleGroup(printAdsDataitem);
        this.editAfterINDIVadsGroup(printAdsDataitem);
        this.editAfterGroupAds(printAdsDataitem);
        this.editAfterPromoAds(printAdsDataitem);
        this.editaddTag(printAdsDataitem);
        this.editShowDesc(printAdsDataitem);
        this.editVideotoShow(printAdsDataitem);

      });
    } else {
      // this.addAdTitleGroup();
    }
  }

  editAdTitleGroup(adTitleData?: any) {

    const adTitleFG = this.fb.group({

      printInfoId: [adTitleData ? adTitleData.printInfoId : ''],
      noOfPrints: [adTitleData ? adTitleData.noOfPrints : ''],
      inputSheetId: [adTitleData ? adTitleData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });

    (this.form.get('adTitle') as UntypedFormArray).push(adTitleFG);
    const adTitleDataIndex = (this.form.get('adTitle') as UntypedFormArray).length - 1;

    //console.log(adTitleDataIndex)
    if (!adTitleData) {
      this.editAdTitlePromoSet(adTitleDataIndex);
    } else {

      const editAdTitleData = this.printDec.editAllData['Section_Data'].addTitle;

      if (editAdTitleData == null) {
        //console.log('skip');
      }
      else {
        //console.log('no of print', adTitleData.noOfPrints);
        // for (let i = 0; i < adTitleData.noOfPrints; i++) {

        //   this.datasection1HeaderList[index].promoSet.push('');
        // }
        adTitleData.promoSet.forEach((adTitleItem, adTitleIndex) => {
          // if (editAdTitleData.printInfoId === adTitleItem.printInfoId) {
          //if(adTitleIndex < adTitleData.noOfPrints) 
          // if(adTitleItem.activeYn == 'Y')
          // {
          this.editAdTitlePromoSet(adTitleDataIndex, adTitleItem);
          // }
          // }
        });
      }
    }
    const promoSetLength = (((this.form.controls.adTitle as UntypedFormArray).controls[adTitleDataIndex] as UntypedFormGroup).controls.promoSet as UntypedFormArray).length;
    for (let i = promoSetLength; i < adTitleData.noOfPrints; i++) {

      this.editAdTitlePromoSet(adTitleDataIndex);

    }
    //console.log(this.form.value);

  }
  editAdTitlePromoSet(adTitleDataIndex: number, data?: any) {

    const promoSetFG = this.fb.group({
      promoSpotPrintTitle: [data ? data.promoSpotPrintTitle : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      inputSheetPromoSpotId: [data ? data.inputSheetPromoSpotId : ''],
      sectionId: '616',
      sectionName: 'AD TITLE(s) / FILE NAME(s)',
    });
    // tslint:disable-next-line: max-line-length
    (((this.form.controls.adTitle as UntypedFormArray).controls[adTitleDataIndex] as UntypedFormGroup).controls.promoSet as UntypedFormArray).push(promoSetFG);
    if ((this.form.invalid) || (this.statusOfPrint === 'Submitted')) {
      this.adTitleButton = true;
      this.disDelete = true;
      //console.log('hsusauysauyau')
    } else if ((this.form.valid) && (this.statusOfPrint === 'Draft')) {
      this.adTitleButton = false;
      this.disDelete = false;
    }
  }

  putAdTitleGroup(putAdTitleGroup: any) {
    (this.form.get('adTitle') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetPromoSpotId.value;
        const promoSpotPrintTitle = ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.promoSpotPrintTitle.value;
        putAdTitleGroup.inputSheetPromoSpot.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetPromoSpotId === '' || editData.promoSpotPrintTitle === promoSpotPrintTitle) {
            ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetPromoSpotId.patchValue(editData.inputSheetPromoSpotId);
          }
        });
      });
    });
    //console.log(putAdTitleGroup);
    //console.log(this.form.value);
  }

  editAfterINDIVadsGroup(afterINDIVData?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [afterINDIVData ? afterINDIVData.inputsheetId : ''],
      printInfoId: [afterINDIVData ? afterINDIVData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 617,
        sequenceNumber: 2,
        sectionName: 'AFTER INDIV PRINT AD',
        sectionGroup: ''
      },
    });
    (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterINDIVDataIndex = (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!afterINDIVData) {
      this.editAfterINDIVadsGroupDescriptionList(afterINDIVDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section2;
      if (editAdTitleData == null) {
        //console.log('section2 skip')
        this.editAfterINDIVadsGroupDescriptionList(afterINDIVDataIndex);
      }
      //console.log(editAdTitleData);
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (afterINDIVData.printInfoId === adTitleItem.printInfoId) {
            this.editAfterINDIVadsGroupDescriptionList(afterINDIVDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formAfterINDIVpAds.value);

  }
  AddRowAfterINDIVAds() {
    const afterGroupDataIndex = (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterINDIVpAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.editAfterINDIVadsGroupDescriptionList(addIndex);
      });
  }
  editAfterINDIVadsGroupDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],

      // sectionId: '617',
      // sectionName: 'AFTER INDIV PRINT AD',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  putAfterINDIVadsGroup(putAfterINDIVadsGroup: any) {
    (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterINDIVadsGroup.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterINDIVadsGroup);
    //console.log(this.formAfterINDIVpAds.value);
  }
  editAfterGroupAds(afterGroupData?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [afterGroupData ? afterGroupData.inputsheetId : ''],
      printInfoId: [afterGroupData ? afterGroupData.printInfoId : ''],
      // noOfPrints: [afterGroupData ? afterGroupData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 618,
        sequenceNumber: 3,
        sectionName: 'AFTER GROUP PRINT AD',
        sectionGroup: ''
      },
    });
    (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!afterGroupData) {
      this.editAfterGroupAdsDescriptionList(afterGroupDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section3;
      //console.log(editAdTitleData);
      if (editAdTitleData == null) {
        //console.log('section3 skip')
        this.editAfterGroupAdsDescriptionList(afterGroupDataIndex);
      }
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (afterGroupData.printInfoId === adTitleItem.printInfoId) {
            this.editAfterGroupAdsDescriptionList(afterGroupDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formAfterGroupAds.value);

  }
  AddRowAfterGroupAds() {
    const afterGroupDataIndex = (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterGroupAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.editAfterGroupAdsDescriptionList(addIndex);
      });
  }
  editAfterGroupAdsDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],

      // sectionId: '618',
      // sectionName: 'AFTER PROMO',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  putAfterGroupAds(putAfterGroupAds: any) {
    (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterGroupAds.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterGroupAds);
    //console.log(this.formAfterGroupAds.value);
  }
  editAfterPromoAds(putAfterPromoAds?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [putAfterPromoAds ? putAfterPromoAds.inputsheetId : ''],
      printInfoId: [putAfterPromoAds ? putAfterPromoAds.printInfoId : ''],
      // noOfPrints: [putAfterPromoAds ? putAfterPromoAds.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 619,
        sequenceNumber: 4,
        sectionName: 'AFTER PROMO',
        sectionGroup: ''
      },
    });
    (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const putAfterPromoAdsDataIndex = (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!putAfterPromoAds) {
      this.editAfterPromoAdsDescriptionList(putAfterPromoAdsDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section4;
      //console.log(editAdTitleData);
      if (editAdTitleData == null) {
        //console.log('section4 skip')
        this.editAfterPromoAdsDescriptionList(putAfterPromoAdsDataIndex);

      }
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (putAfterPromoAds.printInfoId === adTitleItem.printInfoId) {
            this.editAfterPromoAdsDescriptionList(putAfterPromoAdsDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formAfterPromoAds.value);

  }
  AddRowAfterPromoAds() {
    const afterGroupDataIndex = (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterPromoAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.editAfterPromoAdsDescriptionList(addIndex);
      });
  }

  editAfterPromoAdsDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],

      // sectionId: '619',
      // sectionName: 'AFTER PROMO',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  putAfterPromoAds(putAfterPromoAds: any) {
    (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterPromoAds.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterPromoAds);
    //console.log(this.formAfterPromoAds.value);
  }
  editaddTag(tagData?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [tagData ? tagData.inputsheetId : ''],
      printInfoId: [tagData ? tagData.printInfoId : ''],
      // noOfPrints: [tagData ? tagData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 620,
        sequenceNumber: 5,
        sectionName: 'TAG LINE(s)',
        sectionGroup: ''
      },
    });
    (this.formTag.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const putaddTagDataIndex = (this.formTag.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!tagData) {
      this.editaddTagDescriptionList(putaddTagDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section5;
      //console.log(editAdTitleData);
      if (editAdTitleData == null) {
        //console.log('section5 skip')
        this.editaddTagDescriptionList(putaddTagDataIndex);

      }
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (tagData.printInfoId === adTitleItem.printInfoId) {
            this.editaddTagDescriptionList(putaddTagDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formTag.value);

  }
  AddRowTag() {
    const afterGroupDataIndex = (this.formTag.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formTag.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.editaddTagDescriptionList(addIndex);
      });
  }
  editaddTagDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"����?+-=]{1,255})$/))]],

      // sectionId: '619',
      // sectionName: 'AFTER PROMO',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);
  }
  putaddTag(putaddTag: any) {
    (this.formTag.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putaddTag.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putaddTag);
    //console.log(this.formTag.value);
  }
  editShowDesc(showDescData?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [showDescData ? showDescData.inputsheetId : ''],
      printInfoId: [showDescData ? showDescData.printInfoId : ''],
      // noOfPrints: [showDescData ? showDescData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 621,
        sequenceNumber: 6,
        sectionName: 'SHOW DESCRIPTION',
        sectionGroup: ''
      },
    });
    (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const putaddTagDataIndex = (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!showDescData) {
      this.editShowDescDescriptionList(putaddTagDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section6;
      //console.log(editAdTitleData);
      if (editAdTitleData == null) {
        //console.log('section6 skip')
        this.editShowDescDescriptionList(putaddTagDataIndex);

      }
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (showDescData.printInfoId === adTitleItem.printInfoId) {
            this.editShowDescDescriptionList(putaddTagDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formTag.value);

  }
  AddRowShowDesc() {
    const afterGroupDataIndex = (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formShowDesc.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.editShowDescDescriptionList(addIndex);
      });
  }
  editShowDescDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],

      // sectionId: '619',
      // sectionName: 'AFTER PROMO',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);
  }
  putShowDesc(putShowDesc: any) {
    (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putShowDesc.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putShowDesc);
    //console.log(this.formShowDesc.value);
  }
  editVideotoShow(videotoshowData?: any) {
    const inputSheetSection = this.fb.group({
      inputSheetId: [videotoshowData ? videotoshowData.inputsheetId : ''],
      printInfoId: [videotoshowData ? videotoshowData.printInfoId : ''],
      // noOfPrints: [videotoshowData ? videotoshowData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 622,
        sequenceNumber: 7,
        sectionName: 'VIDEO TO SHOW',
        sectionGroup: ''
      },
    });
    (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const putaddTagDataIndex = (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!videotoshowData) {
      this.editVideotoShowDescriptionList(putaddTagDataIndex);
    } else {
      const editAdTitleData = this.printDec.editAllData['Section_Data'].section7;
      //console.log(editAdTitleData);
      if (editAdTitleData == null) {
        //console.log('section7 skip')
        this.editVideotoShowDescriptionList(putaddTagDataIndex);

      }
      else {
        editAdTitleData.forEach(adTitleItem => {
          if (videotoshowData.printInfoId === adTitleItem.printInfoId) {
            this.editVideotoShowDescriptionList(putaddTagDataIndex, adTitleItem);
          }
          // 
        });
      }
    }

    //console.log(this.formVideotoShow.value);

  }
  AddRowVideoToShow() {
    const afterGroupDataIndex = (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formVideotoShow.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {

        this.editVideotoShowDescriptionList(addIndex);
      });
  }
  editVideotoShowDescriptionList(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data.description : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],

      // sectionId: '619',
      // sectionName: 'AFTER PROMO',
    });
    // tslint:disable-next-line: max-line-length
    (((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);
  }
  putVideotoShow(putVideotoShow: any) {
    (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putVideotoShow.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putVideotoShow);
    //console.log(this.formVideotoShow.value);
  }

  // removeRow(i) {
  //   this.dataSource = new MatTableDataSource(this.printDec.printaddData.slice(this.printDec.printaddData.length - 1));
  //   this.dataSource = new MatTableDataSource(this.printDec.printaddData)
  // }

  /* Remove Row */
  removeRow(index) {
    //console.log(index);
    let inputSheetPrintInfo = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    //console.log(this.inputSheetId);
    //console.log(inputSheetPrintInfo);
    //console.log(inputSheetPrintInfo.value[index]);
    if (inputSheetPrintInfo.value[index].printInfoId != null) {
      this.spinnerMTemplate = true;
      const printInfoId = inputSheetPrintInfo.value[index].printInfoId;
      // const options = printInfoId ?
      // { params: new HttpParams().set('InputSheetPrintInfoId', printInfoId) } : {};
      const options = printInfoId ?
        { 'InputSheetPrintInfoId': printInfoId } : {};
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/header`,
        'DELETE', options)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          // this.getTable();
          this.spinnerMTemplate = false;
          (this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).removeAt(index);
          // this.loadSection1(this.inputSheetId);
          while ((this.form.get('adTitle') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.form.get('adTitle') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }

          while ((this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }

          while ((this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formTag.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formTag.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          if ((this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).length == 0) {
            this.showTitleEmpty = true;
          }
          this.getSection1(this.inputSheetId);
          this.getTotalAds();
        },
          error => {
            this.spinnerMTemplate = false;
            this.toastorService.warn('Error' + error.message);
          },
          () => {

            this.toastorService.success('Show Title Deleted Successfully!');
          }
        );
    } else {
      (this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).removeAt(index);
      if ((this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).length == 0) {
        this.showTitleEmpty = true;
      }
    }
    let printDatasec: any;
    printDatasec = this.printDec.printaddData.filter((item, i) => i != index);
    this.printDec.printaddData = printDatasec;
    this.dataSource = new MatTableDataSource(this.printDec.printaddData);
    this.getTotalAds();

    //console.log(this.printDec.printaddData);
    //console.log(inputSheetPrintInfo.value);
  }

  updateVal(ele, event) {
    // console.log(ele);
    ele.setValue(event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));
    // console.log(ele.value);
  }

  toggleAll(selection) {
    if (selection) {
      // this.openall = true;
      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {
      // this.openall = false;
      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }

  }


  /** Section 8 - Add, Delete and Save method **/

  onAddsrawareRow() {
    this.onAddsec8 = true;
    let newRow = { rowid: null, possraware: (this.srAwarenessList.length + 1), srawareness: null, upDateFlag: true };
    const row = this.fb.group({
      srawareness: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section8Form.get('section8Array') as UntypedFormArray).push(row);
    this.srAwarenessList.push(newRow);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    //console.log(this.srAwarenessList);
    // this.dataSource8._updateChangeSubscription();
  }

  deletesraware(elm, index) {
    //console.log(elm);
    //console.log(this.srAwarenessList);
    this.srAwarenessList.splice(index, 1);
    (this.section8Form.get('section8Array') as UntypedFormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);

    if (elm.rowid != null || '') {
      // const delSrAware = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delSrAware = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSrAware)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          // this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        })
    }
  }

  updatesrAware(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.srawareness = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  srawareSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm8 = true;
      return false;
    } else {
      //console.log(this.srAwarenessList);
      //console.log(this.srAwarenessList)
      let srawarenessSave = [];
      //console.log(this.srAwarenessList);

      srawarenessSave = this.srAwarenessList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.srawareness,
          sequence: item.possraware,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 623,
            sequenceNumber: 8,
            sectionName: 'SR AWARENESS',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', srawarenessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadSrAware(result);
          // this.getSrAwareness();
          this.toastorService.success('Updated Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        });
    }
  }

  /** Section 9 - Add, Delete and Save method **/

  onAddQuesRow() {
    this.onAddsec9 = true;
    let newRow = { rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null, upDateFlag: true };
    const row = this.fb.group({
      quesnstim: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section9Form.get('section9Array') as UntypedFormArray).push(row);
    this.questionstimList.push(newRow);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource9._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    this.questionstimList.splice(index, 1);
    (this.section9Form.get('section9Array') as UntypedFormArray).removeAt(index);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);

    if (elm.rowid != null || '') {
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delQues = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //  this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  quesSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm9 = true;
      return false;
    } else {
      //console.log(this.questionstimList);
      //console.log(this.questionstimList)
      let quessSave = [];
      //console.log(this.questionstimList);

      quessSave = this.questionstimList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.quesnstim,
          sequence: item.posques,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 624,
            sequenceNumber: 9,
            sectionName: 'QUESTION AND STIM CHANGES',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quessSave)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          this.loadQuesStim(result);
          // this.getQuestionStim();
          this.toastorService.success('Updated Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        });
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }
  deleteCell(cellValue, pindex, cindex) {
    //console.log(cellValue);



    //console.log('child index ', cindex);
    //console.log(cellValue.controls.promoSpotPrintTitle);
    //console.log('p index', pindex);
    //console.log(cellValue);
    // const options = cellValue.value.inputSheetPromoSpotId ?
    //   { params: new HttpParams().set('INPUT_SHEET_PROMO_SPOT_ID', cellValue.value.inputSheetPromoSpotId) } : {};
    //   const options = cellValue.value.inputSheetPromoSpotId ?
    //     { 'INPUT_SHEET_PROMO_SPOT_ID' : cellValue.value.inputSheetPromoSpotId } : {};

    //   this.commonService.deleteRequest(`
    // ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAdsDashboard}/${this.appConstants.inputSheetSections}/${this.appConstants.inputSheetadTitleDelete}`,
    //     'DELETE', options)
    //     .subscribe(
    //       datasection => {
    //         //console.log(datasection);
    // cellValue.controls.promoSpotPrintTitle.reset();
    //  this.putAdTitleGroup(datasection);
    cellValue.controls.promoSpotPrintTitle.reset();
    this.toastorService.success('Deleted Successfully');
    //   },
    //   (err) => {
    //     this.toastorService.warn('Oops!!! The Server was unable to complete your request');
    //   },
    // );
  }

  deleteSections(cellValue) {
    //console.log(cellValue);
    //  //console.log(cellValue.value.inputSheetPromoSpotId);
    // const options = cellValue.value.inputSheetUserSectionId ?
    //   { params: new HttpParams().set('INPUT_SHEET_USER_SECTION_ID', cellValue.value.inputSheetUserSectionId) } : {};
    //console.log(cellValue.value.inputSheetUserSectionId);
    if (cellValue.value.inputSheetUserSectionId) {
      const options = cellValue.value.inputSheetUserSectionId ?
        { 'INPUT_SHEET_USER_SECTION_ID': cellValue.value.inputSheetUserSectionId } : {};
      this.commonService.deleteRequest(`
  ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPrintAdsDashboard}/${this.appConstants.inputSheetSections}/${this.appConstants.inputSheetSectionsDelete}`,
        'DELETE', options)
        .subscribe(
          datasection => {
            // console.log(datasection);
            // if(sectionNumber='section2'){this.putAfterINDIVadsGroup(datasection);}
            // if(sectionNumber='section3'){this.putAfterGroupAds(datasection);}
            // if(sectionNumber='section4'){this.putAfterPromoAds(datasection);}
            // if(sectionNumber='section5'){this.putaddTag(datasection);}
            // if(sectionNumber='section6'){this.putShowDesc(datasection);}
            // if(sectionNumber='section7'){this.putVideotoShow(datasection);}
            cellValue.controls.description.reset();
            this.toastorService.success('Deleted Successfully');
          },
          (err) => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          },
        );
    }
  }

  public hasErrorsection8Form = (index, errorName: string) => {
    return ((this.section8Form.controls.section8Array as UntypedFormArray).controls[index] as UntypedFormGroup).controls.srawareness.hasError(errorName);
  }
  public hasErrorsection9Form = (index, errorName: string) => {
    return ((this.section9Form.controls.section9Array as UntypedFormArray).controls[index] as UntypedFormGroup).controls.quesnstim.hasError(errorName);
  }
  videoControls() {
    return (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).controls;
  }
  showDecriptionControl() {
    return (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).controls;
  }
  tagLineControls() {
    return (this.formTag.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterpromoAdsControls() {
    return (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterGroupAdsControl() {
    return (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterINIDIVpAdsControls() {
    return (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  adtitleControls() {
    return (this.form.get('adTitle') as UntypedFormArray).controls;
  }

}
