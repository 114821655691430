import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-choice',
  template: `
  <div class="confirm-modal">
  <p> Are you sure you want to delete the {{data}}? </p>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
</div>`
})
export class SingleConfirmModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SingleConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    //  console.log('dialog', data);
     }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
