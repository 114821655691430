import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shared-list-dashboard',
  templateUrl: './shared-list-dashboard.component.html',
  styleUrls: ['./shared-list-dashboard.component.scss']
})
export class SharedListDashboardComponent implements OnInit ,OnChanges{

  @Output() editEvent = new EventEmitter();
  // tslint:disable-next-line: no-output-rename
  @Output('closed') closedStream: EventEmitter<void>;
  // tslint:disable-next-line: no-output-rename
  @Output('opened') openedStream: EventEmitter<void>;
  public globalResponse: any;
  spinnerChoice: boolean;
  appConstants = AppConfig.settings;
  questionConstants: any;
  dataTableHeader: any;
  dataTableColumns: any;
  dataTableFillterColumns: any;
  questionText: any;
  displayedFilterdataTableColumns: string[];
  dataTableSource: any = [];
  connotationControl = new UntypedFormControl();
  categoryControl = new UntypedFormControl();
  connotationList: any[];
  percentileList: any[];
  summaryReportList: any[];
  statusList: any[];
  categoryList: any;
  dataTableGrid: any = [];
  dataTableGrid1: any = [];
  public url = 'question';
  pipe: DatePipe;
  // public categoryControl: FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild('elementToFocus', { static: true }) _input: ElementRef;
  @Input() templateInput;
  @Input() isEditClick;
  constructor() {

  }

  ngOnInit() {
    //console.log('Inside Dashboard', this.templateInput);

  }


  ngOnChanges() {

    // create header using child_id
    //console.log('Inside Dashboard' + this.templateInput);
  }


}
