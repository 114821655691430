import { Component, OnInit, ViewChild, Output, EventEmitter, LOCALE_ID, Inject } from '@angular/core';
//import { SatDatepicker } from 'saturn-datepicker';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChoicedashboardDatatableService } from 'src/app/services/choicedashboard-datatable.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-daterange-picker-component',
  // templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  template: `
  <mat-form-field appearance="fill">
  <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="emitDates()">
    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="emitDates()">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
`
})
export class DaterangePickerComponent implements OnInit {
  /* @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>; */
  @Output() emitRange: EventEmitter<any> = new EventEmitter<any>();
  now: Date = new Date();
  date = new UntypedFormControl('');
  delay;
  dateRange: any;

  constructor(
    private questionservice: QuestiondashboardDatatableService,
    private choiceservice: ChoicedashboardDatatableService,
    private awsCommonService: AwsCommonService, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl()
    });
    this.questionservice.clearDate
      .subscribe(data =>{
        //this.date.setValue('');
        this.dateRange.reset();
      });

    this.choiceservice.clearDate
      .subscribe(data =>{
        //this.date.setValue('');
        this.dateRange.reset();
      });

    this.awsCommonService.clearDate.subscribe(clearDate => {
      //this.date.setValue('');
      this.dateRange.reset();
    });
  }

  emitDates() {
    console.log(this.dateRange);
    const start = formatDate(this.dateRange.value.start, 'MM/dd/yyyy' ,this.locale); //this.dateInput.beginDate;
    const end = formatDate(this.dateRange.value.end, 'MM/dd/yyyy' ,this.locale); //this.dateInput.endDate;
    const daterange = { 'start': start, 'end': end };

    this.emitRange.emit(daterange);
  }

}
