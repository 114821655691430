<div class="container primeNewModal">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>

        <form [formGroup]="editQuesForm" autocomplete="off" novalidate (ngSubmit)="updateQuestion()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Question Category</label>
                    <input matInput type="text" class="form-control" formControlName="questionCategory"
                        [matAutocomplete]="auto" maxlength="255">
                        <span *ngIf="!editQuesForm.controls.questionCategory.value"><i class="bottomArrow"></i></span>
                        <button class="inputCloseIcon" mat-button *ngIf="editQuesForm.controls.questionCategory.value"
                            matSuffix mat-icon-button aria-label="Clear"
                            (click)="editQuesForm.controls.questionCategory.setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="categoryDisplayFn">
                        <mat-option *ngFor="let category of filteredCategory|async" [value]="category">
                            {{category.questionCategoryDesc}}</mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="hasError('questionCategory', 'required')">Please select category or search the
                        category</mat-error>
                    <mat-error *ngIf="hasError('questionCategory', 'incorrect')">Please enter valid input for category
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Generic Question</label>
                    <!-- <mat-label>{{ label.genericQuestion }}</mat-label> -->
                    <textarea matInput placeholder="Enter Generic Question" class="form-control"
                        formControlName="genericText" #genericQuestion (focus)='true'> </textarea>
                    <mat-error *ngIf="hasError('genericText', 'required')">Please enter a generic question
                    </mat-error>
                    <mat-error *ngIf="hasError('genericText', 'pattern')">Please enter valid input for generic question
                    </mat-error>
                    <mat-error *ngIf="hasError('genericText', 'notUnique')">Already exists in our database
                    </mat-error>
                </mat-form-field>
                <div class="slider-container connotation-container">
                    <label class="font-13 required">{{ label.connotation}} </label>
                    <mat-radio-group formControlName="questionPosNegFlag" fxLayout="column">
                        <mat-radio-button *ngFor="let op of posNegFlag" [value]="op.data" name="opList"
                            [ngStyle]="{color: op.color}">{{ op.value}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="hasError('questionPosNegFlag', 'required')">{{validationMsg}}
                        {{label.connotation}}</mat-error>
                </div>
                <div class="slider-container percentileToggle">
                    <label>{{label.percentile}}</label>
                    <input type="hidden" class="form-control" formControlName="percentileYn">
                    <mat-slide-toggle ngDefaultControl (change)="onChange('percentileYn', $event)"
                        [checked]="data.percentileYn=== 'Y' ? true : false"><span class="toggleYes">Yes</span>
                        <span class="toggleNo">No</span></mat-slide-toggle>
                </div>

                <div class="slider-container summaryToggle">
                    <label>{{ label.summaryReport }} </label>
                    <input type="hidden" class="form-control" formControlName="summRptYn">
                    <mat-slide-toggle ngDefaultControl (change)="onChange('summRptYn', $event)"
                        [checked]="data.summRptYn=== 'Y' ? true : false"><span class="toggleYes">Yes</span>
                        <span class="toggleNo">No</span></mat-slide-toggle>
                </div>
                <div class="slider-container activeToggle">
                    <label>{{ label.status}}</label>
                    <input type="hidden" formControlName="activeYn">
                    <mat-slide-toggle (change)="onChange('activeYn', $event)"
                        [checked]="data.activeYn=== 'Y' ? true : false"><span class="toggleYes">Active</span>
                        <span class="toggleNo">Inactive</span></mat-slide-toggle>
                    <!-- <label class="rightLabel">Yes</label>
                    <label class="leftLabel">No</label> -->
                </div>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13">{{label.QuestionSharedDetails}}</label>
                    <!-- <label class="font-13">{{ label.questionSharedList }}</label> -->
                    <input matInput type="text" class="form-control" formControlName="questionSharedList" [matAutocomplete]="autoQuestion" placeholder="Select Question Shared List">
                        <span *ngIf="!editQuesForm.controls.questionSharedList.value"><i class="bottomArrow"></i></span>
                        <button class="inputCloseIcon" mat-button *ngIf="editQuesForm.controls.questionSharedList.value"
                            matSuffix mat-icon-button aria-label="Clear"
                            (click)="editQuesForm.controls.questionSharedList.setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>
                    <mat-autocomplete #autoQuestion="matAutocomplete" [displayWith]="sharedDisplayFn">
                        <mat-option *ngFor="let sharedList of filteredSharedList|async" [value]="sharedList">
                            {{sharedList.sharedListName}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <!-- <label class="font-13 required"> Generic Question</label> -->

                    <textarea matInput placeholder="Shared List Question Long Text" class="form-control"
                        formControlName="questionLongText"> </textarea>


                </mat-form-field>

                <p class="float-left"> <span class="infoIcon font-10">i</span><span class="font-11">To Create New
                        Sharedlist, Please visit to 'Sharedlist' Page </span></p>

            </mat-card-content>
            <div class="spinner" *ngIf="isLoadingSave">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions align="center">
                <input type="hidden" formControlName="questionLibraryId" />
                <button mat-raised-button color="primary" type="submit">Update</button>
                <button type="button" mat-raised-button color="warn" (click)="openLink($event)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>