<div class="question-dashboard-container">
  <div class="spinnerOverlay"  *ngIf="spinnerChoice"></div>
  <div class="spinner" *ngIf="spinnerChoice">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
  </div>
  <!-- matSortDisableClear -->

  <cdk-virtual-scroll-viewport class="demo-viewport demo-table-container" itemSize="30">
    <!-- <table mat-vs-table [dataSource]="matTableDataSource" matSort > -->
    <table mat-table [dataSource]="dataTableSource" matSort *ngIf="!spinnerChoice" matSortDisableClear
      class="mat-elevation-z8 question-datatable" [multiTemplateDataRows]="true">

      <ng-container matColumnDef='choiceLibraryDesc'>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.choiceLibraryDesc | uppercase }} <i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.choiceLibraryDesc}} </td>
        <td mat-footer-cell *matFooterCellDef><span *ngIf="dataTableGridTotalCount; else elseBlock">Showing
            {{dataTableGridTotalCount}} Record(s)</span>
          <ng-template #elseBlock>{{dataTableErrorBlock}}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="posNegFlag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.posNegFlag | uppercase }} <i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{color: (element.posNegFlag=='P' || element.posNegFlag=='Y')? '#09B930':(element.posNegFlag=='N')?'#CE0B0B':''}">
          {{(element.posNegFlag=='P' || element.posNegFlag=='Y' )?"Positive":(element.posNegFlag=='N')?"Negative":(element.posNegFlag=='U')?"Neutral":""}}
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="activeYn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.activeYn | uppercase }} <i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element">
          {{(element.activeYn=='Y')?"Active":"Inactive"}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.createdBy | uppercase }}<i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="createdDt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.createdDt | uppercase }}<i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element">{{element.createdDt | date: 'MM/dd/yyyy'}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedDt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.lastUpdatedDt | uppercase }}<i
            class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.lastUpdatedDt| date: 'MM/dd/yyyy' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="surveylink">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.surveylink | uppercase }}</th>
        <td mat-cell *matCellDef="let element">
          <!-- <span *ngIf="element.isSurveyExists; else elseBlock"><a (click)="openChoiceDialog(element.choiceLibraryId)">
                {{ ViewSurveyData(element.choiceLibraryId) }}</a></span>
                <ng-template #elseBlock></ng-template> -->
          <a (click)="openChoiceDialog(element.choiceLibraryId)"> {{ ViewSurveyData(element.choiceLibraryId) }} </a>
          <!-- {{ ViewSurveyData(element.choiceLibraryId) }} -->
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>{{ dataTableHeader.action | uppercase }} </th>
        <td mat-cell *matCellDef="let element"><i *ngIf="isAdmin" class="editIcon" (click)="startEdit(element)"
            data-toggle="modal" data-target="#myModal2"></i> </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Filter section Start-->
      <ng-container matColumnDef="fchoiceLibraryDesc">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Search Here" [formControl]="choiceLibraryDescControl"
              autocomplete="off">
            <i class="autocomplete-search"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="fposNegFlag">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="choicePosNegFlagControl" placeholder="All">
              <mat-option *ngFor="let choicePosNegFlag of choicePosNegFlagList"
                [value]="choicePosNegFlag.choicePosNegFlagId">{{choicePosNegFlag.choicePosNegFlagDesc}}
              </mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="factiveYn">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="choiceactiveYnControl" placeholder="All">
              <mat-option *ngFor="let activeYnList of choiceactiveYnList" [value]="activeYnList.activeId">
                {{activeYnList.activeDesc}}
              </mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="fcreatedBy">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Search Here" [formControl]="createdByControl" autocomplete="off">
            <i class="autocomplete-search"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="fcreatedDt">
        <th mat-header-cell *matHeaderCellDef>
          <span matTooltip="{{createdate}}">
            <app-daterange-picker-component (emitRange)="getDates($event, 'createdDt')">
            </app-daterange-picker-component>
          </span>
        </th>
      </ng-container>
      <ng-container matColumnDef="flastUpdatedDt">
        <th mat-header-cell *matHeaderCellDef>
          <span matTooltip="{{lastdate}}">
            <app-daterange-picker-component (emitRange)="getDates($event, 'lastUpdatedDt')">
            </app-daterange-picker-component>
          </span>
        </th>
      </ng-container>
      <ng-container matColumnDef="fsurveylink">
        <th mat-header-cell *matHeaderCellDef>
        </th>
      </ng-container>
      <ng-container matColumnDef="faction">
        <th mat-header-cell *matHeaderCellDef>
          <button class="clearFilter" mat-stroked-button color="primary" (click)="clearChoiceTable()">Clear All
            Filters</button>
        </th>
      </ng-container>

      <!-- Filter Section End -->

      <tr mat-header-row *matHeaderRowDef="dataTableColumns; sticky: true;" class="table-header-section"></tr>
      <tr mat-header-row *matHeaderRowDef="dataTableFillterColumns; sticky: true;" class="table-filter-section"></tr>
      <tr mat-row *matRowDef="let row; columns: dataTableColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="dataTableColumns; sticky: true" class="table-footer-section"></tr>

    </table>
    <!-- </table> -->
  </cdk-virtual-scroll-viewport>
</div>