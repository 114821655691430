<!-- <p>grid Question  {{sharedQuestionDetail | json }}</p> -->
<div class="spinnerOverlay" *ngIf="spinnerTemplate"> </div>
<div class="spinner" *ngIf="spinnerTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<div #div class="container primeNewModal sharedQues-QuesView multiQues">

    <!---->
    <!-- {{childInput | json}}  -->
    <!--  <form *ngIf="!spinnerTemplate" [formGroup]="editTempSharedQuesForm" autocomplete="off" class="example-form" novalidate> -->
    <form *ngIf="!spinnerTemplate" [formGroup]="editTempSharedQuesForm" autocomplete="off" class="example-form" novalidate>
        <mat-card>
            <mat-card-title>
                <mat-card-content>
                    <mat-form-field class="example-full-width">
                        <input type="hidden" class="form-control sharedListName" formControlName="questionSharedListId">
                        <input matInput type="text" title="{{this.editTempSharedQuesForm.controls.sharedListName.value}}" class="form-control sharedListName" formControlName="sharedListName" [readonly]="!isEdit">
                        <span (click)="openconfirmModal('Shared List', childInput.id)"><i
                                  class="deleteIcon alignDelete-textbox marginT15"></i></span>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'required')">Please Enter Shared Question Name
                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'pattern')">Please enter valid Question name
                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'notUnique')">Choice Name Already Exists
                        </mat-error>

                    </mat-form-field>
                    <mat-form-field class="example-full-width sharedreadonlyDescription">
                        <input matInput type="text" title="{{this.editTempSharedQuesForm.controls.sharedListDescription.value}}" class="form-control sharedListDescription" formControlName="sharedListDescription" [readonly]="!isEdit">
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'required')">Please Enter Question Description

                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'pattern')">Please Enter Question Description
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card-title>
        </mat-card>
        <div class="sharedQuestionTable">
            <span *ngIf="(!dataSource || dataSource.length==0) && (!sharedListChoices || sharedListChoices.length==0)" class="font-13 float-left blue addQuestion" (click)="openAddQuestion()">
                        <i class="addnewIcon"></i>
                    <span>Add Questions</span></span>
            <span class="font-13 float-right addChoice blue" (click)="openAddChoice()"><i class="addnewIcon float-left"></i>
                  <span>Add Choice</span> </span>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 " *ngIf="(dataSource && dataSource.length>0) || (sharedListChoices && sharedListChoices.length>0)" #table cdkDropListGroup>
                <ng-container [cdkColumnDef]="column.columnDef" *ngFor="let column of columns;let i=index">
                    <th mat-header-cell *matHeaderCellDef 
                    cdkDropList
                    cdkDropListLockAxis="x"
                    cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="dropListDropped($event, i)"
                    cdkDrag  
                    [cdkDragDisabled]="!isEdit "                 
                    (cdkDragStarted)="dragStarted($event, i)"
                    [cdkDragData]="{name: column.columnDef, columIndex: i}"
                    
                    > {{column.header }} </th>
                    <td mat-cell *matCellDef="let element;let j=index;" cdkDropList
                    cdkDropListLockAxis="y"
                    cdkDropListOrientation="vertical"
                   [cdkDropListData]="dataSource"
                    cdkDrag                    
                    (cdkDragStarted)="dragStarted($event,j)"
                    (cdkDropListDropped)="dropTable($event,j)"
                                        
                    > 
          
                        <div *ngIf="column.columnDef== 'action'">
                            <mat-checkbox #ref (click)="$event.stopPropagation()" (change)="$event ? selectRow($event, element) : null"></mat-checkbox>
                        </div>
                        <div *ngIf="column.columnDef == 'name'"> {{element['questionLongText']}}</div>
                        <div *ngIf="column.columnDef!= 'name' && column.columnDef != 'action'">
                            <mat-radio-button disabled></mat-radio-button>
                        </div>
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="column.punchCol" *ngFor="let column of columns; let i=index;" formArrayName="punchCodes">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="(column.columnDef === 'name'); then content else other_content"></div>
                        <ng-template #content>
                            <span class="font-13 float-left blue addQuestion" (click)="openAddQuestion()">
                                  <i class="addnewIcon"></i>
                              <span>Add Questions</span></span>
                        </ng-template>
                        <ng-template #other_content>
                            <button *ngIf="(this.deleteButton || column.choiceId)" (click)="(column.choiceId)? openconfirmModal('choice',column.choiceId) : openconfirmModal('questions','')"><i class="deleteIcon"></i></button>
                            <button *ngIf="!this.deleteButton && !(column.choiceId)"><i class="deleteIcon"></i></button>
                        </ng-template>
                        <div *ngIf="column.punchCode" [formGroupName]="i-2">
                            <input type="hidden" class="form-control punchCodeList" formControlName="sharedListChoiceId" value="{{column.choiceId}}">
                            <input type="hidden" class="form-control punchCodeList readonly" formControlName="choiceLibraryId">
                            <input type="hidden" class="form-control punchCodeList readonly" formControlName="choiceSequenceNumber">
                            <input matInput type="text" class="form-control punchCodeList" formControlName="punchCode" value="{{column.punchCode}}" [readonly]="!isEdit" #p (focus)="somethingChanged(p.value,i-2 )" OnlyNumber="true" required>
                        </div>
                    </th>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayPunchCode ;sticky: true;" class="table-edit-section"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true; " class="table-header-section"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" cdkDrag [cdkDragData]="row"></tr>
            </table>

        </div>
    </form>
</div>