<div class="col-md-11 contentSection" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">{{dashboardTitle}}</h5>
        <div class="float-right createSection" *ngIf="isAdmin">
            <span *ngIf="tooltip" matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue">
                    <span>{{createText}}</span>
                    <i class="addnewIcon"></i>
                </p>
                <i class="excelIcon"></i>
            </span>
            <span *ngIf="!tooltip">
                <p class="float-left font-13 blue" (click)="openCreateQuestion()">
                    <span>{{createText}}</span>
                    <i class="addnewIcon"></i>
                </p>
                <p class="float-left ml-3">
                    <i class="excelIcon" (click)="exportExcel()"></i>
                </p>
            </span>
        </div>
    </div>
    <div class="col-lg-12 row">
        <app-question-dashboard (editEvent)="isAdmin? editForm($event):''"></app-question-dashboard>
    </div>
</div>