<div class="spinnerOverlay" *ngIf="spinnerSTemplate"> </div>
<div class="spinner" *ngIf="spinnerSTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<div class="shared-list-sidenav templateSideNav" [ngClass]="{'expandPanel': expandClass}">

    <div class="shared-list-tap" *ngIf="!spinnerSTemplate">
        <!-- 
    <div class="shared-list-tap">-->
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="Shared Questions">
                <div *ngIf="sharedQuestion.length>0">
                    <div class="col-Mat">
                        <div cdkDropList class="example-list SharedQ" (cdkDropListDropped)="drop($event)" cdkDragHandle [cdkDropListDisabled]="!isAdmin">

                            <div class="example-box" *ngFor="let data of sharedQuestion; let i=index; " cdkDrag (click)="onSharedQuestionEmit(data, null)" [ngClass]="{'createQuesselected': selectedIndex == data.id, 'dot': data.isVariableFound==='Y'}" title="{{data.name}}">

                                <div class="i">
                                    <span>{{data.name}} </span>
                                    <div class="example-handle">
                                        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                        </svg>
                                    </div>
                                    <div *ngIf="isAdmin">
                                        <button mat-button [matMenuTriggerFor]="belowMenu" (click)="$event.stopPropagation();">...</button>
                                        <mat-menu class="templateMenuDropdown" #belowMenu="matMenu" yPosition="below" xPosition="before">
                                            <!-- <button mat-menu-item (click)="copyItem($event, data)">Copy</button> -->
                                            <button mat-menu-item (click)="pasteItem($event, data)">Copy & Paste</button>
                                            <button mat-menu-item (click)="openconfirmModal('sharedList', data)">Delete</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="((sharedQuestion)? sharedQuestion.length<=0: '') && (!spinnerMTemplate)" class="noRecord">No Shared Questions Found</div>

            </mat-tab>

            <mat-tab label="Shared Choices">

                <div cdkDropList class="example-list" (cdkDropListDropped)="dropTable($event)" *ngIf="sharedChoice.length>0">
                    <div class="example-box" *ngFor="let data of sharedChoice" title="{{data.sharedListName}}">
                        <span> {{data.sharedListName}} </span>

                    </div>
                </div>
                <div *ngIf="(sharedChoice)? sharedChoice.length<=0: '' && (!spinnerMTemplate)" class="noRecord">No Shared Choices Found</div>
            </mat-tab>

        </mat-tab-group>

        <div class="createSection" *ngIf="isAdmin">
            <span>
                <p  *ngIf="!this.isEdit" class="float-left font-13 blue" (click)="onReArrange()">
                        <span>Re-Arrange </span>
            </p>
            </span>
            <span>
                <p  *ngIf="!this.isEdit" class="float-left font-13 blue" (click)="openCreateSharedQuestion()">
                        <span>Add Shared Question</span>
            <i class="addnewIcon"></i>
            </p>
            </span>

        </div>

    </div>
</div>