import { Injectable } from '@angular/core';
import { CanActivate, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppConfig } from '../app.config';
import { AwsService } from '../services/aws.service';
import * as aws4 from "ngx-aws4";
import { HttpClient } from '@angular/common/http';
import { reject } from 'q';
import { AwsCommonService } from '../services/aws-common.service';
import { takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class AuthManager implements CanActivate {
  map: any;
  indexAccessToken: number;
  indexAuthCode: number;
  indexIdToken: number;
  indexTokenType: number;
  accessTokenStr: string;
  authCodeStr: string;
  idTokenStr: string;
  urlLength: number;
  firstName: string;
  lastName: string;
  access_code: string = '-1';
  id_token: string = '-1';
  state_str: any;
  public dynSSOId = '-1';
  secretAccessKey: any;
  accessKeyId: any;
  sessionToken: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // Initiate variables for storing user info
  // var dynIpAddress = 'undefined';
  public dynEmail = '';
  public dynFirstName = '';
  public dynLastName = '';
  public dynDeptName = '';
  public userRole = 'USER';
  userGroups = '';
  dynBusiness = '';
  dynSubBusiness = '';
  dynEmpTitle = '';
  dynTimeToExpire = '';
  access_token = '-1';
  refresh_token = '';
  authType = '';
  storedAccessTokenIssueDate = sessionStorage.getItem('access_token_issuedate');
  accessTokenIssueDate = new Date(this.storedAccessTokenIssueDate);
  currentDate = new Date();
  tokenTimeoutMins = 120 - 5;
  accessTokenTimeoutDate: Date = this.accessTokenIssueDate;
  baseurl = AppConfig.settings.appUrl;
  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;
  referenceUrl: string;
  currentUser: boolean = false;
  state_key: any;
  nonce_key: any;

  // buffer for timing out

  userDetailsFromDb: any;

  constructor(private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute, private awsService: AwsService, private http: HttpClient, private awsCommonService: AwsCommonService) {
    this.indexAccessToken = window.location.href.indexOf('access_token');
    this.indexAuthCode = window.location.href.indexOf('code');
    this.indexTokenType = window.location.href.indexOf('&token_type');
    this.indexIdToken = window.location.href.indexOf('id_token');
    this.state_str = window.location.href.indexOf('&state');
    this.accessTokenStr = 'access_token=';
    this.authCodeStr = 'code=';
    this.idTokenStr = 'id_token=';

    if (sessionStorage.getItem('sessionToken') != null) {
      this.dynSSOId = sessionStorage.getItem('SSO');
      this.dynFirstName = sessionStorage.getItem('firstName');
      this.dynLastName = sessionStorage.getItem('lastName');
      this.userRole = sessionStorage.getItem('userRole');
    }

    if (this.accessTokenTimeoutDate != null) {
      this.accessTokenTimeoutDate.setMinutes(this.accessTokenTimeoutDate.getMinutes() + this.tokenTimeoutMins);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let self = this;
    if (!(window.location.href.indexOf('deelf0w3eptlt') > 0)) {
		
		//	if(!(window.location.href.indexOf('d3ml6kk09c3dfk') > 0)){
      // debugger;
      if (this.indexIdToken > -1) {
        // debugger;
        this.id_token = window.location.href.substring(this.indexIdToken + this.idTokenStr.length, this.state_str);

        if (!sessionStorage.getItem('sessionToken')) {
          sessionStorage.setItem('id_token', this.id_token);
          var result = this.authService.getDecodedAccessToken(sessionStorage.getItem('id_token'));
          //console.log(result.sub);
          self.dynSSOId = result.sub;
          sessionStorage.setItem('SSO', self.dynSSOId);
          return this.awsService.authenticatefss(sessionStorage.getItem('id_token')).then(result => {
            // this.accessKeyId = result.accessKeyId;
            // this.secretAccessKey = result.secretAccessKey;
            // this.sessionToken = result.sessionToken;
            // sessionStorage.setItem('access_token_issuedate', this.currentDate.toString())
            sessionStorage.setItem('secretAccessKey', result.secretAccessKey);
            sessionStorage.setItem('accessKeyId', result.accessKeyId);
            sessionStorage.setItem('sessionToken', result.sessionToken);
            const options = self.dynSSOId ? { 'SsoId': self.dynSSOId } : {};
            // debugger;
            return this.awsCommonService.getApiRequest(`${this.appConstants.serverUrl}/auth/login`, 'GET', options, options).pipe(takeUntil(this.destroy$)).subscribe(
              (result: any) => {
                // debugger;
                console.log(result)
                if (result != null) {
                  self.dynFirstName = result.firstName;
                  self.dynLastName = result.lastName;
                  self.userRole = result.role;
                  // self.userRole = 'ADMIN';
                  sessionStorage.setItem('firstName', self.dynFirstName);
                  sessionStorage.setItem('lastName', self.dynLastName);
                  sessionStorage.setItem('userRole', self.userRole);
                  this.router.navigate(['dashboard']);
                } else {
                  this.logoutUser();
                }
              }, err => {
                this.logoutUser();
              }
            );
          });
        } else {
          // this.router.navigate(['dashboard']);
          return true;
        }

      } else if ((this.dynSSOId === '-1' && !sessionStorage.getItem('id_token')) || (this.storedAccessTokenIssueDate !== null && this.currentDate >= this.accessTokenTimeoutDate)) {
        sessionStorage.setItem('referenceUrl', window.location.href);
        this.state_key = this.authService.generateUUID();
        this.nonce_key = this.authService.generateUUID();
        window.location.replace(AppConfig.settings.authUrl + 'authorization.oauth2?client_id=' + AppConfig.settings.clientId + '&redirect_uri=' + this.baseurl + '&response_type=id_token token&scope=openid&state=' + this.state_key + '&nonce=' + this.nonce_key + '&pfidpadapterid=' + AppConfig.settings.pfidpadapterId + '');

      } else if (sessionStorage.getItem('sessionToken') && this.dynSSOId != '-1') {
        //// debugger;
        if (state.url == '/') {
          this.router.navigate(['dashboard']);
        }
        return true;
      } else if (this.dynSSOId === '-1' && !sessionStorage.getItem('sessionToken')) {
        this.router.navigate(['']);
        return new Observable<any>();
      } else {
        // debugger;
        return new Observable<any>();
      }
    } else {
      this.accessKeyId = '12334545';
      this.secretAccessKey = 'agawgewag';
      this.sessionToken = 'gewagew';
      this.dynSSOId = '501230798';
      this.id_token = 'T5rqNRmn0qjP3inZGhSCzCtimZxl&id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6Iks5a1J4cDRjS2FCeXRZNFRzQUVvRUgifQ.eyJzdWIiOiIyMDY2NDEyOTUiLCJhdWQiOiJQcmltZVN1cnZleVRvb2wiLCJqdGkiOiJaUkZHVHhyNzJnNTBFNnVlNE5BZTdNIiwiaXNzIjoiaHR0cHM6XC9cL2Zzcy5zdGcuaW5iY3UuY29tXC9mc3MiLCJpYXQiOjE1ODIzNjQ5NTUsImV4cCI6MTU4MjM3MjE1NSwibm9uY2UiOiJhODUzN2I1MzQ0NTE0YjU2YTY5ZTY2ZDlmNjNlZWUyNCIsImF0X2hhc2giOiJvaGNVQjRxeXBBT2lKamUtY0JyZHd3In0.RNctasrUK23Rsq6bK2E2w9AUhhFDXzpqMX5xRrh87VZxNExEQOFbyl4fJzGkREtLDQavm47anvO7z43DPS9R3wkVxzWZw654_K-gb9fKkO3n4am9HsfpQYShtDE6l6mktsrQ5eNghkMC1Db89CFJeVPrMXImiUu9lBI9DJLro8F2gL9unFZlL4C5VgxROw1u5E3Fl1DclEkHvBmbQkA-JtV1Jp2kj6yhnnx1ihYdYf0H28-UEnANDu5snOle9pRdbJFgEelZIM_mtsPFHBQCVYszDHjrG_LyC002wGkW7XyC-y6QQJgExWhcz3S6zIGaZwTtC1pakKAT08LhOqpwqA';
      this.dynFirstName = 'test';
      this.dynLastName = 'test1';
      this.userRole = 'ADMIN';
      sessionStorage.setItem('secretAccessKey', this.secretAccessKey);
      sessionStorage.setItem('accessKeyId', this.accessKeyId);
      sessionStorage.setItem('sessionToken', this.sessionToken);
      sessionStorage.setItem('firstName', this.dynFirstName);
      sessionStorage.setItem('lastName', this.dynLastName);
      sessionStorage.setItem('userRole', this.userRole);
      sessionStorage.setItem('id_token', this.id_token);
      // debugger;
      //console.log(new Observable<any>())
      if (state.url == '/') {
        this.router.navigate(['dashboard']);
      }
      return true;
      // this.router.navigate(['dashboard']);
      // return this.authService.login(self.dynSSOId).toPromise().then(
      //   result => {
      //     if(result != null){
      //       return this.awsService.authenticatefss(this.id_token).then(result =>{
      //         sessionStorage.setItem('secretAccessKey', result.secretAccessKey);
      //         sessionStorage.setItem('accessKeyId', result.accessKeyId);
      //         sessionStorage.setItem('sessionToken', result.sessionToken);
      //         this.router.navigate(['dashboard']);
      //       });
      //     }
      //   }); 
    }

  }

  public logoutUser() {
    //console.log('123')
    this.dynFirstName = '';
    this.dynLastName = '';
    this.dynSSOId = '';
    this.dynEmail = '';

    // let userType = sessionStorage.getItem('userType');      

    sessionStorage.clear();
    // localStorage.clear();

    // window.location.href = AppConfig.settings.appUrl;
    window.location.href = AppConfig.settings.logoutUri;

    // sessionStorage.setItem('userType', userType);
    // userType = sessionStorage.getItem('userType');

    // if (userType === 'E') {
    //     sessionStorage.removeItem('userType');
    //     window.location.href = environment.appurl + '/#/Login';
    // } else {
    //      sessionStorage.removeItem('userType');
    //     window.location.href = environment.logoutUri + environment.appurl + '%26client_id=' + environment.clientId;
    // }

    // sessionStorage.clear();
    // localStorage.clear();
  }

}