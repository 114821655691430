import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AppConfigModel } from './models/app.config.model';
//import { debug } from 'util';
@Injectable()
export class AppConfig {
  static settings: AppConfigModel;
  constructor(private http: HttpClient) { }

  load() {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    // const jsonFile = `assets/config/config.localhost.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: AppConfigModel) => {
        AppConfig.settings = response as AppConfigModel;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }

}
