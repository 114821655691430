import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppConfig } from 'src/app/app.config';
import { Inputsheetpilotinterface, InputsheetpilotObject } from 'src/app/models/inputsheetpilot';
//import { SatDatepicker } from 'saturn-datepicker';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormControl } from '@angular/forms';
import { Inputsheettypes } from 'src/app/models/inputsheettypes';
import { Subscription, Subject } from 'rxjs';
import { CommunicatingComponentService } from 'src/app/modules/survey/service/communicating-component.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { InputService } from 'src/app/services/inputsheet.service';
import { ExcelService } from 'src/app/services/excel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-internet-dashboard',
  templateUrl: './internet-dashboard.component.html',
  styleUrls: ['./internet-dashboard.component.scss'],
  providers: [DatePipe]
})
export class InternetDashboardComponent implements OnDestroy, AfterViewInit {
  appConstants = AppConfig.settings;
  pilotDeclaration: Inputsheetpilotinterface;
  /* @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>; */
  @Output() editEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  inputSheetTypeControl = new UntypedFormControl();
  inputSheetType: Inputsheettypes[] = [];
  sheetType: number;
  subscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  pipe: DatePipe;
  isLoading: boolean;
  isAdmin:boolean = true;

  InputsheetpilotData: any = [];
  InputsheetData: any = [];
  // Pilotdata = new Array<InputsheetpilotObject>();

  filterReturnData: any;
  objectDeclaration: any = [];
  matTableDataSource = new MatTableDataSource<InputsheetpilotObject>();
  tooltip: boolean;
  filteredData: void[];

  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private awsCommonService: AwsCommonService,
    private inputService: InputService,
    private excelService: ExcelService,
    private toastorService: NotificationService,
    private authManager: AuthManager

  ) {
    this.pipe = new DatePipe('en');
    this.pilotDeclaration = {
      inputSelecteName: '',
      dataShowTitle: '',
      pilotDashboardURL: '',
      gridTotalCount: '',
      tableErrorBlock: 'Loading Data...',
      tableNoDataFound: 'No Records Found',
      pilotDashboardGenresURL: '',
      pilotDashboardShowTitleURL: '',
      pilotTableFillterColumns: '',
      showTitleControl: new UntypedFormControl(''),
      surveyAnalystControl: new UntypedFormControl(''),
      genreDescControl: new UntypedFormControl(''),
      roughCutControl: new UntypedFormControl(''),
      titleVersionNameControl: new UntypedFormControl(''),
      createdDtControl: new UntypedFormControl(''),
      lastUpdatedDtControl: new UntypedFormControl(''),
      inputSheetStatusControl: new UntypedFormControl(''),
      inputSheetNameControl: new UntypedFormControl(''),
      episodeControl: new UntypedFormControl(''),
      pilotTableDisplayColumns: [
        'inputSheetName',
        'genreDesc', 'showTitle',
        'episode', 'surveyAnalyst', 'roughCut', 'titleVersionName', 'createdDt', 'lastUpdatedDt', 'inputSheetStatus',
        'action'],
      pilotTableHeaderColumns: [
        'input Sheet Name', 'genre', 'show title', 'episode #', 'Analyst', 'rought cut',
        'version', 'created date', 'modified date', 'status', 'action'],
      pilotTabelFooterColumns: ['filter_inputSheetName', 'filter_genreDesc',
        'filter_showTitle', 'filter_episode#',
        'filter_surveyAnalyst',
        'filter_roughCut', 'filter_titleVersionName', 'filter_createdate', 'filter_modifieddate', 'filter_status',
        'filter_action'],
      pipe: new DatePipe('en'),
      tooltip: false,
      communication: '',
      placeholderHeight: 0,
      filterValues: {
        inputSheetName: '',
        episode: '',
        showTitle: '',
        surveyAnalyst: '',
        genreDesc: '',
        roughCut: '',
        titleVersionName: '',
        createdDt: '',
        lastUpdatedDt: '',
        inputSheetStatus: '',
        fromCreatedDate: '',
        toCreatedDate: '',
        fromUpdatedDate: '',
        toUpdatedDate: ''
      },
      showtitleData: [],
      genreData: [],
      titleVersionData: [],
      inputsheetNameData: [],
      inputSheetStatusData: ['Draft', 'Submitted']
    };
    this.pilotDeclaration.filterRougtCut = [
      {
        rougtcutid: '',
        rougtcutvalue: 'All'
      },
      {
        rougtcutid: 'Y',
        rougtcutvalue: 'Yes'
      },
      {
        rougtcutid: 'N',
        rougtcutvalue: 'No'
      }
    ];

    this.sheetType = 1;
    // this.pilotTableFillterColumns = 
    this.inputSheetType = [
      { value: 1, viewValue: 'Pilot' },
      { value: 2, viewValue: 'Print Ad' },
      { value: 3, viewValue: 'Promo' },
      {value: 4, viewValue: 'Internet' },
      {value: 5, viewValue: 'Others'}
    ];
    this.subscription = this.communicatingComponentService.getCommunication()
      .subscribe(message => { this.pilotDeclaration.communication = message; }
      );
    this.inputSheetTypeControl.setValue(this.inputSheetType[3].value);

    this.pilotDeclaration.inputSelecteName = this.inputSheetType[3].viewValue;
    this.inputSheetTypeControl.valueChanges
      .subscribe(
        inputSheetType => {
          this.pilotDeclaration.inputSelecteName = '';
          if (inputSheetType === 1) {
            this.router.navigate(['../pilot'], { relativeTo: this.route });
          } else if (inputSheetType === 2) {
            this.router.navigate(['../print'], { relativeTo: this.route });
          } else if (inputSheetType === 3) {
            this.router.navigate(['../promo'], { relativeTo: this.route });
          }
          else if(inputSheetType === 4){
            this.router.navigate(['../internet'], { relativeTo: this.route });
          }
          else if(inputSheetType === 5){
            this.router.navigate(['../others'], { relativeTo: this.route });
          }
          this.sheetType = inputSheetType;
          inputSheetType--;
          this.pilotDeclaration.inputSelecteName = this.inputSheetType[inputSheetType].viewValue;
        }
      );

    if (this.appConstants.checkServerURL) {
      this.pilotDeclaration.pilotDashboardURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPilotDashboard}`;
      this.pilotDeclaration.pilotDashboardGenresURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterGenres}`;
      this.pilotDeclaration.pilotDashboardShowTitleURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterShowTitle}`;

    } else {
      this.pilotDeclaration.pilotDashboardURL = `${this.appConstants.inputSheetPilotDashboardLocalURL}`;
      this.pilotDeclaration.pilotDashboardGenresURL = `${this.appConstants.inputsheetPilotGenreLocalURL}`;
      this.pilotDeclaration.pilotDashboardShowTitleURL = `${this.appConstants.inputsheetPilotShowTitleLocalURL}`;
    }

  }
  ngOnInit() {
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
    this.getpilotDashboard();
  }

  ngAfterViewInit() {
    this.matTableDataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
  }
  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  getpilotDashboard() {
    this.tooltip = true;

    this.isLoading = true;
    this.getMultipleFilter();
    const data: InputsheetpilotObject[] = [];
    let params = new HttpParams().set("inputSheetType",'Internet');
    const options =  { "inputSheetType":"Internet" };
    console.log(options);
    this.awsCommonService.getApiRequest(this.pilotDeclaration.pilotDashboardURL, 'GET',options)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          this.tooltip = false;
          this.pilotDeclaration.showtitleData = [];
          this.pilotDeclaration.genreData = [];
          this.pilotDeclaration.titleVersionData = [];
          this.pilotDeclaration.inputsheetNameData = [];
          this.isLoading = false;
          this.InputsheetpilotData = dataSource,
          console.log(this.InputsheetpilotData);
          // const str="INT-";
          // const regex = new RegExp("/\b?INT-\b?", 'g');
          // this.InputsheetpilotData=this.InputsheetpilotData.filter((item)=>{
          //   if(item.inputsheetName)
          //   return  item.inputsheetName.includes("INT-");
          // })
             console.log(this.InputsheetpilotData);
            this.InputsheetpilotData = this.InputsheetpilotData.map((item, index) => {

              data.push({
                inputSheetId: item.inputSheetId,
                inputSheetName: [item.inputsheetName ? item.inputsheetName: 'No Data'],
                showTitle: [item.primeShowTitle.showTitle ? item.primeShowTitle.showTitle.trim() : 'No Data'],
                showTitleid: item.primeShowTitle.primeShowTitleId,
                surveyAnalyst: [item.analyst ? item.analyst : 'No Data'],
                genreDesc:
                  [item.primeShowTitle.projectType.c2027ProjectTypeDesc ? item.primeShowTitle.projectType.c2027ProjectTypeDesc : 'No Data'],
                genreId: item.primeShowTitle.projectType.c2027ProjectTypeId,
                roughCut: [item.roughCut ? item.roughCut : 'No Data'],
                titleVersionId: [item.titleVersion ? item.titleVersion.titleVersionId : 'No Data'],
                titleVersionName: [item.titleVersion ? item.titleVersion.titleVersionName : 'No Data'],
                createdDt: [item.createdDt ? item.createdDt : '01/01/0001'],
                lastUpdatedDt: [item.lastUpdatedDt ? item.lastUpdatedDt : '01/01/0001'],
                inputSheetStatus: [item.status ? item.status : 'No Data'],
                episode: [item.episodenumber ? item.episodenumber : 'No Data']
              });
              this.pilotDeclaration.showtitleData.push({
                showTitle: item.primeShowTitle.showTitle
              });
              this.pilotDeclaration.genreData.push({
                genreDesc: item.primeShowTitle.projectType.c2027ProjectTypeDesc
              });
              if (typeof (item.titleVersion.titleVersionName) !== 'undefined' && item.titleVersion.titleVersionName !== null) {
                this.pilotDeclaration.titleVersionData.push({
                  titleVersionName: item.titleVersion.titleVersionName
                });
              }
              if (typeof (item.inputsheetName) !== 'undefined' && item.inputsheetName !== null && item.inputsheetName) {
                this.pilotDeclaration.inputsheetNameData.push({
                  inputsheetName: item.inputsheetName
                });
              }
            });

          this.pilotDeclaration.showtitleData =
            [...new Set(Array.from(new Set(this.pilotDeclaration.showtitleData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.showTitle.trim();
              }
            }))];
          this.pilotDeclaration.genreData =
            [...new Set(Array.from(new Set(this.pilotDeclaration.genreData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null && itemInArray) {
                return itemInArray.genreDesc.trim();
              }
            }))];
          this.pilotDeclaration.inputsheetNameData =
            [...new Set(Array.from(new Set(this.pilotDeclaration.inputsheetNameData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null && itemInArray) {
                return itemInArray.inputsheetName.trim();
              }
            }))];
          this.pilotDeclaration.titleVersionData = [...new Set(Array.from(new Set(this.pilotDeclaration.titleVersionData)).map(
            (itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null && itemInArray) {
                return itemInArray.titleVersionName.trim();
              }

            }))];
          this.pilotDeclaration.showtitleData = this.pilotDeclaration.showtitleData.sort();
          this.pilotDeclaration.genreData = this.pilotDeclaration.genreData.sort();
          this.pilotDeclaration.titleVersionData = this.pilotDeclaration.titleVersionData.sort();
          this.pilotDeclaration.inputsheetNameData = this.pilotDeclaration.inputsheetNameData.sort();
          this.matTableDataSource.data = data;
          setTimeout(() => {
            //console.log(this.sort);
            this.matTableDataSource.sort = this.sort;

          }),
            //console.log(this.matTableDataSource);
            this.matTableDataSource.filterPredicate = this.customFilterPredicate();
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
        }
      );
  }

  clearGridTable() {
    this.matTableDataSource.filter = '';
    this.pilotDeclaration.titleVersionNameControl.setValue('');
    this.pilotDeclaration.roughCutControl.setValue('');
    this.pilotDeclaration.episodeControl.setValue('');
    this.pilotDeclaration.genreDescControl.setValue('');
    this.pilotDeclaration.surveyAnalystControl.setValue('');
    this.pilotDeclaration.showTitleControl.setValue('');
    this.pilotDeclaration.inputSheetStatusControl.setValue('');
    this.pilotDeclaration.inputSheetNameControl.setValue('');
    this.pilotDeclaration.filterValues.fromCreatedDate = '';
    this.pilotDeclaration.filterValues.toCreatedDate = '';
    this.pilotDeclaration.filterValues.fromUpdatedDate = '';
    this.pilotDeclaration.filterValues.toUpdatedDate = '';
    this.pilotDeclaration.lastdate = '';
    this.pilotDeclaration.createdate = '';
    this.awsCommonService.clearDate.emit(true);
    this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
    this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
  }
  getMultipleFilter() {
    this.pilotDeclaration.inputSheetNameControl.valueChanges
      .subscribe(
        inputSheetName => {
          this.pilotDeclaration.filterValues.inputSheetName = inputSheetName;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );

    this.pilotDeclaration.episodeControl.valueChanges
      .subscribe(
        episode => {
          this.pilotDeclaration.filterValues.episode = episode;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.surveyAnalystControl.valueChanges
      .subscribe(
        surveyAnalyst => {
          this.pilotDeclaration.filterValues.surveyAnalyst = surveyAnalyst;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.showTitleControl.valueChanges
      .subscribe(
        showTitle => {
          this.pilotDeclaration.filterValues.showTitle = showTitle;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.genreDescControl.valueChanges
      .subscribe(
        genreDesc => {
          this.pilotDeclaration.filterValues.genreDesc = genreDesc;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.roughCutControl.valueChanges
      .subscribe(
        roughCut => {
          this.pilotDeclaration.filterValues.roughCut = roughCut;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.titleVersionNameControl.valueChanges
      .subscribe(
        titleVersionName => {
          this.pilotDeclaration.filterValues.titleVersionName = titleVersionName;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );
    this.pilotDeclaration.inputSheetStatusControl.valueChanges
      .subscribe(
        inputSheetStatus => {
          this.pilotDeclaration.filterValues.inputSheetStatus = inputSheetStatus;
          this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
          this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.pilotDeclaration.tableErrorBlock =
            (this.pilotDeclaration.gridTotalCount === 0) ? this.pilotDeclaration.tableNoDataFound : this.pilotDeclaration.tableErrorBlock;
        },
        error => { }
      );

  }

  customFilterPredicate() {

    return (data: InputsheetpilotObject, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      //console.log(searchString);
      // /inputSheetName
      const inputSheetName =
        data.inputSheetName.toString().trim().toLowerCase().indexOf(searchString.inputSheetName.toLowerCase()) !== -1;
      const surveyAnalyst =
        data.surveyAnalyst.toString().trim().toLowerCase().indexOf(searchString.surveyAnalyst.toLowerCase()) !== -1;
      const surveyepisode =
        data.episode.toString().trim().toLowerCase().indexOf(searchString.episode.toLowerCase()) !== -1;
      const showTitle = data.showTitle.toString().indexOf(searchString.showTitle) !== -1;
      const genreDesc = data.genreDesc.toString().trim().indexOf(searchString.genreDesc) !== -1;
      const roughCut = data.roughCut.toString().trim().toLowerCase().indexOf(searchString.roughCut.toLowerCase()) !== -1;
      const titleVersionName =
        data.titleVersionName.toString().trim().toLowerCase().indexOf(searchString.titleVersionName.toLowerCase()) !== -1;
      const inputSheetStatus =
        data.inputSheetStatus.toString().trim().toLowerCase().indexOf(searchString.inputSheetStatus.toLowerCase()) !== -1;
      const tempCreDt = this.pipe.transform(data.createdDt, 'yyyy-MM-dd');
      const createdDt = (
        (tempCreDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (tempCreDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const tempUpdDt = this.pipe.transform(data.lastUpdatedDt, 'yyyy-MM-dd');
      const lastUpdatedDt = (
        (tempUpdDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (tempUpdDt.toString() <= (searchString.toUpdatedDate).toString())
      );

      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputSheetName && surveyAnalyst && surveyepisode && showTitle && genreDesc && roughCut && titleVersionName && inputSheetStatus;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputSheetName &&
          surveyAnalyst && surveyepisode && showTitle && genreDesc && roughCut && titleVersionName && inputSheetStatus && createdDt;
      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.filterReturnData =
          inputSheetName &&
          surveyAnalyst && surveyepisode && showTitle && genreDesc && roughCut && titleVersionName && inputSheetStatus && lastUpdatedDt;
      } else {
        this.filterReturnData =
          inputSheetName &&
          surveyAnalyst && showTitle
          && surveyepisode && genreDesc && roughCut && titleVersionName && inputSheetStatus && createdDt && lastUpdatedDt;
      }

      return this.filterReturnData;
    };
  }

  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.pilotDeclaration.filterValues.fromCreatedDate = fromDate;
      this.pilotDeclaration.filterValues.toCreatedDate = toDate;
      this.pilotDeclaration.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.pilotDeclaration.filterValues.fromUpdatedDate = fromDate;
      this.pilotDeclaration.filterValues.toUpdatedDate = toDate;
      this.pilotDeclaration.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }

    this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
    // this.matTableDataSource.filter = JSON.stringify(this.pilotDeclaration.filterValues);
    this.pilotDeclaration.gridTotalCount = this.matTableDataSource.filteredData.length;
  }
  onEdit(edata: any) {
    this.awsCommonService.editPilotData = edata;
    this.router.navigate(['../internetedit'], { relativeTo: this.route });
  }

  onCopy(cdata: any) {
    this.isLoading = true;
    const inputSheetIdData = cdata.inputSheetId;
    const inputSheetIdParam = { inputSheetId: inputSheetIdData };
    this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/copylatest`,
      'POST', inputSheetIdParam)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isLoading = false;
        this.toastorService.success('InputSheet Pilot Copied successfully');
        this.clearGridTable();
        this.getpilotDashboard();
      }, err => {
        this.toastorService.warn(err);
      });
  }

  openLink(event: MouseEvent): void {
    //console.log('cancel');
    this.awsCommonService.editPilotData.close('cancel');
    event.preventDefault();
  }
  exportExcel() {
    //console.log(this.matTableDataSource.filteredData);
    //console.log(this.filteredData);
    this.toastorService.success('InputSheet Pilot Excel generated successfully');
    this.excelService.inputsheetPilotExport(this.matTableDataSource.filteredData);
  }

}
