import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { AppConfig } from 'src/app/app.config';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { TemplateSharedGridQuestionEditViewComponent } from '../template-shared-grid-question-edit-view/template-shared-grid-question-edit-view.component';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit,OnDestroy {
 
  addSharedQuesForm: UntypedFormGroup;
  appConstants = AppConfig.settings;
  checkServerURL: any;
  SERVER_URL: any;
  questionCategoryList: any = [];
  getCategoryURL: any;
  getQuestion_URL: any;
  sharedQuestionList: any = [];
  addQuestionURL: any;
  validationMsg: any;
  isLoadingSave: any;
  isHidden: boolean = true;
  isCategorySelected: boolean;
  loadingQuestionList: boolean = true;
  ModuleName: any;
  sharedQuestionListId:any='';
  searchQuesModel: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private addQuestionRef: MatDialogRef<TemplateSharedGridQuestionEditViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.validationMsg = this.appConstants.validationMsg;
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getCategoryURL = `${this.appConstants.serverUrl}/${this.appConstants.getQuestionCategoryParam}`;
      this.getQuestion_URL = `${this.SERVER_URL}`;
      this.addQuestionURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.addQuestionURL}`;
   
    } else {
      this.getCategoryURL = this.appConstants.getQuestionCategoryLocalURL;
      this.getQuestion_URL = this.appConstants.getQuestionLocalURL;
    }
  }

  ngOnInit() {
    this.getCategory();
    this.addSharedQuesForm = new UntypedFormGroup({
      questionSharedListId: new UntypedFormControl(this.data.sharedListId),
      questionCategory: new UntypedFormControl('', Validators.compose([Validators.required])),
      questionLongText: new UntypedFormControl('', Validators.compose([Validators.required,
      Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
      Validators.maxLength(200)])),
      questionLibraryId: new UntypedFormControl('', Validators.compose([Validators.required]))
    });
    this.onChanges();
  }
  onChanges() {
    this.addSharedQuesForm.get('questionCategory').valueChanges.subscribe(data => {
      if (data == null || '') {
        this.addSharedQuesForm.controls['questionLibraryId'].setValidators(null);
        this.addSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = false;
      } else {
        this.addSharedQuesForm.controls['questionLibraryId'].setValidators([Validators.required]);
        this.addSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = true;
      }
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.addSharedQuesForm.controls[controlName].hasError(errorName);
  }

  getCategory(): void {
    this.isLoadingSave = true;
    // this.commonService.getCategoryList(this.getCategoryURL).subscribe(data => {
    this.commonService.getRequest(this.getCategoryURL, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.isLoadingSave = false;
      this.questionCategoryList = data;
    },
    (error) => {
      this.isLoadingSave = false;
      this.toastorService.warn(error.message);
    });
  }

  onChangeQuesCat(event): void {
    let questionIds = [];
    questionIds = event.value;
    this.getQuestionList(questionIds);
  }

  getQuestionList(questionIds) {
    this.isLoadingSave = true;
    let sharedList = [];
    this.getQuestion_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}/${questionIds}/${this.appConstants.templateAddSharedQuestion}`;
    // this.sharedlistService.getQuestionList(this.getQuestion_URL, questionIds).subscribe(data => {
    this.commonService.getRequest(this.getQuestion_URL, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => {
      this.isLoadingSave = false;
      data.forEach((item) => {
        if (item.status === 'Y') {
          sharedList.push(item);
        }
      })
      this.sharedQuestionList = sharedList;
      this.loadingQuestionList = false;
    },
    (error) => {
      this.isLoadingSave = false;
      this.toastorService.warn('Oops!!! The Server was unable to complete your request');
    }
    );
  }

  public onSelect(target: any) {
    this.sharedQuestionListId = target;
  }

  addQuestion() {
    if (this.addSharedQuesForm.invalid) {
      this.isLoadingSave = false;
      return;
    } else {
      this.isLoadingSave = true;
      let inputData = {
        surveyTemplateId: this.data.templateId,
        templateQuestionChoice: [{
          templateQuestionChoiceId: this.data.templateQuestionChoiceId,
          sharedQuestionChoice: {
            sharedQuestionsChoiceId: this.data.sharedQuestionsChoiceId,
            questionSharedList: {
              questionSharedListId: this.data.sharedListId,
              sharedListName: this.data.sharedListName,
              sharedListQuestions: [
                {
                  questionLongText: this.addSharedQuesForm.controls['questionLongText'].value,
                  questionLibrary: {
                    questionLibraryId: this.addSharedQuesForm.controls['questionLibraryId'].value[0]
                  }
                }
              ]
            }
          }
          }
        ]
      }
    this.commonService.postRequest(this.addQuestionURL, 'POST', inputData)
    .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.isLoadingSave = false;
            this.addQuestionRef.close(result);
            this.toastorService.success('Question added successfully');
          },
          error => {
            this.isLoadingSave = false;
            if(error.status == 409){
              this.addSharedQuesForm.controls.questionLongText.setErrors({
                notUnique: true
              })
            } else {
              this.toastorService.warn(error.message);
              this.addQuestionRef.close();
            }
           
          },
          () => {
          }
        );

    }
  }

  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  openLink(event: MouseEvent): void {
    this.addQuestionRef.close();
    event.preventDefault();
  }
}
