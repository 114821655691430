<div class="container primeNewModal createsharedChoice">
        <span class="modalClose" mat-dialog-close><i></i></span>
        <mat-card>
            <mat-card-title></mat-card-title>
            <form [formGroup]="createSharedChoiceForm" autocomplete="off" class="example-form" novalidate
                (ngSubmit)="createChoice()">
                <mat-card-content>
                    <mat-form-field class="example-full-width" floatLabel=never>
                        <label class="font-13 required">{{sharedChoiceCreateLabel.sharedChoiceName}}</label>
                        <input matInput type="text" class="form-control" (blur) = "trimFunction($event)" formControlName="sharedListName" placeholder="Enter Choice Name" >
                        <mat-error *ngIf="hasError('sharedListName', 'required')">{{validationMsg}}
                            {{ sharedChoiceCreateLabel.sharedChoiceName }}</mat-error>
                        <mat-error *ngIf="hasError('sharedListName', 'pattern')">Please enter valid choice Name
                            </mat-error>  
                        <mat-error *ngIf="hasError('sharedListName', 'notUnique')">Already exists in our database
                            </mat-error> 
                    </mat-form-field>

                    <mat-form-field class="example-full-width" floatLabel=never>
                        <label class="font-13 required"> {{sharedChoiceCreateLabel.sharedChoiceDesc}}</label>
                        <textarea matInput class="form-control" (blur) = "trimFunction($event)" formControlName="sharedListDesc" #choiceLibraryDesc maxlength="255" placeholder="Enter Choice Description"></textarea>
                        <mat-error *ngIf="hasError('sharedListDesc', 'required')">{{validationMsg}}
                            {{ sharedChoiceCreateLabel.sharedChoiceDesc }}</mat-error>
                        <mat-error *ngIf="hasError('sharedListDesc', 'pattern')">Please enter valid choice Description
                            </mat-error> 
                        <mat-error *ngIf="hasError('sharedListDesc', 'minLength')">You have more than 10 characters
                        </mat-error>
                    </mat-form-field>
    
                    <!-- <div class="slider-container">
                        <label class="font-13 ">{{choiceCreateLabel.posNegFlag}}</label>
                        <input type="hidden" class="form-control" formControlName="posNegFlag">
                        <mat-slide-toggle ngDefaultControl (change)="onChange('posNegFlag', $event)" checked>
                        </mat-slide-toggle>
                    </div>
    
                    <div class="slider-container">
                        <label class="font-13 ">{{choiceCreateLabel.activeYn}}</label>
                        <input type="hidden" class="form-control" formControlName="activeYn">
                        <mat-slide-toggle ngDefaultControl (change)="onChange('activeYn', $event)" checked>
                        </mat-slide-toggle>
                    </div> -->
                    <!-- <div *ngIf="selectedChoices">
                    <mat-nav-list >
                        <div  *ngFor="let item of selectedChoices;let i=index">
                        <mat-list-item > {{ item }} </mat-list-item> 
                        <button mat-icon-button (click)="onRemove(i)" >
                           delete 
                        </button>
                    </div>                
                     </mat-nav-list>
                    </div> -->
                    <mat-form-field class="searchTextWrap autoSelect selection-placeholder" floatLabel=never>
                        <label class="font-13 float-left"> {{sharedChoiceCreateLabel.sharedChoiceList}}</label>
                        <div class="autoSelect hastag clear" floatLabel=never>
                                <span  *ngFor="let item of selectedChoices;let i=index">
                                    <mat-list-item> {{ item.choiceLibraryDesc }}  
                                    <button mat-icon-button (click)="onRemove(item)">
                                    <mat-icon class="mat-24 hastagDelete">x</mat-icon>
                                    </button></mat-list-item>
                                </span>
                            </div>  
                            <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control"  placeholder="Search" 
                            [(ngModel)]="searchChoiModel" [ngModelOptions]="{standalone: true}" [readonly]="loading"/>
                 
                        <i class="searchicon align-autosearchIcon"></i>
                        </div>
                    
                    
                        
                       
                        <mat-selection-list  #sharedChoices  class="form-control" formControlName="choiceLibraryIds">
                            <mat-list-option title="{{option.choiceLibraryDesc}}" *ngFor="let option of sharedChoiceList | searchFilter: searchChoiModel;let i=index" [selected]="option.selected" 
                                (click)="onAreaListControlChanged(sharedChoices,option)" 
                                [ngClass]="getSomeClass(option)"
                                [value]="option.choiceLibraryId">
                                <span>{{option.choiceLibraryDesc}}</span>
        
                            </mat-list-option>
                                
                        </mat-selection-list> 
                    </mat-form-field>  
                        <!-- <mat-error *ngIf="hasError('choiceLibraryIds', 'required')">{{validationMsg}}
                                {{ sharedChoiceCreateLabel.sharedChoiceList }}</mat-error>          -->
          
                        
                        
                    
                    
                </mat-card-content>
                <div class="spinner" *ngIf="isLoadingSave">
                    <div class="bounceone"></div>
                    <div class="bouncetwo"></div>
                    <div class="bouncethree"></div>
                    <div class="bouncefour"></div>
                    <div class="bouncefive"></div>
                    <div class="bouncesix"></div>
                </div>
                <mat-card-actions>
                    <button mat-raised-button color="primary" type="submit">Create</button>
                    <button type="button" mat-raised-button color="warn" (click)="close($event)">Cancel</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>