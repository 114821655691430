<div class="col-md-7 contentSection withSideNav sharedList" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">{{dashboardTitle}}</h5>

        <div *ngIf="tooltip && isAdmin" class="float-right createSection">
            <span matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue"> <span>Edit
                    </span> <i class="editBlueIcon"></i></p>
            </span>
            <span matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue"> <span>{{sharedChoiceCreate}}</span>
                    <i class="addnewIcon"></i>
                </p>
            </span>
            <span matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue">
                    <span>{{sharedQuestionCreate}}</span>
                    <i class="addnewIcon"></i>
                </p>
            </span>
        </div>
        <div *ngIf="!tooltip && isAdmin" class="float-right createSection">
            <mat-card-actions class="float-left" *ngIf="this.isEdit">
                <button mat-raised-button color="primary" (click)="sharedListQuestionSave();"
                    type="submit">Save</button>
                <button type="button" mat-raised-button color="warn"
                    (click)="openconfirmModal('edit', '')">Cancel</button>
            </mat-card-actions>
            <span *ngIf="!hideEdit">
                <p class="float-left font-13 blue" (click)=" onEdit();this.isEdit=true;" *ngIf="!this.isEdit">
                    <span>Edit
                    </span> <i class="editBlueIcon"></i></p>
            </span>
            <p *ngIf="!this.isEdit" class="float-left font-13 blue" (click)="openCreateSharedChoice()">
                <span>{{sharedChoiceCreate}}</span>
                <i class="addnewIcon"></i>
            </p>
            <p *ngIf="this.isEdit" matTooltip="Come out of Edit Mode" class="float-left font-13 blue">
                <span>{{sharedChoiceCreate}}</span>
                <i class="addnewIcon"></i>
            </p>
            <p *ngIf="!this.isEdit" class="float-left font-13 blue" (click)="openCreateSharedQuestion()">
                <span>{{sharedQuestionCreate}}</span>
                <i class="addnewIcon"></i>
            </p>
            <p *ngIf="this.isEdit" matTooltip="Come out of Edit Mode" class="float-left font-13 blue">
                <span>{{sharedQuestionCreate}}</span>
                <i class="addnewIcon"></i>
            </p>
        </div>
    </div>
    <div class="col-lg-12 row">
        <app-shared-list-sidenav [ngClass]="(this.isEdit==true)?'pointer-event':''" (select)="displayTemplate($event)">
        </app-shared-list-sidenav>
        <app-shared-list-dashboard [templateInput]="template"> </app-shared-list-dashboard>
        <!-- <app-shared-list-dashboard (editEvent)="OpeneditChoiceComponent($event)" [templateInput]="template" [isEditClick]="isEdit" > </app-shared-list-dashboard> -->
    </div>
</div>