<div class="col-md-11 contentSection" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">{{dashboardTitle}}</h5>
        <div class="float-right createSection" *ngIf="isAdmin">
            <p class="float-left font-13 blue" matTooltip="Data is loading please wait until it completes" *ngIf="tooltip"> 
                <span>{{createText}}</span>
                <i class="addnewIcon"></i>
            </p>
            <p class="float-left font-13 blue" (click)="openCreateChoiceComponent()" *ngIf="!tooltip"> 
                <span>{{createText}}</span>
                <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3" *ngIf="tooltip"  matTooltip="Data is loading please wait until it completes">
                <i class="excelIcon"></i>
            </p>
            <p class="float-left ml-3" *ngIf="!tooltip">
                <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
        </div>
    </div>
    <div class="col-lg-12 row">
        <!-- <app-question-dashboard (editEvent)="editForm($event)"></app-question-dashboard> -->
        <app-choice-dashboard (editEvent)="OpeneditChoiceComponent($event)" > </app-choice-dashboard>
    </div>
</div>