<div class="spinnerOverlay" *ngIf="spinnerQuesSL || spinnerChoiSL || spinnerVarSL"> </div>
<div class="spinner" *ngIf="spinnerQuesSL || spinnerChoiSL || spinnerVarSL">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<div class="shared-list-sidenav">
    <div class="sharedQuestionList" *ngIf="!spinnerQuesSL && !spinnerChoiSL && !spinnerVarSL">
        <!-- <app-search [searchModel]="searchQModel" (searchChangeEmit)="searchInput($event, 'sharedQuestions')"></app-search>   -->
        <mat-form-field floatLabel=never>
            <!-- <input matInput type="text" placeholder="Shared Questions" [formControl]="SharedSideNavQuestions" [(ngModel)]="searchQuesModel">
            <i class="searchGrayIcon"></i>
            <span *ngIf="!SharedSideNavQuestions.value"><i class="searchGrayIcon"></i></span>
            <button class="sharedListinputClose inputCloseIcon" mat-button *ngIf="SharedSideNavQuestions.value" matSuffix mat-icon-button aria-label="Clear" (click)="SharedSideNavQuestions.setValue('')">
                        <mat-icon>close</mat-icon>
                    </button> -->
            <input matInput type="search" (keydown.enter)="searchQuestions($event)" placeholder="Search Questions"
                id="example-search-input" [formControl]="SharedSideNavQuestions">
            <span *ngIf="!SharedSideNavQuestions.value"><i class="searchGrayIcon"></i></span>
        </mat-form-field>
        <div>
            <mat-selection-list #sharedQuestions>
                <mat-list-option *ngFor="let option of sharedQuestionList | listFilter: searchQuesModel" [ngClass]="{selected : option?.id == currentSharedQuestion.id}" title="{{option?.value}}">
                    <a mat-list-item (click)="onSelect('sharedQuestions', option)"><span>{{ option?.value }}</span></a>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
    <div class="SharedChoiceList" *ngIf="!spinnerQuesSL && !spinnerChoiSL && !spinnerVarSL">
        <mat-form-field floatLabel=never>
            <input matInput type="text" placeholder="Shared Choices" [formControl]="SharedSideNavChoices" [(ngModel)]="searchChoiModel">
            <!-- <i class="searchGrayIcon"></i> -->
            <span *ngIf="!SharedSideNavChoices.value"><i class="searchGrayIcon"></i></span>
            <button class="sharedListinputClose inputCloseIcon" mat-button *ngIf="SharedSideNavChoices.value" matSuffix mat-icon-button aria-label="Clear" (click)="SharedSideNavChoices.setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>
        <div>
            <mat-selection-list #sharedChoices>
                <mat-list-option class="mat-primary" *ngFor="let option of sharedChoiceList | listFilter: searchChoiModel" title="{{option?.value}}" [ngClass]="{selected:option?.id == currentSharedQuestion.id}">
                    <a mat-list-item (click)="onSelect('sharedChoices', option)"><span>{{ option?.value }}</span></a>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
    <div class="SharedVariableList" *ngIf="!spinnerQuesSL && !spinnerChoiSL && !spinnerVarSL">
        <mat-form-field floatLabel=never>
            <input matInput type="text" placeholder="Shared Variables/Loops" [formControl]="SharedSideNavVariables" [(ngModel)]="searchVarModel">
            <!-- <i class="searchGrayIcon"></i> -->
            <span *ngIf="!SharedSideNavVariables.value"><i class="searchGrayIcon"></i></span>
            <button class="sharedListinputClose inputCloseIcon" mat-button *ngIf="SharedSideNavVariables.value" matSuffix mat-icon-button aria-label="Clear" (click)="SharedSideNavVariables.reset()">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>
        <div>
            <mat-selection-list #sharedVariables>
                <mat-list-option *ngFor="let option of sharedVariableList | listFilter: searchVarModel" title="{{option?.value}}">
                    <a mat-list-item (click)="onSelect('sharedVariables', option)"><span>{{ option?.value }}</span></a>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
</div>