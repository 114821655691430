import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DaterangePickerComponent } from './components/daterange-picker/daterange-picker.component';
import { SearchComponent } from './components/search/search.component';
import { ListFilterPipe } from '../pipes/list-filter.pipe';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { NumberOnlyDirective } from './validators/directives/number-only.directive';
import { ConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';

@NgModule({
  declarations: [ 
    DaterangePickerComponent,
    SearchComponent,
    ListFilterPipe,
    SearchFilterPipe,
    NumberOnlyDirective,
    ConfirmdialogComponent
  ],
  imports: [
    CommonModule,
    /* SatDatepickerModule, SatNativeDateModule, */ 
    MatDatepickerModule, MatInputModule, MatNativeDateModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    DaterangePickerComponent,
    SearchComponent,
    ListFilterPipe,
    SearchFilterPipe,
    NumberOnlyDirective,
    ConfirmdialogComponent
  ]
})
export class SharedModule { }
