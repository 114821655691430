<div class="sharedQuestions" *ngIf="templateInput && templateInput?.type== 'sharedQuestions'">
  <app-shared-question-edit-view [childInput]="templateInput" [childEdit]="isEditClick"  *ngIf="(templateInput?.isGrid==='Grid')"></app-shared-question-edit-view>
  <app-simple-question [childInput]="templateInput" [childEdit]="isEditClick" *ngIf="(templateInput?.isGrid==='Simple')"></app-simple-question>
</div>

<div class="sharedQuestions" *ngIf="templateInput && templateInput?.type== 'sharedVariables'">
  <app-shared-variable-view [childInput]="templateInput" [childEdit]="isEditClick"></app-shared-variable-view>
  <!-- <app-simple-question *ngIf="!(templateInput?.isGrid)"></app-simple-question> -->
</div>
<div class="sharedQuestions" *ngIf="templateInput && templateInput?.type== 'sharedChoices' ">
    <app-shared-choice-view [childInput]="templateInput" [childEdit]="isEditClick"></app-shared-choice-view>
    <!-- <app-simple-question *ngIf="!(templateInput?.isGrid)"></app-simple-question> -->
</div>