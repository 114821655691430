<div class="spinnerOverlay"  *ngIf="isLoading"></div>
<div class="spinner" *ngIf="isLoading">
   <div class="bounceone"></div>
   <div class="bouncetwo"></div>
   <div class="bouncethree"></div>
   <div class="bouncefour"></div>
   <div class="bouncefive"></div>
   <div class="bouncesix"></div>
</div>
<div class="col-md-11 contentSection pilotMain" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">Internet - <span>{{editTitle}}</span></h5>
        <mat-card-actions class="float-right">
            <input type="hidden" />
            <button mat-raised-button color="primary" (click)="openconfirmModal('Submit','')" [disabled]="notSaved || disButtons"
                matTooltip="Please Enter details below and Save">Submit</button>
            <!-- <button mat-raised-button color="primary" type="submit" (click)="submitNewPilot()">Submit</button>
            <button class="savePilot" mat-raised-button color="primary">Save</button> -->
            <button type="button" mat-raised-button color="warn" (click)="openconfirmModal('cancel','')">Cancel</button>
        </mat-card-actions>
    </div>
    <div>
        <div class="float-left programmerNotes" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Programmer Notes</label>
            <!-- <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add programmer Notes" value=""
                [formControl]="programmerNotes" name="programmerNotes" class="form-control"> -->
            <textarea class="form-control" placeholder="Add programmer Notes" name="programmerNotes" [formControl]="programmerNotes" [readonly]="readonlyFlag"></textarea>
            <mat-error *ngIf="programmerNotes.invalid && programmerNotes.touched">Please Enter valid value</mat-error>
        </div>
        <div class="float-left testDate">
            <label for="testDate" class="font-13">Test Date</label>
            <input matInput [matDatepicker]="picker1" placeholder="Select Date" [formControl]="testDate" 
                name="testDate" class="form-control" [readonly]="readonlyFlag">
            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="disableSelect"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </div>
        <div class="float-left vendor">
            <label for="testDate" class="font-13">Vendor</label>
            <mat-form-field floatLabel=never>
                <mat-select matNativeControl required  class="form-control" [formControl]="vendor"  [disabled]="disableSelect"
                    (selectionChange)="onSelectedVendor($event)" placeholder="Select Vendor">
                    <!-- <mat-option [value]="vendorSelected">{{vendorSelected}}</mat-option> -->
                    <mat-option *ngFor="let vendor of vendorData" [value]="vendor.vendorName"
                        placeholder="Please Select Vendor">
                       {{vendor.vendorName}}
                    </mat-option>
                </mat-select>
                <i class="bottomArrow pilot-selectAlign"></i>
               
                <mat-error>Please select a vendor</mat-error>
            </mat-form-field>
            <!-- <input matInput [matDatepicker]="picker1" placeholder="Select Date" [formControl]="pilotDate"
                name="pilotDate" class="form-control">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker> -->
        </div>
    </div>
    <form [formGroup]="headerEditForm" (ngSubmit)="updateNewPilot('DRAFT')">
        <table mat-table  [dataSource]="dataSourceHeaderEdit" 
            class="mat-elevation-z8 header inputSheet-table-create">

            <!-- Genre Column -->
            <ng-container matColumnDef="genre">
                <th mat-header-cell *matHeaderCellDef> GENRE </th>
                <td mat-cell *matCellDef="let element;">
                    <mat-form-field [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                        <mat-select matNativeControl required class="form-control readonly-block" formControlName="genre" [disabled]="disableSelect">
                            <mat-option [value]="selectedGenreId">{{selectedGenre}}</mat-option>
                            <mat-option *ngFor="let genre of genreList"   (onSelectionChange)="onSelectedGenre(genre.projectType)" [value]="genre.projectTypeId"
                                placeholder="Please Select Genre">
                                {{ genre.projectType }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <mat-error>Please select a genre</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Show Title Column -->
            <ng-container matColumnDef="showTitle">
                <th mat-header-cell *matHeaderCellDef> SHOW TITLE </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                        <mat-select matNativeControl required class="form-control readonly-block" formControlName="showTitle" [disabled]="disableSelect">
                            <mat-option [value]="selectedShowTitleId">{{selectedTitle}}</mat-option>
                            <mat-option *ngFor="let show of showTitleList" [value]="show.showTitleId"
                                placeholder="Please Select Show Title">
                                {{ show.showTitle }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <mat-error>Please select a show title</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Episode -->
            <ng-container matColumnDef="episodeNumber">
                <th mat-header-cell *matHeaderCellDef> EPISODE # </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <input matInput placeholder="Add Episode #" [value]="element.episodeNumber" class="form-control" OnlyNumber="true" formControlName="episodeNumber" [readonly]="readonlyFlag">
                        <mat-error>Please enter Episode</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Analyst Column -->
            <ng-container matColumnDef="analyst">
                <th mat-header-cell *matHeaderCellDef> ANALYST </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field>
                        <input matInput class="form-control" floatLabel=never formControlName="analyst" [readonly]="readonlyFlag">
                        <mat-error>Please enter an analyst</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Rough Cut Column -->
            <ng-container matColumnDef="roughCut">
                <th mat-header-cell *matHeaderCellDef> ROUGH CUT</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                        <mat-select matNativeControl required class="form-control readonly-block" formControlName="roughCut" [disabled]="disableSelect">
                            <mat-option value="Y">Yes</mat-option>
                            <mat-option value="N">No</mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <mat-error>Please select a rough cut</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef> VERSION </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                        <mat-select matNativeControl required class="form-control readonly-block" formControlName="version" [disabled]="disableSelect">
                            <!-- <mat-option [value]="selectedVersion">{{selectedVersion}}</mat-option> -->
                            <mat-option *ngFor="let version of versionList" [value]="version.titleVersionId"
                                placeholder="Please Select Version">
                                {{ version.titleVersionName  }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <!-- <input matInput placeholder="Add Version" formControlName="version" class="form-control"> -->
                        <mat-error>Please enter a version</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <button class="pilotGenerate" mat-raised-button color="primary" [disabled] ="disButtons">Generate</button> 
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsHeaderEdit"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsHeaderEdit;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsHeaderEdit"></tr>
        </table>
        <!-- <button class="savePilot float-right" mat-raised-button color="primary" type="submit">Generate</button> -->
    </form>

    <mat-accordion #accordion="matAccordion">

        <a class="exp-toggle text-right"><span (click)="toggleAll(toggle)">{{toggle ? 'Expand All' : 'Collapse All'}}
            </span></a>

        <!-- Section 1 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 1 of 8 </span>Attributes list
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section1Form">
                <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" formArrayName="section1Array">
                    <!-- Attribute Position Column -->
                    <ng-container matColumnDef="posattr">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element, let in = index" [formGroupName]="in">
                            <span> {{in+1}}</span>
                        </td>
                    </ng-container>
                    <!-- Attribute Column -->
                    <ng-container matColumnDef="attributes">
                        <th mat-header-cell *matHeaderCellDef> Attributes </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <!-- <span *ngIf="!onAddsec1"> {{element.attribute}}</span> -->
                            <input matInput class="form-control" placeholder="Attributes" [readonly]="!element.metaDataFlag || readonlyFlag"
                                (keyup)="updateVal(element,$event)" [value]="element.attributes" formControlName="attributes">
                            <mat-error *ngIf="hasErrorsection1Form(in,'pattern')  && submittedForm1">Please enter valid value</mat-error>
                            <mat-error *ngIf="hasErrorsection1Form(in,'required')  && submittedForm1">Please enter required value</mat-error>
                        </td>
                    </ng-container>
                    <!-- Select All Column -->
    
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected()" [indeterminate]="!isAllSelected()" [aria-label]="checkboxLabel()" labelPosition="after" class="readonly-block" [disabled]="disableSelect"> Select All</mat-checkbox>
                        </th>
    
                        <td mat-cell *matCellDef="let row, let i = index" [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="isCheckSelected($event,row)" [checked]="row.select" [aria-label]="checkboxLabel(row)" value="checkedbox" class="readonly-block" [disabled]="disableSelect">
                            </mat-checkbox>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
    
               
                <i class="addnewIcon float-left" [ngClass]="disButtons?  'disabled-button' :'' "
                    (click)="onAddattrRow()"></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="attributeSave()">Save</button> -->
                <i class="saveIcon" (click)="attributeSave(section1Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>
       

        <!-- Section 2 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 2 of 8 </span> Characters
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section2Form">
                <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8" formArrayName="section2Array">
                    <!-- Position Character Definition -->
                    <ng-container matColumnDef="poschar">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" [value]="element.poschar" *ngIf="onAddsec2"
                        formControlName="poschar"> -->
                        </td>
                    </ng-container>
                    <!-- Name Definition -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add name" (keyup)="updateValname(element,$event)" [value]="element.name" formControlName="name" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.name.invalid && submittedForm2">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection2Form(in,'name','pattern')  && submittedForm2">Please enter valid value</mat-error>
                            <mat-error *ngIf="hasErrorsection2Form(in,'name','required')  && submittedForm2">Please enter required value</mat-error>
                        </td>
                    </ng-container>
                    <!-- Description Definition -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add description"
                                (keyup)="updateValdesc(element,$event)" [value]="element.description" 
                                formControlName="description" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.description.invalid &&
                                submittedForm2">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection2Form(in,'description','pattern')  && submittedForm2">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection2Form(in,'description','required')  && submittedForm2">Please enter required value</mat-error>
                        </td>
                    </ng-container>
                    <!-- Gender Definition -->
                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef> Gender(M/F) </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in" class="colGender">
                            <mat-radio-group formControlName="gender" [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                                <mat-radio-button *ngFor="let m of array" [value]="m" 
                                [checked]="element.gender =='M' && m=='Male' ? true : element.gender=='F' && m=='Female'? true:false"
                                 (change)="radioChange(element,$event)" [disabled]="disableSelect" [ngClass]="disableSelect ? 'readonly-block' : ''">
                                    {{m}} </mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.gender.invalid &&
                                submittedForm2">Please enter valid value</mat-error> -->
                                <!-- <mat-error *ngIf="hasErrorsection2Form(in,'gender','pattern')  && submittedForm2">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection2Form(in,'gender','required')  && submittedForm2">Please enter valid value</mat-error>
                        </td>
                    </ng-container>
                    <!-- Lead Definition -->
                    <ng-container matColumnDef="lead">
                        <th mat-header-cell *matHeaderCellDef> Lead(X) </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in" [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                            <mat-checkbox (change)="onChange(element, $event)" [checked]="element.lead" [disabled]="disableSelect" class="readonly-block"></mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- No Open End Definition -->
                    <ng-container matColumnDef="noOpenEnd">
                        <th mat-header-cell *matHeaderCellDef> No Open End ? (X) </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in" [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                            <mat-checkbox (change)="onChangeOpenend(element, $event)" [checked]="element.noOpenEnd" [disabled]="disableSelect" class="readonly-block"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" 
                                (click)="deletechar(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddcharRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="charsecSave()">Save</button> -->
                <i class="saveIcon" (click)="characterSave(section2Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 3 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 3 of 8 </span> Actor Familiarity
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section3Form">
                <table mat-table [dataSource]="dataSource3" class="mat-elevation-z8" formArrayName="section3Array">
                    <!-- Position Actor Definition -->
                    <ng-container matColumnDef="posact">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" [value]="element.posact" *ngIf="onAddsec3"
                        formControlName="posact"> -->
                        </td>
                    </ng-container>
    
                    <!-- Actor Name Definition -->
                    <ng-container matColumnDef="actorname">
                        <th mat-header-cell *matHeaderCellDef> Actor/Actress Name </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add Actor/Actress Name"
                                (keyup)="updateValActname(element,$event)" [value]="element.actorname" 
                                formControlName="actorname" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section3Form.controls.section3Array.controls[in].controls.actorname.invalid && submittedForm3">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection3Form(in,'actorname','pattern')  && submittedForm3">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection3Form(in,'actorname','required')  && submittedForm3">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <!-- Gender Definition -->
                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef> Gender(M/F) </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <mat-radio-group formControlName="gender" [ngClass]="disableSelect ? 'readonly-wrapper' : ''">
                                <mat-radio-button *ngFor="let m of array" labelPosition="after" [value]="m" 
                                [checked]="element.gender =='M' && m=='Male' ? true : element.gender=='F' && m=='Female'? true:false" (change)="radioChangeact(element,$event)" class="readonly-block" [disabled]="disableSelect"> {{m}} </mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="section3Form.controls.section3Array.controls[in].controls.gender.invalid && submittedForm3">Please enter valid value</mat-error> -->
                            <!-- <mat-error *ngIf="hasErrorsection3Form(in,'actorname','pattern')  && submittedForm2">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection3Form(in,'gender','required')  && submittedForm3">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteAct(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddactRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)=" actsecSave()">Save</button> -->
                <i class="saveIcon" (click)="actsecSave(section3Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 4 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 4 of 8 </span>Relationships
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section4Form">
                <table mat-table [dataSource]="dataSource4" class="mat-elevation-z8" formArrayName="section4Array">
                    <!-- Position Relationships Definition -->
    
                    <ng-container matColumnDef="posrel">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" value={{i+1}}> -->
                        </td>
                    </ng-container>
    
                    <!-- Relationships Definition -->
                    <ng-container matColumnDef="relationships">
                        <th mat-header-cell *matHeaderCellDef> Relationships </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add Relationships"
                                (keyup)="updateValRel(element,$event)" [value]="element.relationships" 
                                formControlName="relationships" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section4Form.controls.section4Array.controls[in].invalid &&
                                submittedForm4">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection4Form(in,'relationships','pattern')  && submittedForm4">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection4Form(in,'relationships','required')  && submittedForm4">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td *matCellDef="let element; let in=index" [formGroupName]="in" mat-cell>
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteRel(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns4"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddrelRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="relsecSave()">Save</button> -->
                <i class="saveIcon" (click)="relsecSave(section4Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 5 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 5 of 8 </span> Statements
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                    <p>
                        <label>NBC ANALYST NOTES:</label>
                        <span>For those statements NOT used for this study, please cross them out – Do not delete them.</span>
                        <span>To add statements, type them in below.</span>
                    </p>
                    <p>
                        <label>PROGRAMMER'S NOTE:</label>
                        <span>If Poor Taste Statement is crossed-out, do not include QStd16a and QStd16b)</span>
                    </p>      
                </div>
            <form [formGroup]="section5Form">
                <table mat-table [dataSource]="dataSource5" class="mat-elevation-z8" formArrayName="section5Array">

                    <!-- Position Statement Definition -->
                    <ng-container matColumnDef="posstat">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" [value]="element.posstat" *ngIf="onAddsec5"
                        formControlName="posstat"> -->
                        </td>
                    </ng-container>
    
                    <!-- Statements Definition -->
                    <ng-container matColumnDef="statements">
                        <th mat-header-cell *matHeaderCellDef> Statements </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add Statements"
                                (keyup)="updateValstat(element, $event)" [value]="element.statements" 
                                formControlName="statements" [readonly]="!element.metaDataFlag || readonlyFlag">
                            <!-- <mat-error *ngIf="section5Form.controls.section5Array.controls[in].invalid &&
                                submittedForm5">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection5Form(in,'statements','pattern')  && submittedForm5">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection5Form(in,'statements','required')  && submittedForm5">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteStat(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns5"></tr>
    
                </table>
                <i class="addnewIcon float-left" (click)="onAddStatRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="statsecSave()">Save</button> -->
                <i class="saveIcon" (click)="statsecSave(section5Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 6 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 6 of 8 </span>Story Elements
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section6Form">
                <table mat-table [dataSource]="dataSource6" class="mat-elevation-z8" formArrayName="section6Array">

                    <!-- Position Story Elements Definition -->
                    <ng-container matColumnDef="posstoryelem">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" [value]="element.posstoryelem" *ngIf="onAddsec6"
                        formControlName="posstoryelem"> -->
                        </td>
                    </ng-container>
    
                    <!-- Story Elements Definition -->
                    <ng-container matColumnDef="storyelements">
                        <th mat-header-cell *matHeaderCellDef> Story Elements </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add Story Elements"
                                (keyup)="updateValstory(element, $event)" [value]="element.storyelements" 
                                formControlName="storyelements" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section6Form.controls.section6Array.controls[in].invalid &&
                                submittedForm6">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection6Form(in,'storyelements','pattern')  && submittedForm6">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection6Form(in,'storyelements','required')  && submittedForm6">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteStoryElem(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns6"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns6"></tr>
                </table>
    
                <i class="addnewIcon float-left" (click)="onAddstoryelemRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="storysecSave()">Save</button> -->
                <i class="saveIcon" (click)="storysecSave(section6Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 7 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 7 of 8 </span> Viewership
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section7Form">
                <table mat-table [dataSource]="dataSource7" class="mat-elevation-z8" formArrayName="section7Array">
                    <!-- Position Statement Definition -->
                    <ng-container matColumnDef="posview">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <span>{{in+1}}</span>
                            <!-- <input matInput placeholder="Add sequence" [value]="element.posview" *ngIf="onAddsec7"
                        formControlName="posview"> -->
                        </td>
                    </ng-container>
    
                    <!-- Statements Definition -->
                    <ng-container matColumnDef="viewshowtitle">
                        <th mat-header-cell *matHeaderCellDef> Show Title </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input matInput class="form-control" placeholder="Add Show Title"
                                (keyup)="updateValtitle(element, $event)" [value]="element.viewShowTitle"
                                formControlName="viewShowTitle" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section7Form.controls.section7Array.controls[in].invalid &&
                                submittedForm7">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection7Form(in,'viewShowTitle','pattern')  && submittedForm7">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection7Form(in,'viewShowTitle','required')  && submittedForm7">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteView(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns7"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns7"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddViewRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="viewsecSave()">Save</button> -->
                <i class="saveIcon" (click)="viewsecSave(section7Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

        <!-- Section 8 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 8 of 8 </span> Question & Stim Changes
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                    <p>
                        <label>NBC ANALYST NOTES:</label>
                        <span>For each question or Stim to be deleted, type in its label and the first five words of that question or stim.</span>
                    </p>
                    <p>
                        <label>PROGRAMMER'S NOTE:</label>
                        <span>Completely remove the following question(s) &/or stim(s) from questionnaire.</span>
                    </p>      
                </div>
            <form [formGroup]="section8Form">
                <table mat-table [dataSource]="dataSource8" class="mat-elevation-z8" formArrayName="section8Array">

                    <!-- Position Statement Definition -->
                    <ng-container matColumnDef="posques">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                            <!-- <span *ngIf="!onAddsec5">{{element.posstat}}</span>
                        <input matInput placeholder="Add sequence" [value]="element.posstat" *ngIf="onAddsec5"
                            formControlName="posstat"> -->
                        </td>
                    </ng-container>
    
                    <!-- Question and Stim Changes Definition -->
                    <ng-container matColumnDef="quesnstim">
                        <th mat-header-cell *matHeaderCellDef> Question and Stim Changes </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Question and Stim Changes"
                                [value]="element.quesnstim" formControlName="quesnstim"
                                (keyup)="updateQues(element,$event)" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section8Form.controls.section8Array.controls[in].invalid &&
                                submittedForm8">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection8Form(in,'quesnstim','pattern')  && submittedForm8">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection8Form(in,'quesnstim','required')  && submittedForm8">Please enter required value</mat-error>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteQues(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>
    
    
                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns8"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns8"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddQuesRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="statSave()">Save</button> -->
                <i class="saveIcon" (click)="quesSave(section8Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
            
        </mat-expansion-panel>

    </mat-accordion>
</div>