<div class="tableTitleHeader px-2 mt-3 mb-3 InputSheetHeader" *ngIf="!pilotDeclaration.communication">
    <h5 class="float-left font-20">InputSheet - {{pilotDeclaration.inputSelecteName}}</h5>
    <mat-form-field appearance="outline">
       <mat-select [formControl]="inputSheetTypeControl" class="form-control">
          <mat-option *ngFor="let inputSheetType of inputSheetType" [value]="inputSheetType.value">
             {{inputSheetType.viewValue}}
          </mat-option>
       </mat-select>
       <i class="bottomArrow inputsheet-selectAlign"></i>
    </mat-form-field>
    <div class="float-right createSection" *ngIf="isAdmin">
       <span *ngIf="pilotDeclaration.tooltip" matTooltip="Data is loading please wait until it completes">
          <p class="float-left font-13 blue">
             <!-- <span>{{newSurvey}}</span> -->
             <i class="addnewIcon"></i>
          </p>
          <i class="excelIcon"></i>
       </span>
       <span *ngIf="!pilotDeclaration.tooltip">
          <span *ngIf="sheetType === 1">
             <p class="float-left font-13 blue" (click)="sendMessage()" routerLink="../internetadd">
                <span>Enter New Input Sheet</span>
                <i class="addnewIcon"></i>
             </p>
             <p class="float-left ml-3" *ngIf="tooltip" matTooltip="Data is loading please wait until it completes">
                <i class="excelIcon"></i>
             </p>
             <p class="float-left ml-3" *ngIf="!tooltip">
                <i class="excelIcon" (click)="exportExcel()"></i>
             </p>
          </span>
          <span *ngIf="sheetType === 2">
             <p class="float-left font-13 blue" routerLink="../printadd">
                <span>Enter New Input Sheet</span>
                <i class="addnewIcon"></i>
             </p>
             <p class="float-left ml-3">
                <i class="excelIcon" (click)="exportExcel()"></i>
             </p>
          </span>
          <span *ngIf="sheetType === 3">
             <p class="float-left font-13 blue" routerLink="../promoadd">
                <span>Enter New Input Sheet</span>
                <i class="addnewIcon"></i>
             </p>
             <p class="float-left ml-3">
                <i class="excelIcon" (click)="exportExcel()"></i>
             </p>
          </span>
          <span *ngIf="sheetType === 4">
            <p class="float-left font-13 blue" routerLink="../internetadd">
               <span>Enter New Input Sheet</span>
               <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3">
               <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
         </span>
         <span *ngIf="sheetType === 5">
            <p class="float-left font-13 blue" routerLink="../othersadd">
               <span>Enter New Input Sheet</span>
               <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3">
               <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
         </span>
       </span>
    </div>
 </div>
 <div class="inputsheet-table pilotTable">
    <div class="spinnerOverlay"  *ngIf="isLoading"></div>
    <div class="spinner" *ngIf="isLoading">
       <div class="bounceone"></div>
       <div class="bouncetwo"></div>
       <div class="bouncethree"></div>
       <div class="bouncefour"></div>
       <div class="bouncefive"></div>
       <div class="bouncesix"></div>
    </div>
    <cdk-virtual-scroll-viewport class="demo-viewport demo-table-container" itemSize="10">
 
       <table mat-table [dataSource]="matTableDataSource" matSort matSortDisableClear [multiTemplateDataRows]="true">
 
          <ng-container matColumnDef="inputSheetName">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[0] | uppercase}}
                <i class="sortIcon"></i>
             </th>
             <td mat-cell *matCellDef="let element"> {{element.inputSheetName}} </td>
             <td mat-footer-cell *matFooterCellDef colspan="11" class="sticky-header">
                <span *ngIf="pilotDeclaration.gridTotalCount; else elseBlock">Showing {{pilotDeclaration.gridTotalCount}}
                   Record(s)</span>
                <ng-template #elseBlock>{{pilotDeclaration.tableErrorBlock}}</ng-template>
             </td>
          </ng-container>
 
          <ng-container matColumnDef="genreDesc">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[1] | uppercase}} <i class="sortIcon"></i></th>
             <td mat-cell *matCellDef="let element"> {{element.genreDesc}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="showTitle">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[2] | uppercase}}
                <i class="sortIcon"></i>
             </th>
             <td mat-cell *matCellDef="let element"> {{element.showTitle}} </td>
             <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
 
          <ng-container matColumnDef="episode">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[3] | uppercase}}
                <i class="sortIcon"></i>
             </th>
             <td mat-cell *matCellDef="let element"> {{element.episode}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="surveyAnalyst">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[4] | uppercase}}
                <i class="sortIcon"></i>
             </th>
             <td mat-cell *matCellDef="let element"> {{element.surveyAnalyst}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="roughCut">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[5] | uppercase}} <i class="sortIcon"></i></th>
             <td mat-cell *matCellDef="let element"> {{element.roughCut}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="titleVersionName">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[6] | uppercase}}<i class="sortIcon"></i> </th>
             <td mat-cell *matCellDef="let element"> {{element.titleVersionName}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="createdDt">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[7] | uppercase}}<i class="sortIcon"></i> </th>
             <td mat-cell *matCellDef="let element"> {{element.createdDt | date: 'MM/dd/yyyy'}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="lastUpdatedDt">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[8] | uppercase}}<i class="sortIcon"></i> </th>
             <td mat-cell *matCellDef="let element"> {{element.lastUpdatedDt | date: 'MM/dd/yyyy'}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="inputSheetStatus">
             <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
                {{pilotDeclaration.pilotTableHeaderColumns[9] | uppercase}} <i class="sortIcon"></i></th>
             <td mat-cell *matCellDef="let element"> {{element.inputSheetStatus}} </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="action">
             <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{pilotDeclaration.pilotTableHeaderColumns[10] | uppercase}} </th>
             <td mat-cell *matCellDef="let element" class="StatusCopyIcon">
                <i class="makeCopyIcon" *ngIf="isAdmin" (click)="onCopy(element)"></i>
                <i class="" [ngClass]="!isAdmin ? 'isUser viewIcon':'editIcon'" (click)="onEdit(element)"></i>
             </td>
             <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
 
          <ng-container matColumnDef="filter_inputSheetName">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.inputSheetNameControl" placeholder="Question Category">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let optionData of pilotDeclaration.inputsheetNameData" [value]="optionData">
                         {{optionData}}
                      </mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
 
          <ng-container matColumnDef="filter_genreDesc">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.genreDescControl" placeholder="All">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let optionData of pilotDeclaration.genreData" [value]="optionData">
                         {{optionData}}
                      </mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
 
          <ng-container matColumnDef="filter_showTitle">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.showTitleControl" placeholder="Question Category">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let optionData of pilotDeclaration.showtitleData" [value]="optionData">
                         {{optionData}}
                      </mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
 
          <ng-container matColumnDef="filter_episode#">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <input matInput type="text" placeholder="Search Here" [formControl]="pilotDeclaration.episodeControl"
                      autocomplete="off">
                   <i class="autocomplete-search"></i>
                   <!-- <button mat-button *ngIf="pilotDeclaration.episodeControl.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="pilotDeclaration.episodeControl.value=''">
                      <mat-icon>close</mat-icon> -->
                   <!-- <mat-option *ngFor="let roughCut of pilotDeclaration.filterRougtCut" [value]="roughCut.rougtcutid">
                         {{roughCut.rougtcutvalue}}
                      </mat-option> -->
                   <!-- </mat-select> -->
                   <!-- <i class="bottomArrow filter-selectAlign"></i> -->
                </mat-form-field>
             </th>
          </ng-container>
 
          <ng-container matColumnDef="filter_surveyAnalyst">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <input matInput type="text" placeholder="Search Here"
                      [formControl]="pilotDeclaration.surveyAnalystControl" autocomplete="off">
                   <i class="autocomplete-search"></i>
                   <button mat-button matSuffix mat-icon-button aria-label="Clear">
                      <mat-icon>close</mat-icon>
                   </button>
                </mat-form-field>
             </th>
          </ng-container>
 
          <ng-container matColumnDef="filter_roughCut">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.roughCutControl" placeholder="All">
                      <mat-option *ngFor="let roughCut of pilotDeclaration.filterRougtCut" [value]="roughCut.rougtcutid">
                         {{roughCut.rougtcutvalue}}
                      </mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
          <ng-container matColumnDef="filter_titleVersionName">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.titleVersionNameControl">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let optionData of pilotDeclaration.titleVersionData" [value]="optionData">
                         {{optionData}}
                      </mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
          <ng-container matColumnDef="filter_createdate">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <span matTooltip="{{pilotDeclaration.createdate}}"><app-daterange-picker-component (emitRange)="getDates($event, 'createdDt')">
                </app-daterange-picker-component></span>
             </th>
          </ng-container>
          <ng-container matColumnDef="filter_modifieddate">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <span matTooltip="{{pilotDeclaration.lastdate}}"> <app-daterange-picker-component (emitRange)="getDates($event, 'lastUpdatedDt')">
                </app-daterange-picker-component> </span>
             </th>
          </ng-container>
          <ng-container matColumnDef="filter_status">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <mat-form-field appearance="outline">
                   <mat-select [formControl]="pilotDeclaration.inputSheetStatusControl" placeholder="All">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let StatusData of pilotDeclaration.inputSheetStatusData" [value]="StatusData">
                         {{StatusData}}</mat-option>
                   </mat-select>
                   <i class="bottomArrow filter-selectAlign"></i>
                </mat-form-field>
             </th>
          </ng-container>
          <ng-container matColumnDef="filter_action">
             <th mat-header-cell *matHeaderCellDef class="sticky-header">
                <!-- <button mat-button color="primary" >Clear</button> -->
                <button mat-stroked-button class="clearFilter ClearAllWtext" color="primary"
                   (click)="clearGridTable()">Clear All
                   Filters</button>
                <span class="makeCopyText" *ngIf="isAdmin">Make a Copy</span> <span class="editText" [ngClass]="!isAdmin ? 'isUser':''">{{(isAdmin) ? 'Edit' : 'View'}}</span>
             </th>
          </ng-container>
 
          <tr mat-header-row *matHeaderRowDef="pilotDeclaration.pilotTableDisplayColumns;sticky: true; "
             class="table-header-section">
          </tr>
          <tr mat-header-row *matHeaderRowDef="pilotDeclaration.pilotTabelFooterColumns;sticky: true; "
             class="table-filter-section">
          </tr>
          <tr mat-row *matRowDef="let row; columns: pilotDeclaration.pilotTableDisplayColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="pilotDeclaration.pilotTableDisplayColumns; sticky: true"
             class="table-footer-section"></tr>
       </table>
 
    </cdk-virtual-scroll-viewport>
 
 </div>