import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AppConfig } from '../../../../app.config';
import { FormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormArray } from '@angular/forms';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { map, startWith, tap, debounceTime, switchMap, finalize, takeUntil } from 'rxjs/operators';

import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { SurveyDashboardComponent } from '../../components/survey-dashboard/survey-dashboard.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyCreateRightPopup } from 'src/app/models/surveycreate';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss']
})

export class CreateSurveyComponent implements OnInit, OnDestroy {
  appConstants = AppConfig.settings;
  createsurveyDec: SurveyCreateRightPopup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatAutocompleteTrigger, { static: true }) auto: MatAutocompleteTrigger;
  subscription: Subscription;
  constructor(
    private createSurveyRef: MatDialogRef<SurveyDashboardComponent>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private communicationservice: CommunicatingComponentService,
    private awsCommonService: AwsCommonService,
    private notificationService: NotificationService
  ) {

    this.createsurveyDec = {
      inputSheetLabel: 'Select Input Sheet',
      templateLabel: 'Select template',
      createSurveyForm: this.formBuilder.group({
        InputSheetName: new UntypedFormControl('', [Validators.required]),
        TemplateName: new UntypedFormControl('', [Validators.required]),
        SurveyDesc :new UntypedFormControl('',[Validators.required])
      }),
      formbuilder: '',
      InputSheetList: '',
      selectedTemplate: '',
      dataInputsheet: '',
      dataTemplate: '',
      selectedInputSheet: '',
      filteredInputsheet: [],
      filteredTemplate: '',
      isLoading: true
    };
    if (this.appConstants.checkServerURL) {
      this.createsurveyDec.surveyCreateFetchTemplateAPI =
        `${this.appConstants.serverUrl}/${this.appConstants.surveyURL}/${this.appConstants.surveyCreateFetchTemplate}`;
      this.createsurveyDec.surveyCreateFetchInputSheetAPI =
        `${this.appConstants.serverUrl}/${this.appConstants.surveyURL}/${this.appConstants.surveyCreateFetchInputSheet}`;

    } else {
      this.createsurveyDec.surveyCreateFetchTemplateAPI =
        `${this.appConstants.surveyCreateFetchTemplateLocalURL}`;
      this.createsurveyDec.surveyCreateFetchInputSheetAPI =
        `${this.appConstants.surveyCreateFetchInputSheetLocalURL}`;
    }
  }

  ngOnInit() {

    const dataInputSheetList: any[] = [];
    const dataTemplateList: any[] = [];
    this.createsurveyDec.isLoading = true;
    this.awsCommonService.getRequest(`${this.createsurveyDec.surveyCreateFetchInputSheetAPI}`, 'GET')
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        dataInputSheet => {
          this.createsurveyDec.dataInputsheet = dataInputSheet;
          this.createsurveyDec.dataInputsheet.map((item, index) => {
            if (typeof (item.inputSheetName) !== 'undefined' && item.inputSheetName != null) {
              // if (item.activeYn) {
              dataInputSheetList.push({
                inputSheetName: item.inputSheetName,
                inputSheetId: item.inputSheetId,
                surveyAnalyst: item.surveyAnalyst,
                programmerNotes: item.programmerNotes,
                status: item.status
              });
              // }
            }
          });
          if (dataInputSheetList.length > 0) {
            this.createsurveyDec.dataInputsheet = dataInputSheetList;
            this.createsurveyDec.filteredInputsheet = this.createsurveyDec.dataInputsheet.slice();
          } else {
           // this.notificationService.warn('Empty Object for InputSheet..!');
          }
        },
        error => {
          console.log('inputsheet Error');
          this.createsurveyDec.isLoading = false;
         // this.notificationService.warn('Empty Object for InputSheet..!');
        },
        () => {
          this.createsurveyDec.isLoading = true;
          this.awsCommonService.getRequest(`${this.createsurveyDec.surveyCreateFetchTemplateAPI}`, 'GET')
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe(
              resultTemplate => {
                this.createsurveyDec.dataTemplate = resultTemplate;
                this.createsurveyDec.dataTemplate.map((item, index) => {
                  if (typeof (item.templateDescription) !== 'undefined' && item.templateDescription != null) {
                    // if (item.activeYN) {
                    dataTemplateList.push({
                      surveyTemplateId: item.surveyTemplateId,
                      templateDescription: item.templateDescription,
                      templateType: item.templateType,
                      displaySequenceNumber: item.displaySequenceNumber,
                      activeYN: item.activeYN
                    });
                    // }
                  }
                });
                if (dataTemplateList.length > 0) {
                  this.createsurveyDec.dataTemplate = dataTemplateList;
                  this.createsurveyDec.filteredTemplate = dataTemplateList.slice();
                } else {
                 // this.notificationService.warn('Empty Object for Template..!');
                }
              },
              error => {
                console.log('Tempalte Error');
                this.createsurveyDec.isLoading = false;
               // this.notificationService.warn('Empty Object for Template..!');
              },
              () => {
                this.createsurveyDec.isLoading = false;
              }
            );
        }
      );
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    // this.subscription.unsubscribe();
  }

  openLink(event: MouseEvent): void {
    this.createSurveyRef.close('cancel');
    event.preventDefault();
    this.subscription.unsubscribe();
  }
  surveyCreate(data) {
    this.router.navigate(['../survey/dashboard'], { relativeTo: this.route });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.createsurveyDec.createSurveyForm.controls[controlName].hasError(errorName);
  }

  createSurvey() {
    if (this.createsurveyDec.createSurveyForm.invalid) {
      return false;
    } else {
      console.log(this.createsurveyDec.createSurveyForm.value);
      this.createSurveyRef.close(this.createsurveyDec.createSurveyForm.value);
    }
  }


}
