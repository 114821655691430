<div class="spinnerOverlay"  *ngIf="spinnerMTemplate"></div>
<div class="spinner" *ngIf="spinnerMTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<div class="col-md-11 contentSection pilotMain" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">Pilot - <span>{{createTitle}}</span></h5>
        <mat-card-actions class="float-right">
            <button mat-raised-button color="primary" (click)="openconfirmModal('Submit','')" [disabled]="!generated"
                matTooltip="Please Enter details below and Save">SUBMIT</button>
            <!-- <button *ngIf="!notSaved" mat-raised-button color="primary" (click)="submitNewPilot()">Submit</button> -->
            <!-- <button class="savePilot" mat-raised-button color="primary" (click)="createNewPilot()">Save</button> -->
            <button type="button" mat-raised-button color="warn" (click)="openconfirmModal('cancel','')">CANCEL</button>
        </mat-card-actions>
    </div>
    <div>
        <div class="float-left programmerNotes" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Programmer Notes</label>
            <!-- <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add programmer Notes" value="" [formControl]="programmerNotes" name="programmerNotes" class="form-control"> -->
            <textarea class="form-control" placeholder="Add programmer Notes" name="programmerNotes" 
                [formControl]="programmerNotes"></textarea>
            <mat-error *ngIf="programmerNotes.invalid && programmerNotes.touched">Please Enter valid value</mat-error>
        </div>
        <div class="float-left testDate">
            <label for="testDate" class="font-13">Test Date</label>
            <input matInput [matDatepicker]="picker1" placeholder="Select Date" [formControl]="testDate" name="testDate"
                class="form-control">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </div>
             
    </div>
    <form (ngSubmit)="createNewPilot('DRAFT')" [formGroup]="headerCreateForm">
            <div class="float-left vendor">
                    <label for="testDate" class="font-13">Vendor</label>
                    <mat-form-field floatLabel=never >
                        <mat-select matNativeControl required class="form-control" formControlName="vendor"
                            (selectionChange)="onSelectedVendor($event)" placeholder="Select Vendor">
                            <mat-option *ngFor="let vendor of vendorData" [value]="vendor.vendorName"
                                placeholder="Please Select Vendor">
                               {{vendor.vendorName}}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                       
                        <mat-error *ngIf="vendor.invalid">Please select vendor</mat-error>
                    </mat-form-field>           
                </div> 
        <table mat-table [dataSource]="dataSourceHeaderCreate" class="mat-elevation-z8 header inputSheet-table-create">
            <!-- Genre Column -->
            <ng-container matColumnDef="genre">
                <th mat-header-cell *matHeaderCellDef> GENRE </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <mat-select matNativeControl required formControlName="genre" class="form-control"
                            (selectionChange)="onSelectedGenre($event)" placeholder="Select Genre">
                            <mat-option *ngFor="let genre of genreList" [value]="genre.projectTypeId"
                                placeholder="Please Select Genre">
                                {{ genre.projectType }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <!-- <input type="hidden" value="genre.projectTypeId"> -->
                        <mat-error>Please select a genre</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Show Title Column -->
            <ng-container matColumnDef="showTitle">
                <th mat-header-cell *matHeaderCellDef> SHOW TITLE </th>
                <td mat-cell *matCellDef="let element">
                    <input type="hidden" value="">
                    <mat-form-field floatLabel=never>
                        <mat-select matNativeControl placeholder="Select Show Title" required class="form-control"
                            formControlName="showTitle">
                            <mat-option *ngFor="let show of showTitleList" [value]="show.primeShowTitleId"
                                placeholder="Please Select Show Title">
                                {{ show.showTitle }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <mat-error>Please select a show title</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Episode Column -->
            <ng-container matColumnDef="episodeNumber">
                <th mat-header-cell *matHeaderCellDef> EPISODE # </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <input matInput required placeholder="Add Episode #" [value]="element.episodeNumber"
                            class="form-control" OnlyNumber="true" formControlName="episodeNumber">
                        <mat-error>Please enter Episode #</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Analyst Column -->
            <ng-container matColumnDef="analyst">
                <th mat-header-cell *matHeaderCellDef> ANALYST </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <input matInput required placeholder="Add Analyst" [value]="element.analyst"
                            class="form-control" formControlName="analyst">
                        <mat-error>Please enter an analyst</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Rough Cut Column -->
            <ng-container matColumnDef="roughCut">
                <th mat-header-cell *matHeaderCellDef> ROUGH CUT </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <mat-select matNativeControl required class="form-control" placeholder="Select Rough Cut"
                            formControlName="roughCut">
                            <mat-option value="Y" placeholder="Please Select Rough Cut">Yes</mat-option>
                            <mat-option value="N" placeholder="Please Select Rough Cut">No</mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <mat-error>Please select a rough cut</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef> VERSION </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel=never>
                        <mat-select matNativeControl required class="form-control" placeholder="Select Version"
                            formControlName="version">
                            <!-- <mat-option value="1">NC1</mat-option>
                            <mat-option value="2">NC2</mat-option> -->
                            <mat-option *ngFor="let version of versionList" [value]="version.titleVersionId"
                                placeholder="Please Select Version">
                                {{ version.titleVersionName }}
                            </mat-option>
                        </mat-select>
                        <i class="bottomArrow pilot-selectAlign"></i>
                        <!-- <input matInput placeholder="Add Version" [value]="element.version" class="form-control"
                            formControlName="version"> -->
                        <mat-error>Please enter a version</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <button class="pilotGenerate" type="submit" mat-raised-button color="primary">GENERATE</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsHeaderCreate" class="table-header-section"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsHeaderCreate;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsHeaderCreate"></tr>
        </table>
        <!-- <button class="savePilot float-right" mat-raised-button color="primary" type="submit">Generate</button> -->
    </form>

    <div [hidden]="!generated">
        <mat-accordion #accordion="matAccordion">
            <a class="exp-toggle text-right">
                <span (click)="toggleAll(toggle)">{{toggle ? 'Expand All' : 'Collapse All'}}
                </span></a>

            <!-- Section 1 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 1 of 8 </span>Attributes list
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="section1Form">
                    <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" formArrayName="section1Array">

                        <!-- Attribute Position Column -->
                        <ng-container matColumnDef="posattr">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <span> {{in+1}}</span>
                                <!-- <input matInput placeholder="Add sequence" [value]="element.posattr" *ngIf="onAddsec1"
                    formControlName="posattr">  -->
                            </td>
                        </ng-container>

                        <!-- Attribute Column -->
                        <ng-container matColumnDef="attributes">
                            <th mat-header-cell *matHeaderCellDef> Attributes </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <!-- <span *ngIf="!onAddsec1"> {{element.attribute}}</span> -->
                                <input matInput class="form-control" placeholder="Attributes"
                                    (keyup)="updateAttrVal(element,$event)" [value]="element.attributes" trim="blur" (paste)="onPasteAttr($event,in)"
                                    formControlName="attributes" [readonly]="!element.metaDataFlag">
                                <!-- <mat-error *ngIf="section1Form.controls.section1Array.controls[in].invalid &&
                                submittedForm1">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection1Form(in,'pattern')  && submittedForm1">Please enter
                                    valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection1Form(in,'required')  && submittedForm1">Please enter
                                    required value</mat-error>
                            </td>
                            <!-- <mat-error *ngIf="hasError('attributes', 'required')">Please enter attribute</mat-error>
                        <mat-error *ngIf="hasError('attributes', 'incorrect')">Please enter attribute</mat-error> -->
                        </ng-container>

                        <!-- Select All Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected()"
                                    [indeterminate]="!isAllSelected()" [aria-label]="checkboxLabel()"
                                    labelPosition="after">
                                    Select All</mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row, let in = index" [formGroupName]="in">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="isCheckSelected($event,row)"
                                    [checked]="row.select" [aria-label]="checkboxLabel(row)" value="checkedbox">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddattrRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="attributeSave()">Save</button> -->
                    <i class="saveIcon" (click)="attributeSave(section1Form)"></i>
                </form>
            </mat-expansion-panel>

            <!-- Section 2 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 2 of 8 </span> Characters
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="section2Form">
                    <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8" formArrayName="section2Array">

                        <ng-container matColumnDef="poschar">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}} </td>
                        </ng-container>

                        <!-- Name Definition -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input matInput class="form-control" matInput placeholder="Add Name"
                                    [value]="element.name" formControlName="name" trim="blur" (paste)="onPasteChar($event,in)" 
                                    (keyup)="updateCharName(element,$event,in)"> 
                                <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.name.invalid &&
                                submittedForm2">Please Enter Valid Name</mat-error> -->
                                <mat-error *ngIf="hasErrorsection2Form(in,'name','pattern')  && submittedForm2">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection2Form(in,'name','required')  && submittedForm2">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <!-- Description Definition -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input matInput class="form-control" placeholder="Add Description"
                                    [value]="element.description" formControlName="description" trim="blur" (paste)="onPaste($event,in,'desc')"
                                    (keyup)="updateCharDes(element,$event)">
                                <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.description.invalid &&
                                submittedForm2">Please Enter Valid Description</mat-error> -->
                                <mat-error *ngIf="hasErrorsection2Form(in,'description','pattern')  && submittedForm2">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection2Form(in,'description','required')  && submittedForm2">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <!-- Gender Definition -->
                        <ng-container matColumnDef="gender">
                            <th mat-header-cell *matHeaderCellDef> Gender(M/F) </th>
                            <td mat-cell *matCellDef="let element; let in=index" class="colGender" [formGroupName]="in">
                                <mat-radio-group formControlName="gender">
                                    <!-- <mat-radio-button *ngFor="let m of array" [value]="m" [checked]="mapgenderM "
                                    (change)="radioChange(element,$event)">
                                    {{m}} </mat-radio-button> -->
                                    <mat-radio-button *ngFor="let m of array" labelPosition="after" [value]="m"
                                        (change)="radioChangeact(element,$event)"
                                        [checked]="element.gender =='M' && m=='Male' ? true : element.gender=='F' && m=='Female'? true:false">
                                        {{m}} </mat-radio-button>
                                </mat-radio-group>
                                <!-- <mat-error *ngIf="section2Form.controls.section2Array.controls[in].controls.gender.invalid &&
                                submittedForm2">Please Select Gender</mat-error> -->
                                <mat-error *ngIf="hasErrorsection2Form(in,'gender','required')  && submittedForm2">Please enter valid value</mat-error>
                            </td>
                        </ng-container>

                        <!-- Lead Definition -->
                        <ng-container matColumnDef="lead">
                            <th mat-header-cell *matHeaderCellDef> Lead(X) </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox (change)="onChange(element, $event)" [checked]="element.lead">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- No Open End Definition -->
                        <ng-container matColumnDef="noOpenEnd">
                            <th mat-header-cell *matHeaderCellDef> No Open End ? (X) </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox (change)="onChangeOpenend(element, $event)" [checked]="element.noOpenEnd">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteChar(element, in)"></i>
                            </td>
                        </ng-container>

                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddcharRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="characterSave()">Save</button> -->
                    <i class="saveIcon" (click)="characterSave(section2Form)"></i>
                </form>


            </mat-expansion-panel>

            <!-- Section 3 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 3 of 8 </span> Actor Familiarity
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="section3Form">
                    <table mat-table [dataSource]="dataSource3" class="mat-elevation-z8" formArrayName="section3Array">
                        <!-- Position Actor Definition -->
                        <ng-container matColumnDef="posact">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                <!-- <span *ngIf="!onAddsec3">{{element.posact}}</span>
                    <input matInput placeholder="Add sequence" [value]="element.posact" *ngIf="onAddsec3"
                        formControlName="posact"> -->
                            </td>
                        </ng-container>

                        <!-- Actor Name Definition -->
                        <ng-container matColumnDef="actorname">
                            <th mat-header-cell *matHeaderCellDef> Actor/Actress Name </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input class="form-control" matInput placeholder="Add Actor/Actress Name"
                                    [value]="element.actorname" formControlName="actorname" trim="blur" (paste)="onPasteActor($event,in)"
                                    (keyup)="updateActName(element,$event)">
                                <!-- <mat-error *ngIf="section3Form.controls.section3Array.controls[in].controls.actorname.invalid &&
                                submittedForm3">Please Enter Valid Name</mat-error> -->
                                <mat-error *ngIf="hasErrorsection3Form(in,'actorname','pattern')  && submittedForm3">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection3Form(in,'actorname','required')  && submittedForm3">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <!-- Gender Definition -->
                        <ng-container matColumnDef="gender">
                            <th mat-header-cell *matHeaderCellDef> Gender(M/F) </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <mat-radio-group formControlName="gender">
                                    <mat-radio-button *ngFor="let m of array" labelPosition="after" [value]="m"
                                        [checked]="element.gender =='M' && m=='Male' ? true : element.gender=='F' && m=='Female'? true:false "
                                        (change)="radioChangeact(element,$event)"> {{m}} </mat-radio-button>
                                </mat-radio-group>
                                <!-- <mat-error *ngIf="section3Form.controls.section3Array.controls[in].controls.gender.invalid &&
                                submittedForm3">Please Select Gender</mat-error> -->
                                <mat-error *ngIf="hasErrorsection3Form(in,'gender','required')  && submittedForm3">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteAct(element, in)"></i>
                            </td>
                        </ng-container>


                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddactRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="actorSave()">Save</button> -->
                    <i class="saveIcon" (click)="actorSave(section3Form)"></i>
                </form>
            </mat-expansion-panel>

            <!-- Section 4 -->
            <form>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="expansion_header_title">Section 4 of 8 </span>Relationships
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form [formGroup]="section4Form">
                        <table mat-table [dataSource]="dataSource4" class="mat-elevation-z8"
                            formArrayName="section4Array">
                            <!-- Position Relationships Definition -->
                            <ng-container matColumnDef="posrel">
                                <th mat-header-cell *matHeaderCellDef> # </th>
                                <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                    <!-- <span *ngIf="!onAddsec4">{{element.posrel}}</span>
                                <input matInput placeholder="Add sequence" [value]="element.posrel" *ngIf="onAddsec4"
                                    formControlName="posrel"> -->
                                </td>
                            </ng-container>

                            <!-- Relationships Definition -->
                            <ng-container matColumnDef="relationships">
                                <th mat-header-cell *matHeaderCellDef> Relationships </th>
                                <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                    <input class="form-control" matInput placeholder="Add Relationships"
                                        [value]="element.relationships" formControlName="relationships" trim="blur" (paste)="onPasteRel($event,in)"
                                        (keyup)="updateRel(element,$event)">
                                    <!-- <mat-error *ngIf="section4Form.controls.section4Array.controls[in].invalid &&
                                    submittedForm4">Please enter valid value</mat-error> -->
                                    <mat-error *ngIf="hasErrorsection4Form(in,'relationships','pattern')  && submittedForm4">Please enter valid value</mat-error>
                                    <mat-error *ngIf="hasErrorsection4Form(in,'relationships','required')  && submittedForm4">Please enter required value</mat-error>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="columndelete">
                                <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                    <i class="float-right deleteIcon alignDelete-textbox"
                                        (click)="deleteRel(element, in)"></i>
                                </td>
                            </ng-container>

                            <!-- Header and Row Declarations -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns4"></tr>
                        </table>
                        <i class="addnewIcon float-left" (click)="onAddrelRow()"></i>
                        <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="relationshipSave()">Save</button> -->
                        <i class="saveIcon" (click)="relationshipSave(section4Form)"></i>
                    </form>
                </mat-expansion-panel>
            </form>


            <!-- Section 5 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 5 of 8 </span> Statements
                    </mat-panel-title>
                    <!-- <button class="infoIconBgW" matTooltip="NBC ANALYST NOTES:"
        aria-label="Button that displays a tooltip when focused or hovered over">i</button> -->
                    <!-- <span class="infoIconBgW">i</span> -->
                </mat-expansion-panel-header>
                <div class="InputInfoContent">
                    <p>
                        <label>NBC ANALYST NOTES:</label>
                        <span>For those statements NOT used for this study, please cross them out – Do not delete
                            them.</span>
                        <span>To add statements, type them in below.</span>
                    </p>
                    <p>
                        <label>PROGRAMMER'S NOTE:</label>
                        <span>If Poor Taste Statement is crossed-out, do not include QStd16a and QStd16b)</span>
                    </p>
                </div>
                <form [formGroup]="section5Form">
                    <table mat-table [dataSource]="dataSource5" class="mat-elevation-z8" formArrayName="section5Array">

                        <!-- Position Statement Definition -->
                        <ng-container matColumnDef="posstat">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                <!-- <span *ngIf="!onAddsec5">{{element.posstat}}</span>
                    <input matInput placeholder="Add sequence" [value]="element.posstat" *ngIf="onAddsec5"
                        formControlName="posstat"> -->
                            </td>
                        </ng-container>

                        <!-- Statements Definition -->
                        <ng-container matColumnDef="statements">
                            <th mat-header-cell *matHeaderCellDef> Statements </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input class="form-control" matInput placeholder="Add Statements" (paste)="onPasteState($event,in)"
                                    [value]="element.statements" formControlName="statements"
                                    (keyup)="updateStat(element,$event)" [readonly]="!element.metaDataFlag" trim="blur">
                                <!-- <mat-error *ngIf="section5Form.controls.section5Array.controls[in].invalid &&
                                submittedForm5">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection5Form(in,'statements','pattern')  && submittedForm5">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection5Form(in,'statements','required')  && submittedForm5">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteStat(element, in)"></i>
                            </td>
                        </ng-container>


                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns5"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddStatRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="statSave()">Save</button> -->
                    <i class="saveIcon" (click)="statSave(section5Form)"></i>
                </form>
            </mat-expansion-panel>

            <!-- Section 6 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 6 of 8 </span>Story Elements
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="section6Form">
                    <table mat-table [dataSource]="dataSource6" class="mat-elevation-z8" formArrayName="section6Array">

                        <!-- Position Story Elements Definition -->
                        <ng-container matColumnDef="posstoryelem">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                <!-- <span *ngIf="!onAddsec6">{{element.posstoryelem}}</span>
                    <input matInput placeholder="Add sequence" [value]="element.posstoryelem" *ngIf="onAddsec6"
                        formControlName="posstoryelem"> -->
                            </td>
                        </ng-container>

                        <!-- Story Elements Definition -->
                        <ng-container matColumnDef="storyelements">
                            <th mat-header-cell *matHeaderCellDef> Story Elements </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input class="form-control" matInput placeholder="Add Story Elements"
                                    [value]="element.storyelements" formControlName="storyelements" trim="blur" (paste)="onPasteStory($event,in)"
                                    (keyup)="updateSto(element,$event)">
                                <!-- <mat-error *ngIf="section6Form.controls.section6Array.controls[in].invalid &&
                                submittedForm6">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection6Form(in,'storyelements','pattern')  && submittedForm6">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection6Form(in,'storyelements','required')  && submittedForm6">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteSto(element, in)"></i>
                            </td>
                        </ng-container>

                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns6"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns6"></tr>
                    </table>

                    <i class="addnewIcon float-left" (click)="onAddstoryelemRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="stoSave()">Save</button> -->
                    <i class="saveIcon" (click)="stoSave(section6Form)"></i>
                </form>
            </mat-expansion-panel>

            <!-- Section 7 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 7 of 8 </span> Viewership
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="section7Form">
                    <table mat-table [dataSource]="dataSource7" class="mat-elevation-z8" formArrayName="section7Array">
                        <!-- Position Statement Definition -->
                        <ng-container matColumnDef="posview">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                <!-- <span *ngIf="!onAddsec7">{{element.posview}}</span>
                    <input matInput placeholder="Add sequence" [value]="element.posview" *ngIf="onAddsec7"
                        formControlName="posview"> -->
                            </td>
                        </ng-container>

                        <!-- Statements Definition -->
                        <ng-container matColumnDef="viewShowTitle">
                            <th mat-header-cell *matHeaderCellDef> Show Title </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input class="form-control" matInput placeholder="Add Show Title" 
                                    [value]="element.viewShowTitle" formControlName="viewShowTitle" trim="blur" (paste)="onPasteView($event,in)"
                                    (keyup)="updateShow(element,$event)">
                                <!-- <mat-error *ngIf="section7Form.controls.section7Array.controls[in].invalid &&
                                submittedForm7">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection7Form(in,'viewShowTitle','pattern')  && submittedForm7">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection7Form(in,'viewShowTitle','required')  && submittedForm7">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteShow(element, in)"></i>
                            </td>
                        </ng-container>

                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns7"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns7"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddViewRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="viewSave()">Save</button> -->
                    <i class="saveIcon" (click)="viewSave(section7Form)"></i>
                </form>
            </mat-expansion-panel>

            <!-- Section 8 -->

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="expansion_header_title">Section 8 of 8 </span>Question and stim changes
                    </mat-panel-title>
                    <!-- <span class="infoIconBgW">i</span> -->
                </mat-expansion-panel-header>
                <div class="InputInfoContent">
                    <p>
                        <label>NBC ANALYST NOTES:</label>
                        <span>For each question or Stim to be deleted, type in its label and the first five words of
                            that question or stim.</span>
                    </p>
                    <p>
                        <label>PROGRAMMER'S NOTE:</label>
                        <span>Completely remove the following question(s) &/or stim(s) from questionnaire.</span>
                    </p>
                </div>
                <form [formGroup]="section8Form">
                    <table mat-table [dataSource]="dataSource8" class="mat-elevation-z8" formArrayName="section8Array">

                        <!-- Position Statement Definition -->
                        <ng-container matColumnDef="posques">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                                <!-- <span *ngIf="!onAddsec5">{{element.posstat}}</span>
                        <input matInput placeholder="Add sequence" [value]="element.posstat" *ngIf="onAddsec5"
                            formControlName="posstat"> -->
                            </td>
                        </ng-container>

                        <!-- Question and Stim Changes Definition -->
                        <ng-container matColumnDef="quesnstim">
                            <th mat-header-cell *matHeaderCellDef> Question and Stim Changes </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <input class="form-control" matInput placeholder="Add Question and Stim Changes"
                                    [value]="element.quesnstim" formControlName="quesnstim" trim="blur" (paste)="onPasteQuesStim($event,in)"
                                    (keyup)="updateQues(element,$event)">
                                <!-- <mat-error *ngIf="section8Form.controls.section8Array.controls[in].invalid &&
                                submittedForm8">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection8Form(in,'quesnstim','pattern')  && submittedForm8">Please enter valid value</mat-error>
                                <mat-error *ngIf="hasErrorsection8Form(in,'quesnstim','required')  && submittedForm8">Please enter required value</mat-error>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="columndelete">
                            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                                <i class="float-right deleteIcon alignDelete-textbox"
                                    (click)="deleteQues(element, in)"></i>
                            </td>
                        </ng-container>


                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns8"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns8"></tr>
                    </table>
                    <i class="addnewIcon float-left" (click)="onAddQuesRow()"></i>
                    <!-- <button type="submit" class="float-right" mat-raised-button color="primary" (click)="statSave()">Save</button> -->
                    <i class="saveIcon" (click)="quesSave(section8Form)"></i>
                </form>
            </mat-expansion-panel>

        </mat-accordion>
    </div>

</div>