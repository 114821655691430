<div class="col-md-11 contentSection surveySteps " id="main">
  <form [formGroup]="surveyDec.firstFormGroup">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
      <p class="selectedInputSheet float-left font-13" *ngIf="surveyDec.surveyId">
        Selected Survey Code : {{surveyDec.surveyCode}} </p>
      <!-- <p class="selectedInputSheet float-left font-13">
        {{surveyDec.SelectedInputSheet}} : {{surveyDec.SelectedInputSheetValue}} </p>
      <p class="selectedTemplate float-left font-13">
        {{surveyDec.SelectedTemplate}} : {{surveyDec.SelectedTemplateValue}} </p> -->
      <div class="float-right createSection">
        <mat-card-actions class="float-left">
          <span mat-raised-button color="primary" class="submitSurvey mat-raised-button mat-button-base mat-primary"
            (click)="submitcreateSurvey()" *ngIf="!surveyDec.surveyStatus">Submit</span>
          <button mat-raised-button color="primary" *ngIf="!surveyDec.notSaved" (click)="saveSurvey()">Save</button>
          <button type="button" mat-raised-button color="warn" (click)="openconfirmModal()">Cancel</button>
        </mat-card-actions>
      </div>
    </div>
    <div class="col-lg-12 row surveyWizard inputsheet-table clear">
      <!-- <mat-horizontal-stepper #stepper> -->
      <!-- <mat-step>
          <div class="surveyDetailsWrapper" *ngIf="surveyDec.dataSourceInputSheet">
            <table mat-table [dataSource]="surveyDec.dataSourceInputSheet" class="mat-elevation-z8"
              formArrayName="inputSheetQuestions">
              <ng-container matColumnDef="questionText">
                <th mat-header-cell *matHeaderCellDef> InputSheet Questions </th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                  {{element.questionText}}
                </td>
              </ng-container>
              <ng-container matColumnDef="includeAllFlag">
                <th mat-header-cell *matHeaderCellDef>
                  <span *ngIf="!surveyDec.surveyId && surveyDec.dataSourceInputSheet.data.length>0">
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected()"
                      [indeterminate]="!isAllSelected()" [aria-label]="checkboxLabel()" labelPosition="after">Include All
                    </mat-checkbox>
                  </span>
                </th>
                <td mat-cell *matCellDef="let element;  let indexpostion=index;" [formGroupName]="indexpostion">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="isCheckSelected($event,element,indexpostion,surveyDec.surveyId)"
                    [checked]="(element.select)" [aria-label]="checkboxLabel(element)" value="checkedbox"
                    [disabled]="surveyDec.surveyId">
                  </mat-checkbox>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="surveyDec.displayedColumnsInputSheet;sticky:true"
                class="table-header-section surveyDetailsHeader">
              </tr>
              <tr mat-row *matRowDef="let row; columns: surveyDec.displayedColumnsInputSheet;">
              </tr>
            </table>
          </div>
          <div class="surveyDetailsFooter">
            <button mat-button matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step>
          <div class="surveyDetailsWrapper" *ngIf="surveyDec.dataSourceTempalte">
            <table mat-table [dataSource]="surveyDec.dataSourceTempalte" class="mat-elevation-z8"
              formArrayName="sharedListQustionids">
              <ng-container matColumnDef="questionText">
                <th mat-header-cell *matHeaderCellDef> Template Questions </th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                  {{element.questionText}}
                </td>
              </ng-container>
              <ng-container matColumnDef="includeAllFlag">
                <th mat-header-cell *matHeaderCellDef>
                  <span *ngIf="!surveyDec.surveyId && surveyDec.dataSourceTempalte.data.length>0">
                    <mat-checkbox (change)="$event ? masterToggleTemplate() : null" [checked]="isAllSelectedTemplate()"
                      [indeterminate]="!isAllSelectedTemplate()" [aria-label]="checkboxLabelTemplate()"
                      labelPosition="after">Include All</mat-checkbox>
                  </span>
                </th>
                <td mat-cell *matCellDef="let element;  let indexpostion=index;" [formGroupName]="indexpostion">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="isCheckSelectedTemplate($event,element,indexpostion,surveyDec.surveyId)"
                    [checked]="element.select" [aria-label]="checkboxLabelTemplate(element)"
                    [disabled]="surveyDec.surveyId">
                  </mat-checkbox>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="surveyDec.displayedColumnsInputSheet;sticky:true"
                class="table-header-section surveyDetailsHeader">
              </tr>
              <tr mat-row *matRowDef="let row; columns: surveyDec.displayedColumnsInputSheet;">
              </tr>
            </table>
          </div>
          <div class="surveyDetailsFooter">
            <button mat-button matStepperNext>Next</button>
            <button class="float-left" mat-button matStepperPrevious>Back</button>
          </div>
        </mat-step> -->
      <!-- <mat-step> -->
      <span class="float-right font-13 blue surveyAddChoice"  *ngIf="isAdmin" (click)="summaryExport()"> <i class="excelIcon"></i> </span>
      <span class="float-right font-13 blue surveyAddChoice" *ngIf="!surveyDec.surveyStatus"
        (click)="summaryAddQuestion(surveyDec.submitCreateSurvey.c2301SurveyId)"> <i class="addnewIcon"></i> Add
        Question </span>

      <!-- <span class="float-right font-13 blue surveyAddChoice" (click)="summaryAddQuestion(surveyDec.surveyId)">Add
              Question  <i class="addnewIcon"></i></span> -->
      <div class="surveyDetailsWrapper summaryStep">
        <div class="surveyDetailsHeader">
          <div class="surveydetailsDesc font-14">
            # Summary
          </div>
        </div>
        <div class="surveyDetailsBody" *ngIf="!surveyDec.surveyStatus">
          <mat-accordion #accordion="matAccordion" multi="false">
            <div cdkDropList class="summarySection-list"
              (cdkDropListDropped)="drop($event,surveyDec.submitCreateSurvey.c2301SurveyId)">

              <div class="summarySection-box" *ngFor="let dragitem of surveyDec.dragdropData; let i=index;" cdkDrag>
                <mat-expansion-panel [hideToggle]="true" #mapanel="matExpansionPanel">
                  <mat-expansion-panel-header (click)="OpenExpansion(dragitem.c2311SurveyQuestionId,mapanel,$event) ">
                    <mat-panel-title>
                      <span class="expansionHeaderclos1">{{ i+1 }} </span>
                      <span class="expansionHeaderclos2"
                        *ngIf="(surveyDec.communicationData !== dragitem.c2311SurveyQuestionId)">
                        {{ dragitem.c2311QuestionLongTxt}}
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <input class="titleInput form-control" [formControl]="surveyDec.summaryQuestionName" matInput
                      [value]="dragitem.c2311QuestionLongTxt"
                      *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)">
                    <div class="summary-question-right-icon">
                      <i class="material-icons " mat-button [matMenuTriggerFor]="menu">more_horiz</i>
                      <mat-menu #menu="matMenu">
                        <span mat-menu-item
                          (click)="summaryquestionCopy(dragitem.c2311SurveyQuestionId,dragitem.c2311QuestionLongTxt,dragitem.summarySurveyId)">Copy</span>
                        <span mat-menu-item
                          (click)="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId) ? summaryquestionSave(dragitem.c2311SurveyQuestionId,surveyDec.summaryQuestionName.value,dragitem.summarySurveyId) : summaryquestionEdit(dragitem.c2311SurveyQuestionId)">
                          <span
                            *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId); else elseBlock">Save</span>
                          <ng-template #elseBlock>Edit</ng-template>
                        </span>
                        <span mat-menu-item
                          (click)="summaryquestionDelete(dragitem.c2311SurveyQuestionId,dragitem.summarySurveyId)">Delete</span>
                      </mat-menu>
                    </div>
                    <p *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)"
                      class="float-right font-13 blue surveyAddChoice"
                      (click)="summaryAddChoice(dragitem.c2311SurveyQuestionId)"><i class="addnewIcon"></i> Add Choice
                    </p>
                    <div class="mat-expansion-choice">
                      <table mat-table [dataSource]="surveyDec.dataSource"
                        class="mat-elevation-z8 summary-choice-table">
                        <ng-container matColumnDef="choiceLibraryDesc">
                          <td mat-cell *matCellDef="let element"> {{element.choiceLibraryDesc}} </td>
                        </ng-container>
                        <ng-container matColumnDef="choicePunchCode">
                          <td mat-cell *matCellDef="let element">
                            <span
                              *ngIf="!surveyDec.summaryquestionEdit && (surveyDec.communicationData !== dragitem.c2311SurveyQuestionId)">
                              {{element.choicePunchCode}} </span>
                            <input [formControl]="surveyDec.summaryQuestionChoicePunchCode" matInput
                              [value]="element.choicePunchCode"
                              (blur)="summaryChoicesPunchCode(element,dragitem.c2311SurveyQuestionId,$event,dragitem.summarySurveyId)"
                              *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)">
                            <span *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)"
                              (click)="summaryQuestionChoiceDelete(element.questionChoiceId,dragitem.c2311SurveyQuestionId)"><i
                                class="float-right deleteIcon alignDelete-textbox"></i></span>
                          </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: surveyDec.choiceColumns;"></tr>
                      </table>
                    </div>
                  </ng-template>
                  <mat-action-row>
                    <span class="closeSurveyStep" mat-flat-button (click)="mapanel.close()">Click to close</span>
                  </mat-action-row>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>
        </div>
        <div class="surveyDetailsBody" *ngIf="surveyDec.surveyStatus">

          <mat-accordion #accordion="matAccordion" multi="false">
            <div class="summarySection-list">

              <div class="summarySection-box" *ngFor="let dragitem of surveyDec.dragdropData; let i=index;">
                <mat-expansion-panel [hideToggle]="true" #mapanelview="matExpansionPanel">
                  <mat-expansion-panel-header
                    (click)="OpenExpansion(dragitem.c2311SurveyQuestionId,mapanelview,$event) ">
                    <mat-panel-title>
                      <span class="expansionHeaderclos1">{{ i+1 }} </span>
                      <span class="expansionHeaderclos2">
                        {{ dragitem.c2311QuestionLongTxt}}
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>


                    <div class="mat-expansion-choice">
                      <table mat-table [dataSource]="surveyDec.dataSource"
                        class="mat-elevation-z8 summary-choice-table">
                        <ng-container matColumnDef="choiceLibraryDesc">
                          <td mat-cell *matCellDef="let element"> {{element.choiceLibraryDesc}} </td>
                        </ng-container>
                        <ng-container matColumnDef="choicePunchCode">
                          <td mat-cell *matCellDef="let element">
                            <span
                              *ngIf="!surveyDec.summaryquestionEdit && (surveyDec.communicationData !== dragitem.c2311SurveyQuestionId)">
                              {{element.choicePunchCode}} </span>
                            <input [formControl]="surveyDec.summaryQuestionChoicePunchCode" matInput
                              [value]="element.choicePunchCode"
                              (blur)="summaryChoicesPunchCode(element,dragitem.c2311SurveyQuestionId,$event,dragitem.summarySurveyId)"
                              *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)">
                            <span *ngIf="(surveyDec.communicationData === dragitem.c2311SurveyQuestionId)"
                              (click)="summaryQuestionChoiceDelete(element.questionChoiceId,dragitem.c2311SurveyQuestionId)"><i
                                class="float-right deleteIcon alignDelete-textbox"></i></span>
                          </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: surveyDec.choiceColumns;"></tr>
                      </table>
                    </div>
                  </ng-template>
                  <mat-action-row>
                    <span class="closeSurveyStep" mat-flat-button (click)="mapanelview.close()">Click to close</span>
                  </mat-action-row>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>
        </div>
      </div>
      <!-- <div class="surveyDetailsFooter">
            <button class="float-left" mat-button matStepperPrevious>Back</button>
           
          </div> -->

      <!-- </mat-step> -->
      <!-- </mat-horizontal-stepper> -->
      <div class="spinnerOverlay"  *ngIf="surveyDec.isLoading"></div>
      <div class="spinner" *ngIf="surveyDec.isLoading">
        <div class="bounceone"></div>
        <div class="bouncetwo"></div>
        <div class="bouncethree"></div>
        <div class="bouncefour"></div>
        <div class="bouncefive"></div>
        <div class="bouncesix"></div>
      </div>

    </div>

  </form>
</div>