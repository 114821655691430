<div class="container primeNewModal createSharedQuestion">
    <span class="modalClose" mat-dialog-close ><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="qustDec.summaryAddQuestionForm" autocomplete="off" class="example-form" novalidate>
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Select the Question</label>
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search" name="searchChoiModel"
                            [(ngModel)]="searchChoiModel" [ngModelOptions]="{standalone: true}">
                        <i class="searchicon align-autosearchIcon"></i>
                    </div>
                    <div class="autoSelect hastag clearfix clear" floatLabel=never>
                        <span *ngFor="let item of qustDec.selectedChoices;let i=index">
                            <mat-list-item> {{ item.sharedListName }}
                                <button mat-icon-button (click)="onRemove(item)">
                                    <mat-icon class="mat-24 hastagDelete">x</mat-icon>
                                </button>
                            </mat-list-item>
                        </span>
                    </div>
                    <mat-selection-list matInput #multiSelect
                        class="marginT10 form-control align-autocomplete addChoice-searchChoice"
                        >
                        <mat-list-option title="{{question}}"
                            *ngFor="let question of qustDec.survetSummaryfetchQuestionList | searchFilter: searchChoiModel ;let i=index"
                            [ngClass]="getSomeClass(question)" [selected]="question.isselected === 'true'"
                            (click)="onAreaListControlChanged(question)" [value]="question.sharedChoiceId">
                            <span>{{question.sharedListName}}</span>
                        </mat-list-option>
                    </mat-selection-list>
                </mat-form-field>
            </mat-card-content>
            <div class="spinnerOverlay"  *ngIf="qustDec.isLoading"></div>
            <div class="spinner" *ngIf="qustDec.isLoading">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" (click)="surveySummaryAddQuestion()">
                    ADD </button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close (click)="surveyAddQuectionCancel(true)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>