import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, OnChanges, ɵsetCurrentInjector, ChangeDetectorRef, AfterViewInit, AfterViewChecked, ViewChildren } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { SharedChoiceTable } from 'src/app/models/sharedList.model';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { SingleConfirmModalComponent } from '../simple-question/confirm-modal';
import { HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import {Subject} from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-simple-question',
  templateUrl: './simple-question.component.html',
  styleUrls: ['./simple-question.component.scss']
})

export class SimpleQuestionComponent implements OnInit,AfterViewInit ,AfterViewChecked,OnDestroy{
  punchCodes: UntypedFormArray;
  public spinnerSharedList: boolean;
  editSingleQuesForm: UntypedFormGroup;
  QuestionFormData: any;
  appConstants = AppConfig.settings;
  public globalResponse: any;
  sharedQuestionName: string;
  sharedQuestionDesc: string;
  sharedChoiceHeader: any;
  checkServerURL: any;
  deleteQuestionURL: any;
  dialogRef: any;
  deleteButton: boolean;
  choices: any;
  isEdit: boolean;
  dataSource: any = [];
  checkedArr: any = [];
  choicesharedListId: any;
  SERVER_URL: any;
  deleteSharedListURL: any;
  subscription: any;
  sharedChoiceColumns: any;
  sharedChoiceTableSource: any;
  getQuestionSharedListByIdURL: any;
  columnsToDisplay: string[];
  columnsToDisplayPunchCode: any = [];
  columns: any[];
  deleteChoiceURL: any;
  sharedlistquestionupdate: any;
  sideNavReload: any;
  validationCheck: boolean = false;
  @Input() childInput;
  @Input() childEdit;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('div') viewEdit: ElementRef;
  @ViewChild('table2') table2: MatTable<any>;
  @ViewChildren('uncheckRef') uncheckRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private sharedlistService: SharedlistService,
    private sharedService: SharedService,
    private toastorService: NotificationService,
    public dialog: MatDialog,
    private awsCommonService: AwsCommonService,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private cdr:ChangeDetectorRef
  ) {
    this.deleteButton = false;
    this.sharedlistService.tooltip.emit(true);
  //  this.spinnerSharedList = true;
    this.isEdit = false;
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}`;
      this.deleteChoiceURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListChoiceURL}`;
      this.deleteQuestionURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListQuestionURL}`;
      this.sharedlistquestionupdate = `${this.appConstants.sharedQuestionCreateParam}`;
      this.deleteSharedListURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListURL}`;
    } else {
      this.getQuestionSharedListByIdURL = this.appConstants.getQuestionSharedListByIdLocalURL;
    }
  }
  ngOnChanges(): void {
  //  this.spinnerSharedList = true;
    this.sharedlistService.tooltip.emit(true);
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //console.log(this.childInput);
    this.getQuestionSharedListById(this.childInput);
  }
  ngOnInit() {
    this.editSingleQuesForm = this.formBuilder.group({
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      sharedListDescription: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      id: new UntypedFormControl(''),
      punchCodes: this.formBuilder.array([])
    })
    //console.log("editSingleQuesForm", this.editSingleQuesForm.value)
  }

  getQuestionSharedListById(childInput): void {
    //const url='/sharedlist/questions/' +childInput.id;
  this.spinnerSharedList = true;
    if (this.checkServerURL) {
      //this.getQuestionSharedListByIdURL = `${this.getQuestionSharedListByIdURL}/${childInput.id}`;
      //this.sharedlistService.getQuestionSharedListById(this.getQuestionSharedListByIdURL)
      // this.sharedlistService.getQuestionSharedListById(`${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}/${this.appConstants.getQuestionSharedListByIdParam}/${this.childInput.id}`)
      this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}/${this.appConstants.getQuestionSharedListByIdParam}/${this.childInput.id}`,'GET').pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          //console.log(data);
          this.QuestionFormData = data;

          this.dataSource = data,
            this.spinnerSharedList = false;
          //this.choices=this.dataSource.choices;
          this.sharedlistService.tooltip.emit(false);
          // console.log('single', this.dataSource);
          this.choicesharedListId = (this.dataSource)? this.dataSource.choicesharedListId: null,
            // console.log('data', this.choicesharedListId);
          this.editSingleQuesForm.patchValue({
            //id: this.dataSource.choicesharedListId, 
            id: this.dataSource.id,
            sharedListName: this.dataSource.name,
            sharedListDescription: this.dataSource.description,
          });
          if(!this.isEdit){
            this.viewEdit.nativeElement.classList.remove("editOnly");
          }
          if (this.dataSource.choices) {
            this.refreshTable(this.dataSource.choices);
          }
        }, (error) => {
          this.spinnerSharedList = false;
          this.toastorService.warn(error.message);
        }
        );
    }
  }
  onEdit() {

    this.isEdit = true;
    // this.deleteButton=true;

  }

  /*
  AfterViewInit() {
    this.subscription = this.sharedService.eventAnnounced$.subscribe(data => {
      this.isEdit = data.isEdit;
      if (this.isEdit === true) {
        this.viewEdit.nativeElement.classList.add("editOnly");
      } else {
        this.viewEdit.nativeElement.classList.remove("editOnly");
      }
    });
  }*/
  selectRow($event, dataSource) {
    if ($event.checked) {
      this.checkedArr.push(dataSource.id);
      //console.log('push checked', this.checkedArr)
      if (this.checkedArr) {
        this.deleteButton = false;
      }
      else {
        this.deleteButton = true;
      }
    }
    else {
      let i = this.checkedArr.indexOf(dataSource.id);
      this.checkedArr.splice(i, 1);
      //console.log('pop  deselect', this.checkedArr)
      if (this.checkedArr) {
        this.deleteButton = true;
      }
      else {
        this.deleteButton = false;
      }
    }
  }
    
  openAddChoice(): void {
    this.dialogRef = this.dialog.open((AddChoiceComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { sharedListId: this.childInput.id, choicesharedListId: this.choicesharedListId }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data === 'success') {
        this.sharedService.emitEvent(true);
      //  this.spinnerSharedList = true;
        this.getQuestionSharedListById(this.childInput);
      } else {
        //console.log('cancel');
      //  this.spinnerSharedList = false;
      }
      //   this.getTable(this.choices);
    });
  }
  getTable() {
    // this.sharedlistService.getQuestionSharedListById(this.getQuestionSharedListByIdURL)
    this.awsCommonService.getRequest(this.getQuestionSharedListByIdURL,'GET').pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.refreshTable(this.dataSource.choices);
        //console.log('choices after', this.dataSource.choice)
      },
        error => {
          //console.log('error', error.message);
        });
  }
  refreshTable(choices) {
    let displayedElementWithRows: any[];
    let parentColumn = [
      { columnDef: 'action', header: 'Action', punchCode: '', punchCol: 'actions', choiceId: '' },
      { columnDef: 'name', header: 'Choices', punchCode: '', punchCol: 'questions', choiceId: '' },
      { columnDef: 'punchCode', header: '', punchCode: '', punchCol: 'questionss', choiceId: '' }
    ];
    this.columns = [...parentColumn];
    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
    this.dataSource = choices;
    //console.log('table', this.dataSource);
    const punchList = this.editSingleQuesForm.controls.punchCodes as UntypedFormArray;
    punchList.clear();
    if (choices.length > 0) {
      choices.map(
        item => {
          punchList.push(this.formBuilder.group({
            id: item.id,
            punchCode: item.punchCode,
            sequenceNumber:item.sequenceNumber
          }));
          // return {
          //   'columnDef': 'choice' + item.id,
          //   'header': item.name,
          //   'punchCode': item.punchCode,
          //   'punchCol': 'choices' + item.id,
          //   'choiceId': item.id
          // }
        }
      );
    }
  }
  // Confirmation Popup
  openconfirmModal(type, id): void {
    const dialogRef = this.dialog.open((SingleConfirmModalComponent), { data: type, panelClass: 'confirmModal', disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        if (type === 'choice') {
          this.sharedService.emitEvent(false);
          //console.log('Shared list Question single : ', type);
          this.deleteChoice();
        }
        else if (type === 'sharedList') {
          this.sharedService.emitEvent(false);
          //console.log('Shared list Question Gird : ', type);
          this.deleteSharedList();
        }
      }
    });
  }

  deleteChoice() {
this.spinnerSharedList = true;
    // let params1 = { questionId: Object.values(this.checkedArr).join(",")  };
    const options = this.checkedArr ?
      { 'choiceId': this.checkedArr } : {};

    //console.log('Array format ', this.checkedArr);
    // return false;
    // this.commonService.delete(this.deleteChoiceURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteChoiceURL}`, 'DELETE', options,options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.spinnerSharedList = false;

          this.globalResponse = result;
            this.sharedService.emitEvent(true);
          // this.getQuestionSharedListById(this.childInput);
        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error ' + error.message);
        },
        () => {
          this.toastorService.success('Choice Deleted Successfully!');
          this.getQuestionSharedListById(this.childInput);
        }
      );
  }
  // deleteChoiceList() {
  //   // let params = { sharedListId: this.childInput.id   };
  //   const options = this.childInput.id ?
  //     { params: new HttpParams().set('sharedListId', this.childInput.id) } : {};

  //   // console.log('Deleteb Shared List ', options);
  //   // return false;
  //   this.commonService.delete(this.deleteQuestionURL,options )
  //     .subscribe(
  //       (result) => {
  //         this.globalResponse = result;
  //        // this.getTable();
  //         this.toastorService.success('Deleted Successfully');
  //       },
  //       error => {
  //         this.toastorService.warn('Error' + error.message);
  //       }
  //     );
  // }
  ngAfterViewInit() {
    this.subscription = this.sharedService.eventAnnounced$.subscribe(data => {
      this.isEdit = data.isEdit;
      //console.log('ngAfterViewInit  Single', data);
      if (data.isCancel === true) {
        this.editSingleQuesForm.patchValue({
          id: this.QuestionFormData.id,
          sharedListName: this.QuestionFormData.name,
          sharedListDescription: this.QuestionFormData.description
        });
        this.checkedArr = [];
        const len = this.uncheckRef.length;
        const arr=this.uncheckRef._results;
        for(let i=0;i<len;i++){
          arr[i]._checked=false;          
        }
        console.log(this.uncheckRef._checked);      
        this.uncheckRef._checked = false;
       
      }
      if (this.isEdit === true) {
        //console.log("isEdit  : 123");
        if (this.checkedArr) {
          this.deleteButton = true;
        }
        else {
          this.deleteButton = false;
        }
        this.viewEdit.nativeElement.classList.add('editOnly');
        this.sharedService.emitEvent(true);
      } else if (data.isSave === true && this.editSingleQuesForm.valid) {
        this.sharedListQuestionSingleSave();
        this.viewEdit.nativeElement.classList.remove('editOnly');
      } else if (data.isSave === true && this.editSingleQuesForm.invalid) {
        this.viewEdit.nativeElement.classList.add('editOnly');
        // this.sharedService.emitEvent(true);
        this.sharedListQuestionSingleSave()

      }
      else {
        //console.log(this.QuestionFormData);
        //this.editSingleQuesForm.patchValue(this.QuestionFormData);

        this.viewEdit.nativeElement.classList.remove('editOnly');
      }
    });
  }
  sharedListQuestionSingleSave() {
    this.validationCheck = true;

    if (this.editSingleQuesForm.invalid) {

      this.sharedService.emitEvent(true);
      //console.log('invalid');
    }
    else {
      this.validationCheck = false;
      this.isEdit = false;
      this.sharedService.emitEvent(false);
      this.spinnerSharedList = true;
      // const punchList = this.editSingleQuesForm.controls.punchCodes as FormArray;
      //console.log(' SINGLE', this.editSingleQuesForm.value);

      // this.commonService.update(this.sharedlistquestionupdate, this.editSingleQuesForm.value)
      this.awsCommonService.putRequest(`${this.appConstants.serverUrl}/${this.sharedlistquestionupdate}`, 'PUT', this.editSingleQuesForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.spinnerSharedList = false;
          console.log('Result : ', result);
          console.log('Result this.sideNavReload in Simple', this.sideNavReload);
        },
        error => {
          this.spinnerSharedList = false;
        //  console.log('error', error, error.error.sharedListName);
        if(error.status === 417){
          error.message = "Please enter valid Question Long text"
        } else if(error.error.sharedListName){
            error.message = error.error.sharedListName;
          }
          this.toastorService.warn(error.message);
          // this.sideNavReload = { sideNavReload: true, questionSharedListId: error.error.questionSharedListId, questionType: 'Simple' };
          // this.sharedService.announceEvent(this.sideNavReload);
          const editCancelData: any = { isEdit: true };
          this.sharedService.announceEvent(editCancelData);
          //console.log('isEdit after spinner', editCancelData);*/
        },/*
        error => {
          this.toastorService.warn('Error' + error.message);
          this.spinnerSharedList = false;
          const editCancelData: any = { isEdit: true };
          this.sharedService.announceEvent(editCancelData);

        },*/
        () => {
          this.toastorService.success('Shared Question Updated Successfully!');

          this.sideNavReload = { sideNavReload: true };
          this.sharedService.announceEvent(this.sideNavReload);
          this.getQuestionSharedListById(this.childInput);
          // this.sharedService.announceEvent(true);

        });
    }
  }
  ngAfterViewChecked() {
    console.log('AfterViewChecked');
    this.cdr.detectChanges();
    }
  deleteSharedList() {

    this.spinnerSharedList = true;
    const options = this.childInput.id ?
      { 'sharedListId': this.childInput.id } : {};
    // this.commonService.delete(this.deleteSharedListURL, options)
    // debugger;
    this.awsCommonService.deleteRequest(`${this.deleteSharedListURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          // this.sharedService.emitEvent(false);
          this.spinnerSharedList = false;
          this.globalResponse = result;
          // this.getQuestionSharedListById(this.childInput);
          this.toastorService.success('Shared Question Deleted Successfully!');
          //this.sharedService.announceEvent(true);
          this.sideNavReload = { sideNavReload: true, method: true };
          this.sharedService.announceEvent(this.sideNavReload);
        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error' + error.message);
        }
      );
  }
  editSharedQuestion() {

  }
  dropTable(event: CdkDragDrop<any[]>) {
   
    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource,prevIndex, event.currentIndex);
    moveItemInArray(this.editSingleQuesForm.get('punchCodes')['controls'],prevIndex, event.currentIndex);
    moveItemInArray(this.editSingleQuesForm.get('punchCodes').value,prevIndex, event.currentIndex);
 
    // console.log(len);
    
    this.table2.renderRows();
    const punchList = this.editSingleQuesForm.controls.punchCodes as UntypedFormArray;
    const arr =punchList.value;
    for(let i=0;i<arr.length;i++){
      arr[i].sequenceNumber =i
       }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.editSingleQuesForm.controls[controlName].hasError(errorName);
  }

}
