import { CommonService } from './../../../../services/common.service';
import { Component, OnInit, Input, OnDestroy, ViewChild, ViewChildren, ElementRef, OnChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { SharedChoiceTable } from 'src/app/models/sharedList.model';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { ChoiceConfirmModalComponent } from './confirm-modal';
import { HttpParams } from '@angular/common/http';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-shared-choice-view',
  templateUrl: './shared-choice-view.component.html',
  styleUrls: ['./shared-choice-view.component.scss'],
})

export class SharedChoiceViewComponent implements OnInit, AfterViewInit, OnDestroy {
  punchCodes: UntypedFormArray;
  spinnerSharedList: boolean;
  editSingleQuesForm: UntypedFormGroup;
  choiceFormData: any;
  deleteButton: boolean;
  appConstants = AppConfig.settings;
  sharedQuestionName: string;
  sharedQuestionDesc: string;
  sharedChoiceHeader: any;
  checkServerURL: any;
  dialogRef: any;
  isEdit: boolean;
  child: any;
  dataSource: any = [];
  checkedArr: any = [];
  SERVER_URL: any;
  globalResponse: any;
  choicesharedListid: any;
  subscription: any;
  sharedChoiceColumns: any;
  sharedChoiceTableSource: any;
  getQuestionSharedListByIdURL: any;
  columnsToDisplay: string[];
  columnsToDisplayPunchCode: any = [];
  columns: any[];
  deleteChoiceURL: any;
  deleteChoiceListURL: any;
  sharedlistquestionupdate: any;
  sideNavReload: any;
  choiceList;
  validationMsg: any
  validationCheck: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() childInput;
  @Input() childEdit;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('div') viewEdit: ElementRef;
  @ViewChildren('uncheckRef') uncheckRef;
  @ViewChild('table1') table1: MatTable<any>;
  constructor(
    private sharedlistService: SharedlistService,
    private sharedService: SharedService,
    private toastorService: NotificationService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private awsCommonService: AwsCommonService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.deleteButton = false;
  //  this.spinnerSharedList = true;
    this.validationMsg = this.appConstants.validationMsg;
    this.sharedlistService.tooltip.emit(true);
    this.isEdit = false;
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}`;
      this.deleteChoiceURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListChoiceURL}`;
      this.sharedlistquestionupdate = `${this.appConstants.sharedChoiceListParam}`;
      this.deleteChoiceListURL = `${this.SERVER_URL}/${this.appConstants.getSharedChoiceList}`;
    } else {
      //this.getQuestionSharedListByIdURL = this.appConstants.getQuestionSharedListByIdLocalURL;
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}`;
      this.deleteChoiceURL = `${this.SERVER_URL}/${this.appConstants.deleteChoiceURL}`;
      this.deleteChoiceListURL = `${this.SERVER_URL}/${this.appConstants.getSharedChoiceList}`;
    }
  }
  ngOnInit() {
    // this.editSingleQuesForm = new FormGroup({
    //   sharedListName: new FormControl('', []),
    //   sharedListDesc: new FormControl(''),
    //   punchCode: new FormControl('')
    // });
    this.editSingleQuesForm = this.formBuilder.group({
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField
      ])),
      sharedListDesc: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField
      ])),
      id: new UntypedFormControl(this.childInput.id),
      punchCodes: this.formBuilder.array([]),
    })


  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
   // console.log('Indide choice comp', this.childInput);
  //  this.spinnerSharedList = true;

    this.getSharedChoicebyId(this.childInput);
  }

  getSharedChoicebyId(childInput): void {
    this.spinnerSharedList = true;

    //  const url='sharedlist/choices/' +childInput.id;
    // this.sharedlistService.getSharedChoicebyId(url).subscribe
    if (this.checkServerURL) {
      //this.getQuestionSharedListById_URL = `${this.getQuestionSharedListById_URL}/${childInput.id}`;
      // this.sharedlistService.getSharedChoicebyId(`/${this.appConstants.sharedListParam}/${this.appConstants.choiceDashboardParam}/${childInput.id}`)
      this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}/${this.appConstants.choiceDashboardParam}/${childInput.id}`, 'GET').pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.spinnerSharedList = false;
          // console.log(data);
          this.sharedlistService.tooltip.emit(false);
          this.dataSource = data;
         // console.log('Data Source' ,this.dataSource );
          this.choiceFormData = data;

          this.choicesharedListid = this.dataSource.choiceSharedListId;
          //console.log('datasource choice', this.dataSource)
          //console.log('datasource choice', this.choicesharedListid)
          // this.choiceList=data;
          // console.log('choiceList' + this.choiceList.choices);
          this.editSingleQuesForm.patchValue(this.dataSource);
          // if (!this.isEdit) {
            // this.viewEdit.nativeElement.classList.remove('editOnly');
          // }
          this.viewEdit.nativeElement.classList.remove('editOnly');
          if (this.dataSource.choices) {
            this.refreshTable(this.dataSource.choices);
          }
        }, (error) => {
          this.spinnerSharedList = false;
          this.toastorService.warn(error.message);
        }

        );
    }
  }
  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      id: '',
      punchCode: '',
    });
  }
  onEdit() {
    this.isEdit = true;
  }
  // AfterViewInit() {
  //   this.subscription = this.sharedService.eventAnnounced$.subscribe(data => {
  //     this.isEdit = data.isEdit;
  //     if (this.isEdit === true) {
  //       this.viewEdit.nativeElement.classList.add("editOnly");
  //     } else {
  //       this.viewEdit.nativeElement.classList.remove("editOnly");
  //     }
  //   });
  // }
  selectRow($event, dataSource) {
    if ($event.checked) {
      this.checkedArr.push(dataSource.id);
      //console.log('push checked', this.checkedArr);
      if (this.checkedArr) {
        this.deleteButton = false;
      }
      else {
        this.deleteButton = true;
      }
    }
    else {
      let i = this.checkedArr.indexOf(dataSource.id);
      this.checkedArr.splice(i, 1);
      //console.log('pop  deselect', this.checkedArr)
      if (this.checkedArr) {
        this.deleteButton = true;
      }
      else {
        this.deleteButton = false;
      }
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true)
    this.destroy$.unsubscribe();
  }
  openAddChoice(): void {
    //console.log('child', this.choicesharedListid);
    this.dialogRef = this.dialog.open((AddChoiceComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { choicesharedListId: this.choicesharedListid }

    });
    this.dialogRef.afterClosed().subscribe((data) => {
     if(data=='success'){
      this.sharedService.emitEvent(false);
     this.getSharedChoicebyId(this.childInput);}
    });
  }

  // openAddIndChoice():void {
  //   this.dialogRef = this.dialog.open((AddIndChoicesComponent), {
  //     panelClass: 'newQuestion', disableClose: true, data: { choicesharedListId: this.choicesharedListid }

  //   });
  //   this.dialogRef.afterClosed().subscribe((data) => {
  //    if(data=='success'){
  //     this.sharedService.emitEvent(false);
  //    this.getSharedChoicebyId(this.childInput);}
  //   });
  // }
  getTable() {
    // this.sharedlistService.getQuestionSharedListById(this.getQuestionSharedListByIdURL)
    this.awsCommonService.getRequest(this.getQuestionSharedListByIdURL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.refreshTable(this.dataSource.choices);
      },
        error => {
          //console.log('error', error.message);
        });
  }
  refreshTable(choices) {
    let displayedElementWithRows: any[];
    let parentColumn = [
      { columnDef: 'action', header: 'Action', punchCode: '', punchCol: 'actions', choiceId: '' },
      { columnDef: 'name', header: 'Choices', punchCode: '', punchCol: 'questions', choiceId: '' },
      { columnDef: 'punchCode', header: '', punchCode: '', punchCol: 'questionss', choiceId: '' }
    ];
    this.columns = [...parentColumn];
    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
    this.dataSource = choices;
   // console.log('table', this.dataSource.sequenceNumber);

    const punchList = this.editSingleQuesForm.controls.punchCodes as UntypedFormArray;
    punchList.clear();
    if (choices.length > 0) {
      choices.map(
        item => {
          punchList.push(this.formBuilder.group({
            id: item.id,
            punchCode: item.punchCode,
            sequenceNumber:item.sequenceNumber
          }));
     //     console.log(item);
          // return {
          //   'columnDef': 'choice' + item.id,
          //   'header': item.name,
          //   'punchCode': item.punchCode,
          //   'punchCol': 'choices' + item.id,
          //   'choiceId': item.id
          // }
        });
    }
   // this.isEdit = false;
  }

  // Confirmation Popup
  openconfirmModal(type, id): void {
    const dialogRef = this.dialog.open((ChoiceConfirmModalComponent), { data:type,  panelClass: 'confirmModal', disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        dialogResult === true ? this.isEdit = false : this.isEdit = true;
        if (type === 'choice') {
          this.sharedService.emitEvent(false);
          //console.log('Shared list Question single : ', type);
          this.deleteChoice();
        }
        else if (type === 'choiceList') {
          this.sharedService.emitEvent(false);
          //console.log('Shared list Question Gird : ', type);
          this.deleteSharedList();
        }
      }
    });
  }
  deleteSharedList() {
    this.spinnerSharedList = true;
    const options = this.childInput.id ?
      { 'sharedListId': this.childInput.id } : {};
    // this.commonService.delete(this.deleteChoiceListURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteChoiceListURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.spinnerSharedList = false;
          this.globalResponse = result;
          // this.getQuestionSharedListById(this.childInput);
          this.toastorService.success('Shared Choice deleted successfully!');
          this.sideNavReload = { sideNavReload: true, method: true };
          this.sharedService.announceEvent(this.sideNavReload);

        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error' + error.message);
        }
      );
  }
  // deleteChoiceList() {
  //   // let params = { sharedListId: this.childInput.id   };
  //   const options = this.childInput.id ?
  //     { params: new HttpParams().set('sharedListId', this.childInput.id) } : {};

  //   // console.log('Deleteb Shared List ', options);
  //   // return false;
  //   this.commonService.delete(this.deleteChoiceListURL,options )
  //     .subscribe(
  //       (result) => {
  //         this.globalResponse = result;
  //         this.getTable();
  //         this.toastorService.success('Deleted Successfully');
  //       },
  //       error => {
  //         this.toastorService.warn('Error' + error.message);
  //       }
  //     );
  // }
  deleteChoice() {
    this.spinnerSharedList = true;
    // let params1 = { questionId: Object.values(this.checkedArr).join(",")  };
    const options = this.checkedArr ?
      { 'choiceId': this.checkedArr } : {};

    //console.log('Array format ', options);
    //console.log('array', this.checkedArr);
   
    // this.commonService.delete(this.deleteChoiceURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteChoiceURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          //this.isEdit=false;
          this.spinnerSharedList = false;
          this.globalResponse = result;
          // this.sharedService.emitEvent(false);
          //this.getTable();
        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error ' + error.message);
        },
        () => {
          this.toastorService.success('Choice Deleted Successfully!');

          this.getSharedChoicebyId(this.childInput);
        }
      );
  }
  dropTable(event: CdkDragDrop<any[]>) {

    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource,prevIndex, event.currentIndex);
    moveItemInArray(this.editSingleQuesForm.get('punchCodes')['controls'],prevIndex, event.currentIndex);
    moveItemInArray(this.editSingleQuesForm.get('punchCodes').value,prevIndex, event.currentIndex);
 
    // console.log(len);
    
    this.table1.renderRows();
    const punchList = this.editSingleQuesForm.controls.punchCodes as UntypedFormArray;
    const arr =punchList.value;
    for(let i=0;i<arr.length;i++){
      arr[i].sequenceNumber =i
       }

  }
  ngAfterViewInit() {
    this.subscription = this.sharedService.eventAnnounced$.subscribe(data => {
      this.isEdit = data.isEdit;
      //console.log('ngAfterViewInit', data);
      if (data.isCancel === true) {
        this.editSingleQuesForm.patchValue(this.choiceFormData);
        this.checkedArr=[];
        const len= this.uncheckRef.length;
        const arr=this.uncheckRef._results;
        for(let i=0;i<len;i++){
          arr[i]._checked=false;          
        }
        // console.log(this.uncheckRef._checked);      
        this.uncheckRef._checked = false;
       
       

      }
      if (this.isEdit === true) {
        //console.log("isEdit  : ");
        if (this.checkedArr) {
          this.deleteButton = true;
        }
        else {
          this.deleteButton = false;
        }
        this.viewEdit.nativeElement.classList.add('editOnly');
        this.sharedService.emitEvent(true);
      } else if (data.isSave === true && this.editSingleQuesForm.valid) {
        //console.log(this.editSingleQuesForm.valid);
        this.sharedListQuestionSingleSave();
        this.viewEdit.nativeElement.classList.remove('editOnly');
      } else if (data.isSave === true && this.editSingleQuesForm.invalid) {
        //this.editSingleQuesForm.patchValue(this.choiceFormData);
        this.sharedListQuestionSingleSave()
        this.viewEdit.nativeElement.classList.add('editOnly');

      }
      else {
        this.viewEdit.nativeElement.classList.remove('editOnly');
      }
    });
  }
  sharedListQuestionSingleSave() {
    this.validationCheck = true;
    if (this.editSingleQuesForm.invalid) {
      this.sharedService.emitEvent(true)
      //console.log("invalid");
    }
    else {
      this.spinnerSharedList = true;
      this.isEdit = false;
      const punchList = this.editSingleQuesForm.controls.punchCodes as UntypedFormArray;
      //console.log(' SINGLE', this.editSingleQuesForm.value);
      //  punchList.removeAt(punchList[0]);
      // this.commonService.update(this.sharedlistquestionupdate, this.editSingleQuesForm.value)
      this.awsCommonService.putRequest(`${this.appConstants.serverUrl}/${this.sharedlistquestionupdate}`, 'PUT', this.editSingleQuesForm.value)
        .subscribe(
          (result) => {
          this.spinnerSharedList = false;
            //console.log('result this.sideNavReload', this.sideNavReload);
          // this.sideNavReload = { sideNavReload : true };
          // this.sharedService.announceEvent(this.sideNavReload);
          this.globalResponse = result;
          this.toastorService.success('Shared Choice Updated Successfully!');
          this.sideNavReload = { sideNavReload: true ,childInput:this.childInput};
          this.sharedService.announceEvent(this.sideNavReload);

        },
        error => {
          this.spinnerSharedList = false;
          // console.log('error', error, error.error.sharedListName);
          if(error.status === 417){
          error.message = "Please enter valid Question Long text"
          } else if(error.error.sharedListName){
            error.message = error.error.sharedListName;
          }  
          this.toastorService.warn(error.message);
          // this.sideNavReload = { sideNavReload: true, choiceSharedListId: error.error.choiceSharedListId };
          // this.sharedService.announceEvent(this.sideNavReload);
          const editCancelData: any = { isEdit: true };
          this.sharedService.announceEvent(editCancelData);
        },

        );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.editSingleQuesForm.controls[controlName].hasError(errorName);
  }
  editSharedQuestion() {

  }
}
