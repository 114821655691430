import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataSource } from '@angular/cdk/table';
import { AppConfig } from '../../../app.config';
import { takeUntil } from 'rxjs/operators';
import { Subject, throwError, of } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommonService } from 'src/app/services/common.service';
import { CommunicatingComponentService } from '../../survey/service/communicating-component.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})

export class DashboardLayoutComponent implements OnInit, OnDestroy {

  appConstants = AppConfig.settings;
  SERVER_URL: any;
  tableDataURL: any;
  checkServerURL: any;
  spinnerMTemplate:any;
  data: any[];
  gridData = [];
  gridDataset = [];
  dataSource;
  displayedColumns = [];

  public pieChartType: string = 'pie';
  public pieChartLabels: Label[];
  public pieChartData: any;
  public pieChartLegend = true;
  public totalNoOfSurveys: any;
  public resultData;
  public pieChartColors: any[] = [{
    backgroundColor: []
  }]
  destroy$: Subject<boolean> = new Subject<boolean>();
  dataTableErrorBlock: any;
  dataTableNoDataFound: any;


  public pieChartOptions: ChartOptions = {
    responsive: true
  };

  constructor(
    private appconfig: AppConfig,
    // private chartservice: ChartDashboardService,
    private notificationService: NotificationService,
    private awsCommonService: AwsCommonService,
    private service: CommonService,
    private communicatingService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute
  ) {
   
    this.checkServerURL = this.appConstants.checkServerURL;
    // this.checkServerURL = '';
    if (this.checkServerURL) {
      this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.chartDashboardParam}`;
      this.tableDataURL = `${this.SERVER_URL}/${this.appConstants.chartListDashboardParam}`;
    } else {
      this.SERVER_URL = this.appConstants.chartDashboardLocalURL;
      this.tableDataURL = this.appConstants.chartListDashboardLocalURL;
    }
    // alert(this.SERVER_URL)
  }

  ngOnInit() {
    // console.log(searchData);
    this.getChart();
    this.displayedColumns = ['surveyCode', 'showTitle', 'testDate', 'surveyType'];
    this.getDashboardTable();
    
    this.service.dashboardValue.next(true);

      this.service.searchData.subscribe(
        result => {
          if(result){
            this.spinnerMTemplate=true;
            console.log(result);
            const options = result ? { 'surveySearch': result } : {};
            this.awsCommonService.getApiRequest(`${this.appConstants.serverUrl}/${this.appConstants.chartDashboardParam}/${this.appConstants.surveyList}`,
              'GET', options).pipe(takeUntil(this.destroy$))
              .subscribe(
                dataSource => {
                  this.spinnerMTemplate=false;
                  this.gridDataset = dataSource;
                  this.gridData = this.gridDataset;
                  this.dataSource = new MatTableDataSource(this.gridData);
                  //this.service.setFilter(this.gridData)
                  // this.service.searchData=false;
                },
                error => {
                  this.notificationService.warn(error.message);
        
                }
              )
          // }
              }
          else{
              this.getDashboardTable();
            }
        }
      )
  }

  public getChart() {
    // this.chartservice.getChartDashboard(this.SERVER_URL).pipe(takeUntil(this.destroy$))
    this.awsCommonService.getRequest(this.SERVER_URL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          this.data = dataSource
          this.pieChartLabels = this.data.map(function (e) {
            return e.surveyType
          });
          this.pieChartData = this.data.map(function (e) { return e.noOfsurveys });
          this.totalNoOfSurveys = this.pieChartData.reduce((a, b) => a + b, 0);
          //console.log(this.pieChartLabels)
          this.pieChartLabels.forEach((myObject, index) => {
            if (myObject == 'Pilot') {
              this.pieChartColors[0].backgroundColor.push('rgb(0, 137, 208)');
            } else if (myObject == 'Print Ad') {
              this.pieChartColors[0].backgroundColor.push('#3a3593');
            } else if (myObject == 'Promo') {
              this.pieChartColors[0].backgroundColor.push('#FCB60F');
            } else {
              this.pieChartColors[0].backgroundColor.push('#2696a8');
            }
          });
          // this.pieChartColors[0].backgroundColor.push('rgb(0, 137, 208)','#FCB60F','#6460AA','#CC004C')
          //console.log(this.pieChartColors[0].backgroundColor)
        },
        error => {
          this.notificationService.warn(error.message);
          this.dataTableErrorBlock = '';
        }
      )
  }
  

  public getDashboardTable() {
    // this.chartservice.getChartListDashboard(this.tableDataURL).pipe(takeUntil(this.destroy$))
    this.spinnerMTemplate=true;
    this.awsCommonService.getRequest(this.tableDataURL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          this.spinnerMTemplate=false;
        
          this.gridDataset = dataSource;
          this.gridData = this.gridDataset;
          this.dataSource = new MatTableDataSource(this.gridData);
        },
        error => {
          this.notificationService.warn(error.message);
          this.dataTableErrorBlock = '';
          
        }
      )
  }

  public pieChartPlugins = [{
    afterLayout: function (chart) {
      chart.legend.legendItems.forEach(
        (label) => {
          let value = chart.data.datasets[0].data[label.index];
          label.text += ': ' + value;
          return label;
        }
      )
    }
  }]

  // events
  public chartClicked(e: any): void {
    if (e.active != '') {
      if (this.gridData.length != this.gridDataset.length) {
        this.gridData = this.gridDataset;
      }
      var labelVal = e.active[0]._view.label;
      this.resultData = this.gridData.filter(function (e) {
        if (labelVal == "Others") {
          return e.surveyType != 'Print Ad' && e.surveyType != 'Promo' && e.surveyType != 'Pilot'
        } else {
          return e.surveyType == labelVal
        }
      });
      this.gridData = this.resultData;
      this.dataSource = new MatTableDataSource(this.gridData);
    }
  }

  reset() {
    this.gridData = this.gridDataset;
    this.dataSource = new MatTableDataSource(this.gridData);
  }
  surveyEdit(editData) {
    
    console.log(editData);
   
    if (editData) {
      this.communicatingService.editstorage = {
        surveyId: editData.id,
        surveycode: editData.surveyCode,
        status: (editData.status === 'Submitted') ? true : false
      };
      this.router.navigate(['../survey/surveyedit'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../dashboard'], { relativeTo: this.route });
    }
  }
  public chartHovered(e: any): void {
    //console.log(e);
  }

  ngOnDestroy() {
    this.service.dashboardValue.next(false);
  }
}