import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnInit, Input, EventEmitter, Output, Injectable, OnChanges, AfterViewInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { List } from 'src/app/models/list.model';
import { AppConfig } from 'src/app/app.config';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {Subject} from 'rxjs';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { resource } from 'selenium-webdriver/http';
@Component({
  selector: 'app-shared-list-sidenav',
  templateUrl: './shared-list-sidenav.component.html',
  styleUrls: ['./shared-list-sidenav.component.scss'],
  providers: [SharedlistService]
})
@Injectable({
  providedIn: 'root'
})
export class SharedListSidenavComponent implements OnInit, OnChanges, AfterViewInit,OnDestroy {

  sharedQuestionList: any[] = [];
  sharedChoiceList: List[];
  sharedVariableList: any[];
  SharedSideNavQuestions = new UntypedFormControl('');
  SharedSideNavVariables = new UntypedFormControl('');
  SharedSideNavChoices = new UntypedFormControl('');

  private subscription: Subscription

  appConstants = AppConfig.settings;
  public currentContact: any;
  public searchQuesModel: string;
  public searchChoiModel: string;
  public searchVarModel: string;
  searchQModel: string;
  data: any;
  checkServerURL: any;
  SERVER_URL: any;
  getSharedQuestionsList_URL: any;
  getSharedChoiceList_URL: any;
  getSharedVariableList_URL: any;
  currentSharedQuestion: any;
  loadInit:boolean = false;
  spinnerQuesSL: boolean = false;
  spinnerChoiSL: boolean = false;
  spinnerVarSL: boolean = false;

  @Output() public select: EventEmitter<{}> = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private sharedlistService: SharedlistService,
    private sharedservice: SharedService,
    private awsCommonService: AwsCommonService,
    private render: Renderer2,
    private toastorService: NotificationService
  ) {
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getSharedQuestionsList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedQuestionsList}`;
      this.getSharedChoiceList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedChoiceList}`;
      this.getSharedVariableList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedVariableList}`;
    } else {
      this.getSharedQuestionsList_URL = this.appConstants.getSharedQuestionsListLocalURL;
      this.getSharedChoiceList_URL = this.appConstants.getSharedChoiceListLocalURL;
      this.getSharedVariableList_URL = this.appConstants.getSharedVariableListLocalURL;
    }
    // this.getSharedQuestionsList();
    // this.getSharedChoiceList();
    // this.getSharedVariableList();
  }

  ngOnInit() {
    this.getSharedQuestionsList(true);
    this.getSharedChoiceList();
    this.getSharedVariableList();



  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.subscription = this.sharedservice.eventAnnounced$.subscribe((event) => {
      //console.log('event.sideNavReload', event.sideNavReload);
        if(event.sideNavReload===true){
         this.getSharedChoiceList();
         if(event.childInput){
          let target ={type: event.childInput.type , id: event.childInput.id}
          // console.log(target);
          this.onSelect(target.type,target);
         }
        
        if(event.method===true){ this.getSharedQuestionsList(true); } else {
          this.getSharedQuestionsList(); 
        }
        }
        if(event.createdChoice ===true || event.choiceSharedListId ){
          let target = {type: "sharedChoices", id: (event.choiceSharedListId)? event.choiceSharedListId : event.response.choiceSharedListId}
          //console.log(target);
          this.onSelect(target.type,target);
        }
        if(event.questionSharedListId){
          //console.log(event);
          let target ={type: "sharedQuestions", id: event.questionSharedListId,isGrid:event.questionType}
          //console.log(target);
          this.onSelect(target.type,target);
        }

    });
  }

  getSharedQuestionsList(loadInit?:boolean) {
    this.spinnerQuesSL = true;
    // this.sharedlistService.getSharedQuestions(this.getSharedQuestionsList_URL)
    this.awsCommonService.getRequest(this.getSharedQuestionsList_URL, 'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
    this.spinnerQuesSL = false;
      this.SharedSideNavQuestions.reset();
      this.SharedSideNavVariables.reset();
      this.sharedQuestionList = data.map(item => {
        return {
          id: item.id,
          value: item.name,
          isGrid: item.isGrid
        };
      })
    if(this.sharedQuestionList.length>0 && loadInit){
      let target = this.sharedQuestionList[0];
      //console.log(target);
      this.currentSharedQuestion = {id: this.sharedQuestionList[0].id}; 
      this.onSelect("sharedQuestions",target);
    }
    },
    (error) => {
      this.spinnerQuesSL = false;
      this.toastorService.warn(error.message);
    }
    );
  }


  getSharedChoiceList() {
    this.spinnerChoiSL = true;
    // this.sharedlistService.getSharedChoice(this.getSharedChoiceList_URL)
    this.awsCommonService.getRequest(this.getSharedChoiceList_URL, 'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
     this.spinnerChoiSL = false;
      this.SharedSideNavChoices.reset();
      this.sharedChoiceList = data.map(item => {
        return {
          id: item.choiceSharedListId,
          value: item.sharedListName
        };
      })
      //console.log('choice', data);
      //console.table('choiceList', this.sharedChoiceList);

    },
    (error) => {
      this.spinnerChoiSL = false;
      this.toastorService.warn(error.message);
    }
    );
  }

  getSharedVariableList() {
    this.spinnerVarSL = true;
    // this.sharedlistService.getSharedVariable(this.getSharedVariableList_URL)
    this.awsCommonService.getRequest(this.getSharedVariableList_URL, 'GET').pipe(takeUntil(this.destroy$))
    .subscribe(data => {
    this.spinnerVarSL = false;
      this.SharedSideNavVariables.reset();
      this.sharedVariableList = data.map(item => {
        return {
          id: item.variableSharedListId,
          value: item.sharedListName,
        };
      })
    }, (error) => {
      this.spinnerVarSL = false;
      this.toastorService.warn(error.message);
    }
    );
  }

  public searchInput(input: any, type: string): void {
    if (type === 'sharedQuestions') {
      this.searchQuesModel = input;
    } else {
      this.searchChoiModel = input;
    }
  }
  public onSelect(type: string, target: any) {
    this.data = {
      type: type,
      id: target.id
    }
    //console.log(type);
    //console.log(target);
    this.currentSharedQuestion = { id: target.id };
    this.data.isGrid = target.isGrid;
    //console.log('test', this.data);

    //  target.classList.add('selected'); 
    //  this.sharedChoicesDiv.nativeElement.classList.add('selected'); //matListOption
    this.select.emit(this.data);
  }

  searchQuestions(ev) {
    // console.log(ev.target.value);
    if(ev.target.value) {
      this.spinnerQuesSL = true;
      const searchQues = {'sharedQuestionSearch': ev.target.value};
      this.awsCommonService.getApiRequest(`${this.SERVER_URL}/sharedQuestions`, 'GET', searchQues)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.spinnerQuesSL = false;
        // console.log(result);
        // this.sharedQuestionList = result;
        this.sharedQuestionList = result.map(item => {
          return {
            id: item.id,
            value: item.name,
            isGrid: item.isGrid
          };
        })
      }, error => {
        this.spinnerQuesSL = false;
        this.toastorService.warn(error.message);
      });
    } else {
      this.getSharedQuestionsList();
    }

  }
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /*
  onSelected(event){
    //console.log('event', event);
    event.preventDefault()
    this.render.setElementClass(event.target,"selected1",true);
   // event.classList.add('selected'); 
  }*/

}
