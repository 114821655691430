import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, ViewChildren, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
// import { TemplateService } from 'src/app/services/template.service';
import { AppConfig } from 'src/app/app.config';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AddQuestionComponent } from '../add-question/add-question.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { ConfirmModalComponent } from '../../layout/confirm-modal';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { TemplateModel } from 'src/app/models/template.model';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { Subscription, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray, CdkDragStart, CdkDropList } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-shared-grid-question-edit-view',
  templateUrl: './template-shared-grid-question-edit-view.component.html',
  styleUrls: ['./template-shared-grid-question-edit-view.component.scss']
})
export class TemplateSharedGridQuestionEditViewComponent implements OnInit, OnDestroy,AfterViewChecked,AfterViewInit {
 
  @Input() childInput;
  appConstants = AppConfig.settings;
  SERVER_URL:any;
  checkServerURL: any;
  editTempSharedQuesForm: UntypedFormGroup;
  punchCodes: UntypedFormArray;
  childInput1: any;
  getQuestionSharedListByIdURL: any;
  templateSharedlistquestionUpdateURL: any;
  deleteChoiceURL: any;
  deleteQuestionURL: any;
  deleteSharedListURL: any;
  sharedQuestionDetail:any=[];
  columns:any[];
  columnsToDisplay: string[];
  columnsToDisplayPunchCode: any[];
  dataSource: any[]=[];
  isEdit: boolean = false;
  choicesharedListId: number;
  spinnerTemplate: boolean = false;
  checkedArr:any = [];
  deleteButton:boolean = false;
  isNew: boolean = false;
  questionType: any  = 'Grid';
  subscription:Subscription;
  subscriptionSave: Subscription;
  private saveSubscription: Subscription;
  private spinnerSubscription: Subscription;
  sharedQuestionList: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  inputData:TemplateModel;
  templateQuestionChoiceList:any = null;
  sharedListChoices:any = null;

  @ViewChild('div') viewEdit: ElementRef;
  @ViewChildren('ref') ref;
  @ViewChild('table') table: MatTable<any>;
  previousIndex: number;
  testArr: any[];
  dragFlag: boolean;
  constructor( 
  //  private templateService: TemplateService,
    private commonService: AwsCommonService,
    private toastorService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    public dialog:MatDialog,
    private cdr:ChangeDetectorRef
  ) { 
    this.SERVER_URL = `${this.appConstants.serverUrl}`;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`;
      this.templateSharedlistquestionUpdateURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}`
      this.deleteChoiceURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.deleteChoiceURL}`;
      this.deleteQuestionURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.deleteQuestionURL}`;
      this.deleteSharedListURL = `${this.SERVER_URL}/${this.appConstants.templateURL}/${this.appConstants.deleteTemplateSharedQuestionURL}`;
    } else {
      this.getQuestionSharedListByIdURL = this.appConstants.getTemplateQuestionSharedListByIdLocalURL;
      this.templateSharedlistquestionUpdateURL = `${this.appConstants.templateURL}`
    }
  }

  ngOnInit() {
    this.editTempSharedQuesForm = this.formBuilder.group({
      templateDescription: new UntypedFormControl(''),
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
      ])),
      sharedListDescription: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
      ])),
      questionSharedListId: new UntypedFormControl(''),
      punchCodes: this.formBuilder.array([]),
    });
    this.sharedService.templateData.subscribe( data => {
      this.questionType = data.isGrid;
    });

    this.saveSubscription =this.sharedService.templateSaveEvent$.subscribe(data => {
      if(data.isSave==true && this.questionType=='Grid'){
        this.editTempSharedQuesForm.controls['templateDescription'].setValue(data.templateDescription)
        this.sharedListGridQuestionSave();
      }
    })
   
  }
  ngAfterViewInit(): void {
    //throw new Error("Method not implemented.");
    this.dragFlag=false;
  }
  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }

  ngOnChanges(){
    this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
    this.sharedService.templateData.subscribe( data => {
      this.questionType = data.isGrid;
    });

    if(this.questionType === 'Grid'){
    this.subscription = this.sharedService.templateEditEvent$.subscribe(data => {
      this.isEdit = data.isEdit;
      this.editTempSharedQuesForm.controls['templateDescription'].setValue(data.templateDescription);
      if(this.isEdit === true){
        this.viewEdit.nativeElement.classList.add('editOnly');
      }
      if(this.isEdit === false){
        this.viewEdit.nativeElement.classList.remove('editOnly');
      }
      if(data.isEdit === false && data.isCancel === true){
        this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
     /*    this.editTempSharedQuesForm.patchValue({
          sharedListName:this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListName,
          sharedListDescription:(this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions.length>0)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListDescription : '',
        });
        this.checkedArr=[];
        const len= this.ref.length;
        const arr=this.ref._results;
        for(let i=0;i<len;i++){
          arr[i]._checked=false;          
        }
       
        this.ref._checked = false; */
      }
    });
    }
/* 
    this.spinnerSubscription = this.sharedService.spinnerEvent$.subscribe(data => {
      this.spinnerTemplate = data;
    });
 */
  }

  getQuestionSharedListById(templateId, sharedQuestionId){
  this.spinnerTemplate=true;
  // this.sharedService.spinnerEvent(this.spinnerTemplate);
  
  this.columns = [];
  this.columnsToDisplay = [];
  this.columnsToDisplayPunchCode = [];
  this.dataSource = [];
  let parentColumn = [];
  if (this.checkServerURL) {
    this.getQuestionSharedListByIdURL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${templateId}/${this.appConstants.templateSharedQuestions}/${sharedQuestionId}`;
  }

    this.commonService.getRequest(`${this.getQuestionSharedListByIdURL}`, 'GET')
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (data) => {
        this.dragFlag=false
        this.spinnerTemplate=false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
        this.sharedQuestionDetail = data['templateQuestionChoice'][0];
        if(this.sharedQuestionDetail.templateQuestionChoiceId){
          this.sharedService.templateQuesChoiceEmit(this.sharedQuestionDetail.templateQuestionChoiceId);
            }
        if(this.sharedQuestionDetail){
        this.editTempSharedQuesForm.patchValue({
          questionSharedListId: data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.questionSharedListId, 
          sharedListName:data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.sharedListName,
          sharedListDescription:data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList.sharedListDescription,
          
        })
        
        this.choicesharedListId = (data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList) ? data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList.choiceSharedListId: null;
      }
       
        let questions = (data['templateQuestionChoice'].length>0)? data['templateQuestionChoice'][0].sharedQuestionChoice.questionSharedList: ''; // .sharedListQuestions
        let choices =   (data['templateQuestionChoice'].length>0)? data['templateQuestionChoice'][0].sharedQuestionChoice.choiceSharedList:''; // .sharedListChoices;
           
        // Enable all readonly to editable
        if(this.isEdit === true){
          this.viewEdit.nativeElement.classList.add('editOnly');
        } else {
          this.viewEdit.nativeElement.classList.remove('editOnly');
        }
        const punchList = this.editTempSharedQuesForm.controls.punchCodes as UntypedFormArray;
        punchList.clear();
        if (questions) {
       let displayedElementWithRows: any[] = [];
       let parentColumn = [
         { 'columnDef': 'action', 'header': 'Action', 'punchCode': '', 'punchCol': 'actions', 'choiceId': '' },
         { 'columnDef': 'name', 'header': 'Questions', 'punchCode': '', 'punchCol': 'questionss', 'choiceId': '' }
       ];
      
      this.sharedListChoices = (choices)? choices.sharedListChoices : null;

      if(this.sharedListChoices!=null){
       if (this.sharedListChoices.length > 0) {
        punchList.clear();
         displayedElementWithRows = this.sharedListChoices.map(
           item => {
             punchList.push(this.formBuilder.group({
               sharedListChoiceId: [item.sharedListChoiceId, Validators.required],
               punchCode: [item.punchCode, Validators.required],
               choiceLibraryId: [item.choiceLibrary.choiceLibraryId],
               choiceSequenceNumber: [item.choiceSequenceNumber] ,
               activeYn:[item.choiceLibrary.activeYn]
             }));
             return {
               'columnDef': 'choice' + item.sharedListChoiceId,
               'header': item.choiceLibrary.choiceLibraryDesc,
               'punchCode': item.punchCode,
               'punchCol': 'choices' + item.sharedListChoiceId,
               'choiceId': item.sharedListChoiceId
             }
           }
         );
       }}
       this.columns = [...parentColumn, ...displayedElementWithRows];
       this.columnsToDisplay = this.columns.map(c => c.columnDef);
       this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
       this.dataSource = questions.sharedListQuestions;
      
        }
      },
      error => {
        this.spinnerTemplate=false;
        this.dragFlag=false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
      }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.editTempSharedQuesForm.controls[controlName].hasError(errorName);
  }

// Form submit 
sharedListGridQuestionSave() { 
  if(this.editTempSharedQuesForm.invalid) {
    return false;
  } else {
      this.templateSave();
  }
}

templateSave() {
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
    let choiceSharedListData;
    let sharedListQuestionsData=null;
    const punchList = this.editTempSharedQuesForm.controls.punchCodes as UntypedFormArray;
     
    if(punchList.length>0){
      let punchdata = punchList.controls.map(item => {
        return {
         sharedListChoiceId: item.get('sharedListChoiceId').value,
         punchCode: item.get('punchCode').value,
         choiceLibrary: {
          choiceLibraryId: item.get('choiceLibraryId').value,
          },
          choiceSequenceNumber: item.get('choiceSequenceNumber').value ,
          activeYn:item.get('activeYn').value
        }
      })
      choiceSharedListData = {
        choiceSharedListId:this.choicesharedListId,
        sharedListChoices: punchdata
        
      }
    }
    if(this.dragFlag && this.testArr.length>0){
      let punchSeq= this.testArr.map(item=>{
      
        return{
          sharedListChoiceId: item.choiceId,
          punchCode:item.punchCode,
          choiceSequenceNumber:item.choiceSequenceNumber,
          choiceLibrary:{
            choiceLibraryId:item.choiceLibraryId
          },
          activeYn:item.activeYn
          
        }
       });
       if(this.dragFlag){
        choiceSharedListData={
          choiceSharedListId:this.choicesharedListId,
          sharedListChoices: punchSeq
  
        }
       }
     
    }
    let questions = (this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList:null;
    if(questions){
      sharedListQuestionsData = questions.sharedListQuestions.map(item => {
        // consio.log(item);
        return {
          questionLibrary:{
            questionLibraryId:item.questionLibrary.questionLibraryId
          },
          sharedListQuestionsId: item.sharedListQuestionsId,
          questionLongText: item.questionLongText,
          questionSequenceNumber: item.questionSequenceNumber,
          activeYn:item.questionLibrary.activeYn
        }
      })
    }

    let inputData:TemplateModel = {
      surveyTemplateId: this.childInput.templateId,
      templateDescription: this.editTempSharedQuesForm.controls['templateDescription'].value,
      templateQuestionChoice: [{
        templateQuestionChoiceId:this.sharedQuestionDetail.templateQuestionChoiceId,
        sharedQuestionChoice: {
          sharedQuestionsChoiceId: this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId,
          questionSharedList: {
            questionSharedListId: this.editTempSharedQuesForm.controls['questionSharedListId'].value,
            sharedListName: this.editTempSharedQuesForm.controls['sharedListName'].value,
            sharedListDescription: this.editTempSharedQuesForm.controls['sharedListDescription'].value,
            source:this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.source,​
            activeYn: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.activeYn,
            questionType: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.questionType,
            variableYN: this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.variableYN,
          sharedListQuestions: sharedListQuestionsData
          },
            choiceSharedList: choiceSharedListData
          }
    }]}
    this.commonService.putRequest(this.templateSharedlistquestionUpdateURL, 'PUT', inputData)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (result) => { 
        this.dragFlag=false;
       
        this.spinnerTemplate=false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
        this.toastorService.success('Template Updated Successfully!');
        let templateData = { surveyTemplateId: this.childInput.templateId, id:result, isGrid: this.questionType } 
        const reload={reload:true, response:templateData, isEdit: false}
        this.sharedService.templateDataEmit(reload);
      /*  let questionSharedListData = {
          id:result, 
          isGrid: this.questionType
        } 
        this.sharedService.sharedListDataEmit(questionSharedListData); */
       },
      error => {
        this.spinnerTemplate=false;
        this.dragFlag=false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
        if(error.status==409){
          error.message = error.error;
          this.dragFlag=false;
        }
        this.toastorService.warn(error.message);
        const editCancelData: any = { isEdit: true };
        this.sharedService.templateEmitEvent(editCancelData);
      },
      () => {
      
      //  this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);
      });
  }

  
  // ADD QUESTION 
  openAddQuestion(): void {
   
   let dialogRef = this.dialog.open((AddQuestionComponent), {
      panelClass: 'newQuestion', disableClose: true,
       data: { 
          templateId: this.childInput.templateId, 
          templateQuestionChoiceId:this.sharedQuestionDetail.templateQuestionChoiceId,
          sharedQuestionsChoiceId: this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId,
          sharedListId: this.childInput.sharedQuestionId, 
          sharedListName: (this.childInput.sharedQuestionId)? this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListName: '',
        }
    });
    dialogRef.afterClosed().subscribe((data) => {
     
      if (data) {
        const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
        this.sharedService.templateEmitEvent(editCancelData);
        let questionSharedListData = {
        //  id:data.questionSharedListId, 
          id:data, 
          isGrid: this.questionType
        } 
        this.sharedService.sharedListDataEmit(questionSharedListData);
       /* this.childInput.sharedQuestionId =  data.questionSharedListId;
        this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);*/
      }
    });
  }

  //ADD CHOICE 
  openAddChoice(): void {
    let dialogRef = this.dialog.open((AddChoiceComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { sharedListId: this.childInput.sharedQuestionId, choicesharedListId: this.choicesharedListId, 
        surveyTemplateId: this.childInput.templateId , templateQuestionChoiceId:this.sharedQuestionDetail.templateQuestionChoiceId}
    });
      dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
        this.sharedService.templateEmitEvent(editCancelData);
        let questionSharedListData = {
        //  id:data.questionSharedListId, 
          id:data, 
          isGrid: this.questionType
        } 
        this.sharedService.sharedListDataEmit(questionSharedListData);
     /*   this.childInput.sharedQuestionId =  data.questionSharedListId;
        this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId);*/
      } 
    });
  }


  selectRow($event, dataSource) {
    if ($event.checked) {
      this.checkedArr.push(dataSource.sharedListQuestionsId);
    } else {
      let i = this.checkedArr.indexOf(dataSource.sharedListQuestionsId);
      this.checkedArr.splice(i, 1);
    }
    if (this.checkedArr.length>0) {
      this.deleteButton = true;
    } else {
      this.deleteButton = false;
    }
  }
  
  //Confirmation Popup
  openconfirmModal(type, id): void {
      const dialogRef = this.dialog.open((ConfirmModalComponent), { data: { msg: `delete the ${type}` }, panelClass: 'confirmModal', disableClose: true });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === true) {
          if (type === 'Shared List') {
            this.deleteSharedList();
          } else if (type === 'questions') {
            this.deleteQuestion();
          } else if (type === 'choice') {
            this.deleteChoice(id);
          }
        }
      });
  }

  
   // Delete Choice Function
   deleteChoice(choiceId) {
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
  //  const options = choiceId ? { params: new HttpParams().set('choiceId', choiceId).set('squestionId', this.childInput.sharedQuestionId).set('templateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId) } : {};
//  const options = choiceId ? { params: new HttpParams().set('sharedListChoicesId', choiceId).set('sharedQuestionsChoiceId', this.sharedQuestionDetail.templateQuestionChoice[0].sharedQuestionChoice.sharedQuestionsChoiceId).set('surveyTemplateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoice[0].sharedQuestionChoice.sharedQuestionsChoiceId) } : {};
 // const options = choiceId ? { params: new HttpParams().set('sharedListChoicesId', choiceId).set('sharedQuestionsChoiceId', this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId).set('surveyTemplateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId) } : {};
    const options = choiceId ? {'sharedListChoicesId': choiceId, 'sharedQuestionsChoiceId': this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId, 'surveyTemplateId': this.childInput.templateId, 'templateQuestionChoiceId': this.sharedQuestionDetail.templateQuestionChoiceId } : {};
    this.commonService.deleteRequest(this.deleteChoiceURL, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe((result:any) => {
        this.spinnerTemplate = false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
        const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
        this.sharedService.templateEmitEvent(editCancelData);
        let questionSharedListData = {
        //  id:result.questionSharedListId, 
          id:result, 
          isGrid: this.questionType
        } 
        this.sharedService.sharedListDataEmit(questionSharedListData);
     //   this.childInput.sharedQuestionId = result.questionSharedListId;
      },
      error => {
        this.spinnerTemplate = false;
        // this.sharedService.spinnerEvent(this.spinnerTemplate);
        this.toastorService.warn('Error' + error.message);
      },
      () => {
        this.toastorService.success('Choice Deleted Successfully!');
     //   this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId );
      }
      );
  }

  deleteQuestion() {
    // let params1 = { questionId: Object.values(this.checkedArr).join(",")  };
  //  const options = this.checkedArr ? { params: new HttpParams().set('questionId', this.checkedArr).set('squestionId', this.childInput.sharedQuestionId).set('templateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId)  } : {};
  // const options = this.checkedArr ? { params: new HttpParams().set('sharedListQuestionId', this.checkedArr).set('sharedQuestionsChoiceId', this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId).set('surveyTemplateId', this.childInput.templateId).set('templateQuestionChoiceId',this.sharedQuestionDetail.templateQuestionChoiceId)  } : {};
   const options = this.checkedArr ? { 'sharedListQuestionId': this.checkedArr, 'sharedQuestionsChoiceId': this.sharedQuestionDetail.sharedQuestionChoice.sharedQuestionsChoiceId, 'surveyTemplateId': this.childInput.templateId, 'templateQuestionChoiceId': this.sharedQuestionDetail.templateQuestionChoiceId } : {};
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
    this.commonService.deleteRequest(this.deleteQuestionURL,'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result:any) => {
          this.checkedArr = []; 
          this.deleteButton = false;
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          const editCancelData: any  = { isEdit: true, sideNavReload: true, type:'sharedListADD' }
          this.sharedService.templateEmitEvent(editCancelData);
          this.toastorService.success('Question Deleted Successfully!');
          let questionSharedListData = {
          //  id:result.questionSharedListId, 
            id:result, 
            isGrid: this.questionType
          } 
          this.sharedService.sharedListDataEmit(questionSharedListData);
         /* this.childInput.sharedQuestionId = result.questionSharedListId;
          this.getQuestionSharedListById(this.childInput.templateId, this.childInput.sharedQuestionId );*/
          },
        error => {
          this.checkedArr = [];
          this.deleteButton = false;
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          this.toastorService.warn('Error ' + error.message);
        },
        () => {
         
        }
      );
  }
  
  
  deleteSharedList() {
    
  //  const options = this.childInput.sharedQuestionId ? { params: new HttpParams().set('squestionId', this.childInput.sharedQuestionId).set('templateId', this.childInput.templateId) } : {};
   // const options = this.childInput.sharedQuestionId ? { params: new HttpParams().set('questionSharedListId', this.childInput.sharedQuestionId).set('surveyTemplateId', this.childInput.templateId) } : {};
   const options = this.childInput.sharedQuestionId ? { 'questionSharedListId':this.childInput.sharedQuestionId,'templateQuestionChoiceId':this.sharedQuestionDetail.templateQuestionChoiceId,'surveyTemplateId':this.childInput.templateId} : {};
  // const options = this.childInput.sharedQuestionId ? { 'questionSharedListId': this.childInput.sharedQuestionId, 'surveyTemplateId': this.childInput.templateId } : {};
    this.spinnerTemplate = true;
    // this.sharedService.spinnerEvent(this.spinnerTemplate);
    this.commonService.deleteRequest(this.deleteSharedListURL,'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result:any) => {
          this.checkedArr = [];
      this.spinnerTemplate = false;
      // this.sharedService.spinnerEvent(this.spinnerTemplate);
      this.toastorService.success('Shared Question Deleted Successfully!');
      const editCancelData: any  = { isEdit: false, sideNavReload: true, type:'sharedListDEL' }
      this.sharedService.templateEmitEvent(editCancelData);
        },
        error => {
          this.spinnerTemplate = false;
          // this.sharedService.spinnerEvent(this.spinnerTemplate);
          this.toastorService.warn('Error' + error.message);
        }
      );
  }
  somethingChanged(val,index){
    
    if(this.dragFlag){
      this.toastorService.warn('Please save the choices order before editing the punchcode');
    }
    
  }
  dragStarted(event: CdkDragStart, index: number ) {
    this.previousIndex = index;
  }
  dropListDropped(event: CdkDropList, index: number) {
    this.dragFlag=true;
    
    if (event && this.previousIndex >1 && index >1) {      
       moveItemInArray(this.columns, this.previousIndex, index);
      this.testArr =this.columns.slice(2);
     const punchList  =this.editTempSharedQuesForm.get('punchCodes').value ;
        this.editTempSharedQuesForm.patchValue({
          punchodes:this.testArr
        })
      punchList.forEach(x=>{
        for(let i=0;i<this.testArr.length;i++){
         if(x.sharedListChoiceId==this.testArr[i].choiceId){
          this.testArr[i].choiceLibraryId=x.choiceLibraryId;
          this.testArr[i].choiceSequenceNumber =i;
          this.testArr[i].activeYn=x.activeYn
         } 
        }
      })
         
      this.setDisplayedColumns();
    }
  }
  setDisplayedColumns() {
    const punchList = this.editTempSharedQuesForm.controls.punchCodes as UntypedFormArray;
    const len=punchList.value.length;
    let arr=punchList.value;
    this.columns.forEach(( colunm, index) => {
      colunm.index = index;
      this.columnsToDisplay[index] = colunm.columnDef;
     this.columnsToDisplayPunchCode[index]=colunm.punchCol;
     for(let i=0;i<len;i++){
          if( arr[i].sharedListChoiceId == colunm.choiceId){
            colunm.punchCode=arr[i].punchCode;
          

          } 
     }
    });
  }
  dropTable(event: CdkDragDrop<any[]>,index) {
    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    if(this.isEdit){
    moveItemInArray(this.dataSource,this.previousIndex, index);
    const len=this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions.length;
    for(let i=0;i<len;i++){
      this.sharedQuestionDetail.sharedQuestionChoice.questionSharedList.sharedListQuestions[i].questionSequenceNumber =i;
    }
    

  //  this.table1.renderRows();
  this.table.renderRows();
 //this.refreshTable(this.dataSource);
  }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.saveSubscription.unsubscribe();
  //  this.spinnerSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
