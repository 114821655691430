<div class="tableTitleHeader px-2 mt-3 mb-3">
  <h5 class="float-left font-20">{{surveyDec.surveyTitle}}</h5>
  <div class="float-right createSection" *ngIf="isAdmin">
    <span>
      <p class="float-left font-13 blue" (click)="createsurvey()">
        <span>{{surveyDec.newSurvey}}</span>
        <i class="addnewIcon"></i>
      </p>
      <p class="float-left ml-3">
        <i class="excelIcon" (click)="exportExcel()"></i>
      </p>
      <!-- <p class="float-left ml-3">
          <i class="uploadicon material-icons" (click)="uploadExcel()">cloud_upload </i>
        </p> -->
    </span>
  </div>
</div>
<div class="inputsheet-table ">
  <div class="spinnerOverlay"  *ngIf="surveyDec.isLoading"></div>
  <div class="spinner" *ngIf="surveyDec.isLoading">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
  </div>
  <cdk-virtual-scroll-viewport class="demo-viewport demo-table-container" itemSize="30">
    <!-- (matSortChange)="sortData($event)" matSortDisableClear
           [multiTemplateDataRows]="true" -->
    <!-- <table mat-vs-table [dataSource]="matTableDataSource" matSort > -->
    <table class="surveyTable" mat-table [dataSource]="matTableDataSource" matSort matSortDisableClear
      [multiTemplateDataRows]="true">
      <ng-container matColumnDef="surveyCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[0] | uppercase}} <i class="sortIcon"></i>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.surveyCode }} </td>
        <td mat-footer-cell *matFooterCellDef class="sticky-header">
          <span *ngIf="surveyDec.gridTotalCount; else elseBlock">Showing {{surveyDec.gridTotalCount}}
            Records</span>
          <ng-template #elseBlock>{{surveyDec.tableErrorBlock}}</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="showTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[1] | uppercase}}
          <i class="sortIcon"></i>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.showTitle}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="showGenre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[2] | uppercase}} <i class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.showGenre}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="episodeNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[3] | uppercase}} <i class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.episodeNumber}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="testDt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[4] | uppercase}}<i class="sortIcon"></i> </th>
        <td mat-cell *matCellDef="let element"> {{element.testDt | date: 'MM/dd/yyyy'}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="surveyTypeDesc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[5] | uppercase}}<i class="sortIcon"></i> </th>
        <td mat-cell *matCellDef="let element"> {{element.surveyTypeDesc}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="titleVersion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[6] | uppercase}}<i class="sortIcon"></i> </th>
        <td mat-cell *matCellDef="let element"> {{element.titleVersion}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="inputsheet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[7] | uppercase}} <i class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.inputsheet}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="LastUpdatedDt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[8] | uppercase}} <i class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.LastUpdatedDt | date: 'MM/dd/yyyy'}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
          {{surveyDec.tableHeaderColumns[9] | uppercase}} <i class="sortIcon"></i></th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{surveyDec.tableHeaderColumns[10] | uppercase}} </th>
        <td mat-cell *matCellDef="let element" class="StatusCopyIcon">
          <i class="makeCopyIcon" *ngIf="isAdmin" (click)="confirmDialog(element.surveyId)"></i>
          <i class="editIcon" [ngClass]="!isAdmin ? 'isUser viewIcon':'editIcon'" (click)="surveyEdit(element)"></i> 
          
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Filter section Start-->
      <ng-container matColumnDef="filter_surveycode">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="surveyDec.surveyCodeControl" placeholder="Search Survey Code"
              autocomplete="off">
            <i class="autocomplete-search"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_showtitle">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Search Show Title" [formControl]="surveyDec.showtitleControl"
              autocomplete="off">
            <i class="autocomplete-search"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_genre">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <mat-select [formControl]="surveyDec.genreControl" placeholder="All">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let showGenre of surveyDec.showGenreData" [value]="showGenre">{{showGenre}}
              </mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_episode">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="surveyDec.episodeControl" placeholder="All" autocomplete="off">
            <i class="autocomplete-search"></i>
            <button mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_testdate">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <span matTooltip="{{surveyDec.createdate}}">
            <app-daterange-picker-component (emitRange)="getDates($event, 'createdDt')">
            </app-daterange-picker-component>
          </span>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_surveytype">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <mat-select [formControl]="surveyDec.surveytypeControl" placeholder="All">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let surveyType of surveyDec.surveyTypeData" [value]="surveyType">
                {{surveyType}}</mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_version">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <mat-select [formControl]="surveyDec.versionControl" placeholder="All">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let optionData of surveyDec.titleVersionData" [value]="optionData">
                {{optionData}}</mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>

      <ng-container matColumnDef="filter_inputsheet">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="surveyDec.inputsheetControl" placeholder="All"
              autocomplete="off">
          </mat-form-field>
        </th>
      </ng-container>

      <ng-container matColumnDef="filter_modifieddate">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <span matTooltip="{{surveyDec.lastdate}}">
            <app-daterange-picker-component (emitRange)="getDates($event, 'lastUpdatedDt')">
            </app-daterange-picker-component>
          </span>
        </th>
      </ng-container>

      <ng-container matColumnDef="filter_surveystatus">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <mat-form-field appearance="outline">
            <mat-select [formControl]="surveyDec.surveystatusControl" placeholder="All">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let status of surveyDec.surveyStatusData" [value]="status">
                {{status}}</mat-option>
            </mat-select>
            <i class="bottomArrow filter-selectAlign"></i>
          </mat-form-field>
        </th>
      </ng-container>
      <ng-container matColumnDef="filter_action">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <button class="clearFilter" mat-stroked-button class="clearFilter ClearAllWtext" color="primary"
            (click)="clearGridTable()">Clear All
            Filters</button>
          <span class="makeCopyText" *ngIf="isAdmin">Make a Copy</span> <span class="editText" [ngClass]="!isAdmin ? 'isUser':''">{{(isAdmin) ? 'Edit' : 'View'}}</span>
        </th>
      </ng-container>
      <!-- Filter Section End -->

      <tr mat-header-row *matHeaderRowDef="surveyDec.tableDisplayColumns; sticky: true;" class="table-header-section">
      </tr>
      <tr mat-header-row *matHeaderRowDef="surveyDec.tableFillterColumns; sticky: true;" class="table-filter-section">
      </tr>
      <tr mat-row *matRowDef="let row; columns: surveyDec.tableDisplayColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="surveyDec.tableDisplayColumns; sticky: true" class="table-footer-section">
      </tr>
    </table>

  </cdk-virtual-scroll-viewport>
</div>