import { Component, OnInit, Inject } from '@angular/core';
import { ChoiceDashboardComponent } from '../choice-dashboard/choice-dashboard.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ChoiceService } from 'src/app/services/choice.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AppConfig } from '../../../../app.config';
import { Observable } from 'rxjs';
import { sharedList, List } from 'src/app/models/list.model';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { ChoicedashboardDatatable } from 'src/app/models/choicedashboard-datatable';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { QuestionService } from 'src/app/services/question.service';
import { HttpParams } from '@angular/common/http';
import { QuestiondashboardDatatableService } from 'src/app/services/questiondashboard-datatable.service';
import { RequiredSharedListChoiceMatch } from 'src/app/shared/requiredMatch';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-choice-edit',
  templateUrl: './choice-edit.component.html',
  styleUrls: ['./choice-edit.component.scss']
})
export class ChoiceEditComponent implements OnInit {

  appConstants = AppConfig.settings;
  choiceCreateLabel: any;
  validationMsg: any;
  editChoiceForm: UntypedFormGroup;
  globalResponse: any;
  editData: any;
  checkServerURL: any;
  choiceParam: any;
  SERVER_URL: any;
  sharedChoiceList: List[];
  filteredChoice: any;
  choiceList = [];
  getSharedChoiceList_URL: any;
  editLiveData: any;
  isLoadingSharedQuestion = false;
  isLoadingSave = false;
  radioItem = [
    { id: 1, value: 'POSITIVE', data: 'Y', color: 'green' },
    { id: 2, value: 'NEGATIVE', data: 'N', color: 'red' },
    { id: 3, value: 'NEUTRAL', data: 'U', color: '' }
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();
  commanPattern: string;
  constructor(
    private appconfig: AppConfig,
    private editChoiceRef: MatDialogRef<ChoiceDashboardComponent>,
    private choiceService: ChoiceService,
    private toastorService: NotificationService,
    private commonService: CommonService,
    private sharedlistService: SharedlistService,
    private quesService: QuestionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private questionDashboardService: QuestiondashboardDatatableService,
    private awsCommonService: AwsCommonService
  ) {
    this.commanPattern = '^([a-zA-Z0-9 _-]{10,255})$';
    this.choiceCreateLabel = AppConfig.settings.choiceModel;
    this.validationMsg = AppConfig.settings.validationMsg;
    this.editData = data;
    this.choiceParam = this.appConstants.choiceDashboardParam;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    this.getSharedChoiceList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedChoiceList}`;

  }

  public url = 'choices';
  filteredSharedList: Observable<sharedList[]>;
  sharedList: sharedList[] = [
    { sharedListId: 1, sharedListDesc: 'Value' },
    { sharedListId: 2, sharedListDesc: 'sdfsd' }
  ];
  getChoiceEdit() {
    this.isLoadingSave = true;
    const options = this.editData.choiceLibraryId ?
      {'id': this.editData.choiceLibraryId } : {};
    // this.questionDashboardService.getQuestionedit(`${this.appConstants.serverUrl}/choices/editChoice`, options)
    this.awsCommonService.getApiRequest(`${this.appConstants.serverUrl}/choices/editChoice`,'GET',options).pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.isLoadingSave = false;
          //console.log(data),
            this.editLiveData = data;
          this.editChoiceForm.patchValue({
            choiceLibraryId: this.editData.choiceLibraryId,
            choiceLibraryDesc: this.editData.choiceLibraryDesc,
            posNegFlag: this.editData.posNegFlag,
            activeYn: this.editData.activeYn,
            choicesharedListId: this.editLiveData.choiceSharedListId,
            choicesharedList: (this.editLiveData.choicesharedListId) ?
              { choiceSharedListId: this.editLiveData.choicesharedListId, sharedListName: this.editLiveData.sharedListName } :
              ''
          });
        }
      );
  }
  ngOnInit() {
    this.getSharedList();
    this.editChoiceForm = new UntypedFormGroup({
      choiceLibraryId: new UntypedFormControl(''),
      choiceLibraryDesc: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        Validators.maxLength(255),
        validateEmptyField
      ])),
      posNegFlag: new UntypedFormControl(''),
      activeYn: new UntypedFormControl(''),
      choicesharedList: new UntypedFormControl(''),
      choicesharedListId: new UntypedFormControl('')
    });
    this.getChoiceEdit();
    this.initializeFormGroup();
  }


  // Validation Error Message
  public hasError = (controlName: string, errorName: string) => {
    return this.editChoiceForm.controls[controlName].hasError(errorName);
  }

  // Set Slider field value
  onChange(controlName: string, e) {
    if (e.checked === true) {
      this.editChoiceForm.controls[controlName].setValue('Y');
    } else {
      this.editChoiceForm.controls[controlName].setValue('N');
    }
  }

  //Close Popup
  close(event: MouseEvent): void {
    this.editChoiceRef.close('cancel');
    event.preventDefault();
  }
  initializeFormGroup() {

  }

  //Shared List API
  getSharedList() {
    this.isLoadingSharedQuestion = true;
    // this.quesService.getChoiceList().subscribe(
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/sharedlist/choices`,'GET').pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.sharedList = data;
        //console.log(this.sharedList);
        this.isLoadingSharedQuestion = false;
        this.filteredSharedList = this.editChoiceForm.controls.choicesharedList.valueChanges
          .pipe(
            startWith(''),
            map(shared => shared ? this._filterValue(shared, this.sharedList, 'sharedList') : this.sharedList.slice())
          );
      },
      error => {
        this.isLoadingSharedQuestion = false,
          //console.log(error),
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      },
      () => { }
    );
  }
  _filterValue(val: any, ArrList: any, Type: any) {
    const name = val.sharedListName || val; // val can be List or string
    //console.log(ArrList);
    const list = ArrList.filter(
      option => option.sharedListName.toLowerCase().includes(name.toLowerCase()));

    this.editChoiceForm.controls.choicesharedListId.setValue(this.editChoiceForm.controls.choicesharedList.value.choiceSharedListId);
    // /return (list.length > 0 ? list : [{ questionCategoryId: null, questionCategoryDesc: 'No record found' } as any]);
    return (list.length > 0 ? list : [{ choiceSharedListId: null, sharedListName: 'No record found' } as any]);
  }
  sharedDisplayFn(filterBy): string {
    return filterBy ? filterBy.sharedListName : filterBy;
  }

  isRequired() {
    ////console.log('ssssssssssss');
    return false;
  }

  // Update Choice
  updateChoice() {
    //console.log(this.editChoiceForm.controls.choicesharedList.value);
    //console.log(this.editChoiceForm.value);
    // this.editChoiceForm.setValue({ invalid: false });
    //console.log(this.editChoiceForm.invalid);
    if (this.editChoiceForm.invalid) {
      return;
    } else {
      //console.log('islaoding');
      this.isLoadingSave = true;
      this.editChoiceForm.patchValue({
        choiceLibraryDesc: this.editChoiceForm.controls.choiceLibraryDesc.value,
        posNegFlag: this.editChoiceForm.controls.posNegFlag.value,
        activeYn: this.editChoiceForm.controls.activeYn.value,
        choicesharedListId:
          (this.editChoiceForm.controls.choicesharedList.value) ?
            this.editChoiceForm.controls.choicesharedList.value.choiceSharedListId : '',
        choicesharedList: (this.editChoiceForm.controls.choicesharedList.value) ? {
          choiceSharedListId: this.editChoiceForm.controls.choicesharedList.value.choiceSharedListId,
          sharedListName: this.editChoiceForm.controls.choicesharedList.value.sharedListName
        } :
          { choiceSharedListId: '', sharedListName: '' }
      });
      // this.editChoiceForm.removeControl('choicesharedList');
      // this.editChoiceForm.removeControl('questionSharedList');
      // this.isLoadingSave = true;
      // //console.log(this.editChoiceForm.value); return false;
      // this.commonService.update(this.choiceParam, this.editChoiceForm.value).subscribe(
      this.awsCommonService.putRequest(`${this.appConstants.serverUrl}/${this.choiceParam}`, 'PUT', this.editChoiceForm.value).subscribe(
        (result) => {
          this.globalResponse = result;
          this.isLoadingSave = false;
        },
        error => {
          // this.toastorService.warn('Error' + error.message);
          if (error.status === 409) {
            this.editChoiceForm.controls.choiceLibraryDesc.setErrors({
              notUnique: true
            });
            this.isLoadingSave = false;
            // this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            // this.createQuesForm.controls.genericText.setValue('');
            // this.toastorService.warn('already exists in our database');
          } else {
            this.editChoiceRef.close('close');
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          }

        },
        () => {
          this.isLoadingSave = false;
          this.editChoiceRef.close('success');
          this.toastorService.success('Updated Successfully');
        });
      // tslint:disable-next-line:deprecation
      event.preventDefault();
    }
  }

}