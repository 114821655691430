import { SharedService } from 'src/app/shared/services/shared.service';
// import { TemplateService } from 'src/app/services/template.service';
import { CommonService } from 'src/app/services/common.service';
import { TemplateDashboardComponent } from './../template-dashboard/template-dashboard.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { NotificationService } from 'src/app/services/notification.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-shared-question-create',
  templateUrl: './template-shared-question-create.component.html',
  styleUrls: ['./template-shared-question-create.component.scss']
})
export class TemplateSharedQuestionCreateComponent implements OnInit, AfterViewInit, OnDestroy {

  CreateSharedQuestionForm: UntypedFormGroup
  appConstants = AppConfig.settings;
  getSharedQuestionsList_URL: string;
  postSharedQuestion_URL: string;
  SERVER_URL: string;
  sharedQuestionsList: any;
  selectedQuestions: any = []
  QuestionIds: any = []
  surveyTemplateId: any;
  spinnerChoice: boolean
  searchChoiModel: any;
  loading: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('sharedQuestions') private selectionList: MatSelectionList;

  constructor(private sharedListService: SharedlistService,
    private createChoiceRef: MatDialogRef<TemplateDashboardComponent>,
    private fb: UntypedFormBuilder,
    //  private commonService:CommonService,
    //  private templateService:TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: AwsCommonService,
    private sharedService: SharedService,
    private toastorService: NotificationService
  ) {
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.SERVER_URL) {
      this.getSharedQuestionsList_URL = `${this.SERVER_URL}/${this.appConstants.getSharedQuestionsList}`;
      this.postSharedQuestion_URL = `${this.appConstants.serverUrl}/${this.appConstants.templateURL}/${this.appConstants.templateAddSharedQuestion}`;
    }

    else {
      this.getSharedQuestionsList_URL = this.appConstants.getSharedQuestionsListLocalURL;
    }
  }
  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
    this.sharedService.templateData.subscribe(data => {
      // console.log('tempalte-sharedQuestion', data);
      this.surveyTemplateId = data.templateId;

      // this.templateInput = Object.assign({}, data);
      //  this.templateId = data.templateId;
      //  this.sharedQuestionId = data.sharedQuestionId;
      //   this.onSharedQuestionDetail(data.templateId, data.sharedQuestionId);
    })

  }
  ngOnInit() {
    // console.log(this.data);
    this.getSharedQuestions();
    this.CreateSharedQuestionForm = this.fb.group({
      surveyTemplateId: this.fb.control(this.data.templateId),
      templateQuestionChoice: this.fb.array([]),
      questionSharedListId: this.fb.control('')

    })
    // this.CreateSharedQuestionForm = new FormGroup({
    //   questionIds: new FormControl(null)

    // });
  }

  getSharedQuestions() {
    this.loading = true;
    /* this.sharedListService.getSharedQuestions(this.getSharedQuestionsList_URL) */
    this.commonService.getRequest(this.getSharedQuestionsList_URL, 'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.loading = false;
        // console.log(data);
        this.sharedQuestionsList = data.map(item => {
          return {
            id: item.id,
            name: item.name,

          };
        })
        // consio.log('QuestionsList' , this.sharedQuestionsList);
      }, (error) => {
        this.loading = false;
        this.toastorService.warn('Error' + error.message);
      })

  }
  onAreaListControlChanged(sharedQuestions, option) {
    //console.log(this.createSharedChoiceForm.value.choiceLibraryIds);
    // console.log(this.sharedQuestionList);
    // consio.log(option);
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);

    const found = this.selectedQuestions.some(el => el.id === option.id);
    if (!found) {
      this.selectedQuestions.push(option);
      this.QuestionIds.push(option.id);
      //this.CreateSharedQuestionForm.patchValue({ questionIds: this.QuestionIds });
    } else {
      //const index = this.selectedChoices.findIndex(el => el.choiceLibraryId === option.choiceLibraryId);
      this.onRemove(option);
    }

    //return this.sharedQuestionList;
  }
  onRemove(item) {
    // this.selectedChoices.splice(index, 1);
    // consio.log(this.selectedQuestions);
    // consio.log(item);
    let index = this.selectedQuestions.findIndex(x => x.id === item.id);
    this.selectedQuestions.splice(index, 1);
    let questionIds = this.selectedQuestions.map(x => x.id);
    this.QuestionIds = questionIds;


    //this.CreateSharedQuestionForm.patchValue({ choiceLibraryIds: this.QuestionIds });
  }
  getSomeClass(option) {
    //console.log(option);
    const found = this.selectedQuestions.some(el => el.id === option.id);
    if (found) {
      return { createQuesselected: found };
    }
    else {
      return { removeBg: !found };
    }

  }
  public trimFunction(val) {
    val.target.value = val.target.value.trim();
  }
  // createSharedQuestion(){
  //   console.log(this.CreateSharedQuestionForm);
  // }
  close(event: MouseEvent): void {
    this.createChoiceRef.close();
    event.preventDefault();
  }

  createSharedQuestion() {
    // consio.log(this.CreateSharedQuestionForm.value);
    this.CreateSharedQuestionForm.removeControl('questionSharedListId');
    let control = <UntypedFormArray>this.CreateSharedQuestionForm.controls.templateQuestionChoice;
    this.spinnerChoice = true;
    this.QuestionIds.forEach((x) => {
      // consio.log(x);
      control.push(
        this.fb.group({

          sharedQuestionChoice: {
            questionSharedList: {
              questionSharedListId: this.fb.control(x).value
            }
          }
        })
      )
    })

    /* this.commonService.insert(this.postSharedQuestion_URL,this.CreateSharedQuestionForm.value) */
    this.commonService.postRequest(this.postSharedQuestion_URL, 'POST', this.CreateSharedQuestionForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => { // consio.log(res); 
          this.spinnerChoice = false;
          this.createChoiceRef.close();
          const editCancelData: any = { isEdit: false, sideNavReload: true, type: 'sharedList', isNew: false }
          this.sharedService.templateEmitEvent(editCancelData);


          //   this.createTemplateForm.reset()
          // this.QuestionIds=[]
        },
        (error) => {
          this.spinnerChoice = false;
          if (error.status === 409) {
            this.toastorService.warn('Shared Question Already Exists');
            const tempArr = this.CreateSharedQuestionForm.get('templateQuestionChoice') as UntypedFormArray;
            tempArr.clear();

          }
          else if(error.status === 417){
            this.toastorService.warn('Please Add Choices to the Shared Question');
            const tempArr = this.CreateSharedQuestionForm.get('templateQuestionChoice') as UntypedFormArray;
            tempArr.clear();
            
          }
          this.CreateSharedQuestionForm.addControl('questionSharedListId', new UntypedFormControl(''))


        },
        () => {
          this.spinnerChoice = false;
          this.toastorService.success('New Questions added successfully!');
          // this.isLoadingSave = false;
          // this.toastorService.success('Choice Added Successfully!');
          // this.spinnerChoice = false;

          // this.addChoiceRef.close('success');


        }
      );
      event.preventDefault();

  }
  ngOnDestroy(): void {
    //  throw new Error("Method not implemented.");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
