import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';

@Component({
  selector: 'app-template-saveas',
  templateUrl: './template-saveas.component.html'
})
export class TemplateSaveasComponent implements OnInit {
  templateSaveName: UntypedFormGroup;
  name:any;
  constructor( 
    public dialogRef: MatDialogRef<TemplateSaveasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:UntypedFormBuilder
      ) {
      this.templateSaveName = this.fb.group({
        name: new UntypedFormControl('', Validators.compose([
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
          validateEmptyField
        ])),
      }) 
    }
     

  ngOnInit() {}
  public hasError = (controlName: string, errorName: string) => {
    return this.templateSaveName.controls[controlName].hasError(errorName);
  }
 
  onConfirm(): void{
    if(this.templateSaveName.invalid){

    }else{
     this.name=this.templateSaveName.controls.name.value;
    this.dialogRef.close({"templateName":this.name, "status":true});
    }
  }
}
