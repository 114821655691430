import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { QuestiondashboardComponent } from '../question-dashboard/question-dashboard.component';
import * as AppConst from '../../../../app.config';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, FormArray } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { List, categoryList, sharedList, choiceList, questionSharedList } from '../../../../models/list.model';
import { map, startWith, tap, debounceTime, switchMap, finalize } from 'rxjs/operators';

import { RequiredMatch } from '../../../../shared/requiredMatch';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { QuestionsModel } from 'src/app/models/questions.model';
// import { ChoiceDashboardComponent } from 'src/app/modules/choice/components/choice-dashboard/choice-dashboard.component';
import { AppConfig } from '../../../../app.config';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})

export class CreatequestionComponent implements OnInit {

  appConstants = AppConfig.settings;
  createQuesForm: UntypedFormGroup;
  label: any;
  validationMsg: any;
  public globalResponse: any;
  public url = 'questions';
  questionSharedListControl = new UntypedFormControl();
  // questionSharedList: string[] = [' FUTURE ACTIVITY', 'FAVORITE SHOWS', 'HOST ADJECTIVES', 'CLUSTER', 'ANCHOR', 'FIRST FAVORITE'];
  posNegFlag = [
    { id: 1, value: 'POSITIVE', data: 'Y', color: 'green' },
    { id: 2, value: 'NEGATIVE', data: 'N', color: 'red' },
    { id: 3, value: 'NEUTRAL', data: 'U', color: '' }
  ];
  dialog: any;

  filteredCategory;
  filteredSharedList;
  filteredChoice: Observable<List[]>;

  categoryList = [];
  sharedList = [];
  choiceList: choiceList[] = [
    { choiceListId: 1, choiceListDesc: 'Frequently' },
    { choiceListId: 2, choiceListDesc: 'Rarely' }
  ];
  @ViewChild(MatAutocompleteTrigger, { static: true }) auto: MatAutocompleteTrigger;

  isLoadingCategory = false;
  isLoadingSharedQuestion = false;
  isLoadingSave = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private createQuestionRef: MatDialogRef<QuestiondashboardComponent>,
    private toastorService: NotificationService,
    private commonService: CommonService,
    private appconfig: AppConfig,
    private formbuilder: UntypedFormBuilder,
    private awsCommonService: AwsCommonService,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.label = this.appConstants.question;
    this.validationMsg = this.appConstants.validationMsg;
  }

  ngOnInit() {
    this.initializeFormGroup();
    this.getCategory();
    this.getSharedList();
    // this.getChoiceList();
  }
  // public inputData = new QuestionsModel();

  initializeFormGroup() {
    this.createQuesForm = this.formbuilder.group({
      questionCategory: new UntypedFormControl('', [Validators.required, RequiredMatch]),
      questionCategoryId: new UntypedFormControl(''),
      questionCategoryDesc: new UntypedFormControl(''),
      genericText: new UntypedFormControl('',
        Validators.compose([
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
          Validators.maxLength(255),
          validateEmptyField
        ]
        )),
      questionPosNegFlag: new UntypedFormControl(this.posNegFlag[0].data, [Validators.required]),
      percentileYn: new UntypedFormControl('Y'),
      summRptYn: new UntypedFormControl('Y'),
      activeYn: new UntypedFormControl('Y'),
      questionSharedList: new UntypedFormControl(''),
      questionLongText: new UntypedFormControl(''),
      questionSharedListId: new UntypedFormControl('')
    });
  }

  _filterValue(val: any, ArrList: any, Type: any) {
    //console.log('filter');
    //console.log(val);
    if (Type === 'categoryList') {
      const name = val.questionCategoryDesc || val; // val can be List or string
      const list = ArrList.filter(
        option => option.questionCategoryDesc.toLowerCase().includes(name.toLowerCase()));
      return (list.length > 0 ? list : [{ questionCategoryId: null, questionCategoryDesc: 'No record found' } as any]);
    } else if (Type === 'sharedList') {
      const name = val.sharedListName || val;
      // //console.log('shared');
      // console.log(name) 
      const list = ArrList.filter(
        option => option.sharedListName.toLowerCase().includes(name.toLowerCase()));
      return (list.length > 0 ? list : [{ questionSharedListId: null, sharedListName: 'No record found' } as any]);
    } else if (Type === 'choiceList') {
      const name = val.choiceListDesc || val; // val can be List or string
      const list = ArrList.filter(
        option => option.choiceListDesc.toLowerCase().indexOf(name.toLowerCase()) === 0);
      return list;
    }

  }

  categoryDisplayFn(filterBy: any): string {
    return filterBy ? filterBy.questionCategoryDesc : filterBy;
  }
  sharedDisplayFn(filterBy: any): string {
    return filterBy ? filterBy.sharedListName : filterBy;
  }
  // choiceDisplayFn(filterBy: any): string {
  //   return filterBy ? filterBy.choiceListDesc : filterBy;
  // }

  openLink(event: MouseEvent): void {
    this.createQuestionRef.close('cancel');
    event.preventDefault();
  }


  // Validation
  getErrorMessage() {
    return this.createQuesForm.controls.genericText.hasError('required') ? 'Please enter a generic question' :
      this.createQuesForm.controls.genericText.hasError('pattern') ? 'Not a valid input ' :
        this.createQuesForm.controls.genericText.hasError('maxlength') ? 'Generic Text is set to store 255 characters Only' : '';
  }


  // Validation Error Message
  public hasError = (controlName: string, errorName: string) => {
    return this.createQuesForm.controls[controlName].hasError(errorName);
  }

  // Set Slider field value
  onChange(controlName: string, e) {
    if (e.checked === true) {
      this.createQuesForm.controls[controlName].setValue('Y');
    } else {
      this.createQuesForm.controls[controlName].setValue('N');
    }
  }

  onClose() {
    this.createQuesForm.reset();
    this.initializeFormGroup();
  }

  // Auto Populate for each dropdown
  // Category API
  getCategory(): void {
    this.isLoadingCategory = true;
    // this.quesService.getCategoryList()
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/categories?page=1`, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.categoryList = data;
        // //console.log(this.categoryList);
        this.auto.closePanel(),
          this.isLoadingCategory = false;
        this.filteredCategory = this.createQuesForm.controls.questionCategory.valueChanges
          .pipe(
            debounceTime(150),
            startWith(''),
            tap(() => {  }),
            map(
              category => category ? this._filterValue(category, this.categoryList, 'categoryList') : this.categoryList.slice()
            )
          );
      },
        error => {
          this.isLoadingCategory = false,
            //console.log(error);
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        },
        () => { this.isLoadingCategory = false; },
      );
  }
  // Shared List API
  getSharedList(): void {
    this.isLoadingSharedQuestion = true;
    // this.quesService.getSharedList().subscribe(
    this.awsCommonService.getRequest(`${this.appConstants.serverUrl}/questions/question`, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(
      data => {
        this.sharedList = data;
        //console.log(this.sharedList);
        this.isLoadingSharedQuestion = false;
        this.filteredSharedList = this.createQuesForm.controls.questionSharedList.valueChanges
          .pipe(
            startWith(''),
            map(shared => shared ? this._filterValue(shared, this.sharedList, 'sharedList') : this.sharedList.slice())
          );
      },
      error => {
        this.isLoadingSharedQuestion = false;
        //console.log(error);
        this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      },
      () => { this.isLoadingSharedQuestion = false; }
    );
  }

  createQuestion() {
    if (this.createQuesForm.invalid) {
      return;
    } else {
      this.isLoadingSave = true;
      // //console.log(this.createQuesForm);
      this.createQuesForm.patchValue({
        questionCategoryId: this.createQuesForm.controls.questionCategory.value.questionCategoryId,
        questionCategoryDesc: this.createQuesForm.controls.questionCategory.value.questionCategoryDesc,
        genericText: this.createQuesForm.controls.genericText.value,
        questionPosNegFlag: this.createQuesForm.controls.questionPosNegFlag.value,
        percentileYn: this.createQuesForm.controls.percentileYn.value,
        summRptYn: this.createQuesForm.controls.summRptYn.value,
        activeYn: this.createQuesForm.controls.activeYn.value,
        questionSharedListId:
          (this.createQuesForm.controls.questionSharedList.value) ?
            this.createQuesForm.controls.questionSharedList.value.questionSharedListId : '',
        questionLongText: this.createQuesForm.controls.questionLongText.value,
        questionSharedList:
          (this.createQuesForm.controls.questionSharedList.value) ? this.createQuesForm.controls.questionSharedList.value :
          { questionSharedListId: '', sharedListName: '' }

      });
      // //console.log(this.createQuesForm.value); return false;
      // this.commonService.insert(this.url, this.createQuesForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.url}`, 'POST', this.createQuesForm.value)
        .subscribe(
          (result) => {
            this.isLoadingSave = false;
            this.globalResponse = result;
          },
          error => {
            // this.toastorService.warn('Error' + error.message);
            //console.log(error);
            if (error.status === 409) {
              this.isLoadingSave = false;
              this.createQuesForm.controls.genericText.setErrors({
                notUnique: true
              });
              // 428
              this.isLoadingSave = false;
            } else if (error.status === 428) {
              //console.log(error.status);
              this.createQuesForm.controls.questionSharedList.setErrors({
                required: true
              });
              this.isLoadingSave = false;
            } else {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
              this.createQuestionRef.close('cancel');
            }
          },
          () => {
            // return false;
            this.isLoadingSave = false;
            this.createQuestionRef.close('success');
            this.toastorService.success('Submitted Successfully');
          }
        );
      // tslint:disable-next-line:deprecation
      event.preventDefault();
    }
  }
}
