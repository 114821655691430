<div class="container primeNewModal createsharedChoice">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="CreateSharedQuestionForm" autocomplete="off" class="example-form" novalidate (ngSubmit)="createSharedQuestion()">
            <mat-card-content>
                <!--  <div formArrayName="templateQuestionChoice"> -->
                <mat-form-field class="searchTextWrap autoSelect selection-placeholder" floatLabel=never>
                    <label class="font-13 float-left"> Select Question</label>
                    <div class="autoSelect hastag clear" floatLabel=never>
                        <span *ngFor="let item of selectedQuestions;let i=index">
                                    <mat-list-item> {{ item.name }}  
                                    <button mat-icon-button (click)="onRemove(item)">
                                    <mat-icon class="mat-24 hastagDelete">x</mat-icon>
                                    </button></mat-list-item>
                                </span>
                    </div>
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search Questions from Shared list" [(ngModel)]="searchChoiModel" [ngModelOptions]="{standalone: true}" [readonly]="loading" />

                        <i class="searchicon align-autosearchIcon"></i>
                    </div>
                    <mat-selection-list #sharedQuestions class="form-control marginT10" formControlName="questionSharedListId">
                        <mat-list-option title="{{option.choiceLibraryDesc}}" *ngFor="let option of sharedQuestionsList | searchFilter: searchChoiModel;let i=index" [selected]="option.selected" (click)="onAreaListControlChanged(sharedQuestions,option)" [ngClass]="getSomeClass(option)"
                            [value]="option.id">
                            <span>{{option.name}}</span>

                        </mat-list-option>

                    </mat-selection-list>
                </mat-form-field>
                <!-- <mat-error *ngIf="hasError('choiceLibraryIds', 'required')">{{validationMsg}}
                                {{ sharedChoiceCreateLabel.sharedChoiceList }}</mat-error>          -->
                <!-- </div> -->




            </mat-card-content>
            <div class="spinner" *ngIf="spinnerChoice">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit">ADD</button>
                <button type="button" mat-raised-button color="warn" (click)="close($event)">CANCEL</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>