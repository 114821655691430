import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { DataSource } from '@angular/cdk/table';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subject, generate } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { Inputsheetprintads, InputsheetprintCreateHeader, InputsheetPrintCreate, InputsheetPrintQuesSection, InputSheetPrintSrAwarenessSection } from 'src/app/models/inputsheetprintads';
import { ConfirmModalComponent } from '../../layout/confirm-modal.component';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpParams } from '@angular/common/http';
import { fbind } from 'q';
import { DatePipe } from '@angular/common';
import { Directive, HostListener, Input } from '@angular/core';
import data from '../../../../../assets/json/vendorData';

@Directive({
  selector: '[editable]'
})

export class EditableDirectivePrint {
  @Input() keysban: number[];

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // check banned key
    if (this.keysban) {
      if (this.keysban.indexOf(event.keyCode) > -1) {
        event.preventDefault();
      }
    }
    //console.log(event.keyCode);
  }

  constructor() { }

}

@Component({
  selector: 'app-print-create',
  templateUrl: './print-create.component.html',
  styleUrls: ['./print-create.component.scss'],
  providers: [DatePipe]
})
export class PrintCreateComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  updateGenerate: any;
  printNotSaved: boolean = true;
  appConstants = AppConfig.settings;
  printDec: Inputsheetprintads;
  printAddTableRow: UntypedFormGroup;
  dataSource = new MatTableDataSource<InputsheetprintCreateHeader>();
  indexpostion: UntypedFormGroup;
  createInputPrint: InputsheetPrintCreate;
  genreList: any = [];
  selected: any = [];
  adTitleButton: any;
  printInfoArr: any = [];
  valueFound: any;
  showTitleList: any[] = [];
  Section1PromoSet: any = [];
  sectionData: any[] = [];
  TotalAds: number = 0;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any = [];
  dataValue: any = [];
  inputSheetId: any;
  srAwarenessList: any = [{ rowid: null, possraware: 1, srawareness: '', upDateFlag: true }];
  questionstimList: any = [{ rowid: null, posques: 1, quesnstim: '', upDateFlag: true }];
  showTitleEmpty: boolean = false;

  pilotDateVal: string;
  pilotDate = new UntypedFormControl();
  showSelected: boolean = false;
  spinnerMTemplate: boolean = false;
  statusOfPrint: any;
  disButtons: boolean = false;


  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  toggle: boolean = true;
  datasection1HeaderList: any[] = [];
  dataset: any[] = [];
  sectionResponseData: any;
  public form: UntypedFormGroup;
  public formAfterINDIVpAds: UntypedFormGroup;
  public formAfterGroupAds: UntypedFormGroup;
  public formAfterPromoAds: UntypedFormGroup;
  public formTag: UntypedFormGroup;
  public formShowDesc: UntypedFormGroup;
  public formVideotoShow: UntypedFormGroup;

  srawarenessSave: boolean = false;
  quessSave: boolean = false;
  adTitleSaveEdit: boolean;
  sec2SaveEdit: boolean;
  sec3SaveEdit: boolean;
  sec4SaveEdit: boolean;
  sec5SaveEdit: boolean;
  sec6SaveEdit: boolean;
  sec7SaveEdit: boolean;

  submittedForm1: boolean = false;
  submittedForm2: boolean = false;
  submittedForm8: boolean = false;
  submittedForm9: boolean = false;

  section8Form: UntypedFormGroup;
  onAddsec8: boolean = false;
  dataSource8;
  displayedColumns8: string[] = ['possraware', 'srawareness', 'columndelete'];

  section9Form: UntypedFormGroup;
  onAddsec9: boolean = false;
  dataSource9;
  displayedColumns9: string[] = ['posques', 'quesnstim', 'columndelete'];
  vendorData: { "vendorId": number; "vendorName": string; }[];
  selectedVendor: any;


  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private dialog: MatDialog,
    private commonService: AwsCommonService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private toastorService: NotificationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.adTitleSaveEdit = true;

    this.sec2SaveEdit = true;
    this.sec3SaveEdit = true;
    this.sec4SaveEdit = true;
    this.sec5SaveEdit = true;
    this.sec6SaveEdit = true;
    this.sec7SaveEdit = true;

    this.form = fb.group({ adTitle: fb.array([]) });
    this.formAfterINDIVpAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formAfterGroupAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formAfterPromoAds = fb.group({ inputSheetSection: fb.array([]) });
    this.formTag = fb.group({ inputSheetSection: fb.array([]) });
    this.formShowDesc = fb.group({ inputSheetSection: fb.array([]) });
    this.formVideotoShow = fb.group({ inputSheetSection: fb.array([]) });

    this.printDec = {
      createTitle: this.appConstants.inputsheetHeader.createTitle,
      printAddHeader: ['genre', 'show Title', 'episode #', 'no of print ads'],
      printAddColumns: ['genreId', 'showTitleID', 'episode', 'noofprintads'],
      printAddFooter: ['genreId', 'showTitleID', 'episode', 'noofprintads']
    };
    this.printDec.printaddData = [{ genreId: '', showTitleID: '', episode: '', noofprintads: '' }];
    // this.dataSource(this.printDec.printaddData);
    this.dataSource = new MatTableDataSource(this.printDec.printaddData);

    this.section8Form = fb.group({
      section8Array: fb.array([this.fb.group({
        srawareness: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
          validateEmptyField
        ])
      })])
    });

    this.section9Form = fb.group({
      section9Array: fb.array([this.fb.group({
        quesnstim: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
          validateEmptyField
        ])
      })])
    });

    this.printDec.printAddCreateForm = this.fb.group({
      
      inputSheetId: new UntypedFormControl(null),
      inputSheetType: this.fb.group({
        inputSheetType: 'Print',
        activeYn: 'Y'
      }),

      surveyAnalyst: ['', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      programmerNotes: ['', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      testDate: [''],      
      vendor:['',[Validators.required]],
      activeYn: 'Y',
      inputSheetStatus: this.fb.group({
        inputSheetStatus: 'Draft'
      }),
      inputSheetPrintInfo: this.fb.array([this.createItem()]),

    });
    //console.log(this.printDec.printAddCreateForm);

    // this.printAddTableRow = this.fb.group({
    //   rows: this.fb.array([])
    // });
    this.sendMessage();
    this.initGroup();
  }
  createItem(): UntypedFormGroup {
    return this.fb.group({
      //  sequenceid:this.printDec.printAddCreateForm.controls.inputSheetPrintInfo[],
      showTitleSequenceNumber: 1,
      printInfoId: new UntypedFormControl(null),
      primeShowTitle: this.fb.group({
        primeShowTitleId: [''],
        projectType: this.fb.group({
          c2027ProjectTypeId: [''],
        }),
      }),
      noOfPrints: ['', [Validators.required]],
      episodeSerialNumber: ['', [Validators.required]]
    });
  }
  createItemSection(): UntypedFormGroup {
    return this.fb.group({
      sequenceid: [],
      printInfoId: [],
      showTitle: [],
      noOfPrints: [],
      inputsheetId: [],
      promoSet: this.fb.array([this.section1()])
    })

  }

  section1(): UntypedFormGroup {
    return this.fb.group({
      promoSpotPrintTitle: ['', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
  }
  initGroup() {
    this.createItem();
  }


  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  clearMessage() {
    this.communicatingComponentService.sendCommunication(false);
    this.router.navigate(['../print'], { relativeTo: this.route });
  }

  ngOnInit() {
    // this.addprintads.accountdetails()
    // .subscribe(data =>
    // this.dataSource.data= data.map(obj => ({...obj, value: 0}))
    // );
    this.vendorData=data;
    this.dataSource8 = new MatTableDataSource<InputSheetPrintSrAwarenessSection>(this.srAwarenessList);
    this.dataSource9 = new MatTableDataSource<InputsheetPrintQuesSection>(this.questionstimList);
    this.commonService.getRequest(`${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showprojectgenres`,
      'GET')
      .subscribe(
        data => {
          this.genreList = data;
          //console.log(data);
          //this.statusOfPrint=data.inputSheetStatus.inputSheetStatus;
          // if(data.inputSheetStatus.inputSheetStatus==='Submitted'){
          //   //console.log('submitted print');
          //   this.disButtons=true;
          // }
        },
        err => {
          this.toastorService.warn(err);
        }
      );
    // this.getSection1(1997);
  }
  section1Generate(dataArray) {
    if (this.datasection1HeaderList) {
      this.datasection1HeaderList.forEach(printAdsData => {
        this.addAdTitleGroup(printAdsData);
      });
    } else {
      this.addAdTitleGroup();
    }
  }
  sections(section) {
    if (this.dataset) {
      this.dataset.forEach(printAdsData => {
        //this.addAdTitleGroup(printAdsData);
        this.addAfterINDIVadsGroup(printAdsData);
        this.addAfterGroupAds(printAdsData);
        this.addAfterPromoAds(printAdsData);
        this.addTag(printAdsData);
        this.addShowDesc(printAdsData);
        this.addVideotoShow(printAdsData);
      });
    } else {
      //this.addAdTitleGroup();
      this.addAfterINDIVadsGroup();
      this.addAfterGroupAds();
      this.addAfterPromoAds();
      this.addTag();
      this.addShowDesc();
      this.addVideotoShow();
    }
  }

  addAdTitleGroup(adTitleData?: any) {
    //console.log(adTitleData);
    const adTitleFG = this.fb.group({
      printInfoId: [adTitleData ? adTitleData.printInfoId : ''],
      noOfPrints: [adTitleData ? adTitleData.noOfPrints : ''],
      inputSheetId: [adTitleData ? adTitleData.inputsheetId : ''],
      promoSet: this.fb.array([]),
    });
    (this.form.get('adTitle') as UntypedFormArray).push(adTitleFG);
    const adTitleDataIndex = (this.form.get('adTitle') as UntypedFormArray).length - 1;
    if (!adTitleData) {
      this.addAdTitlePromoSet(adTitleDataIndex);
    } else {
      adTitleData.promoSet.forEach(phone => {
        this.addAdTitlePromoSet(adTitleDataIndex, phone);
      });
    }

  }
  addAdTitlePromoSet(adTitleDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      promoSpotPrintTitle: [data ? data.promoSpotPrintTitle : '', [Validators.required, Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
      inputSheetPromoSpotId: [data ? data.inputSheetPromoSpotId : ''],
      sectionId: '616',
      sectionName: 'AD TITLE(s) / FILE NAME(s)',
    });
    // tslint:disable-next-line: max-line-length
    (((this.form.controls.adTitle as UntypedFormArray).controls[adTitleDataIndex] as UntypedFormGroup).controls.promoSet as UntypedFormArray).push(promoSetFG);
    if (this.form.invalid) {
     
      this.adTitleButton = true;

    } else {
      this.adTitleButton = false;
    }
  }

  putAdTitleGroup(putAdTitleGroup: any) {
    (this.form.get('adTitle') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetPromoSpotId.value;
        const promoSpotPrintTitle = ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.promoSpotPrintTitle.value;
        putAdTitleGroup.inputSheetPromoSpot.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetPromoSpotId === '' || editData.promoSpotPrintTitle === promoSpotPrintTitle) {
            ((((this.form.controls.adTitle as UntypedFormArray).controls[index] as UntypedFormGroup).controls.promoSet as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetPromoSpotId.patchValue(editData.inputSheetPromoSpotId);
          }
        });
      });
    });
    //console.log(putAdTitleGroup);
    //console.log(this.form.value);
  }

  addAfterINDIVadsGroup(afterINDIVData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [afterINDIVData ? afterINDIVData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [afterINDIVData ? afterINDIVData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 617,
        sequenceNumber: 2,
        sectionName: 'AFTER INDIV PRINT AD',
        sectionGroup: ''
      },
    });
    (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterINDIVDataIndex = (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!afterINDIVData) {
      this.addAfterINDIVAdsPromoSet(afterINDIVDataIndex);
    } else {
      afterINDIVData.promoSet.forEach(phone => {
        this.addAfterINDIVAdsPromoSet(afterINDIVDataIndex, phone);
      });
    }

  }
  addAfterINDIVAdsPromoSet(afterINDIVDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[afterINDIVDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  AddRowAfterINDIVAds() {
    const afterGroupDataIndex = (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterINDIVpAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addAfterINDIVAdsPromoSet(addIndex);
      });
  }
  putAfterINDIVadsGroup(putAfterINDIVadsGroup: any) {
    (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterINDIVadsGroup.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterINDIVpAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterINDIVadsGroup);
    //console.log(this.formAfterINDIVpAds.value);
  }
  addAfterGroupAds(afterGroupData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [afterGroupData ? afterGroupData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [afterGroupData ? afterGroupData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 618,
        sequenceNumber: 3,
        sectionName: 'AFTER GROUP PRINT AD',
        sectionGroup: ''
      },
    });
    (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!afterGroupData) {
      this.addAfterGroupAdsPromoSet(afterGroupDataIndex);
    } else {
      afterGroupData.promoSet.forEach(promoData => {
        this.addAfterGroupAdsPromoSet(afterGroupDataIndex, promoData);
      });
    }

  }
  addAfterGroupAdsPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[afterGroupDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  AddRowAfterGroupAds() {
    const afterGroupDataIndex = (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterGroupAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addAfterGroupAdsPromoSet(addIndex);
      });
  }
  putAfterGroupAds(putAfterGroupAds: any) {
    (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterGroupAds.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterGroupAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterGroupAds);
    //console.log(this.formAfterGroupAds.value);
  }

  addAfterPromoAds(afterPromoData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [afterPromoData ? afterPromoData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [afterPromoData ? afterPromoData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 619,
        sequenceNumber: 4,
        sectionName: 'AFTER PROMO',
        sectionGroup: ''
      },
    });
    (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!afterPromoData) {
      this.addAfterPromoAdsPromoSet(afterGroupDataIndex);
    } else {
      afterPromoData.promoSet.forEach(promoData => {
        this.addAfterPromoAdsPromoSet(afterGroupDataIndex, promoData);
      });
    }

  }
  addAfterPromoAdsPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[afterGroupDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  AddRowAfterPromoAds() {
    const afterGroupDataIndex = (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formAfterPromoAds.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addAfterPromoAdsPromoSet(addIndex);
      });
  }

  putAfterPromoAds(putAfterPromoAds: any) {
    (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putAfterPromoAds.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formAfterPromoAds.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putAfterPromoAds);
    //console.log(this.formAfterPromoAds.value);
  }
  addTag(tagData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [tagData ? tagData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [tagData ? tagData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 620,
        sequenceNumber: 5,
        sectionName: 'TAG LINE(s)',
        sectionGroup: ''
      },
    });
    (this.formTag.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formTag.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!tagData) {
      this.addTagPromoSet(afterGroupDataIndex);
    } else {
      tagData.promoSet.forEach(promoData => {
        this.addTagPromoSet(afterGroupDataIndex, promoData);
      });
    }

  }
  addTagPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[afterGroupDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);

  }
  AddRowTag() {
    const afterGroupDataIndex = (this.formTag.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formTag.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addTagPromoSet(addIndex);
      });
  }
  putaddTag(putaddTag: any) {
    (this.formTag.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putaddTag.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formTag.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putaddTag);
    //console.log(this.formTag.value);
  }

  addShowDesc(showDescData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [showDescData ? showDescData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [showDescData ? showDescData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 621,
        sequenceNumber: 6,
        sectionName: 'SHOW DESCRIPTION',
        sectionGroup: ''
      },
    });
    (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!showDescData) {
      this.addShowDescPromoSet(afterGroupDataIndex);
    } else {
      showDescData.promoSet.forEach((promoData, index) => {

        this.addShowDescPromoSet(afterGroupDataIndex, promoData);


      });
    }

  }
  addShowDescPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[afterGroupDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);
  }
  AddRowShowDesc() {
    const afterGroupDataIndex = (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formShowDesc.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {
        this.addShowDescPromoSet(addIndex);
      });
  }

  putShowDesc(putShowDesc: any) {
    (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putShowDesc.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formShowDesc.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putShowDesc);
    //console.log(this.formShowDesc.value);
  }

  addVideotoShow(videotoshowData?: any) {
    const inputSheetSection = this.fb.group({
      //  attributeCheck: 'Y',
      //  description: 'After INDIV Print Ads Desc',
      //  sequence: 2,

      inputSheetId: [videotoshowData ? videotoshowData.inputsheetId : ''],
      // showTitle: [afterINDIVData ? afterINDIVData.showTitle : ''],
      printInfoId: [videotoshowData ? videotoshowData.printInfoId : ''],
      // noOfPrints: [afterINDIVData ? afterINDIVData.noOfPrints : ''],

      descriptionList: this.fb.array([]),
      sheetSectionMaster: {
        inputSheetSectionMasterId: 622,
        sequenceNumber: 7,
        sectionName: 'VIDEO TO SHOW',
        sectionGroup: ''
      },
    });
    (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).push(inputSheetSection);
    const afterGroupDataIndex = (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length - 1;
    if (!videotoshowData) {
      this.addVideoShowPromoSet(afterGroupDataIndex);
    } else {
      videotoshowData.promoSet.forEach((promoData, index) => {

        this.addVideoShowPromoSet(afterGroupDataIndex, promoData);


      });
    }

  }
  addVideoShowPromoSet(afterGroupDataIndex: number, data?: any) {
    const promoSetFG = this.fb.group({
      inputSheetUserSectionId: [data ? data.inputSheetUserSectionId : ''],
      description: [data ? data : '', [Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/))]],
    });
    // tslint:disable-next-line: max-line-length
    (((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[afterGroupDataIndex] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).push(promoSetFG);
  }

  AddRowVideoToShow() {
    const afterGroupDataIndex = (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length - 1;
    this.formVideotoShow.controls.inputSheetSection.value.forEach(
      (addshowData, addIndex) => {

        this.addVideoShowPromoSet(addIndex);
      });
  }
  putVideotoShow(putVideotoShow: any) {
    (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).controls.forEach((e, index) => {
      const printInfoId = ((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.printInfoId.value;
      (((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls.forEach((promoArray, pindex) => {
        const promoSpotId = ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.value;
        const description = ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.description.value;
        putVideotoShow.InputUserSection.forEach(editData => {
          if (printInfoId === editData.printInfoId && editData.inputSheetUserSectionId === '' || editData.description === description) {
            ((((this.formVideotoShow.controls.inputSheetSection as UntypedFormArray).controls[index] as UntypedFormGroup).controls.descriptionList as UntypedFormArray).controls[pindex] as UntypedFormGroup).controls.inputSheetUserSectionId.patchValue(editData.inputSheetUserSectionId);
          }
        });
      });
    });
    //console.log(putVideotoShow);
    //console.log(this.formVideotoShow.value);
  }




  onSubmit(formValue, sectionType) {
    // this.putAdTitleGroup();
    // return false;
    if (this.adTitleSaveEdit === true) {
      if (formValue.invalid) {
        //console.log('invalid form real')  
      } else {
        this.adTitleSaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSection}/${sectionType}`, 'POST', formValue.value)
          .subscribe(result => {
            //console.log(this.form);
            //console.log(result)
            this.putAdTitleGroup(result);
            this.spinnerMTemplate = false;
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      }
    } else if (this.adTitleSaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      if (formValue.invalid) {
        //console.log('invalid form real')  
      } else {
        this.spinnerMTemplate = true;
        this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSection}/${sectionType}`, 'PUT', formValue.value)
          .subscribe(result => {
            //console.log(this.form);
            //console.log(result)
            this.putAdTitleGroup(result);
            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      }

    }
  }
  disableAddTitleButton(formValue) {
    if (formValue.invalid) {
      this.adTitleButton = true;
      console.log('hsusauysauyau')
    } else {
      this.adTitleButton = false;
    }
  }
  onSection2Submit(formValue) {
    if (this.sec2SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');
        this.sec2SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putAfterINDIVadsGroup(result);
              this.spinnerMTemplate = false;

              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec2SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putAfterINDIVadsGroup(result);

            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }
  onSection3Submit(formValue) {
    if (this.sec3SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');
        this.sec3SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putAfterGroupAds(result);
              this.spinnerMTemplate = false;

              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec3SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putAfterGroupAds(result);

            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }
  onSection4Submit(formValue) {
    if (this.sec4SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');

        this.sec4SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putAfterPromoAds(result);

              this.spinnerMTemplate = false;
              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec4SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putAfterPromoAds(result);
            this.spinnerMTemplate = false;

            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }
  onSection5Submit(formValue) {
    if (this.sec5SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');
        this.sec5SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putaddTag(result);

              this.spinnerMTemplate = false;
              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec5SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putaddTag(result);
            this.spinnerMTemplate = false;

            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }
  onSection6Submit(formValue) {
    if (this.sec6SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');
        this.sec6SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putShowDesc(result);

              this.spinnerMTemplate = false;
              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec6SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putShowDesc(result);
            this.spinnerMTemplate = false;

            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }
  onSection7Submit(formValue) {
    if (this.sec7SaveEdit === true) {
      if (formValue.invalid) {
        //console.log('form invalid');
      } else {
        //console.log('form valid');
        this.sec7SaveEdit = false;
        this.spinnerMTemplate = true;
        this.commonService.postRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetSave}/${this.appConstants.inputSheetPrintPromoSection}`, 'POST', formValue.value)
          .subscribe(
            result => {
              //console.log(result);

              this.putVideotoShow(result);
              this.spinnerMTemplate = false;

              this.toastorService.success('Saved Successfully');
            }, err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      }
    }
    else if (this.sec7SaveEdit === false) {
      //console.log(formValue);
      //console.log(this.adTitleSaveEdit, ' Edit ');
      this.spinnerMTemplate = true;
      this.commonService.putRequest(`
      ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetUpdate}/${this.appConstants.inputSheetPrintPromoSection}`, 'PUT', formValue.value)
        .subscribe(
          result => {
            //console.log(result);

            this.putVideotoShow(result);
            this.spinnerMTemplate = false;

            this.toastorService.success('Updated Successfully');
          }, err => {
            this.toastorService.warn('Oops!!! The Server was unable to complete your request');
          });
    }



  }

  myMethod() {
    //console.log('!!!!', this.printDec.printAddCreateForm.controls.inputSheetPrintInfo);

    //  //console.log( '!@@@@@',this.printDec.printAddCreateForm.controls.inputSheetPrintInfo.map(t => t.noofprintads).reduce((acc, value) => acc + value, 0));

    //console.log(event);

    //  this.printDec.printAddCreateForm.get('noOfPrints').valueChanges.subscribe(value => {
    //   //console.log(value);
    // });

  }

  getTotalAds() {
    // this.TotalAds = this.TotalAds + parseInt(evt.target.value);
    this.TotalAds = 0;
    const inputSheetPrintInfoArray = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    let indexVal: any = 0;
    inputSheetPrintInfoArray.value.map(
      t => {
        if (t.noOfPrints != 'undefined' && t.noOfPrints != '') {
          this.TotalAds = this.TotalAds + parseInt(t.noOfPrints);
        }
        indexVal = parseInt(indexVal + 1)
        t.showTitleSequenceNumber = indexVal;
      }
    )
  }

  printGenerate() {
    //console.log('generate');
    //console.log(this.promoData.promoAddCreateForm.value);
    if (this.printDec.printAddCreateForm.invalid) {
      return false;
    }
    else {
      this.spinnerMTemplate = true;
      this.printDec.printAddCreateForm.get('testDate').setValue(this.datePipe.transform(this.pilotDate.value, 'yyyy/MM/dd'));
      this.printDec.printAddCreateForm.get('vendor').setValue(this.selectedVendor);
      // console.log(this.printDec.printAddCreateForm.value);
      if (this.printNotSaved === true) {
        //console.log('POST')
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'POST', this.printDec.printAddCreateForm.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            result => {
              //console.log(result);
              this.printNotSaved = false;
              this.printDec.printAddCreateForm.controls.inputSheetId.setValue(result);
              this.inputSheetId = result;
              this.getSection1(result);

              this.spinnerMTemplate = false;
              this.showSelected = true;

              this.toastorService.success('Generated Successfully');
            },
            err => {
              this.toastorService.warn('Oops!!! The Server was unable to complete your request');
            });
      } else {
        //console.log('PUT')
        // status == 'Draft' ? 
        // this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Draft' :
        // this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Submitted';
        if (this.printDec.printAddCreateForm.invalid) {
          console.log('invalid');

        } else {
          this.spinnerMTemplate = true;

          this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (result: any) => {
                this.printNotSaved = false;
                //console.log(result.inputSheetId);


                while ((this.form.get('adTitle') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.form.get('adTitle') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }

                while ((this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }

                while ((this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }

                while ((this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }
                while ((this.formTag.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formTag.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }
                while ((this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }
                while ((this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length !== 0) {
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                  (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).removeAt(0);
                  //console.log( (this.formSpot.get('adTitle') as FormArray));
                }

                // if(status == 'Draft') {
                //   this.getSection1(result.inputSheetId);
                //   this.spinnerMTemplate = false;
                //   this.toastorService.success('Updated Successfully');
                // } else {
                //   this.toastorService.success('Submitted Successfully');
                //   this.router.navigate(['../print'], { relativeTo: this.route });
                // }

                this.getSection1(result.inputSheetId);
                this.spinnerMTemplate = false;
                this.toastorService.success('Updated Successfully');
              },
              err => {
                this.toastorService.warn('Oops!!! The Server was unable to complete your request');
              });
        }
      }
    }

  }

  //Confirmation PopUP

  //  openconfirmModal(type: any, id: any): void {
  //   const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'edit') ? 'cancel the changes' : (type=='Submit')?'submit? All the unsaved changes will be lost':'' } });
  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult === 'edit') {
  //       this.router.navigate(['../pilot'], { relativeTo: this.route });
  //     }
  //     if(dialogResult==='Submit'){
  //       this.createNewPrint(type);
  //     }
  //     // else {
  //     //   //console.log(this.clearMessage)
  //     // }
  //   });
  // }

  createNewPrint(status) {
    //console.log('PUT')
    status == 'Draft' ?
      this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Draft' :
      this.printDec.printAddCreateForm.value.inputSheetStatus.inputSheetStatus = 'Submitted';
    console.log('submit button value', this.printDec.printAddCreateForm.value);

    this.spinnerMTemplate = true;
    this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.spinnerMTemplate = false;
          this.toastorService.success('Print Ad Submitted Successfully');
          this.router.navigate(['../print'], { relativeTo: this.route });
        },
        err => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        });

  }


  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { panelClass: 'confirmModal', disableClose: true, data: { msg: (type === 'cancel') ? 'cancel the changes' : (type == 'Submit') ? 'submit? All the unsaved changes will be lost' : '' } });
    dialogRef.afterClosed().subscribe(dialogResult => {
      //console.log(dialogResult);
      if (dialogResult === 'edit') {
        this.router.navigate(['../print'], { relativeTo: this.route });
      }
      if (dialogResult === 'Submit') {
        this.createNewPrint(type);
      }
    });
  }


  // printGenerate() {

  //   //console.log(this.printDec.printAddCreateForm.value);
  //   if (this.printDec.printAddCreateForm.invalid) {
  //     return false;
  //   } else if (this.printNotSaved) {
  //     this.printNotSaved = false;
  //     this.commonService.postRequest(`
  //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'POST', this.printDec.printAddCreateForm.value)
  //       .subscribe(
  //         result => {
  //           //console.log(result);
  //           // this.updateGenerate=result;
  //           this.printDec.printAddCreateForm.controls.inputSheetId.setValue(result);
  //           this.getSection1(result);
  //           this.toastorService.success('Generated Successfully');
  //           // this.spinnerMTemplate = false;
  //           // this.showSelected = true;
  //         },
  //         err => {
  //           this.toastorService.warn('Oops!!! The Server was unable to complete your request');
  //         });

  //   }
  //   else {
  //     this.commonService.putRequest(`
  //     ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}`, 'PUT', this.printDec.printAddCreateForm.value)
  //       .subscribe(
  //         result => {
  //         //  this.printDec.printAddCreateForm.controls.inputSheetId.setValue(result);
  //           this.getSection1(result);
  //           this.toastorService.success('Updated Successfully');
  //         },
  //         err => {
  //           this.toastorService.warn('Oops!!! The Server was unable to complete your request');
  //         });

  //   }

  // }
  onSelectedVendor(e){
    console.log(e)
    this.selectedVendor=e.value;
    console.log(this.selectedVendor)
    
  }
  onSelectedGenre(e, event, index) {

    console.log(event.source._mostRecentViewValue);

    if (e != event.source._mostRecentViewValue) {

      this.genere(e, index);
    }
    else {
      return false;
    }
  }
  genere(e, index) {
    this.spinnerMTemplate = true;
    let selectedGenre = e;
    console.log('before', selectedGenre);
    this.commonService.getRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/showtitles/?genretype=${selectedGenre}`,
      'GET')
      .subscribe(data => {
        console.log('after', selectedGenre);
        this.showTitleList[index] = data;
        this.spinnerMTemplate = false;
      }, (err) => {
        this.toastorService.warn('Oops!!! The Server was unable to complete your request');
      }
      );
  }

  getSection1(inputSheetId) {

    //const localURL = 'assets/json/test_printAds_section1.json';
    const datasection1ColumnsList: any[] = [];
    this.datasection1HeaderList = [];
    this.dataset = [];
    // const options = inputSheetId ?
    //   { params: new HttpParams().set('inputSheetId', inputSheetId) } : {};

    const options = inputSheetId ? { 'inputSheetId': inputSheetId } : {};

    const inputSheetPrintInfoArray = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    // while ((this.form.get('adTitle') as FormArray).length !== 0) {
    //   (this.form.get('adTitle') as FormArray).removeAt(0);
    // }

    this.commonService.getApiRequest(`
    ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetprintTitles}`,
      'GET', options)
      //  this.commonService.getRequest(`${localURL}`, 'GET')
      .subscribe(
        datasection => {
          this.sectionData = datasection;

          this.sectionData.map((item, index) => {

            this.datasection1HeaderList.push({
              printInfoId: item.printInfoId,
              showTitle: item.primeShowTitle.showTitle,
              noOfPrints: item.noOfPrints,
              inputsheetId: item.inputsheetId,
              promoSet: []
            });
            this.dataset.push({
              printInfoId: item.printInfoId,
              showTitle: item.primeShowTitle.showTitle,
              noOfPrints: item.noOfPrints,
              inputsheetId: item.inputsheetId,
              promoSet: []
            });
            //console.log(inputSheetPrintInfoArray.controls[index].get('printInfoId'))
            inputSheetPrintInfoArray.controls[index].get('printInfoId').setValue(item.printInfoId);
            //console.log(inputSheetPrintInfoArray.controls[index].get('printInfoId'))


            for (let i = 0; i < item.noOfPrints; i++) {

              this.datasection1HeaderList[index].promoSet.push('');
            }

            this.dataset[index].promoSet.push('');
          });
          const maxValueOfPrints = Math.max(...datasection.map(o => o.noOfPrints), 0);
          this.columnsToDisplay = this.displayedColumns.slice();

          this.displayedColumns = this.datasection1HeaderList.map(c => c.showTitle);

          const result = Object.assign({}, this.printDec.section1Form);
          this.section1Generate(this.datasection1HeaderList);
          this.sections(this.dataset);
        },
        (err) => {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        }
      );
  }

  printaddRow() {
    this.showTitleEmpty = false;
    let rows = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    rows.push(this.createItem());
    this.printDec.printaddData.push([
      { genreId: null, showTitleID: '', episode: '', noofprintads: '' }
    ]);
    this.dataSource = new MatTableDataSource(this.printDec.printaddData)
  }
  removeRow(index) {
    //console.log(index);
    let inputSheetPrintInfo = this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray;
    // console.log(this.inputSheetId);
    console.log(inputSheetPrintInfo);
    // console.log(inputSheetPrintInfo.value[index]);
    if (inputSheetPrintInfo.value[index].printInfoId != null) {
      this.spinnerMTemplate = true;
      const printInfoId = inputSheetPrintInfo.value[index].printInfoId;
      //  const options = printInfoId ?
      //  { params: new HttpParams().set('InputSheetPrintInfoId', printInfoId) } : {};
      // tslint:disable-next-line: object-literal-key-quotes
      const options = printInfoId ? { 'InputSheetPrintInfoId': printInfoId } : {};

      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/header`,
        'DELETE', options)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          // this.getTable();
          this.spinnerMTemplate = false;
          (this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).removeAt(index);
          // this.loadSection1(this.inputSheetId);
          while ((this.form.get('adTitle') as UntypedFormArray).length !== 0) {
            // console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.form.get('adTitle') as UntypedFormArray).removeAt(0);
            // console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            // console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            // console.log( (this.formSpot.get('adTitle') as FormArray));
          }

          while ((this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }

          while ((this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formTag.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formTag.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formShowDesc.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          while ((this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).length !== 0) {
            //console.log( (this.formSpot.get('adTitle') as FormArray));
            (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).removeAt(0);
            //console.log( (this.formSpot.get('adTitle') as FormArray));
          }
          this.getSection1(this.inputSheetId);
          this.getTotalAds();
        },
          error => {
            this.spinnerMTemplate = false;
            this.toastorService.warn('Error' + error.message);
          },
          () => {

            this.toastorService.success('Show Title Deleted Successfully!');
          }
        );
    } else {
      (this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).removeAt(index);
    }
    let printDatasec: any;
    printDatasec = this.printDec.printaddData.filter((item, i) => i != index);
    this.printDec.printaddData = printDatasec;
    this.dataSource = new MatTableDataSource(this.printDec.printaddData);
    this.getTotalAds();
    if ((this.printDec.printAddCreateForm.get('inputSheetPrintInfo') as UntypedFormArray).length == 0) {
      this.showTitleEmpty = true;
    }
    //console.log(this.printDec.printaddData);
    //console.log(inputSheetPrintInfo.value);
  }

  updateVal(ele, event) {
    // console.log(ele);
    ele.setValue(event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"'));
    // console.log(ele.value);
  }

  toggleAll(selection) {
    if (selection) {

      this.Accordion.multi = true;
      this.Accordion.openAll();
      this.toggle = false;
    } else {

      this.Accordion.closeAll();
      this.Accordion.multi = false;
      this.toggle = true;
    }

  }

  clear(cellValue) {
    //console.log(cellValue.controls.promoSpotPrintTitle);
    cellValue.controls.promoSpotPrintTitle.reset();
    if (this.form.invalid) {
      console.log('hhhsdauahdsau');
      this.adTitleButton = true;

    } else {
      this.adTitleButton = false;
    }

  }

  clearInput(cellValue) {
    //console.log(cellValue);
    cellValue.controls.description.reset();
  }
  /** Section 8 - Add, Delete and Save method **/

  onAddsrawareRow() {
    this.onAddsec8 = true;
    let newRow = { rowid: null, possraware: (this.srAwarenessList.length + 1), srawareness: null, upDateFlag: true };
    const row = this.fb.group({
      srawareness: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section8Form.get('section8Array') as UntypedFormArray).push(row);
    this.srAwarenessList.push(newRow);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);
    //console.log(this.srAwarenessList);
    this.dataSource8._updateChangeSubscription();
  }

  deletesraware(elm, index) {
    //console.log(elm);
    //console.log(this.srAwarenessList);
    this.srAwarenessList.splice(index, 1);
    (this.section8Form.get('section8Array') as UntypedFormArray).removeAt(index);
    this.dataSource8 = new MatTableDataSource(this.srAwarenessList);

    if (elm.rowid != null || '') {
      // const delSrAware = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delSrAware = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delSrAware)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          // this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        })
    }
  }

  updatesrAware(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.srawareness = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  srawareSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm8 = true;
      return false;
    } else {
      this.spinnerMTemplate = true;
      //console.log(this.srAwarenessList);
      //console.log(this.srAwarenessList)
      let srawareSave = [];
      //console.log(this.srAwarenessList);

      srawareSave = this.srAwarenessList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.srawareness,
          sequence: item.possraware,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 623,
            sequenceNumber: 8,
            sectionName: 'SR AWARENESS',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }
      });
      if (!this.srawarenessSave) {
        //console.log(srawareSave);
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', srawareSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.srawarenessSave = true;
            this.loadSrAware(result);
            //   this.getSrAwareness();
            this.spinnerMTemplate = false;
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      } else {
        this.spinnerMTemplate = true;
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', srawareSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.loadSrAware(result);
            //   this.getSrAwareness();
            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      }
    }
  }

  loadSrAware(srAwarenesssec) {
    if (srAwarenesssec == null) {
      this.onAddsrawareRow();
    } else {
      let data = [];
      (this.section8Form.controls.section8Array as UntypedFormArray).clear();
      srAwarenesssec = srAwarenesssec.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          srawareness: mapdata.description,
          possraware: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          srawareness: new UntypedFormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section8Form.get('section8Array') as UntypedFormArray).push(ArrayData);
        //console.log(this.section8Form.value);
      })

      this.srAwarenessList = data;
      //console.log(this.srAwarenessList);
      this.dataSource8 = new MatTableDataSource<InputSheetPrintSrAwarenessSection>(this.srAwarenessList)
    }
  }

  getSrAwareness() {
    let srAwarenesssec = [];
    let data = [];
    // const getSrAware = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'SR AWARENESS')
    //     .append('sectiontype', 'PRINT')
    //   }
    const getSrAware = { 'inputSheetId': this.inputSheetId, 'sectionName': 'SR AWARENESS', 'sectiontype': 'PRINT' };

    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getSrAware)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        srAwarenesssec = chardata;
        srAwarenesssec = srAwarenesssec.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            sraware: mapdata.description,
            possraware: mapdata.sequence
          })
        })

        this.srAwarenessList = data;
        //console.log(this.srAwarenessList);
        this.dataSource8 = new MatTableDataSource<InputSheetPrintSrAwarenessSection>(this.srAwarenessList);

      }, error => {
        this.toastorService.warn(error.message);
      })
  }


  /** Section 9 - Add, Delete and Save method **/

  onAddQuesRow() {
    this.onAddsec9 = true;
    let newRow = { rowid: null, posques: (this.questionstimList.length + 1), quesnstim: null, upDateFlag: true };
    const row = this.fb.group({
      quesnstim: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
        validateEmptyField
      ])
    });
    (this.section9Form.get('section9Array') as UntypedFormArray).push(row);
    this.questionstimList.push(newRow);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);
    //console.log(this.questionstimList);
    this.dataSource9._updateChangeSubscription();
  }

  deleteQues(elm, index) {
    //console.log(elm);
    //console.log(this.questionstimList);
    this.questionstimList.splice(index, 1);
    (this.section9Form.get('section9Array') as UntypedFormArray).removeAt(index);
    this.dataSource9 = new MatTableDataSource(this.questionstimList);

    if (elm.rowid != null || '') {
      // const delQues = {
      //   params: new HttpParams()
      //     .set('inputSheetuserSectionId', elm.rowid)
      // }
      const delQues = { 'inputSheetuserSectionId': elm.rowid };
      this.commonService.deleteRequest(
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/section`,
        'DELETE', delQues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          //console.log(result);
          //  this.getQuestionStim();
          this.toastorService.success('Deleted Successfully');
        }, error => {
          this.toastorService.warn(error.message);
        })
    }
  }

  updateQues(ele, event) {
    //console.log(ele)
    //console.log(event.target.value)
    ele.quesnstim = event.target.value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    ele.upDateFlag = true;
  }

  quesSave(formValue) {
    if (formValue.invalid) {
      //console.log('invalid');
      this.submittedForm9 = true;
      return false;
    } else {
      this.spinnerMTemplate = true;
      //console.log(this.questionstimList);
      //console.log(this.questionstimList)
      let quessSave = [];
      //console.log(this.questionstimList);

      quessSave = this.questionstimList.map(item => {
        return {
          inputSheetUserSectionId: item.rowid,
          description: item.quesnstim,
          sequence: item.posques,
          sheetSectionMaster: {
            inputSheetSectionMasterId: 624,
            sequenceNumber: 9,
            sectionName: 'QUESTION AND STIM CHANGES',
            sectionGroup: ""
          },
          sheetQuestion: null,
          inputSheet: {
            inputSheetId: this.inputSheetId
          },
          upDateFlag: item.upDateFlag
        }

      })
      if (!this.quessSave) {
        //console.log(quessSave);
        this.commonService.postRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/promosection`, 'POST', quessSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.quessSave = true;
            this.loadQuesStim(result);
            //    this.getQuestionStim();
            this.spinnerMTemplate = false;
            this.toastorService.success('Saved Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      } else {
        this.spinnerMTemplate = true;
        this.commonService.putRequest(`
        ${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/update/PromoSection`, 'PUT', quessSave)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            //console.log(result);
            this.loadQuesStim(result);
            //  this.getQuestionStim();
            this.spinnerMTemplate = false;
            this.toastorService.success('Updated Successfully');
          }, error => {
            this.toastorService.warn(error.message);
          });
      }
    }
  }

  loadQuesStim(questionstim) {
    if (questionstim == null) {
      this.onAddQuesRow();
    } else {
      let data = [];
      (this.section9Form.controls.section9Array as UntypedFormArray).clear();
      questionstim = questionstim.map(mapdata => {
        data.push({
          rowid: mapdata.inputSheetUserSectionId,
          quesnstim: mapdata.description,
          posques: mapdata.sequence,
          upDateFlag: mapdata.upDateFlag
        });
        const ArrayData = this.fb.group({
          quesnstim: new UntypedFormControl(mapdata.description, [
            Validators.required,
            Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"’”‘“?+-=]{1,255})$/)),
            validateEmptyField
          ])
        });
        (this.section9Form.get('section9Array') as UntypedFormArray).push(ArrayData);
        //console.log(this.section9Form.value);
      })

      this.questionstimList = data;
      //console.log(this.questionstimList);
      this.dataSource9 = new MatTableDataSource<InputsheetPrintQuesSection>(this.questionstimList);
    }
  }

  getQuestionStim() {
    let questionstim = [];
    let data = [];
    // const getQuesStim = {
    //   params: new HttpParams()
    //     .set('inputSheetId', this.inputSheetId)
    //     .append('sectionName', 'QUESTION AND STIM CHANGES')
    //     .append('sectiontype', 'PRINT')
    //   } 
    const getQuesStim = { 'inputSheetId': this.inputSheetId, 'sectionName': 'QUESTION AND STIM CHANGES', 'sectiontype': 'PRINT' };

    this.commonService.getApiRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/sections`,
      'GET', getQuesStim)
      .pipe(takeUntil(this.destroy$))
      .subscribe(chardata => {
        //console.log(chardata);
        questionstim = chardata;
        questionstim = questionstim.map(mapdata => {
          data.push({
            rowid: mapdata.inputSheetUserSectionId,
            quesnstim: mapdata.description,
            posques: mapdata.sequence
          })
        })

        this.questionstimList = data;
        //console.log(this.questionstimList);
        this.dataSource9 = new MatTableDataSource<InputsheetPrintQuesSection>(this.questionstimList);

      }, error => {
        this.toastorService.warn(error.message);
      })
  }
  public hasErrorsection8Form = (index, errorName: string) => {
    return ((this.section8Form.controls.section8Array as UntypedFormArray).controls[index] as UntypedFormGroup).controls.srawareness.hasError(errorName);
  }
  public hasErrorsection9Form = (index, errorName: string) => {
    return ((this.section9Form.controls.section9Array as UntypedFormArray).controls[index] as UntypedFormGroup).controls.quesnstim.hasError(errorName);
  }
  videoControls() {
    return (this.formVideotoShow.get('inputSheetSection') as UntypedFormArray).controls;
  }
  showDecriptionControl() {
    return (this.formShowDesc.get('inputSheetSection') as UntypedFormArray).controls;
  }
  tagLineControls() {
    return (this.formTag.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterpromoAdsControls() {
    return (this.formAfterPromoAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterGroupAdsControl() {
    return (this.formAfterGroupAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  afterINIDIVpAdsControls() {
    return (this.formAfterINDIVpAds.get('inputSheetSection') as UntypedFormArray).controls;
  }
  adtitleControls() {
    return (this.form.get('adTitle') as UntypedFormArray).controls;
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.destroy$.next(true);
  }


}
