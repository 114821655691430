import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { List } from '../models/list.model';
// import {  } from 'events';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  appConstants = AppConfig.settings;
  public apiUrl = this.appConstants.serverUrl;
  httpOptions = {
    headers: new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
  };

  constructor(
    private http: HttpClient,
    private appconfig: AppConfig) {
  }

  dashboardValue = new Subject();
  searchData = new Subject();
  gridData = [];
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse instanceof ErrorEvent) {
      console.error('Client side error', errorResponse);
    } else {
      console.error('Server side error', errorResponse);
    }
    return throwError(errorResponse);
  }

  insert(url: string, inputData: any): Observable<any> {
    return this.http.post(`${this.appConstants.serverUrl}/${url}`, inputData)
      .pipe(
        (map(res => res),
          (catchError(this.handleError))
        ));
  }
  // insertc(url, inputData) : Observable<any> {

  //    return this.http.post(`${url}`, inputData)
  //     .pipe(
  //       (map(res => res),
  //         (catchError(this.handleError))
  //       ));
  // }

  edit(url, id: any): Observable<any> {
    return this.http.get(`${this.appConstants.serverUrl}/${url}`, id)
      .pipe(
        (map(res => res),
          (catchError(this.handleError))
        ));
  }

  update(url, inputData: any): Observable<any> {
    return this.http.put(`${this.appConstants.serverUrl}/${url}`, inputData)
      .pipe(
        (map(res => res),
          (catchError(this.handleError))
        ));
  }

  delete(url, inputData: any): Observable<any> {
    return this.http.delete(`${url}`, inputData)
      .pipe(
        (map(res => res),
          (catchError(this.handleError))
        ));
  }

  exportExcel(url, filename, filteredData) {
    // //console.log('this', `${this.appConstants.serverUrl}/${url}`); // responseType: 'arraybuffer',headers:headers
    return this.http.post(`${this.appConstants.serverUrl}/${url}`, filteredData, { observe: 'response', responseType: 'blob' })
      .pipe(
        (map(res => {
          //console.log('data', res);
          const data = {
            fileContent: new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            filename: filename // res.headers.get('filename')
          };
          return data;
        })),
        catchError(this.handleError)
      );
  }


  getCategoryList(url): Observable<any> {
    // //console.log('apiUrl', this.apiUrl);
    // const url = `${this.apiUrl}/categories`;
    //  const url = `${this.apiUrl}/categories?page=1`;
    return this.http.get<List[]>(url, this.httpOptions).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }
  public getFilter() {
    return this.gridData;
  }

  public setFilter(filter) {
    this.gridData = filter;

  }

}
