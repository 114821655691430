<div class="confirm-modal templateSaveAs">
   <form [formGroup]="templateSaveName"  class="example-form"> 
        <mat-form-field class="example-full-width float-left" floatLabel=never>
            <label class="font-13 required">Save Template as</label>
            <input matInput class="form-control" placeholder="Template Name" formControlName="name"> 
            <mat-error *ngIf="hasError('name', 'required')">Please enter Template Name
                </mat-error>
                <mat-error *ngIf="hasError('name', 'pattern')">Please enter valid Template Name</mat-error>
                
        </mat-form-field>
    
   <div class="saveAsAction">
        <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="onConfirm()" >Save</button>
</div>
</form> 
</div>