import { Subscription, Subject } from 'rxjs';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateSharedChoiceComponent } from '../components/create-shared-choice/create-shared-choice.component';
import { SharedQuestionCreateComponent } from '../components/shared-question-create/shared-question-create.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { ConfirmModalComponent } from '../components/shared-question-edit-view/confirm-modal';
import { CancelModalComponent } from './confirm-modal';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-shared-list',
  templateUrl: './shared-list.component.html',
  styleUrls: ['./shared-list.component.scss']
})

export class SharedListComponent implements OnInit,AfterViewInit,OnDestroy {
  appConstants = AppConfig.settings;
  dashboardTitle: string;
  sharedQuestionCreate: string;
  sharedChoiceCreate: string;
  sharedEdit: string;
  dialogRef: any;
  template: any[];
  isEdit: any;
  isSave: any;
  isEditButton: boolean;
  subscription:Subscription;
  hideEdit:boolean;
  isCancel:any;
  tooltip: boolean;
  isAdmin:boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private appconfig: AppConfig,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public sharedlistservice: SharedlistService,
    private authManager: AuthManager,
    private commonService: CommonService
  ) {
    // debugger;
    this.tooltip=true;
    this.sharedlistservice.tooltip
    .subscribe(data => {
      if (data === true) {
        this.tooltip = true;

      } else {
        this.tooltip = false;
      }
    });
    
   // this.isEdit=true;
    this.isEditButton = true;
    this.dashboardTitle = this.appConstants.SharedListTitle;
    this.sharedQuestionCreate = this.appConstants.SharedQuestionCreate;
    this.sharedChoiceCreate = this.appConstants.SharedChoiceCreate;
    this.sharedEdit = this.appConstants.SharedEdit;
    //this.hideEdit=true;
  }
  ngOnInit() { 

    this.hideEdit=true;
    //console.log('12')
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  //   console.log('Inside SharedList');
  this.subscription=  this.sharedService.editEvent$.subscribe((event)=>{
      this.isEdit=event;
    })
  }
  openCreateSharedQuestion() {
    this.dialogRef = this.dialog.open(SharedQuestionCreateComponent, { panelClass: 'newQuestion', disableClose: true });
    // this.dialogRef.afterClosed()
  }
  openCreateSharedChoice() {
    this.dialogRef = this.dialog.open((CreateSharedChoiceComponent), { panelClass: 'newQuestion', disableClose: true });
    // let CreatequestionComponent = { hasBackdrop: false, skipHide: true, panelClass: 'cssthemeInfo', width: '300px', height: '400px' };
    this.dialogRef.afterClosed();
  }
  // Confirmation PopUP
  openconfirmModal(type: any, id: any): void {
    const dialogRef = this.dialog.open(CancelModalComponent, { panelClass: 'confirmModal', disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        if (type === 'edit') {
          this.isEdit = false;
          this.isCancel=true;
          const editCancelData: any = { isEdit: this.isEdit ,isCancel:this.isCancel};
          this.sharedService.announceEvent(editCancelData);
          //this.sharedService.emitEvent(true);
        }
      }
    });
  }
  public displayTemplate(event): void {
    //console.log('shared list layout : Click side panel testt', event);
    this.template = event;
    event.type == 'sharedVariables' ? this.hideEdit = true : this.hideEdit=false;
  }
  public onEdit(): void {
    this.isEdit = true;
    this.isEditButton = true;
    const editClickedData: any = { isEdit: this.isEdit };
    //console.log('shared list layout Component onEdit Event');
    this.sharedService.announceEvent(editClickedData);
  }
  sharedListQuestionSave() {
    //console.log('Shared List Question Save');
    this.isEdit = false;
    this.isSave = true;
    const editClickedData: any = { isSave: this.isSave, isEdit: this.isEdit };
    this.sharedService.announceEvent(editClickedData);
    // this.sharedlistservice.sendMessage('Message from Home Component to App Component!');
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
    
    
  }
}
