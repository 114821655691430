<div class="container primeNewModal createSharedQuestion">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="createSharedQuesForm" autocomplete="off" class="example-form" novalidate
            (ngSubmit)="createQuestion()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required">{{ label.sharedListName }}</label>
                    <input matInput placeholder="Enter {{label.sharedListName}}" (blur)="trimFunction($event)"
                        class="form-control" formControlName="sharedListName">
                    <mat-error *ngIf="hasError('sharedListName', 'required')">
                        {{validationMsg}} {{ label.sharedListName }}
                    </mat-error>
                    <mat-error *ngIf="hasError('sharedListName', 'pattern')">Please enter valid Question name
                    </mat-error>
                    <mat-error *ngIf="hasError('sharedListName', 'notUnique')">Already exists in our database
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required">{{ label.sharedListDescription }}</label>
                    <textarea matInput placeholder="Enter Question Description" class="form-control"
                        formControlName="sharedListDescription" (blur)="trimFunction($event)" #sharedListDescription
                        maxlength="255"> </textarea>
                    <mat-error *ngIf="hasError('sharedListDescription', 'required')">{{validationMsg}}
                        {{ label.sharedListDescription }}
                    </mat-error>
                    <mat-error *ngIf="hasError('sharedListDescription', 'pattern')">Please enter valid Question
                        Description
                    </mat-error>
                    <!-- 
                    <mat-error *ngIf="hasError('sharedListDescription', 'pattern')">
                        {{validationMsg}} valid {{ label.sharedListDescription }}
                    </mat-error> -->
                </mat-form-field>

                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13" [ngClass]="{required: isRequired === true}">
                        {{ label.sharedListQuestions }}</label><i class="bottomArrow"></i>
                    <mat-select class="form-control" (selectionChange)="onChangeQuesCat($event)"
                        placeholder="Select Question Category" formControlName="questionCategory">
                        <mat-select-filter [placeholder]="'Search Question Category'"
                            [displayMember]="'questionCategoryDesc'" [array]="questionCategoryList"
                            (filteredReturn)="filteredList=$event"></mat-select-filter>
                        <mat-option *ngFor="let category of filteredList" [value]="category.questionCategoryId">
                            {{category.questionCategoryDesc }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('questionCategory', 'required') && isRequired">
                        {{validationMsg + 'Question Category' }}
                    </mat-error>
                </mat-form-field>

                <!-- <span>{{selectedOptions}}</span> -->
                <mat-form-field *ngIf="isCategorySelected" floatLabel=never
                    class="searchTextWrap createQuesselection woLabel selection-placeholder">
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search"
                            [(ngModel)]="searchQuesModel" name="searchQuesModel" [ngModelOptions]="{standalone: true}"
                            [readonly]="isLoading">
                        <i class="searchicon"></i>
                    </div>

                    <mat-selection-list #sharedQuestions class="form-control marginT10 "
                        formControlName="questionLibraryId">
                        <mat-list-option title="{{category.name}}"
                            *ngFor="let category of sharedQuestionList | searchFilter: searchQuesModel;let i=index"
                            [ngClass]="{'createQuesselected': selectedIndex == category.id}"
                            (click)="handleSelection(category)" [selected]="category.selected" [value]="category.id">
                            <span>{{category.name}}</span>
                        </mat-list-option>
                    </mat-selection-list>
                    <!-- <mat-error *ngIf="isRequired && createSharedQuesForm.submitted">{{'Please Select Question list'}} -->

                    <mat-error *ngIf="isRequired">{{'Please Select Question list'}}
                    </mat-error>
                </mat-form-field>


                <mat-form-field floatLabel=never class="">
                    <label class="font-13" [ngClass]="{required: isRequired === true}">Question Long Text</label>
                    <input matInput type="text" class="form-control" formControlName="questionLongText"
                        placeholder="Enter Question Long Text">
                    <mat-error *ngIf="hasError('questionLongText', 'required') && isRequired">
                        {{validationMsg + 'Question Long Text'}}</mat-error>
                    <mat-error *ngIf="hasError('questionLongText', 'notUnique')">Please enter valid Question Long text
                    </mat-error>
                </mat-form-field>


                <mat-form-field class="searchTextWrap smallSection choiceList selection-placeholder" floatLabel=never>
                    <label class="font-13 float-left">{{ label.sharedListChoices }}</label>
                    <!-- <span>{{selectedOptions}}</span> -->
                    <div class="autoSelect hastag clearfix clear" floatLabel=never>
                        <span *ngFor="let item of selectedChoices;let i=index">
                            <mat-list-item> {{ item.sharedListName }}
                                <button mat-icon-button (click)="onRemove(item)">
                                    <mat-icon class="mat-24 hastagDelete">x</mat-icon>
                                </button>
                            </mat-list-item>
                        </span>
                    </div>
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search"
                            [(ngModel)]="searchChoiModel" [ngModelOptions]="{standalone: true}">
                        <i class="searchicon align-autosearchIcon"></i>
                    </div>
                    <mat-selection-list #sharedChoices class="marginT10 form-control list"
                        formControlName="choiceSharedListId">
                        <mat-list-option title="{{option.sharedListName}}"
                            *ngFor="let option of sharedChoiceList | searchFilter: searchChoiModel"
                            [selected]="option.selected" (click)="onAreaListControlChanged(sharedChoices,option)"
                            [ngClass]="getSomeClass(option)" [value]="option.choiceSharedListId">
                            <span>{{option.sharedListName}}</span>
                        </mat-list-option>
                    </mat-selection-list>

                </mat-form-field>

                <!-- <div>
                    <mat-selection-list #sharedChoices class="form-control list" formControlName="choiceSharedListId">
                        <mat-list-option *ngFor="let option of sharedChoiceList | searchFilter: searchChoiModel"
                            [selected]="option.selected" (click)="onAreaListControlChanged(sharedChoices,option)"
                            [value]="option.choiceSharedListId">
                            {{option.sharedListName}}
                        </mat-list-option>
                        
                    </mat-selection-list>
                    <mat-error *ngIf="hasError('choiceSharedListId', 'required')">{{'Please Select Choice Shared List'}} </mat-error>    
                </div> -->

                <div class="slider-container">
                    <label class="font-13 required">{{ label.questionType}} </label>
                    <mat-radio-group class="marginT10" formControlName="questionType" fxLayout="column">
                        <mat-radio-button value="Simple">Simple</mat-radio-button>
                        <mat-radio-button value="Grid">Grid</mat-radio-button>
                    </mat-radio-group>

                </div>
            </mat-card-content>
            <div class="spinner" *ngIf="isLoadingSave">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit">Create</button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>