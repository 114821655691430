<div class="spinnerOverlay" *ngIf="spinnerMTemplate"></div>
<div class="spinner" *ngIf="spinnerMTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<!--<div class="col-md-7 contentSection withSideNav sharedList" *ngIf="!spinnerTemplate" id="main"> -->
<div class="col-md-7 contentSection withSideNav sharedList" *ngIf="!spinnerMTemplate" [ngClass]="{'expandPanel': expandClass, 'pointer-event': (!templateId && templateList.length>0)}" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3 templateHeader">
        <form [formGroup]="editTemplateForm" autocomplete="off" class="example-form" novalidate>
            <mat-form-field class="example-full-width templateDescription">
                <!-- -->
                <input matInput formControlName="templateDescription" title="{{this.editTemplateForm.controls.templateDescription.value}}" class="float-left font-20 form-control sharedListName" [readonly]="!isEdit">
                <mat-error *ngIf="hasError('templateDescription', 'required')">Please Enter Template Name</mat-error>
                <mat-error class="marginT20" *ngIf="hasError('templateDescription', 'pattern')">Please enter valid Template Name</mat-error>
                <mat-error class="marginT20" *ngIf="hasError('templateDescription', 'notUnique')">Template Name Already Exists</mat-error>
                <span *ngIf="isEdit" (click)="openconfirmModal('template', templateId.surveyTemplateId)"><i class="deleteIcon alignDelete-textbox"></i></span>
            </mat-form-field>
            <!--   -->

        </form>
        <!-- template dropdown start-->
        <mat-form-field *ngIf="!this.isEdit" [ngClass]="(isEdit)?'pointer-event':''" class="templateRepository" floatLabel=never>
            <mat-label>Template Repository</mat-label>
            <mat-select (selectionChange)="onSelectTemplate(value = { surveyTemplateId:$event.value, id:null, isGrid: null})" class="form-control" [formControl]="templateListControl">
                	<mat-select-filter [placeholder]="'Search Template'" [displayMember]="'templateDescription'" [array]="templateList" (filteredReturn)="filteredList=$event"></mat-select-filter>
                <mat-option *ngFor="let template of filteredList" [value]="template.surveyTemplateId">
                    {{template.templateDescription}}
                </mat-option>
            </mat-select> <i class="bottomArrow"></i>
        </mat-form-field>

        <!-- template dropdown end-->
        <div *ngIf="isAdmin" class="float-right createSection" [ngClass]="(spinnerMTemplate===true)?'pointer-event':''">
            <mat-card-actions class="float-left" *ngIf="this.isEdit">
                <button mat-raised-button color="primary" (click)="sharedListQuestionSave();" id="save">SAVE</button>
                <button mat-raised-button color="warn" (click)="openconfirmModal('edit', '')" type="button">CANCEL</button>
            </mat-card-actions>
            <span *ngIf="!this.isEdit">
                    <button class="float-left font-13 copy" mat-raised-button color="primary" (click)="openSaveAsNameModal()" id="saveAs">Copy</button>   
                    
                <p class="float-left font-13 blue" (click)=" onEdit();this.isEdit=true;" *ngIf="!this.isEdit">
                    <span>Edit
                    </span> <i class="editBlueIcon"></i></p>
            </span>
            <p class="float-left font-13 blue" (click)="openCreateTemplate()" [ngClass]="(this.isEdit)?'pointer-event':''">
                <span>{{templateCreate}}</span>
                <i class="addnewIcon"></i>
            </p>
        </div>
        <!-- 
        <div *ngIf="tooltip" class="float-right createSection">
            <span matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue"> <span>Edit
                    </span> <i class="editBlueIcon"></i></p>
            </span>

            <span matTooltip="Data is loading please wait until it completes">
                <p class="float-left font-13 blue">
                    <span>{{templateCreate}}</span>
            <i class="addnewIcon"></i>
            </p>
            </span>
        </div> -->
    </div>

    <!-- <div *ngIf="!expandClass && (showMoreCount>15)" class="showMoreSection">
        <span>
            <p  class="float-left font-13 blue" (click)="templateSideNav_Expand($event)">
                            <span>+ Show More </span>
        </p>
        </span>
    </div>
    <div *ngIf="expandClass" class="showLessSection" (click)="templateSideNav_Collapse($event)">
        <i class="collapseArrow"></i>
    </div> -->
    <div class="col-lg-12 row">
        <!-- <app-template-sidenav (select)="displayTemplate($event)" (showMore)="showMoreDisplay($event)" [templateInput]="templateId" [ngClass]="(isEdit)?'pointer-event':''">
        </app-template-sidenav> -->
        <app-template-sidenav (showMore)="showMoreDisplay($event)" [templateInput]="templateId" [ngClass]="(isEdit)?'pointer-event':''">
        </app-template-sidenav>
        <app-template-dashboard> </app-template-dashboard>
        <!-- <app-shared-list-dashboard (editEvent)="OpeneditChoiceComponent($event)" [templateInput]="template" [isEditClick]="isEdit" > </app-shared-list-dashboard> -->
    </div>
</div>