import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-pilot-dashboard',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
 data1:any
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any
   ) {     }

  ngOnInit() {
    this.data1=this.data.msg;
  }

  onConfirm(): void{
    this.dialogRef.close('edit');
  }
  onConfirmSubmit(){
    this.dialogRef.close('Submit');
  }
  
}
