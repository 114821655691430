<div class="container primeNewModal createSharedQuestion">
    <span class="modalClose" mat-dialog-close routerLink="./survey/dashboard"><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="createsurveyDec.createSurveyForm" autocomplete="off" class="example-form" novalidate
            (ngSubmit)="createSurvey()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> {{createsurveyDec.inputSheetLabel}}</label>
                    <span *ngIf="!createsurveyDec.createSurveyForm.controls.InputSheetName.value"></span>
                    <mat-select [disableOptionCentering]="true" #inputsheet class="form-control"
                        formControlName="InputSheetName" placeholder="Select Inputsheet">
                        <mat-select-filter *ngIf="inputsheet.focused" [placeholder]="'Filter'"
                            [displayMember]="'inputSheetName'" [showSpinner]="false"
                            [array]="createsurveyDec.dataInputsheet"
                            (filteredReturn)="createsurveyDec.filteredInputsheet =$event"></mat-select-filter>

                        <mat-option *ngFor="let item of createsurveyDec.filteredInputsheet" [value]="item">
                            {{item.inputSheetName}}
                        </mat-option>
                    </mat-select>
                    <i class="bottomArrow"></i>
                    <mat-error *ngIf="hasError('InputSheetName', 'required')">Please select or search the
                        Input Sheet</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> {{createsurveyDec.templateLabel}}</label>

                    <span *ngIf="!createsurveyDec.createSurveyForm.controls.TemplateName.value"></span>

                    <mat-select [disableOptionCentering]="true" #template class="form-control"
                        formControlName="TemplateName" placeholder="Select Template">
                        <mat-select-filter *ngIf="template.focused" [placeholder]="'Filter'"
                            [displayMember]="'templateDescription'" [showSpinner]="false"
                            [array]="createsurveyDec.dataTemplate"
                            (filteredReturn)="createsurveyDec.filteredTemplate =$event"></mat-select-filter>

                        <mat-option *ngFor="let item of createsurveyDec.filteredTemplate" [value]="item">
                            {{item.templateDescription}}
                        </mat-option>
                    </mat-select>
                    <i class="bottomArrow"></i>
                    <mat-error *ngIf="hasError('TemplateName', 'required')">Please select or search the
                        Template</mat-error>
                </mat-form-field>
                  
                <mat-form-field class="example-full-width" floatLabel=never>
                        <label class="font-13 required"> Survey Description</label>
                        <textarea matInput class="form-control"  formControlName="SurveyDesc"  maxlength="100" placeholder="Enter Survey Description"></textarea>
                         <mat-error *ngIf="hasError('SurveyDesc', 'required')">Please Enter Survey Description
                            </mat-error>
                      
                    </mat-form-field>

            </mat-card-content>
            <div class="spinnerOverlay"  *ngIf="createsurveyDec.isLoading"></div>
            <div class="spinner" *ngIf="createsurveyDec.isLoading">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>


            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit">
                    Create </button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close (click)="surveyCreate(true)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>