<div class="spinnerOverlay" *ngIf="spinnerMTemplate"> </div>
<div class="spinner" *ngIf="spinnerMTemplate">
      <div class="bounceone"></div>
      <div class="bouncetwo"></div>
      <div class="bouncethree"></div>
      <div class="bouncefour"></div>
      <div class="bouncefive"></div>
      <div class="bouncesix"></div>
</div>

<div class="col-md-11 contentSection pilotMain" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">Print - <span>{{printDec.editTitle}}</span></h5>
        <mat-card-actions class="float-right">
            <button mat-raised-button color="primary" type="submit" (click)="openconfirmModal('Submit','')" [disabled]="notSaved || disButtons" matTooltip="Please Enter details below and Save">Submit</button>
            <button type="button" mat-raised-button color="warn" (click)="openconfirmModal('cancel','')">Cancel</button>
        </mat-card-actions>
    </div>
    <form [formGroup]="printDec.printAddCreateForm" (ngSubmit)="printGenerate()" *ngIf="dataSource">
        <div class="programmerNotes float-left surveyanalyst" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Analyst</label>
            <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add Analyst"
                formControlName="surveyAnalyst" class="form-control" [readonly]="readonlyFlag"> 
            <mat-error *ngIf="printDec.printAddCreateForm.controls.surveyAnalyst.errors?.pattern">Please Enter Valid Value</mat-error>
        </div>
        <div class="programmerNotes float-left" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Programmer Notes</label>
            <!-- <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add programmer Notes"
                formControlName="programmerNotes" class="form-control"> -->
            <textarea class="form-control" placeholder="Add programmer Notes"
            formControlName="programmerNotes" [readonly]="readonlyFlag"></textarea>
            <mat-error *ngIf="printDec.printAddCreateForm.controls.programmerNotes.errors?.pattern">Please Enter Valid Value</mat-error>
        </div>
        <div class="float-left testDate testDateAlign">
            <label for="testDate" class="font-13">Test Date</label>
            <input matInput [matDatepicker]="picker1" placeholder="Select Date" [formControl]="pilotDate" 
                name="pilotDate" class="form-control" [readonly]="readonlyFlag">
            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="disableSelect"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </div>
        <div class="float-left vendor">
            <label for="testDate" class="font-13">Vendor</label>
            <mat-form-field floatLabel=never>
                <mat-select matNativeControl required  class="form-control" [formControl]="vendor" [disabled]="disableSelect"
                    (selectionChange)="onSelectedVendor($event)" placeholder="Select Vendor">                   
                    <mat-option *ngFor="let vendor of vendorData" [value]="vendor.vendorName"
                        placeholder="Please Select Vendor">
                       {{vendor.vendorName}}
                    </mat-option>
                </mat-select>
                <i class="bottomArrow pilot-selectAlign"></i>
                <!-- <input type="hidden" value="genre.projectTypeId"> -->
                <mat-error>Please select a genre</mat-error>
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 header inputSheet-table-create"
            formArrayName="inputSheetPrintInfo">

            <ng-container matColumnDef="genreDesc">
                <th mat-header-cell *matHeaderCellDef>{{printDec.printAddHeader[0] | uppercase}}</th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                    <div formGroupName="primeShowTitle">
                        <div formGroupName="projectType">
                            <mat-form-field floatLabel=never class="readonly-wrapper">
                                <mat-select matNativeControl formControlName="c2027ProjectTypeId"
                                    class="form-control readonly-block" [disabled]="disableSelect">
                                    <mat-option *ngFor="let genre of genreList" [value]="genre.projectTypeId"
                                         placeholder="Please Select Genre"
                                         (onSelectionChange)="onSelectedGenre(genre.projectType,indexpostion)">
                                        {{ genre.projectType }}
                                    </mat-option>
                                </mat-select>
                                <i class="bottomArrow pilot-selectAlign"></i>
                                <mat-error>Please select a genre</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </td>
                <td class="addInputSheetRows" mat-footer-cell *matFooterCellDef>
                    <button mat-button type="button" (click)="printaddRow()" class="blue" [disabled] ="disButtons"> <span class="addnewIcon"></span> Add New Show Title</button>
                </td>   
            </ng-container>
            <ng-container matColumnDef="showTitle">
                <th mat-header-cell *matHeaderCellDef>{{printDec.printAddHeader[1] | uppercase}}</th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                    <div formGroupName="primeShowTitle">
                        <mat-form-field floatLabel=never class="readonly-wrapper">
                            <mat-select matNativeControl class="form-control readonly-block"
                                formControlName="primeShowTitleId" [disabled]="disableSelect">
                                <mat-option *ngFor="let show of showTitleList[indexpostion]"
                                    [value]="show.primeShowTitleId" placeholder="Please Select Show Title">
                                    {{ show.showTitle }}
                                </mat-option>
                            </mat-select>
                            <i class="bottomArrow pilot-selectAlign"></i>
                            <mat-error>Please select a show title</mat-error>
                        </mat-form-field>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <ng-container matColumnDef="episode">
                <th mat-header-cell *matHeaderCellDef> {{printDec.printAddHeader[2] | uppercase}} </th>
                <td mat-cell *matCellDef="let element;let indexpostion=index;" [formGroupName]="indexpostion">
                    <mat-form-field floatLabel=never>
                        <input matInput type="text" class="font-13 marginT5 float-right"
                            placeholder="Add programmer Notes" formControlName="episodeSerialNumber" OnlyNumber="true" class="form-control" [readonly]="readonlyFlag">
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="noOfPrints">
                <th mat-header-cell *matHeaderCellDef> {{printDec.printAddHeader[3] | uppercase}} </th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">

                    <mat-form-field floatLabel=never>
                        <input matInput type="text" class="font-13 marginT5 float-right"
                            placeholder="Add programmer Notes" formControlName="noOfPrints" class="form-control" (keyup)="getTotalAds()" [readonly]="readonlyFlag">
                    </mat-form-field>
                    <i class="deleteIcon alignDelete-textbox float-right marginT15 marginR10" (click)="removeRow(indexpostion)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </td>
                <td class="generateData" mat-footer-cell *matFooterCellDef>
                    <button class="savePilot float-right" mat-raised-button color="primary"
                        type="submit" [ngClass]="disButtons ? 'disabled-button' : ''" [disabled] ="showTitleEmpty">GENERATE</button>
                    <span class="float-left">Total Number of Ads : {{TotalAds}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="printDec.printAddColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: printDec.printAddColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef=" printDec.printAddColumns;"></tr>
        </table>
    </form>

    <div>
    <mat-accordion #accordion="matAccordion">
        <a class="exp-toggle text-right" (click)="toggleAll(toggle)">{{toggle ? 'Expand All' : 'Collapse All'}} </a>
        <!-- Section 1 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 1 of 9 </span>Ad Title(s) / File Name(s)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="form"  class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="adTitle" class="adTitleWrapper">
                    <div class="inputTitleHeader" *ngFor="let adTitle of adtitleControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="promoSet" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let promoSpotPrintTitle of adTitle.controls.promoSet.controls; let j = index"
                                formGroupName="{{j}}" >
                                <mat-icon (click)="deleteCell(promoSpotPrintTitle,i, j)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <input mat-input class="form-control" formControlName="promoSpotPrintTitle" (keyup)="disableAddTitleButton(form, promoSpotPrintTitle.controls.promoSpotPrintTitle, $event)" [readonly]="readonlyFlag">
                                <mat-error *ngIf="promoSpotPrintTitle.controls.promoSpotPrintTitle.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="container">
                    <!-- <button type="submit"> <i class="saveIcon"></i></button> -->
                    <i class="saveIcon" (click)="onSubmit(form,'addTitle')" [ngClass]="adTitleButton? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Section 2 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 2 of 9 </span>After INDIV Print Ad, Additional
                    Statement(s)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="formAfterINDIVpAds" class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader"
                        *ngFor="let inputSheetSection of afterINIDIVpAdsControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}

                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowAfterINDIVAds()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formAfterINDIVpAds,'section2')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>
        
        <!-- Section 3 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 3 of 9 </span>After Group Print Ad, Additional
                    Statement(s)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="formAfterGroupAds" class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader"
                        *ngFor="let inputSheetSection of afterGroupAdsControl(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}

                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowAfterGroupAds()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formAfterGroupAds,'section3')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Section 4 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 4 of 9 </span>After Promo Print Ad, Additional
                    Statement(s)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="formAfterPromoAds" class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader"
                        *ngFor="let inputSheetSection of afterpromoAdsControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}

                            </div>
                        </div>

                        <div formArrayName="descriptionList"class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowAfterPromoAds()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formAfterPromoAds,'section4')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>
        <!-- Section 5 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 5 of 9 </span>Tag Line(s)
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="formTag" class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader" *ngFor="let inputSheetSection of tagLineControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="descriptionList"class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowTag()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formTag,'section5')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>

        <!-- Section 6 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 6 of 9 </span>Show Description
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="formShowDesc" class="form-horizontal">

                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader" *ngFor="let inputSheetSection of showDecriptionControl(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowShowDesc()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formShowDesc,'section6')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>

            </form>
        </mat-expansion-panel>

        <!-- Section 7 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 7 of 9 </span>Video to Show
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="formVideotoShow" class="form-horizontal">
                <!-- <div class="container">
                        <button type="submit"> <i class="saveIcon"></i></button>
                    </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader"
                        *ngFor="let inputSheetSection of videoControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let description of inputSheetSection.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(description.controls.description,$event)">
                                <mat-icon (click)="deleteSections(description)" [ngClass]="disDelete? 'disabled-button':'' ">close</mat-icon>
                                <mat-error *ngIf="description.controls.description.invalid">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowVideoToShow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <i class="saveIcon" (click)="onSectionSubmit(formVideotoShow,'section7')" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>

        <!-- Section 8 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 8 of 9 </span>Sr Awareness
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                <p>
                    <label>Sr AWARENESS:</label>
                    <span>If not included, enter “Not Asked” in the space to the right. If included, enter each program’s name below & to the right.</span>
                </p>     
            </div>
            <form [formGroup]="section8Form">
            <table mat-table [dataSource]="dataSource8" class="mat-elevation-z8" formArrayName="section8Array">

                <!-- Position Sr Awareness Definition -->
                <ng-container matColumnDef="possraware">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                    </td>
                </ng-container>

                <!-- Sr Awareness Definition -->
                <ng-container matColumnDef="srawareness">
                    <th mat-header-cell *matHeaderCellDef> Sr Awareness </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                        <input class="form-control" matInput placeholder="Add Sr Awareness"
                            [value]="element.srawareness" formControlName="srawareness"
                            (keyup)="updatesrAware(element,$event)" [readonly]="readonlyFlag">
                            <mat-error *ngIf="hasErrorsection8Form(in,'pattern')  && submittedForm8">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection8Form(in,'required')  && submittedForm8">Please enter
                                required
                                value</mat-error>
                    </td>
                </ng-container>

                <ng-container matColumnDef="columndelete">
                    <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                        <i class="float-right deleteIcon alignDelete-textbox" (click)="deletesraware(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    </td>
                </ng-container>


                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns8"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns8"></tr>
            </table>
            <i class="addnewIcon float-left" (click)="onAddsrawareRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
            <i class="saveIcon" (click)="srawareSave(section8Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 9 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 9 of 9 </span>Question and stim changes
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                <p>
                    <label>NBC ANALYST NOTE:</label>
                    <span>For each question or Stim to be deleted, type in its label and the first five words of that question or stim.</span>
                </p>
                <p>
                    <label>PROGRAMMER'S NOTE:</label>
                    <span>Completely remove the following question(s) &/or stim(s) from questionnaire.</span>
                </p>      
            </div>
            <form [formGroup]="section9Form">
            <table mat-table [dataSource]="dataSource9" class="mat-elevation-z8" formArrayName="section9Array">

                <!-- Position Statement Definition -->
                <ng-container matColumnDef="posques">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                    </td>
                </ng-container>

                <!-- Question and Stim Changes Definition -->
                <ng-container matColumnDef="quesnstim">
                    <th mat-header-cell *matHeaderCellDef> Question and Stim Changes </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                        <input class="form-control" matInput placeholder="Add Question and Stim Changes"
                            [value]="element.quesnstim" formControlName="quesnstim"
                            (keyup)="updateQues(element,$event)" [readonly]="readonlyFlag">
                            <mat-error *ngIf="hasErrorsection9Form(in,'pattern') && submittedForm9">Please enter
                                valid value</mat-error>
                            <mat-error *ngIf="hasErrorsection9Form(in,'required') && submittedForm9">Please enter
                                required value</mat-error>
                    </td>
                </ng-container>

                <ng-container matColumnDef="columndelete">
                    <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                        <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteQues(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    </td>
                </ng-container>

                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns9"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns9"></tr>
            </table>
            <i class="addnewIcon float-left" (click)="onAddQuesRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
            <i class="saveIcon" (click)="quesSave(section9Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

    </mat-accordion>
</div>
</div>