<div class="spinnerOverlay" *ngIf="spinnerTemplate"> </div>
<div class="spinner" *ngIf="spinnerTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>

<div #div class="container primeNewModal sharedQues-QuesView">

    <form *ngIf="!spinnerTemplate" [formGroup]="editTempSingleQuesForm" autocomplete="off" class="example-form" invalidate (ngSubmit)="editSharedQuestion()">
        <mat-card>
            <mat-card-title>
                <mat-card-content>
                    <mat-form-field class="example-full-width">
                        <input type="hidden" class="form-control sharedListName" formControlName="questionSharedListId">
                        <input matInput type="text" title="{{this.editTempSingleQuesForm.controls.sharedListName.value}}" class="form-control sharedListName" formControlName="sharedListName" [readonly]="!isEdit ">
                        <span (click)="openconfirmModal('Shared List', childInput.id)"><i class="deleteIcon alignDelete-textbox marginT15" ></i></span>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'required')">Please Enter Shared Question Name

                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'pattern')">Please enter valid Question name
                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'notUnique')">Choice Name Already Exists
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width sharedreadonlyDescription">
                        <input matInput type="text" title="{{this.editTempSingleQuesForm.controls.sharedListDescription.value}}" class="form-control sharedListDescription" formControlName="sharedListDescription" [readonly]="!isEdit ">
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'required')">Please Enter the Long Text

                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'pattern')">Please Enter the Long Text
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card-title>
        </mat-card>
        <div class="sharedQuestionTable simpleQues">
            <div *ngIf="dataSource.length==0 && isEdit===true">
                <span class="font-13 float-left addQuestion blue" (click)="openAddChoice()"><i class="addnewIcon"></i>
                    <span>Add Choice</span> </span>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="dataSource.length>0" #table cdkDropList [cdkDropListData]="dataSource" (cdkDropListDropped)="dropTable($event)" [cdkDropListDisabled]="!isEdit">
                <ng-container [cdkColumnDef]="column.columnDef" *ngFor="let column of columns; let i=index;" formArrayName="punchCodes">
                    <th mat-header-cell *matHeaderCellDef> {{column.header }} </th>
                    <td mat-cell *matCellDef="let element; let x=index">
                        <div *ngIf="column.columnDef== 'action'">
                            <mat-checkbox #ref (click)="$event.stopPropagation()" (change)="$event ? selectRow($event, element) : null">
                            </mat-checkbox>
                        </div>
                        <div *ngIf="column.columnDef == 'name'"> {{element['choiceLibrary'].choiceLibraryDesc}}</div>
                        <div *ngIf="column.columnDef== 'punchCode'" [formGroupName]="x">
                            <input type="text" matInput class="form-control punchCodeList editable" formControlName="punchCode" value="{{element['punchCode']}}" [readonly]="!isEdit" OnlyNumber="true">
                            <input type="hidden" class="form-control punchCodeList readonly" formControlName="sharedListChoiceId" value="{{element['sharedListChoiceId']}}">
                            <input type="hidden" class="form-control punchCodeList readonly" formControlName="choiceLibraryId">
                            <input type="hidden" class="form-control punchCodeList readonly" formControlName="choiceSequenceNumber">
                        </div>
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="column.punchCol" *ngFor="let column of columns">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="(column.columnDef === 'name'); then content"></div>
                        <div *ngIf="(column.columnDef === 'action'); then other_content"></div>
                        <ng-template #content>
                            <span class="font-13 float-left addQuestion blue" (click)="openAddChoice()"><i class="addnewIcon"></i>
                    <span>Add Choice</span> </span>
                        </ng-template>
                        <ng-template #other_content>
                            <button *ngIf="this.deleteButton" (click)="openconfirmModal('choice','') ">
                    <i class="deleteIcon"></i></button>

                            <button *ngIf="!this.deleteButton">
                    <i class="deleteIcon"></i></button>


                        </ng-template>
                    </th>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayPunchCode; sticky : true;" class="table-edit-section"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky : true;" class="table-header-section"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay; " cdkDrag [cdkDragData]="row"></tr>
            </table>

        </div>
    </form>
</div>