import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';
import { QuestiondashboardDatatable } from '../models/questiondashboard-datatable';
import { HttpErrorHandlerService } from '../shared/services/httperrorhandler.service';
import { HandleError } from '../shared/services/httperrorhandler.service';
import { SurveyTable } from '../models/survey.model';
@Injectable({
  providedIn: 'root'
})
export class QuestiondashboardDatatableService {
  // public handleError: HandleError;
  protected appConstants = AppConfig.settings;
  clearDate = new EventEmitter();
  
  httpOptions = {
    headers: new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
  };
  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService,
    private appconfig: AppConfig) {
    // this.handleError = httpErrorHandler.createHandleError('QuestionDashboardService');
  }
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse instanceof ErrorEvent) {
      console.error('Client side error', errorResponse);

    } else {
      console.error('Server side error', errorResponse);

    }
    console.error('error', errorResponse);
    return throwError(errorResponse);
  }
  getQuestionDashboard(apiURL: any): Observable<QuestiondashboardDatatable[]> {
    return this.http.get<QuestiondashboardDatatable[]>(apiURL, this.httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }
  getSurveyTable(apiSurveyURL: any): Observable<SurveyTable[]> {
    return this.http.get<SurveyTable[]>(apiSurveyURL, this.httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }
  getQuestionedit(apiURL: any, options) {
    return this.http.get(apiURL, options)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

}
