import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Renderer2, ElementRef, Directive, AfterViewInit, AfterViewChecked, ChangeDetectorRef, ViewChildren, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { SharedQuestionModel } from '../../../../models/sharedquestion.model'
import { AppConfig } from 'src/app/app.config';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AddQuestionComponent } from '../add-question/add-question.component';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { ConfirmModalComponent } from './confirm-modal'
import { HttpParams } from '@angular/common/http';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-shared-question-edit-view',
  templateUrl: './shared-question-edit-view.component.html',
  styleUrls: ['./shared-question-edit-view.component.scss'],

})
export class SharedQuestionEditViewComponent implements OnInit,AfterViewInit,AfterViewChecked,OnDestroy {

  editSharedQuesForm: UntypedFormGroup;
  punchCodes: UntypedFormArray;
  QuestionFormData: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() childInput;
  @Input() childEdit;
  appConstants = AppConfig.settings;
  public globalResponse: any;
  public questionDetails: SharedQuestionModel;
  columnsToDisplayPunchCode: any = [];
  // spinnerChoice: boolean;
  dialogRef: any;
  displayedColumns: string[];
  columnToDisplay: string[];
  columnsToDisplay: string[];
  columns: any[];
  isEdit: boolean = false;
  subscription: any;
  checkServerURL: any;
  SERVER_URL: any;
  checkedArr: any = [];
  getQuestionSharedListByIdURL: any;
  deleteChoiceURL: any;
  deleteQuestionURL: any;
  deleteSharedListURL: any;
  reDirectConfirm: any;
  sharedlistquestionupdate: any;
  data: any[];
  deleteButton: boolean;
  choicesharedListId: any;
  sideNavReload: any;
  spinnerSharedList: boolean = false;
  public validationMsg = AppConfig.settings.validationMsg;



  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('div') viewEdit: ElementRef;
  @ViewChild('table') table: MatTable<any>;
  @ViewChildren('uncheckRef') uncheckRef;
  validationCheck: boolean;

  constructor(
    public dialog: MatDialog,
    private sharedlistService: SharedlistService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private toastorService: NotificationService,
    private awsCommonService: AwsCommonService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.checkedArr = [];
    this.deleteButton = false;
    //  this.spinnerChoice=true;
    this.sharedlistService.tooltip.emit(true);
    this.checkServerURL = this.appConstants.checkServerURL;
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}`;
      this.deleteChoiceURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListChoiceURL}`;
      this.deleteQuestionURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListQuestionURL}`;
      this.deleteSharedListURL = `${this.SERVER_URL}/${this.appConstants.deleteSharedListURL}`;
      this.sharedlistquestionupdate = `${this.appConstants.sharedQuestionCreateParam}`;

    } else {
      this.getQuestionSharedListByIdURL = this.appConstants.getQuestionSharedListByIdLocalURL;
    }

  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {

    this.editSharedQuesForm = this.formBuilder.group({
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      sharedListDescription: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      id: new UntypedFormControl(''),
      punchCodes: this.formBuilder.array([]),
      questionIdList: this.formBuilder.array([])
    });

    //console.log('this.editSharedQuesForm', this.editSharedQuesForm.value);
    //, [Validators.required,   Validators.pattern(new RegExp(/^[0-9]$/))]
    // this.editSharedQuesForm = this.formBuilder.group({
    //   punchCodeList: this.formBuilder.array([])
    // });
    // this.editSharedQuesForm.setControl('punchCodeList');
    // this.getQuestionSharedListById(this.childInput);
  }


  ngOnChanges(): void {
   // this.spinnerChoice = true;
    this.sharedlistService.tooltip.emit(true);
    //console.log(this.childInput);
    this.getQuestionSharedListById(this.childInput);
  }

  /*
//Create Item
createItem(): FormGroup {
  return this.formBuilder.group({
    id: new FormControl(''),
    punchCode: new FormControl(''),
  });
}*/

  // convenience getters for easy access to form fields
  // get t() { return this.editSharedQuesForm.controls.punchCodes as FormArray; }


  // ADD QUESTION 
  openAddQuestion(): void {
    this.dialogRef = this.dialog.open((AddQuestionComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { sharedListId: this.childInput.id }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //console.log('after closed');
      data == 'success' ? this.spinnerSharedList = true : this.spinnerSharedList = false;

      //console.log(this.spinnerChoice);
      //console.log(this.isEdit);
      // this.getTable();
      // if (data == 'success') {
      //   this.sharedService.emitEvent(false);
      // }
      // if (data == 'success') {
      //   debugger;
      //   this.getQuestionSharedListById(this.childInput);
      // }

      if (data == 'success') {
        this.sharedService.emitEvent(true);
      //  debugger;
        this.getQuestionSharedListById(this.childInput);
      }
    });
    //this.spinnerChoice=true;
    // this.getTable();
    //  this.spinnerChoice=true;
    //  this.getQuestionSharedListById(this.childInput);
  }

  //ADD CHOICE 
  openAddChoice(): void {
    this.dialogRef = this.dialog.open((AddChoiceComponent), {
      panelClass: 'newQuestion', disableClose: true, data: { sharedListId: this.childInput.id, choicesharedListId: this.choicesharedListId }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data === 'success') {
        //console.log('success question');
        //      this.getTable();
        this.sharedService.emitEvent(true);
      //  this.spinnerChoice = tfrue;
        this.getQuestionSharedListById(this.childInput);
      } else {
        //console.log('cancel question');
      //  this.spinnerChoice = false;
      }

      // this.spinnerChoice=true;
      //this.getTable();


    });
  }




  //Confirmation Popup
  openconfirmModal(type, id): void {

    //console.log('OpenConfirm : ---', this.checkedArr.length, 'type :', type);
    if (type === 'questions' && this.checkedArr.length === 0) {

      //console.log('----------------openconfirm-------------');

    } else {
      const dialogRef = this.dialog.open((ConfirmModalComponent), { data: type, panelClass: 'confirmModal', disableClose: true });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === true) {
          this.isEdit = false;
          if (type === 'sharedList') {
            this.sharedService.emitEvent(false);
            //console.log('Shared list Question Gird : ', type);
            this.spinnerSharedList = true;
            this.deleteSharedList();
          } else if (type === 'questions') {
            this.sharedService.emitEvent(false);
            this.spinnerSharedList = true;
            //console.log('Shared list Question Gird : ', type);
            this.deleteQuestion();
          } else if (type === 'choice') {
            this.sharedService.emitEvent(false);
            this.spinnerSharedList = true;
            //console.log('Shared list Question Gird : ', type);
            this.deleteChoice(id);
          }
        }
      });
    }
  }

  //View and Edit data
  getQuestionSharedListById(childInput): void {
    this.spinnerSharedList = true;
    if (this.checkServerURL) {
      this.getQuestionSharedListByIdURL = `${this.SERVER_URL}/${this.appConstants.getQuestionSharedListByIdParam}/${childInput.id}`;
    }
    // this.sharedlistService.getQuestionSharedListById(this.getQuestionSharedListByIdURL)
    this.awsCommonService.getRequest(this.getQuestionSharedListByIdURL,'GET').pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        //console.log('test level 1 QuestionSharedList data : ', data),
          //console.log('QuestionSharedList Choice Details data : ', data.choices),
          this.QuestionFormData = data;
          this.spinnerSharedList = false;
          this.sharedlistService.tooltip.emit(false);
        if(data){
          this.QuestionFormData = data;
        this.choicesharedListId = data.choicesharedListId,
          this.editSharedQuesForm.patchValue({
            id: data.id,
            sharedListName: data.name,
            sharedListDescription: data.description
          });

        // this.editSharedQuesForm.patchValue(this.questionDetails);
        // alert('this.isEdit' + this.isEdit);
        if(!this.isEdit){
          this.viewEdit.nativeElement.classList.remove('editOnly');
        }
        if (data.questions && data.choices) {
          this.refreshTable(data.questions, data.choices);
        }
      }
      },
        error => {
          //console.log('error', error.message);

        }
      );
  }

  getTable() {
    // this.sharedlistService.getQuestionSharedListById(this.getQuestionSharedListByIdURL)
    this.awsCommonService.getRequest(this.getQuestionSharedListByIdURL,'GET').pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.refreshTable(data.questions, data.choices);
      },
        error => {
          //console.log('error', error.message);
        });
  }

  get questionIdList(){
    return this.editSharedQuesForm.get('questionIdList') as UntypedFormArray
  }

  refreshTable(questions, choices) {
    let displayedElementWithRows: any[] = [];
    let parentColumn = [
      { 'columnDef': 'action', 'header': 'Action', 'punchCode': '', 'punchCol': 'actions', 'choiceId': '' },
      { 'columnDef': 'name', 'header': 'Questions', 'punchCode': '', 'punchCol': 'questionss', 'choiceId': '' }
    ];
    const punchList = this.editSharedQuesForm.controls.punchCodes as UntypedFormArray;
    punchList.clear();
    //console.log('PunchList', punchList);
    if (choices.length > 0) {
      displayedElementWithRows = choices.map(
        item => {
          punchList.push(this.formBuilder.group({
            id: [item.id, Validators.required],
            punchCode: [item.punchCode, Validators.required],
            sequenceNumber: [item.sequenceNumber]
         
           
          }));
          return {
            'columnDef': 'choice' + item.id,
            'header': item.name,
            'punchCode': item.punchCode,
            'punchCol': 'choices' + item.id,
            'choiceId': item.id
          }
        }
      );
      // Below code need to delete 
    }

    this.columns = [...parentColumn, ...displayedElementWithRows];

    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    this.columnsToDisplayPunchCode = this.columns.map(c => c.punchCol);
    this.data = questions;
    //this.isEdit = true;
  }

  selectRow($event, dataSource) {
    if ($event.checked) {
      this.checkedArr.push(dataSource.id);
      //console.log('push checked', this.checkedArr)
      if (this.checkedArr) {
        this.deleteButton = false;
      } else {
        this.deleteButton = true;
      }
    } else {
      let i = this.checkedArr.indexOf(dataSource.id);
      this.checkedArr.splice(i, 1);
      //console.log('pop  deselect', this.checkedArr)
      if (this.checkedArr) {
        this.deleteButton = true;
      } else {
        this.deleteButton = false;
      }
    }
  }
  addColumn() {
    let i = this.columnsToDisplay.length;
    let addColumn: any[] = [{ 'columnDef': 'addNewColumn' + i, 'header': 'New Choice Column' }];
    this.columns = [...this.columns, ...addColumn];
    this.columnsToDisplay = this.columns.map(c => c.columnDef);
  }

  onEdit() {
    this.isEdit = true;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.subscription = this.sharedService.eventAnnounced$.subscribe(data => {
      this.isEdit = data.isEdit;
      // console.log('ngAfterViewInit  Multigrid', data);
      if (data.isCancel === true) {
        this.editSharedQuesForm.patchValue({
          id: this.QuestionFormData.id,
          sharedListName: this.QuestionFormData.name,
          sharedListDescription: this.QuestionFormData.description
        });
        this.checkedArr = [];
        const len = this.uncheckRef.length;
        const arr=this.uncheckRef._results;
        for(let i=0;i<len;i++){
          // console.log('this.uncheckRef._results', arr[i]._checked);
          arr[i]._checked=false;          
        }
        // console.log(this.uncheckRef._checked);      
        this.uncheckRef._checked = false;
       
      }
      if (this.isEdit === true) {
        
        this.deleteButton = false;

        this.viewEdit.nativeElement.classList.add('editOnly');
        this.sharedService.emitEvent(true);
      } else if (data.isSave === true && this.editSharedQuesForm.valid) {
        this.sharedListGridQuestionSave();
        this.viewEdit.nativeElement.classList.remove('editOnly');
      } else if (data.isSave === true && this.editSharedQuesForm.invalid) {
        // this.editSingleQuesForm.patchValue(this.choiceFormData);
        this.sharedListGridQuestionSave()
        this.viewEdit.nativeElement.classList.add('editOnly');

      } else {
        this.viewEdit.nativeElement.classList.remove('editOnly');
      }

    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  dropTable(event: CdkDragDrop<any[]>) {
    //console.log(event);
    this.editSharedQuesForm.setControl('questionIdList',new UntypedFormArray([]))
   // console.log(' SINGLE', this.editSharedQuesForm.value);
    
    const prevIndex = this.data.findIndex((d) => d === event.item.data);
  // console.log(event.item);
  // console.log(event.container);
  if (event.previousContainer === event.container) {
    moveItemInArray(event.container.data, prevIndex, event.currentIndex);
  }
    
//  console.log(this.data.length);
 const len=this.data.length;
 for(let i=0;i<len;i++){
   this.data[i].sequenceNumber =i
 }
//  console.log(this.data);
//  console.log(this.QuestionFormData);

  this.refreshTable(this.data,this.QuestionFormData.choices)
   this.table.renderRows();
   //this.editSharedQuesForm.controls
  //  this.editSharedQuesForm.patchValue({
  //   questionLibraryIds :this.data
  //  })
 // const control = <FormArray>this.editSharedQuesForm.get('questionIdList');
  this.data.forEach((x)=>{
    this.questionIdList.push(new UntypedFormControl(x));
  })
  

    // console.log('drop table ');
    // console.log(' SINGLE', this.editSharedQuesForm.value);
  }
 
  sharedListGridQuestionSave() {
    this.validationCheck = true;
    if (this.editSharedQuesForm.invalid) {
      this.sharedService.emitEvent(true)
      //console.log('invalid');
    } else {
      this.checkedArr = [];
      this.isEdit = false;
      this.spinnerSharedList = true;
      // const punchList = this.editSharedQuesForm.controls.punchCodes as FormArray;
      //console.log('this.editSharedQuesForm.value', this.editSharedQuesForm.value);
      //  this.editSharedQuesForm.controls.punchCodes.removeAt(this.editSharedQuesForm.controls.punchCodes[0]);
      //  punchList.removeAt(punchList[0]);
      // this.commonService.update(this.sharedlistquestionupdate, this.editSharedQuesForm.value)
     
      this.awsCommonService.putRequest(`${this.appConstants.serverUrl}/${this.sharedlistquestionupdate}`, 'PUT', this.editSharedQuesForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.spinnerSharedList = false;
          // console.log(result);

          //  this.sideNavReload = { sideNavReload : true };
          //   this.sharedService.announceEvent(this.sideNavReload);
        },
        error => {
          this.spinnerSharedList = false;
        //  this.isEdit = true;
        //  this.sharedService.emitEvent(true)
          //console.log('invalid');
          //console.log('error', error, error.error.sharedListName);
          if(error.error.sharedListName){
            error.message = error.error.sharedListName;
          }
          this.toastorService.warn(error.message);
          // this.sideNavReload = { sideNavReload: true, questionSharedListId: error.error.questionSharedListId, questionType: 'Grid' };
          // this.sharedService.announceEvent(this.sideNavReload);
          const editCancelData: any = { isEdit: true };
          this.sharedService.announceEvent(editCancelData);
        },
        () => {
          this.toastorService.success('Shared Question Updated Successfully!');
          this.sideNavReload = { sideNavReload: true, method: true };
          this.sharedService.announceEvent(this.sideNavReload);
        });
    }
  }

  // Delete Choice Function
  deleteChoice(choiceId) {
    // let params = { sharedListId: this.childInput.id, choiceId: choiceId  }

    const options = choiceId ?
      { 'choiceId': choiceId } : {};
    // //console.log('Array format ',options);
    // return false;
    // this.commonService.delete(this.deleteChoiceURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteChoiceURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.sharedService.emitEvent(false);
        this.spinnerSharedList = false;
        // this.getTable();
      },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error' + error.message);
        },
        () => {

          this.toastorService.success('Choice Deleted Successfully!');
          this.getQuestionSharedListById(this.childInput);
        }
      );
  }

  deleteQuestion() {
    // let params1 = { questionId: Object.values(this.checkedArr).join(",")  };
    const options = this.checkedArr ?
      {'questionId':this.checkedArr } : {};

    // //console.log('Array format ',options);
    // return false;
    // this.commonService.delete(this.deleteQuestionURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteQuestionURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.spinnerSharedList = false;
          this.sharedService.emitEvent(false);
          this.globalResponse = result;
          // this.getTable();
        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error ' + error.message);
        },
        () => {
          this.checkedArr = []; 
          this.toastorService.success('Question Deleted Successfully!');
          this.getQuestionSharedListById(this.childInput);
        }
      );
  }

  deleteSharedList() {

    // let params = { sharedListId: this.childInput.id   };
    const options = this.childInput.id ?
      {'sharedListId': this.childInput.id } : {};
    // //console.log('Deleteb Shared List ', options);
    // return false;
    // this.commonService.delete(this.deleteSharedListURL, options)
    this.awsCommonService.deleteRequest(`${this.deleteSharedListURL}`, 'DELETE', options, options)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.checkedArr = [];
          this.sharedService.emitEvent(false);
          this.spinnerSharedList = false;
          this.globalResponse = result;
          // this.getTable();
          this.toastorService.success('Shared Question Deleted Successfully!');
          this.sideNavReload = { sideNavReload: true, method: true };
          this.sharedService.announceEvent(this.sideNavReload);
        },
        error => {
          this.spinnerSharedList = false;
          this.toastorService.warn('Error' + error.message);
        }
      );
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.editSharedQuesForm.controls[controlName].hasError(errorName);
  }

}
