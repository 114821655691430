<div class="question-dashboard-container">
  <div class="spinnerOverlay"  *ngIf="spinnerQuestion"></div>
  <div class="spinner" *ngIf="spinnerQuestion">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
  </div>
  <!-- (matSortChange)="sortData($event)" *ngIf="!spinnerQuestion"  matSort (matSortChange)="sortData($event)" matSortDisableClear -->
  <div>
    <cdk-virtual-scroll-viewport class="demo-viewport demo-table-container" itemSize="30">
      <!-- <table mat-vs-table [dataSource]="matTableDataSource" matSort > -->
      <table mat-table [dataSource]="dataTableGrid" matSort *ngIf="!spinnerQuestion" matSortDisableClear
        class="mat-elevation-z8 question-datatable" [multiTemplateDataRows]="true">
        <ng-container matColumnDef='questionCategoryDesc'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.questionCategory | uppercase }} <i
              class="sortIcon"></i> </th>
          <td mat-cell *matCellDef="let element"> {{element.questionCategoryDesc}} </td>
          <td mat-footer-cell *matFooterCellDef> <span *ngIf="dataTableGridTotalCount; else elseBlock">Showing
              {{dataTableGridTotalCount}} Record(s)</span>
            <ng-template #elseBlock>{{dataTableErrorBlock}}</ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="genericText">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.genericQuestion | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element"> <span
              matTooltip="{{element.genericText}}">{{ element.genericText.length > 17 ? element.genericText.substring(0,17)+"..." : element.genericText }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="questionPosNegFlag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.connotation | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element"
            [ngStyle]="{color: (element.questionPosNegFlag=='Y')? '#09B930':(element.questionPosNegFlag=='N')?'#CE0B0B':''}">
            {{(element.questionPosNegFlag=='Y')?"Positive":(element.questionPosNegFlag=='N')?"Negative":(element.questionPosNegFlag=='U')?"Neutral":""}}
          </td>

          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="percentileYn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.percentile | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element">
            {{(element.percentileYn=='Y')?"Yes":(element.percentileYn=='N')?"No":""}} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="summRptYn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{dataTableHeader.summaryReport | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element">{{(element.summRptYn=='Y')?"Yes":"No"}}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="activeYn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.status | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element">
            {{(element.activeYn=='Y')?"Active":(element.activeYn=='N')?"Inactive":"Null"}} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="createdDt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.createDate | uppercase }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element"> {{element.createdDt| date: 'MM/dd/yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdatedDt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dataTableHeader.modifiedDate | uppercase  }} <i
              class="sortIcon"></i></th>
          <td mat-cell *matCellDef="let element"> {{element.lastUpdatedDt| date: 'MM/dd/yyyy' }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="surveylink">
          <th mat-header-cell *matHeaderCellDef>{{ dataTableHeader.surveylink | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
            <!-- isSurveyExists --><span *ngIf="element.isSurveyExists; else elseBlock"><a
                (click)="openDialog(element.questionLibraryId)">
                {{ ViewSurveyData(element.questionLibraryId) }}</a></span>
            <!-- <ng-template #elseBlock>No Mapping</ng-template> -->
            <ng-template #elseBlock></ng-template>

          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>{{ dataTableHeader.action | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
            <i *ngIf="isAdmin" class="editIcon" data-toggle="modal" data-target="#myModal2"
              (click)="startEdit(element)"></i>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <!-- Filter section Start-->
        <ng-container matColumnDef="filter_catrgory">
          <th mat-header-cell *matHeaderCellDef>

            <mat-form-field appearance="outline">
              <mat-select [formControl]="categoryControl" placeholder="Question Category">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let category of categoryList" [value]="category.questionCategoryDesc">
                  {{category.questionCategoryDesc}}
                </mat-option>
              </mat-select>
              <i class="bottomArrow filter-selectAlign"></i>
            </mat-form-field>

          </th>
        </ng-container>
        <ng-container matColumnDef="filter_generic_question">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <input matInput type="text" placeholder="Search Here" [formControl]="genericTextControl"
                autocomplete="off">
              <i class="autocomplete-search"></i>
              <button mat-button *ngIf="questionText" matSuffix mat-icon-button aria-label="Clear"
                (click)="questionText=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_connotation">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-select [formControl]="connotationControl" placeholder="All">
                <mat-option *ngFor="let connot of connotationList" [value]="connot.connotationId">
                  {{connot.connotationDesc}}
                </mat-option>
              </mat-select>
              <i class="bottomArrow filter-selectAlign"></i>
            </mat-form-field>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_percentile">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-select [formControl]="percentileYnControl" placeholder="All">
                <mat-option *ngFor="let percent of percentileList" [value]="percent.percentileId">
                  {{percent.percentileDesc}}
                </mat-option>
              </mat-select>
              <i class="bottomArrow filter-selectAlign"></i>
            </mat-form-field>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_summaryreport">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-select [formControl]="summRptYnControl" placeholder="All">
                <mat-option *ngFor="let summaryre of summaryReportList" [value]="summaryre.summaryReportId">
                  {{summaryre.summaryReportDesc}}</mat-option>
              </mat-select>
              <i class="bottomArrow filter-selectAlign"></i>
            </mat-form-field>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_status">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-select [formControl]="activeYnControl" placeholder="All">
                <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                  {{status.statusDesc}}</mat-option>
              </mat-select>
              <i class="bottomArrow filter-selectAlign"></i>
            </mat-form-field>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_createdate">
          <th mat-header-cell *matHeaderCellDef>
            <span matTooltip="{{createdate}}">
              <app-daterange-picker-component (emitRange)="getDates($event, 'createdDt')">
              </app-daterange-picker-component>
            </span>
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_modifieddate">
          <th mat-header-cell *matHeaderCellDef>
            <span matTooltip="{{lastdate}}">
              <app-daterange-picker-component (emitRange)="getDates($event, 'lastUpdatedDt')">
              </app-daterange-picker-component>
            </span>
            <!-- <mat-form-field appearance="outline">
              <input matInput
                     placeholder="Choose a date"
                     [satDatepicker]="picker2"
                     [formControl]="lastUpdatedDtControl">
              <sat-datepicker #picker2 [rangeMode]="true">
              </sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
          </mat-form-field> -->
          </th>
        </ng-container>
        <ng-container matColumnDef="filter_surveylink">
          <th mat-header-cell *matHeaderCellDef> </th>
        </ng-container>
        <ng-container matColumnDef="filter_action">
          <th mat-header-cell *matHeaderCellDef>
            <!-- <button mat-button color="primary" >Clear</button> -->
            <button class="clearFilter" mat-stroked-button color="primary" (click)="clearQuestionTable()">Clear All
              Filters</button>
          </th>
        </ng-container>
        <!-- Filter Section End -->

        <tr mat-header-row *matHeaderRowDef="dataTableColumns; sticky: true;" class="table-header-section"></tr>
        <tr mat-header-row *matHeaderRowDef="dataTableFillterColumns; sticky: true;" class="table-filter-section"></tr>


        <tr mat-row *matRowDef="let row; columns: dataTableColumns;"></tr>

        <tr mat-footer-row *matFooterRowDef="dataTableColumns;sticky: true" class="table-footer-section"></tr>
      </table>
      <!-- </table> -->
    </cdk-virtual-scroll-viewport>
  </div>
</div>