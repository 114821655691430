import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InputService {
  public apiUrl = environment.serverUrl;
  protected appConstants = AppConfig.settings;
  // public editPilotData: any;
  // public editPrintData: any;
  // public editPromoData: any;

  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
  };
  data = [
    { code: 'one', desc: 'One1', id: 1 },
    { code: 'two', desc: 'Two1', id: 2 },
    { code: 'three', desc: 'Three1', id: 3 }
  ]
  data2 = [
    { 'category': 'contract', 'element': 'element', 'amount': 10, 'shortDesc': 'contract 1' },
    { 'category': 'labor', 'element': 'element', 'amount': 10, 'shortDesc': 'labor' },
    { 'category': 'contract', 'element': 'element', 'amount': 10, 'shortDesc': 'contract 2' },
    { 'category': 'material', 'element': 'element', 'amount': 10, 'shortDesc': 'material' }
  ]

  constructor(private http: HttpClient, private appconfig: AppConfig) { }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse instanceof ErrorEvent) {
      console.error('Client side error', errorResponse);
    } else {
      console.error('Server side error', errorResponse);
    }
    console.error('error', errorResponse);
    return throwError(errorResponse);
  }


  getData() {
    //normally you will have some like
    //return this.httpClient("http://my.url.com/getData")
    return of(this.data2)
  }

}
