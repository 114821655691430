<div class="container primeNewModal createSharedQuestion">
    <span class="modalClose" mat-dialog-close (click)="surveyAddChoiceCancel(false)"><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="choiceDec.summaryAddChoiceForm" autocomplete="off" class="example-form" novalidate
            (ngSubmit)="surveySummaryAddChoice()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Select the Choice</label>
                    
                    <span *ngIf="!choiceDec.summaryAddChoiceForm.controls.choiceLibraryId">Data</span>
                    <mat-select [disableOptionCentering]="true" #select1 class="form-control" formControlName="choiceLibraryId" >
                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                            [displayMember]="'surveychoiceLibraryDesc'" [array]="choiceDec.survetSummaryfetchChoiceList"
                            [showSpinner]="false" (filteredReturn)="choiceDec.survetSummaryFilterChoiceList =$event">
                        </mat-select-filter>
                        <mat-option *ngFor="let item of choiceDec.survetSummaryFilterChoiceList"
                            [value]="item.surveychoiceLibraryId" >
                            {{item.surveychoiceLibraryDesc}}
                        </mat-option>
                    </mat-select>
                    <i class="bottomArrow"></i>
                    <mat-error *ngIf="hasError('choiceLibraryId', 'required')">Please select or search the
                        Choice </mat-error>
                   
                </mat-form-field>

                

            </mat-card-content>
            <div class="spinnerOverlay"  *ngIf="choiceDec.isLoading"></div>
            <div class="spinner" *ngIf="choiceDec.isLoading">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>


            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit">
                    ADD </button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close (click)="surveyAddChoiceCancel(false)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>