

<!-- MAIN CONTAINER START -->
<div class="container-fluid">
  <div class="row">
    <!-- HEADER START -->
    <app-header *ngIf="this.currentUser" ></app-header>
    <!-- HEADER END -->
   <!-- LEFT MENU START -->
   <app-menu *ngIf="this.currentUser "></app-menu>     
   
    
    
    <!-- LEFT MENU END -->

    <!-- TABLE START -->
    <div class="col" id="main" style="padding-top:20px;padding-left: 20px;">
        
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </div>
    <!-- TABLE END -->
  </div>
</div>