<div class="headerBar">
    <a class="nbcuLogo font-18" routerLink='/dashboard'>PRIME Survey Tool</a>
    <!-- SEARCH BOX -->
    <div *ngIf="data" class="input-group searchBar">
        <span class="input-group-append">
            <button class="btn" type="button">
                <i class="searchicon"></i>
            </button>
        </span>
        <input class="form-control font-13" type="search" (keydown.enter)="applyFilter($event)" placeholder="search"
            id="example-search-input">
    </div>
    <div class="profileInfo float-right" [matMenuTriggerFor]="menu"> <span class="userName float-left font-13">{{(firstName)? firstName : '' }}
            {{(lastName) ? lastName : ''}}</span> <i class="profileArrow"></i>
        <mat-menu #menu="matMenu"> <button mat-menu-item (click)='logoutUser()'>Log out</button> </mat-menu>
    </div>
</div>