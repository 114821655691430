<div class="container primeNewModal primeNewModal1 addQuestion-sharedlist templateSelQuestion">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="addSharedQuesForm" autocomplete="off" class="example-form" novalidate (ngSubmit)="addQuestion()">
            <mat-card-content>
                <mat-form-field class="example-full-width marginT20" floatLabel=never>
                    <label class="font-13 required"> Select the Questions</label><i class="bottomArrow"></i>
                    <mat-select class="form-control" formControlName="questionCategory" (selectionChange)="onChangeQuesCat($event)" placeholder="Select Question Category">
                        <mat-option *ngFor="let category of questionCategoryList" [value]="category.questionCategoryId">
                            {{category.questionCategoryDesc}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('questionCategory', 'required')">Please select Question Category
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="isCategorySelected" class="searchTextWrap marginT10 woLabel align-autocomplete" floatLabel=never>
                    <div class="input-searchWrapper">
                        <input matInput type="text" class="form-control" placeholder="Search Questions" [(ngModel)]="searchQuesModel" [ngModelOptions]="{standalone: true}" [readonly]="loadingQuestionList">
                        <i class="searchicon"></i>
                        <!-- <span>{{addSharedQuesForm.searchQuesModel}}</span> -->
                    </div>

                </mat-form-field>


                <div class="list searchTextWrap" *ngIf="isCategorySelected">
                    <mat-selection-list role="list" #sharedQuestions class="marginT10 form-control align-autocomplete addQues-searchQues" formControlName="questionLibraryId">
                        <mat-list-option role="listitem" title="{{category.name}}" *ngFor="let category of sharedQuestionList | searchFilter: searchQuesModel;let i=index" [ngClass]="{'createQuesselected': category.id == sharedQuestionListId}" [selected]="category.selected" [value]="category.id">
                            <a mat-list-item (click)="onSelect(category.id)"><span>{{category.name}}</span></a>
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Question long text </label>
                    <textarea matInput class="form-control" formControlName="questionLongText" placeholder="Enter Question long Text"></textarea>
                    <mat-error *ngIf="hasError('questionLongText', 'required')">Please enter Question Long text
                    </mat-error>
                    <mat-error *ngIf="hasError('questionLongText', 'pattern')">Please enter valid Question Long text
                    </mat-error>
                    <mat-error *ngIf="hasError('questionLongText', 'notUnique')">You are trying to enter a invalid varaible.please enter a valid variable</mat-error>
                </mat-form-field>

            </mat-card-content>
            <div class="spinner" *ngIf="isLoadingSave">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!addSharedQuesForm.valid">ADD</button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close (click)="openLink($event)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>