import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { AppConfig } from '../../../app.config';
import { ChoiceDashboardComponent } from '../components/choice-dashboard/choice-dashboard.component';
import { ChoiceCreateComponent } from '../components/choice-create/choice-create.component';
import { ChoiceEditComponent } from '../components/choice-edit/choice-edit.component';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ChoiceService } from 'src/app/services/choice.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/toPromise';
import { Subject } from 'rxjs';
import { AuthManager } from 'src/app/helpers/auth-manager';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-choice-layout',
  templateUrl: './choice-layout.component.html',
  styleUrls: ['./choice-layout.component.scss']
})
export class ChoiceLayoutComponent implements OnInit {

  appConstants = AppConfig.settings;
  dashboardTitle: string;
  createText: string;
  dialogRef: any;
  checkServerURL: any;
  exportExcelURL: any;
  tooltip: boolean;
  validationMSG: any;
  @ViewChild(ChoiceDashboardComponent, { static: true }) private choicedashboard: ChoiceDashboardComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isAdmin:boolean = true;
  constructor(
    private toastorService: NotificationService,
    private appconfig: AppConfig,
    public dialog: MatDialog,
    private commonService: CommonService,
    private choicedashb: ChoiceDashboardComponent,
    private choiceService: ChoiceService,
    private awsCommonService: AwsCommonService,
    private authManager: AuthManager,
    private excelService: ExcelService
  ) {
    this.choiceService.tooltip
      .subscribe(data => {
        if(data === true) {
          this.tooltip = true;
        } else {
          this.tooltip = false;
        }
    });
    // tslint:disable-next-line: no-debugger
    // debugger;
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.exportExcelURL = `${this.appConstants.choiceDashboardExportExcelParam}`;
      // this.dashboardSurvey_URL = `${this.appConstants.serverUrl}/${this.appConstants.questionDashboardSurveyParam}`;
    }
    this.dashboardTitle = this.appConstants.choiceTitle;
    this.createText = this.appConstants.choiceCreateText;
  }

  ngOnInit() {

    //console.log('12')

    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
  }

  openCreateChoiceComponent(): void {
    this.dialogRef = this.dialog.open(ChoiceCreateComponent, { panelClass: 'newQuestion', disableClose: true });
    this.dialogRef.afterClosed().subscribe(result => {
      if(result === 'success') {
        this.choicedashboard.getDashboard();
      }
    });
  }
  OpeneditChoiceComponent(data: any) {
    this.dialogRef = this.dialog.open(ChoiceEditComponent, { data, panelClass: 'newQuestion', disableClose: true });
    this.dialogRef.afterClosed().subscribe(result => {
      if(result === 'success') {
        this.choicedashboard.getDashboard();
      }
    });
  }
  // getAuthors() {
  //   this.choicedashb.getDashboard();
  // }
  exportExcel() {
    this.toastorService.inProgress('Exporting data please wait...');
    //console.log(this.choicedashboard)
    if (this.checkServerURL) {
      // this.commonService.exportExcel(this.exportExcelURL, 'Prime Survey Choice Library', this.choicedashboard.dataTableSource.filteredData).subscribe(
      //   (res) => {
      //     this.downLoadFile(res);
      //     this.toastorService.success(res.filename + 'Excel generated  successfully');
      //   },
      //   error => {
      //     this.toastorService.warn('Error' + error.message);
      //   }
      // );
      this.excelService.generateExcelChoice(this.choicedashboard.dataTableSource.filteredData)


    } else {
      this.toastorService.warn('Server Not Connected. Please contact to Server Team');
    }

  }
  // downLoadFile(data) {
  //   const element = document.createElement('a');
  //   element.href = URL.createObjectURL(data.fileContent);
  //   element.download = data.filename;
  //   document.body.appendChild(element);
  //   element.click();
  // }
}
