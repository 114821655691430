<div class="spinnerOverlay" *ngIf="spinnerMTemplate"> </div>
<div class="spinner" *ngIf="spinnerMTemplate">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>


<div class="col-md-11 contentSection dashboardSection" id="main">
    
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">Dashboard <span>- Surveys YTD</span></h5>
    </div>
    <div class="dashboardWrapper">
        <div class="dashboardData">
            <i class="refreshIcon" (click)=reset() ></i>
            <div *ngIf="pieChartData">
                <div class="dashboardChart">
                    <canvas baseChart 
                    [data]="pieChartData" 
                    [labels]="pieChartLabels" 
                    [chartType]="pieChartType" 
                    [options]="pieChartOptions" 
                    [colors]="pieChartColors" 
                    [plugins]="pieChartPlugins" 
                    [legend]="false" 
                    (chartHover)="chartHovered($event)" 
                    (chartClick)="chartClicked($event)">
                </canvas>
                </div>
                <div class="dashboardLegend">
                    <p class="font-18">Total Surveys : {{totalNoOfSurveys}}</p>
                    <ul>
                        <li *ngFor="let legendValue of data">
                            <i class="{{legendValue.surveyType|lowercase}}"></i><span><span>{{legendValue.surveyType}}</span> : <span>{{legendValue.noOfsurveys}}</span></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="dashboardTable">
            <div>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="surveyCode">
                        <th mat-header-cell *matHeaderCellDef> Survey Code </th>
                        <td mat-cell *matCellDef="let element"><a class="surveyLink" (click)="surveyEdit(element)"> {{element.surveyCode}} </a></td>
                    </ng-container>
                    <ng-container matColumnDef="showTitle">
                        <th mat-header-cell *matHeaderCellDef> Survey Title </th>
                        <td mat-cell *matCellDef="let element"> {{element.showTitle}} </td>
                    </ng-container>
                    <ng-container matColumnDef="testDate">
                        <th mat-header-cell *matHeaderCellDef> Test Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.testDate | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="surveyType">
                        <th mat-header-cell *matHeaderCellDef> Survey Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.surveyType}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="table-header-section"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>