import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-add-choice',
  templateUrl: './confirm-modal.html'
})
export class ConfirmModalComponent implements OnInit {

  constructor( 
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any
    ) {     }
     

  ngOnInit() {}

  onConfirm(): void{
    this.dialogRef.close(true);
  }
}
