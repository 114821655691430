import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './layout/template.component';
import { TemplateRoutingModule } from './template.routing.module';
import { MaterialModule } from 'src/app/app.material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TemplateSidenavComponent } from './components/template-sidenav/template-sidenav.component';
import { TemplateDashboardComponent } from './components/template-dashboard/template-dashboard.component';
import { TemplateSharedGridQuestionEditViewComponent } from './components/template-shared-grid-question-edit-view/template-shared-grid-question-edit-view.component';
import { TemplateSharedSimpleQuestionEditViewComponent } from './components/template-shared-simple-question-edit-view/template-shared-simple-question-edit-view.component';
import { TemplateCreateComponent } from './components/template-create/template-create.component';
import { TemplateSharedQuestionCreateComponent } from './components/template-shared-question-create/template-shared-question-create.component';
import { ConfirmModalComponent } from './layout/confirm-modal';
import { AddChoiceComponent } from './components/add-choice/add-choice.component';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { TemplateSaveasComponent } from './components/template-saveas/template-saveas.component';
import { MatSelectFilterModule } from 'mat-select-filter';




@NgModule({
  declarations: [
    TemplateComponent,
    TemplateSidenavComponent, 
    TemplateDashboardComponent, 
    TemplateSharedGridQuestionEditViewComponent, 
    TemplateSharedSimpleQuestionEditViewComponent, 
    TemplateCreateComponent, 
    AddChoiceComponent, 
    AddQuestionComponent, 
    TemplateSharedQuestionCreateComponent,
    ConfirmModalComponent,
    TemplateSaveasComponent
  ],
  imports: [
    CommonModule,
    TemplateRoutingModule,
    MaterialModule,
    MatSelectFilterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatInputModule
  ],
  /* entryComponents:[
    TemplateComponent,
    TemplateCreateComponent,
    TemplateSharedQuestionCreateComponent,
    ConfirmModalComponent,
    AddChoiceComponent, 
    AddQuestionComponent, 
    TemplateSaveasComponent
  ], */
})
export class TemplateModule { }
