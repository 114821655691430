import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';

export class ServerErrorDialogModel {

  constructor(public title: string, public message: string, public redirectURL: string) {
  }
}

@Component({
  selector: 'app-server-errordialog',
  template: `
  <div class="confirm-modal">
      <p> {{message}} </p>
      <button type="button" mat-raised-button color="warn"
      class="mat-raised-button mat-button-base mat-warn" (click)="onDismiss()" mat-dialog-close>CANCEL</button>
  </div>
  `,
  styles: []
})
export class ServerErrordialogComponent implements OnInit {
  title: string;
  message: string;
  constructor(
    public dialogRef: MatDialogRef<ServerErrordialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ServerErrorDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);

  }
}

