<div class="container primeNewModal">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="createChoiceForm" autocomplete="off" class="example-form" novalidate
            (ngSubmit)="createChoice()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> {{choiceCreateLabel.choiceLibraryDesc}}</label>
                    <textarea matInput placeholder="Enter Choice Description" class="form-control"
                        formControlName="choiceLibraryDesc" #choiceLibraryDesc>
                    </textarea>
                    <mat-error *ngIf="hasError('choiceLibraryDesc', 'required')">{{validationMsg}}
                        {{ choiceCreateLabel.choiceLibraryDesc }}</mat-error>
                    <mat-error *ngIf="hasError('choiceLibraryDesc', 'pattern')">
                        {{validationMsg}} valid {{ choiceCreateLabel.choiceLibraryDesc }}
                    </mat-error>
                    <mat-error *ngIf="hasError('choiceLibraryDesc', 'notUnique')">Already exists in our database
                    </mat-error>
                </mat-form-field>

                <div class="slider-container posnegToggle">
                    <label class="font-13">{{choiceCreateLabel.posNegFlag}} </label>
                    <mat-radio-group formControlName="posNegFlag" fxLayout="column">
                        <mat-radio-button *ngFor="let op of radioItem" [value]="op.data" name="opList"
                            [ngStyle]="{color: op.color}">{{ op.value}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="hasError('posNegFlag', 'required')">{{validationMsg}}</mat-error>
                </div>

                <div class="slider-container activeYnToggle">
                    <label class="font-13 ">{{choiceCreateLabel.activeYn}}</label>
                    <input type="hidden" class="form-control" formControlName="activeYn">

                    <mat-slide-toggle ngDefaultControl (change)="onChange('activeYn', $event)" checked>
                        <span class="toggleYes">Active</span>
                        <span class="toggleNo">Inactive</span>
                    </mat-slide-toggle>
                </div>

                <!-- <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 ">{{choiceCreateLabel.sharedList}}</label>
                    <input matInput type="text" class="form-control" formControlName="sharedList"
                        [matAutocomplete]="autoChoice" placeholder="Select Choice Shared List">
                    <i class="bottomArrow"></i>
                    <mat-autocomplete #autoChoice="matAutocomplete" [displayWith]="sharedDisplayFn" >
                        <mat-option *ngFor="let choicedata of filteredSharedList" [value]="choicedata" (optionSelected)="onOptionSelected($event)">
                            {{shared.sharedListName}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field> -->
                <mat-form-field class="example-full-width" floatLabel=never>
                        <!-- <label class="font-13">{{label.QuestionSharedDetails}}</label> -->
                        <label class="font-13 ">{{choiceCreateLabel.sharedList}}</label>
                        <input matInput type="text" class="form-control" formControlName="questionSharedList" [matAutocomplete]="autoQuestion" placeholder="Select Choice Shared List">
                        <span *ngIf="!createChoiceForm.controls.questionSharedList.value"><i class="bottomArrow"></i></span>
                        <button class="inputCloseIcon" mat-button *ngIf="createChoiceForm.controls.questionSharedList.value"
                            matSuffix mat-icon-button aria-label="Clear"
                            (click)="createChoiceForm.controls.questionSharedList.setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>
    
                        <mat-autocomplete #autoQuestion="matAutocomplete" [displayWith]="sharedDisplayFn">
                            <!-- <mat-option *ngIf="isLoadingSharedQuestion">
                                <mat-progress-bar mode="buffer" class="spinnercolor"></mat-progress-bar>
                            </mat-option> -->
                            <ng-container *ngIf="!isLoadingSharedQuestion">
                                <mat-option *ngFor="let shared of filteredSharedList|async" [value]="shared">
                                    {{shared.sharedListName}}</mat-option>
                            </ng-container>
    
                            <!-- <ng-container>
                                <mat-option *ngFor="let shared of sharedList" (optionSelected)="onOptionSelected($event)"
                                    [value]="shared">
                                    {{shared.sharedListName}}</mat-option>
                            </ng-container> -->
                        </mat-autocomplete>
                        <!-- <mat-error *ngIf="hasError('questionSharedList', 'required')">Required for Question Shared List </mat-error> -->
                    </mat-form-field>

                <p class="float-left"> <span class="infoIcon font-10">i</span><span class="font-11">To Create New
                        Sharedlist, Please visit to 'Sharedlist' Page </span></p>
            </mat-card-content>
            <div class="spinner" *ngIf="isLoadingSave">
                    <div class="bounceone"></div>
                    <div class="bouncetwo"></div>
                    <div class="bouncethree"></div>
                    <div class="bouncefour"></div>
                    <div class="bouncefive"></div>
                    <div class="bouncesix"></div>
                </div>
            <mat-card-actions align="center">
                <button mat-raised-button color="primary" type="submit">Create</button>
                <button type="button" mat-raised-button color="warn" (click)="close($event)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>