<!-- <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel2">Create Question</h4>
        <button type="button" class="close" id="close" data-dismiss="modal" aria-label="Close" (click)="openLink($event)"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body"> -->

<div class="container primeNewModal">
    <span class="modalClose" mat-dialog-close><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form [formGroup]="createQuesForm" autocomplete="off" novalidate (ngSubmit)="createQuestion()">
            <mat-card-content>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Question Category</label>
                    <!-- <input matInput type="text" formControlName="questionCategoryId"> -->
                    <input matInput type="text" placeholder="Select Question Category" class="form-control"
                        formControlName="questionCategory" [matAutocomplete]="auto">
                    <span *ngIf="!createQuesForm.controls.questionCategory.value"><i class="bottomArrow"></i></span>
                    <button class="inputCloseIcon" mat-button *ngIf="createQuesForm.controls.questionCategory.value"
                        matSuffix mat-icon-button aria-label="Clear"
                        (click)="createQuesForm.controls.questionCategory.setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="categoryDisplayFn">
                        <!-- <mat-option *ngIf="isLoadingCategory">
                            <mat-progress-bar mode="buffer" class="spinnercolor"></mat-progress-bar>
                        </mat-option>
                        <ng-container *ngIf="!isLoadingCategory">
                            <mat-option *ngFor="let category of filteredCategory | async" [value]="category">
                                {{category.questionCategoryDesc}}</mat-option>
                        </ng-container> -->

                        <ng-container>
                            <mat-option *ngFor="let category of filteredCategory | async" [value]="category">
                                {{category.questionCategoryDesc}}</mat-option>
                        </ng-container>


                    </mat-autocomplete>
                    <mat-error *ngIf="hasError('questionCategory', 'required')">Please select category or search the
                        category</mat-error>
                    <mat-error *ngIf="hasError('questionCategory', 'incorrect')">Please enter valid input for category
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13 required"> Generic Question</label>

                    <textarea matInput placeholder="Enter Generic Question" class="form-control"
                        formControlName="genericText" #genericText> </textarea>

                    <mat-error *ngIf="hasError('genericText', 'required')">Please enter a generic question
                    </mat-error>
                    <mat-error *ngIf="hasError('genericText', 'pattern')">Please enter valid input for generic question
                    </mat-error>
                    <mat-error *ngIf="hasError('genericText', 'notUnique')">Already exists in our database
                    </mat-error>
                </mat-form-field>
                <div class="slider-container connotation-container">
                    <label class="font-13 required">{{ label.connotation}} </label>
                    <mat-radio-group formControlName="questionPosNegFlag" fxLayout="column">
                        <mat-radio-button *ngFor="let op of posNegFlag" [value]="op.data" name="opList"
                            [ngStyle]="{color: op.color}">{{ op.value }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="hasError('questionPosNegFlag', 'required')">{{validationMsg}}
                        {{label.connotation}}</mat-error>
                </div>
                <div class="slider-container percentileToggle">
                    <label>{{label.percentile}}</label>
                    <input type="hidden" class="form-control" formControlName="percentileYn">
                    <mat-slide-toggle ngDefaultControl (change)="onChange('percentileYn', $event)" checked>
                        <span class="toggleYes">Yes</span>
                        <span class="toggleNo">No</span></mat-slide-toggle>
                </div>
                <div class="slider-container summaryToggle">
                    <label>{{ label.summaryReport }}</label>
                    <input type="hidden" class="form-control" formControlName="summRptYn">
                    <mat-slide-toggle ngDefaultControl (change)="onChange('summRptYn', $event)" checked>
                        <span class="toggleYes">Yes</span>
                        <span class="toggleNo">No</span></mat-slide-toggle>
                </div>
                <div class="slider-container activeToggle">
                    <label>{{ label.status}}</label>
                    <input type="hidden" formControlName="activeYn">
                    <mat-slide-toggle ngDefaultControl (change)="onChange('activeYn', $event)" checked>
                        <span class="toggleYes">Active</span>
                        <span class="toggleNo">Inactive</span></mat-slide-toggle>
                </div>

                <mat-form-field class="example-full-width" floatLabel=never>
                    <label class="font-13">{{label.QuestionSharedDetails}}</label>
                    <!-- <label class="font-13">{{ label.questionSharedList }}</label> -->
                    <input matInput type="text" class="form-control" formControlName="questionSharedList" [matAutocomplete]="autoQuestion" placeholder="Select Question Shared List">
                    <span *ngIf="!createQuesForm.controls.questionSharedList.value"><i class="bottomArrow"></i></span>
                    <button class="inputCloseIcon" mat-button *ngIf="createQuesForm.controls.questionSharedList.value"
                        matSuffix mat-icon-button aria-label="Clear"
                        (click)="createQuesForm.controls.questionSharedList.setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>

                    <mat-autocomplete #autoQuestion="matAutocomplete" [displayWith]="sharedDisplayFn">
                        <!-- <mat-option *ngIf="isLoadingSharedQuestion">
                            <mat-progress-bar mode="buffer" class="spinnercolor"></mat-progress-bar>
                        </mat-option>
                        <ng-container *ngIf="!isLoadingSharedQuestion">
                            <mat-option *ngFor="let shared of sharedList | async" (optionSelected)="onOptionSelected($event)"
                                [value]="shared">
                                {{shared.sharedListName}}</mat-option>
                        </ng-container> -->

                        <ng-container>
                            <mat-option *ngFor="let shared of filteredSharedList|async" [value]="shared">
                                {{shared.sharedListName}}</mat-option>
                        </ng-container>
                    </mat-autocomplete>
                    <mat-error *ngIf="hasError('questionSharedList', 'required')">Required for Question Shared List </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width woLabel" floatLabel=never>
                    <!-- <label class="font-13 required"> Generic Question</label> -->

                    <textarea matInput placeholder="Shared List Question Long Text" class="form-control"
                        formControlName="questionLongText"> </textarea>


                </mat-form-field>


                <p class="float-left"> <span class="infoIcon font-10">i</span><span class="font-11">To Create New
                        Sharedlist, Please visit to 'Sharedlist' Page </span></p>
            </mat-card-content>
            <!-- <span *ngIf="isLoadingSave" class="saveloader">
                <mat-spinner></mat-spinner>
            </span> -->

            <div class="spinner" *ngIf="isLoadingSave">
                <div class="bounceone"></div>
                <div class="bouncetwo"></div>
                <div class="bouncethree"></div>
                <div class="bouncefour"></div>
                <div class="bouncefive"></div>
                <div class="bouncesix"></div>
            </div>
            <mat-card-actions align="center">
                <button mat-raised-button color="primary" type="submit">Create</button>
                <button type="button" mat-raised-button color="warn" (click)="openLink($event)">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
