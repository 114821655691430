import { SharedService } from 'src/app/shared/services/shared.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TemplateDashboardComponent } from './../template-dashboard/template-dashboard.component';
import { SharedlistService } from './../../../../services/sharedlist.service';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { AppConfig } from 'src/app/app.config';
import { map, takeUntil } from 'rxjs/operators';
import { MatLegacySelectionList as MatSelectionList, MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { Subject } from 'rxjs';
export interface QuestionsList{
  id:number;
  description:string;

}
@Component({
  selector: 'app-template-create',
  templateUrl: './template-create.component.html',
  styleUrls: ['./template-create.component.scss']
})
export class TemplateCreateComponent implements OnInit,OnDestroy {
  
  createTemplateForm:UntypedFormGroup
  appConstants = AppConfig.settings;
  getSharedQuestionsList_URL:string;
  SERVER_URL:string;
  sharedQuestionsList:any;
 sharedQuestionList :any;
 selectedQuestions:any=[]
 QuestionIds:any=[]
 spinnerModel:boolean;
 public validationMsg = AppConfig.settings.validationMsg;
 public templateName= AppConfig.settings.TemplateModel.templateName;
 searchChoiModel:any;
 destroy$: Subject<boolean> = new Subject<boolean>();
 
 @ViewChild('sharedQuestions') private selectionList: MatSelectionList;
  ADD_TEMP_URL: string;
  constructor(
    private fb:UntypedFormBuilder,
    private createChoiceRef:MatDialogRef<TemplateDashboardComponent>,
    private sharedService:SharedService,
    private commonService: AwsCommonService,
    private toastorService: NotificationService
    ) {
    this.SERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    if(this.SERVER_URL){
      this.getSharedQuestionsList_URL =`${this.SERVER_URL}/${this.appConstants.getSharedQuestionsList}`;
      this.ADD_TEMP_URL = `${this.appConstants.serverUrl}/${this.appConstants.templateAddParam}`
    } else {
        this.getSharedQuestionsList_URL = this.appConstants.getSharedQuestionsListLocalURL;
    }
  
   }

  ngOnInit() {
    this.getSharedQuestions();
    this.createTemplateForm=this.fb.group({
      templateDescription:this.fb.control('',Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField
      ])),
      templateQuestionChoice:this.fb.array([]),
    questionSharedListId: this.fb.control('')
    })
    
  }
getSharedQuestions(){
  this.spinnerModel=true;
  /* this.sharedListService.getSharedQuestions(this.getSharedQuestionsList_URL) */
  this.commonService.getRequest(this.getSharedQuestionsList_URL, 'GET')
  .pipe(takeUntil(this.destroy$))
  .subscribe((data)=>{
    this.spinnerModel=false;
    this.sharedQuestionsList=data.map(item => {
      return {
        id: item.id,
        name: item.name,
       
      };
    })
  },
  (error) => {
    this.spinnerModel = false;
    this.toastorService.warn('Oops!!! The Server was unable to complete your request');
  }
  )
  
}
onAreaListControlChanged(sharedQuestions, option) {
   this.selectionList.selectedOptions = new SelectionModel<MatListOption>(true);
 
  const found = this.selectedQuestions.some(el => el.id === option.id);
  if (!found) {
    this.selectedQuestions.push(option);
    this.QuestionIds.push(option.id);
  } else {
    this.onRemove(option);
  }
  
  return this.sharedQuestionList;
}

onRemove(item) {
   let index = this.selectedQuestions.findIndex(x=> x.id===item.id);
   this.selectedQuestions.splice(index,1);
   let questionIds=this.selectedQuestions.map(x=>x.id);
   this.QuestionIds=questionIds;
 }

 getSomeClass(option){
  const found=this.selectedQuestions.some(el => el.id === option.id);
  if(found){
    return {createQuesselected:found};
  }
  else{
    return{removeBg:!found};
  }
  
}
public trimFunction(val) {
  val.target.value = val.target.value.trim();
}
createTemplate(){
  if(this.createTemplateForm.invalid){
    return ;
  }
  else{
  this.createTemplateForm.removeControl('questionSharedListId');
  let control=<UntypedFormArray>this.createTemplateForm.controls.templateQuestionChoice;
  control.clear();
  this.spinnerModel=true;
    this.QuestionIds.forEach(x=>{
      control.push(
        this.fb.group({
      
          sharedQuestionChoice:{
          questionSharedList:{
            questionSharedListId:this.fb.control(x).value
          }
          }
        })
      )
    
    })
   /*  this.commonservice.insert(this.ADD_TEMP_URL, this.createTemplateForm.value) */
   this.commonService.postRequest(this.ADD_TEMP_URL, 'POST',this.createTemplateForm.value)
   .pipe(takeUntil(this.destroy$))
    .subscribe(
      
      (res) => { 
        this.spinnerModel=false;
        this.createChoiceRef.close();
        const reload={reload:true,response:res}
        // console.log('res from create template', reload);
        this.sharedService.templateDataEmit(reload);
  },
      (error) => {
        this.spinnerModel=false;
        this.createTemplateForm.addControl('questionSharedListId', new UntypedFormControl(''));
        if(error.status==409){
          this.createTemplateForm.controls.templateDescription.setErrors({
            notUnique:true
          }) 
        } 
        else if(error.status ===417) {
          this.toastorService.warn('Please Add Choices to the Shared Question')
        }
        else {
          this.toastorService.warn('Oops!!! The Server was unable to complete your request');
        }
      },
      () => {
        this.spinnerModel=false;
         this.toastorService.success('Template created Successfully!');
      }
    );
   
  event.preventDefault();
  }
}

close(event: MouseEvent): void {
  this.createChoiceRef.close();
  event.preventDefault();
}

public hasError = (controlName: string, errorName: string) => {
  return this.createTemplateForm.controls[controlName].hasError(errorName);
}
ngOnDestroy(): void {
 // throw new Error("Method not implemented.");
 this.destroy$.next(true);
 this.destroy$.unsubscribe();
}

}

