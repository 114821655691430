<div class="spinnerOverlay" *ngIf="spinnerSharedList"> </div>
<div class="spinner" *ngIf="spinnerSharedList">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>

<div #div class="container primeNewModal sharedQues-QuesView sharedVariable" *ngIf="sharedListName && !spinnerSharedList">


    <form [formGroup]="sharedVariableForm" autocomplete="off" class="example-form" novalidate>
        <mat-card>
            <mat-card-title>
                <mat-card-content>

                    <mat-form-field class="example-full-width">
                        <div><input matInput type="text" class="form-control sharedListName" formControlName="sharedListName" readonly>
                            <span _ngcontent-nlu-c24="" class="infoIcon-white font-10">i</span>
                        </div>
                        <!--  <span (click)="openconfirmModal('sharedList', childInput.id)"></span>
                        (click)="deleteSharedList()"
                  -->
                    </mat-form-field>

                    <table>

                        <tr class="wrap-value">
                            {{statements}}
                        </tr>
                        <tr class="wrap-value">
                            {{questionGroup}}
                        </tr>
                    </table>


                </mat-card-content>
            </mat-card-title>
        </mat-card>
    </form>
</div>