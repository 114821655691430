<div class="spinnerOverlay" *ngIf="spinnerSharedList"> </div>
<div class="spinner" *ngIf="spinnerSharedList">
    <div class="bounceone"></div>
    <div class="bouncetwo"></div>
    <div class="bouncethree"></div>
    <div class="bouncefour"></div>
    <div class="bouncefive"></div>
    <div class="bouncesix"></div>
</div>
<div #div class="container primeNewModal sharedQues-QuesView multiQues">
    <!---->
    <!-- {{childInput | json}}  -->
    <!--  <form *ngIf="!spinnerChoice" [formGroup]="editSharedQuesForm" autocomplete="off" class="example-form" novalidate> -->
    <form *ngIf="!spinnerSharedList" [formGroup]="editSharedQuesForm" autocomplete="off" class="example-form" novalidate>
        <mat-card>
            <mat-card-title>
                <mat-card-content>
                    <mat-form-field class="example-full-width">
                        <input type="hidden" class="form-control sharedListName" formControlName="id">
                        <input matInput type="text" class="form-control sharedListName" formControlName="sharedListName" [readonly]="!isEdit && !validationCheck">
                        <span (click)="openconfirmModal('sharedList', childInput.id)"><i
                              class="deleteIcon alignDelete-textbox"></i></span>
                        <!--(click)="deleteSharedList()"
                      -->
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'required')">Please Enter Shared Question Name
                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'pattern')">Please enter valid Question name
                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListName', 'notUnique')">Choice Name Already Exists
                        </mat-error>

                    </mat-form-field>
                    <mat-form-field class="example-full-width sharedreadonlyDescription">
                        <input matInput type="text" class="form-control sharedListDescription" formControlName="sharedListDescription" [readonly]="!isEdit && !validationCheck">
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'required')">Please Enter Question Description

                        </mat-error>
                        <mat-error class="marginT20" *ngIf="hasError('sharedListDescription', 'pattern')">Please Enter Question Description
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card-title>
        </mat-card>
        <div class="sharedQuestionTable">
            <!--(click)="addColumn()" -->
            <span class="font-13 float-right addChoice blue" (click)="openAddChoice()"><i class="addnewIcon float-left"></i>
              <span>Add Choice</span> </span>
            <table mat-table [dataSource]="data" class="mat-elevation-z8 " #table cdkDropList [cdkDropListData]="data" (cdkDropListDropped)="dropTable($event)" [cdkDropListDisabled]="!isEdit">
                <ng-container [cdkColumnDef]="column.columnDef" *ngFor="let column of columns;">
                    <th mat-header-cell *matHeaderCellDef> {{column.header }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="column.columnDef== 'action'">
                            <mat-checkbox #uncheckRef (click)="$event.stopPropagation()" (change)="$event ? selectRow($event, element) : null"></mat-checkbox>
                        </div>
                        <div *ngIf="column.columnDef == 'name'"> {{element[column.columnDef]}}</div>
                        <div *ngIf="column.columnDef!= 'name' && column.columnDef != 'action'">
                            <mat-radio-button disabled></mat-radio-button>
                        </div>
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="column.punchCol" *ngFor="let column of columns; let i=index;" formArrayName="punchCodes">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="(column.columnDef === 'name'); then content else other_content"></div>
                        <ng-template #content>
                            <span class="font-13 float-left blue addQuestion" (click)="openAddQuestion()">
                              <i class="addnewIcon"></i>
                          <span>Add Questions</span></span>
                        </ng-template>
                        <ng-template #other_content>
                            <button *ngIf="!this.deleteButton" (click)="(column.choiceId)? openconfirmModal('choice',column.choiceId) : openconfirmModal('questions','')"><i class="deleteIcon"></i></button>
                            <button *ngIf="this.deleteButton"><i class="deleteIcon"></i></button>
                        </ng-template>
                        <div *ngIf="column.punchCode" [formGroupName]="i-2">
                            <input type="hidden" class="form-control punchCodeList" formControlName="id" value="{{column.choiceId}}">
                            <input matInput type="text" class="form-control punchCodeList" formControlName="punchCode" value="{{column.punchCode}}" [readonly]="!isEdit" OnlyNumber="true" required>

                            <!--  <mat-error *ngIf="editSharedQuesForm.invalid">
                                      Please enter number only
                                  </mat-error> -->
                        </div>
                    </th>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayPunchCode ;sticky: true;" class="table-edit-section"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true; " class="table-header-section"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" cdkDrag [cdkDragData]="row"></tr>
            </table>

        </div>
        <!-- <mat-card-actions class="float-left" *ngIf="this.isEdit">
          <button mat-raised-button color="primary" (click)="sharedListQuestionSave()"   type="submit">Save</button>
          <button type="button" mat-raised-button color="warn" (click)="openconfirmModal('edit', '')">Cancel</button>
      </mat-card-actions> -->
    </form>
</div>