import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Inputsheettypes } from 'src/app/models/inputsheettypes';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, from, Subject, Observable, BehaviorSubject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { CommunicatingComponentService } from '../../service/communicating-component.service';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { PromoDashboardinterface, PromoDashboardObject } from 'src/app/models/inputsheetpromo';
import { DatePipe } from '@angular/common';
//import { SatDatepicker } from 'saturn-datepicker';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { InputService } from 'src/app/services/inputsheet.service';
import { ExcelService } from 'src/app/services/excel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthManager } from 'src/app/helpers/auth-manager';
@Component({
  selector: 'app-promo-dashboard',
  templateUrl: './promo-dashboard.component.html',
  styleUrls: ['./promo-dashboard.component.scss']
})
export class PromoDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  appConstants = AppConfig.settings;
  promoDeclaring: PromoDashboardinterface;
  /* @ViewChild('picker', { static: true }) dateInput: SatDatepicker<any>; */
  @Output() editEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  inputSheetTypeControl = new UntypedFormControl();
  inputSheetType: Inputsheettypes[] = [];
  sheetType: number;
  subscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  pipe: DatePipe;
  isLoading: boolean;
  isAdmin:boolean;

  InputsheetpromoData: any = [];
  InputsheetData: any = [];

  filterReturnData: any;
  objectDeclaration: any = [];
  matTableDataSource = new MatTableDataSource<PromoDashboardObject>();
  constructor(
    private communicatingComponentService: CommunicatingComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private awsCommonService: AwsCommonService,
    private inputService: InputService,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private authManager: AuthManager
  ) {
    this.pipe = new DatePipe('en');
    this.isAdmin = true;
    this.promoDeclaring = {
      inputSelecteName: '',
      dataShowTitle: '',
      promoDashboardURL: '',
      gridTotalCount: '',
      tableErrorBlock: 'Loading Data...',
      tableNoDataFound: 'No Records Found',
      promoDashboardGenresURL: '',
      promoDashboardShowTitleURL: '',
      promoTableFillterColumns: '',
      showTitleControl: new UntypedFormControl(''),
      surveyAnalystControl: new UntypedFormControl(''),
      genreDescControl: new UntypedFormControl(''),
      episodeControl: new UntypedFormControl(''),
      noOfPrintsControl: new UntypedFormControl(''),
      createdDtControl: new UntypedFormControl(''),
      lastUpdatedDtControl: new UntypedFormControl(''),
      inputSheetStatusControl: new UntypedFormControl(''),
      programmerNotesControl: new UntypedFormControl(''),
      inputSheetNameControl: new UntypedFormControl(''),
      promoTableDisplayColumns: [
        'inputsheetName',
        'genreDesc',
        'showTitle', 'surveyAnalyst', 'episode', 'noOfPrints', 'programmerNotes', 'createdDt', 'lastUpdatedDt', 'inputSheetStatus',
        'action'],
      promoTableHeaderColumns: [
        'input Sheet Name', 'genre', 'show title', 'Analyst', 'Episode #',
        'Number of Promos', 'Notes to Programmer', 'created date', 'modified date', 'status', 'action'],
      promoTableFooterColumns: ['filter_inputsheetName', 'filter_genreDesc',
        'filter_showTitle',
        'filter_surveyAnalyst',
        'filter_episode', 'filter_noOfPrints', 'filter_programmerNotes', 'filter_createdate', 'filter_modifieddate', 'filter_status',
        'filter_action'],
      pipe: new DatePipe('en'),
      tooltip: false,
      communication: '',
      filterValues: {
        inputsheetName: '',
        showTitle: '',
        surveyAnalyst: '',
        genreDesc: '',
        episode: '',
        noOfPrints: '',
        programmerNotes: '',
        createdDt: '',
        lastUpdatedDt: '',
        inputSheetStatus: '',
        fromCreatedDate: '',
        toCreatedDate: '',
        fromUpdatedDate: '',
        toUpdatedDate: ''
      },
      showtitleData: [],
      genreData: [],
      inputsheetNameData: [],
      inputSheetStatusData: ['Draft', 'Submitted'],
      showTitleArr: [],
      genreArr: [],
      showTitleArrd: [],
      genreArrd: [],
      noOfPrintsArr: null
    };
    this.promoDeclaring.filterRougtCut = [
      {
        rougtcutid: '',
        rougtcutvalue: 'All'
      },
      {
        rougtcutid: 'Y',
        rougtcutvalue: 'Yes'
      },
      {
        rougtcutid: 'N',
        rougtcutvalue: 'No'
      }
    ];
    this.sheetType = 3;
    this.inputSheetType = [
      { value: 1, viewValue: 'Pilot' },
      { value: 2, viewValue: 'Print Ad' },
      { value: 3, viewValue: 'Promo' },
      {value: 4, viewValue: 'Internet'},
      {value: 5, viewValue: 'Others'}
    ];
    this.subscription = this.communicatingComponentService.getCommunication()
      .subscribe(message => { this.promoDeclaring.communication = message; }
      );
    this.inputSheetTypeControl.setValue(this.inputSheetType[2].value);

    this.promoDeclaring.inputSelecteName = this.inputSheetType[2].viewValue;
    this.inputSheetTypeControl.valueChanges
      .subscribe(
        inputSheetType => {
          this.promoDeclaring.inputSelecteName = '';
          if (inputSheetType === 1) {
            this.router.navigate(['../pilot'], { relativeTo: this.route });
          } else if (inputSheetType === 2) {
            this.router.navigate(['../print'], { relativeTo: this.route });
          } else if (inputSheetType === 3) {
            this.router.navigate(['../promo'], { relativeTo: this.route });
          }
          else if(inputSheetType === 4){
            this.router.navigate(['../internet'], { relativeTo: this.route });
          }
          else if(inputSheetType === 5){
            this.router.navigate(['../others'], { relativeTo: this.route });
          }          
          this.sheetType = inputSheetType;
          inputSheetType--;
          this.promoDeclaring.inputSelecteName = this.inputSheetType[inputSheetType].viewValue;
        }
      );

    if (this.appConstants.checkServerURL) {
      this.promoDeclaring.promoDashboardURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetPromoDashboard}`;
      this.promoDeclaring.promoDashboardGenresURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterGenres}`;
      this.promoDeclaring.promoDashboardShowTitleURL =
        `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/${this.appConstants.inputSheetDashboardFilterShowTitle}`;

    } else {
      this.promoDeclaring.promoDashboardURL = `${this.appConstants.inputSheetpromoDashboardLocalURL}`;
      this.promoDeclaring.promoDashboardGenresURL = `${this.appConstants.inputsheetpromoGenreLocalURL}`;
      this.promoDeclaring.promoDashboardShowTitleURL = `${this.appConstants.inputsheetpromoShowTitleLocalURL}`;
    }
    this.getpromoDashboard();
  }
  ngOnInit() {
    if(this.authManager.userRole != 'ADMIN'){
      this.isAdmin = false;
    }
    this.matTableDataSource.sort = this.sort;
  }
  ngAfterViewInit() {
    this.matTableDataSource.sort = this.sort;
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.destroy$.next(true);
  }
  sendMessage() {
    this.communicatingComponentService.sendCommunication(true);
  }
  getpromoDashboard() {

    this.isLoading = true;
    this.getMultipleFilter();
    const data: PromoDashboardObject[] = [];
    let PromoTitleArr = [];
    const localURL = 'assets/json/promoDash.json';
    this.awsCommonService.getRequest(this.promoDeclaring.promoDashboardURL, 'GET')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dataSource => {
          //console.log(dataSource);
          this.isLoading = false;
          this.InputsheetpromoData = dataSource,
            this.promoDeclaring.showtitleData = [];
          this.promoDeclaring.genreData = [];
          this.promoDeclaring.inputsheetNameData = [];
          this.InputsheetpromoData = this.InputsheetpromoData.map((item, index) => {
            PromoTitleArr = item.printInfoDto;
            PromoTitleArr.map((itemData) => {
              this.promoDeclaring.showTitleArr = itemData.showTitle;
              this.promoDeclaring.genreArr = itemData.genreDesc;
            });
            this.promoDeclaring.showTitleArrd = PromoTitleArr.map((itemData) => {
              return itemData.showTitle.trim();
            });
            this.promoDeclaring.genreArrd = PromoTitleArr.map((itemData) => {
              return itemData.genreDesc;
            });
            this.promoDeclaring.noOfPrintsArr = PromoTitleArr.reduce((total, noitem) => {
              return total + noitem.noOfPrints;
            }, 0);

            data.push({
              inputSheetId: item.inputSheetId,
              inputsheetName: item.inputSheetName ? item.inputSheetName : 'No Data',
              showTitle: (this.promoDeclaring.showTitleArrd.length > 0) ? this.promoDeclaring.showTitleArrd : 'No Data',
              surveyAnalyst: item.surveyAnalyst ? item.surveyAnalyst : 'No Data',
              genreDesc: (this.promoDeclaring.genreArrd.length > 0) ? this.promoDeclaring.genreArrd : 'No Data',
              episode:
                item.printInfoDto ? (item.printInfoDto[0]) ?
                  (item.printInfoDto[0].episodeNumber) ? (item.printInfoDto[0].episodeNumber) : 'No Data' : 'No Data' : 'No Data',
              titleVersionId: '',
              noOfPrints: (this.promoDeclaring.noOfPrintsArr != null) ? this.promoDeclaring.noOfPrintsArr : 'No Data',
              createdDt: item.createdDt ? item.createdDt : '01/01/0001',
              lastUpdatedDt: item.lastUpdatedDt ? item.lastUpdatedDt : '01/01/0001',
              inputSheetStatus: item.inputSheetStatus ? item.inputSheetStatus : 'No Data',
              programmerNotes: item.programmerNotes ? item.programmerNotes : 'No Data',
              inputsheetId: item.inputSheetId
            });

            if (this.promoDeclaring.showTitleArr && this.promoDeclaring.showTitleArr.length > 0) {
              this.promoDeclaring.showtitleData.push({
                showTitle: this.promoDeclaring.showTitleArr
              });
            }
            if (this.promoDeclaring.genreArr && this.promoDeclaring.genreArr.length > 0) {
              this.promoDeclaring.genreData.push({
                genreDesc: this.promoDeclaring.genreArr
              });
            }

            if (typeof (item.inputSheetName) !== 'undefined' && item.inputSheetName != null) {
              this.promoDeclaring.inputsheetNameData.push({
                inputsheetName: item.inputSheetName
              });
            }
          });

          this.promoDeclaring.showtitleData =
            [...new Set(Array.from(new Set(this.promoDeclaring.showtitleData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.showTitle.trim();
              }
            }))];
          this.promoDeclaring.genreData =
            [...new Set(Array.from(new Set(this.promoDeclaring.genreData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.genreDesc.trim();
              }
            }))];
          this.promoDeclaring.inputsheetNameData =
            [...new Set(Array.from(new Set(this.promoDeclaring.inputsheetNameData)).map((itemInArray) => {
              if (typeof (itemInArray) !== 'undefined' && itemInArray !== null) {
                return itemInArray.inputsheetName.trim();
              }
            }))];
          this.promoDeclaring.showtitleData = this.promoDeclaring.showtitleData.sort();
          this.promoDeclaring.genreData = this.promoDeclaring.genreData.sort();
          this.promoDeclaring.inputsheetNameData = this.promoDeclaring.inputsheetNameData.sort();
          this.matTableDataSource.data = data;
          setTimeout(() => {
            this.matTableDataSource.sort = this.sort;
          }),

            this.matTableDataSource.filterPredicate = this.customFilterPredicate();
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
        }
      );
  }

  clearGridTable() {
    this.promoDeclaring.noOfPrintsControl.setValue('');
    this.promoDeclaring.episodeControl.setValue('');
    this.promoDeclaring.genreDescControl.setValue('');
    this.promoDeclaring.surveyAnalystControl.setValue('');
    this.promoDeclaring.showTitleControl.setValue('');
    this.promoDeclaring.inputSheetStatusControl.setValue('');
    this.promoDeclaring.inputSheetNameControl.setValue('');
    this.promoDeclaring.filterValues.fromCreatedDate = '';
    this.promoDeclaring.filterValues.toCreatedDate = '';
    this.promoDeclaring.filterValues.fromUpdatedDate = '';
    this.promoDeclaring.filterValues.toUpdatedDate = '';
    this.promoDeclaring.lastdate = '';
    this.promoDeclaring.createdate = '';
    this.awsCommonService.clearDate.emit(true);
    this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
    this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
  }
  getMultipleFilter() {
    this.promoDeclaring.inputSheetNameControl.valueChanges
      .subscribe(
        inputsheetName => {
          this.promoDeclaring.filterValues.inputsheetName = inputsheetName;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );

    this.promoDeclaring.surveyAnalystControl.valueChanges
      .subscribe(
        surveyAnalyst => {
          this.promoDeclaring.filterValues.surveyAnalyst = surveyAnalyst;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.showTitleControl.valueChanges
      .subscribe(
        showTitle => {
          this.promoDeclaring.filterValues.showTitle = showTitle;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.genreDescControl.valueChanges
      .subscribe(
        genreDesc => {
          this.promoDeclaring.filterValues.genreDesc = genreDesc;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.episodeControl.valueChanges
      .subscribe(
        episode => {
          this.promoDeclaring.filterValues.episode = episode;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.noOfPrintsControl.valueChanges
      .subscribe(
        noOfPrints => {
          this.promoDeclaring.filterValues.noOfPrints = noOfPrints;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.inputSheetStatusControl.valueChanges
      .subscribe(
        inputSheetStatus => {
          this.promoDeclaring.filterValues.inputSheetStatus = inputSheetStatus;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );
    this.promoDeclaring.programmerNotesControl.valueChanges
      .subscribe(
        programmerNotes => {
          this.promoDeclaring.filterValues.programmerNotes = programmerNotes;
          this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
          this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
          this.promoDeclaring.tableErrorBlock =
            (this.promoDeclaring.gridTotalCount === 0) ? this.promoDeclaring.tableNoDataFound : this.promoDeclaring.tableErrorBlock;
        },
        error => { }
      );

  }
  customFilterPredicate() {

    return (data: PromoDashboardObject, filter: string): boolean => {
      const searchString = JSON.parse(filter);
      const inputsheetName =
        data.inputsheetName.toString().trim().toLowerCase().indexOf(searchString.inputsheetName.toLowerCase()) !== -1;
      const surveyAnalyst =
        data.surveyAnalyst.toString().trim().toLowerCase().indexOf(searchString.surveyAnalyst.toLowerCase()) !== -1;
      const showTitle = data.showTitle.toString().trim().indexOf(searchString.showTitle) !== -1;
      const genreDesc = data.genreDesc.toString().trim().indexOf(searchString.genreDesc) !== -1;
      const episode = data.episode.toString().trim().toLowerCase().indexOf(searchString.episode.toLowerCase()) !== -1;
      const noOfPrints =
        data.noOfPrints.toString().trim().toLowerCase().indexOf(searchString.noOfPrints.toLowerCase()) !== -1;
      const inputSheetStatus =
        data.inputSheetStatus.toString().trim().toLowerCase().indexOf(searchString.inputSheetStatus.toLowerCase()) !== -1;
      const programmerNotes =
        data.programmerNotes.toString().trim().toLowerCase().indexOf(searchString.programmerNotes.toLowerCase()) !== -1;
      const tempCreDt = this.pipe.transform(data.createdDt, 'yyyy-MM-dd');
      const createdDt = (
        (tempCreDt.toString() >= (searchString.fromCreatedDate).toString())
        &&
        (tempCreDt.toString() <= (searchString.toCreatedDate).toString())
      );
      const tempUpdDt = this.pipe.transform(data.lastUpdatedDt, 'yyyy-MM-dd');
      const lastUpdatedDt = (
        (tempUpdDt.toString() >= (searchString.fromUpdatedDate).toString())
        &&
        (tempUpdDt.toString() <= (searchString.toUpdatedDate).toString())
      );
      if (!(searchString.fromCreatedDate) && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputsheetName && surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes;
      } else if (searchString.fromCreatedDate && !(searchString.fromUpdatedDate)) {
        this.filterReturnData =
          inputsheetName &&
          surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && createdDt;
      } else if (!(searchString.fromCreatedDate) && searchString.fromUpdatedDate) {
        this.filterReturnData =
          inputsheetName &&
          surveyAnalyst && showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && lastUpdatedDt;
      } else {
        this.filterReturnData =
          surveyAnalyst &&
          inputsheetName &&
          showTitle && genreDesc && episode && noOfPrints && inputSheetStatus && programmerNotes && createdDt && lastUpdatedDt;
      }
      return this.filterReturnData;
    };
  }

  edit(data) {

    this.awsCommonService.editPromoData = data;
    this.router.navigate(['../promoedit'], { relativeTo: this.route });
  }

  onCopy(element) {
    this.isLoading = true;
    const inputSheetIdData = element.inputSheetId;
    const inputSheetIdParam = { inputSheetId: inputSheetIdData };
    this.awsCommonService.postRequest(
      `${this.appConstants.serverUrl}/${this.appConstants.inputSheetParams}/copyprint`, 'POST', inputSheetIdParam)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.notificationService.success('InputSheet Promo Copied successfully');
        this.clearGridTable();
        this.getpromoDashboard();
      }, err => {
        this.notificationService.warn(err);
      });
  }

  getDates(range: any, column: any) {
    const fromDate = this.pipe.transform(range.start, 'yyyy-MM-dd');
    const toDate = this.pipe.transform(range.end, 'yyyy-MM-dd');
    if (column === 'createdDt') {
      this.promoDeclaring.filterValues.fromCreatedDate = fromDate;
      this.promoDeclaring.filterValues.toCreatedDate = toDate;
      this.promoDeclaring.createdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    } else {
      this.promoDeclaring.filterValues.fromUpdatedDate = fromDate;
      this.promoDeclaring.filterValues.toUpdatedDate = toDate;
      this.promoDeclaring.lastdate = this.pipe.transform(fromDate, 'MM/dd/yyyy') + '  to  ' + this.pipe.transform(toDate, 'MM/dd/yyyy');
    }

    this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
    this.matTableDataSource.filter = JSON.stringify(this.promoDeclaring.filterValues);
    this.promoDeclaring.gridTotalCount = this.matTableDataSource.filteredData.length;
  }

  exportExcel() {
    // console.log(this.matTableDataSource.filteredData);
    //console.log(this.filteredData);
    this.notificationService.success('InputSheet Promo Excel generated successfully');
    this.excelService.inputsheetPromoExport(this.matTableDataSource.filteredData);
  }



}
