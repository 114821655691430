<div class="spinnerOverlay" *ngIf="isLoading"> </div>
<div class="spinner" *ngIf="isLoading">
      <div class="bounceone"></div>
      <div class="bouncetwo"></div>
      <div class="bouncethree"></div>
      <div class="bouncefour"></div>
      <div class="bouncefive"></div>
      <div class="bouncesix"></div>
</div>

<div class="col-md-11 contentSection pilotMain" id="main">
    <div class="tableTitleHeader px-2 mt-3 mb-3">
        <h5 class="float-left font-20">Promo - <span>{{promoData.editTitle}}</span></h5>
        <mat-card-actions class="float-right">
            <button mat-raised-button color="primary" type="submit" (click)="openconfirmModal('Submit','')" [disabled]="notSaved || disButtons"
            matTooltip="Please Enter details below and Save">Submit</button>
            <button type="button" mat-raised-button color="warn" (click)="openconfirmModal('cancel','')">Cancel</button>
        </mat-card-actions>

    </div>
    <form [formGroup]="promoData.promoAddCreateForm" (ngSubmit)="promoGenerate('DRAFT')" *ngIf="dataSource">
        <div class="programmerNotes float-left surveyanalyst" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Analyst</label>
            <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add Analyst"
                formControlName="surveyAnalyst" class="form-control" [readonly]="readonlyFlag">
            <mat-error *ngIf="promoData.promoAddCreateForm.controls.surveyAnalyst.invalid">Please Enter Valid Value</mat-error>
        </div>
        <div class="programmerNotes float-left" floatLabel=never>
            <label for="programmerNotes" class="font-13 float-left">Programmer Notes</label>
            <!-- <input matInput type="text" class="font-13 marginT5 float-left" placeholder="Add programmer Notes"
                formControlName="programmerNotes" class="form-control"> -->
                <textarea class="form-control" placeholder="Add programmer Notes"
                formControlName="programmerNotes" [readonly]="readonlyFlag"></textarea>
            <mat-error *ngIf="promoData.promoAddCreateForm.controls.programmerNotes.invalid">Please Enter Valid Value</mat-error>
        </div>
        <div class="float-left testDate testDateAlign">
            <label for="testDate" class="font-13">Test Date</label>
            <input matInput [matDatepicker]="picker1" placeholder="Select Date" [formControl]="pilotDate" 
                name="pilotDate" class="form-control" [readonly]="readonlyFlag">
            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="disableSelect"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </div>
        <div class="float-left vendor">
            <label for="testDate" class="font-13">Vendor</label>
            <mat-form-field floatLabel=never>
                <mat-select matNativeControl required  class="form-control" [formControl]="vendor" [disabled]="disableSelect"
                    (selectionChange)="onSelectedVendor($event)" placeholder="Select Vendor">
                    <mat-option [value]="vendorSelected">{{vendorSelected}}</mat-option>
                    <mat-option *ngFor="let vendor of vendorData" [value]="vendor.vendorName"
                        placeholder="Please Select Vendor">
                       {{vendor.vendorName}}
                    </mat-option>
                </mat-select>
                <i class="bottomArrow pilot-selectAlign"></i>
                <!-- <input type="hidden" value="genre.projectTypeId"> -->
                <mat-error>Please select a genre</mat-error>
            </mat-form-field>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 header inputSheet-table-create"
            formArrayName="inputSheetPrintInfo">

            <ng-container matColumnDef="genreDesc">
                <th mat-header-cell *matHeaderCellDef>{{promoData.promoAddHeader[0] | uppercase}}</th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                    <div formGroupName="primeShowTitle">
                        <div formGroupName="projectType">
                            <mat-form-field floatLabel=never class="readonly-wrapper">
                                <mat-select matNativeControl required formControlName="c2027ProjectTypeId" class="form-control readonly-block" [disabled]="disableSelect" placeholder="Select Genre">
                                    <mat-option *ngFor="let genre of genreList" [value]="genre.projectTypeId"
                                         placeholder="Please Select Genre"
                                         (onSelectionChange)="onSelectedGenre(genre.projectType,indexpostion)">
                                        {{ genre.projectType }}
                                    </mat-option>
                                </mat-select>

                                <i class="bottomArrow pilot-selectAlign"></i>
                                <mat-error>Please select a genre</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </td>
                <td class="addInputSheetRows" mat-footer-cell *matFooterCellDef>
                    <button mat-button type="button" (click)="promoaddRow()" class="blue" [disabled] ="disButtons"> <span class="addnewIcon" ></span> Add New
                        Show Title</button>
                </td>
            </ng-container>


            <ng-container matColumnDef="showTitle">
                <th mat-header-cell *matHeaderCellDef>{{promoData.promoAddHeader[1] | uppercase}}</th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">

                    <div formGroupName="primeShowTitle">
                        <mat-form-field floatLabel=never class="readonly-wrapper">
                            <mat-select matNativeControl required class="form-control readonly-block" formControlName="primeShowTitleId" [disabled]="disableSelect" placeholder="Select Title">
                                <mat-option *ngFor="let show of showTitleList[indexpostion]"
                                    [value]="show.primeShowTitleId" placeholder="Please Select Show Title">
                                    {{ show.showTitle }}
                                </mat-option>
                            </mat-select>
                            <i class="bottomArrow pilot-selectAlign"></i>
                            <mat-error>Please select a show title</mat-error>
                        </mat-form-field>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <ng-container matColumnDef="episode">
                <th mat-header-cell *matHeaderCellDef> {{promoData.promoAddHeader[2] | uppercase}} </th>
                <td mat-cell *matCellDef="let element;let indexpostion=index;" [formGroupName]="indexpostion">
                    <mat-form-field floatLabel=never>
                        <input matInput required type="text" class="font-13 marginT5 float-right"
                        placeholder="Add Episode number" formControlName="episodeSerialNumber" OnlyNumber="true" class="form-control" [readonly]="readonlyFlag">
                        <mat-error>Please Enter Episode #</mat-error>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="noOfPrints">
                <th mat-header-cell *matHeaderCellDef> {{promoData.promoAddHeader[3] | uppercase}} </th>
                <td mat-cell *matCellDef="let element; let indexpostion=index;" [formGroupName]="indexpostion">
                    <mat-form-field floatLabel=never>
                        <input matInput required type="text" class="font-13 marginT5 float-right" OnlyNumber="true" placeholder="Add Spot" formControlName="noOfPrints" (keyup)="getTotalAds()" class="form-control" [readonly]="readonlyFlag">
                        <mat-error>Please Enter Number Of Spots</mat-error>
                    </mat-form-field>
                    <i class="deleteIcon alignDelete-textbox float-right marginT15 marginR10"
                    (click)="removeRow(element, indexpostion)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </td>
                <td class="generateData" mat-footer-cell *matFooterCellDef>
                    <button class="savePilot float-right" mat-raised-button color="primary"
                        type="submit" [ngClass]="disButtons ? 'disabled-button' : ''" [disabled] ="showTitleEmpty">GENERATE</button>
                    <span class="float-left">Total Number Of Spots : {{TotalAds}}</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="promoData.promoAddColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: promoData.promoAddColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef=" promoData.promoAddColumns;"></tr>
        </table>
    </form>

    <mat-accordion #accordion="matAccordion" class="promoMatAccordion" >
        <a class="exp-toggle text-right">
            <span (click)="toggleAll(toggle)">{{toggle ? 'Expand All' : 'Collapse All'}} </span>
        </a>
        <!-- Section 1 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 1 of 9 </span>Spot Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="formSpot">
                <div formArrayName="adTitle">
                    <div class="promoDyntable" *ngFor="let adTitle of spotdetailControls(); let i = index"
                        formGroupName="{{i}}">
                        <span formArrayName="promoSet">
                            <table class="promoSetcol inputSheet-table-create">
                                <div *ngFor="let promoSpotPrintTitle of adTitle.controls.promoSet.controls; let j = index"
                                    formGroupName="{{j}}" class="spotTableCol">
                                    <tr *ngIf="i===0 && j===0">
                                        <th class="promoNumberCol">#</th>
                                        <th> Show Title </th>
                                        <th> Spot Title </th>
                                        <th> Length </th>
                                        <th> Show # Times </th>
                                        <th> Ad Type </th>
                                        <th> Show Type </th>
                                        <th> Print Quality </th>
                                        <th> Notes </th>
                                    </tr>
                                    <tr>
                                        <td class="promoNumberCol"><input readonly mat-input class="form-control"
                                                formControlName="spotPosition"></td>
                                        <td>
                                            <input readonly mat-input class="form-control" formControlName="showTitle" [readonly]="readonlyFlag">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.showTitle.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input class="form-control" formControlName="promoSpotPrintTitle" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.promoSpotPrintTitle,$event)">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.promoSpotPrintTitle.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input type="number" class="form-control" formControlName="promoLength" min="0" 
                                                oninput="validity.valid||(value='')" [readonly]="readonlyFlag">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.promoLength.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input type="number" class="form-control" formControlName="showNoTimes" min="0" 
                                                oninput="validity.valid||(value='')" [readonly]="readonlyFlag">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.showNoTimes.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input class="form-control" formControlName="adType" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.adType,$event)">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.adType.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input class="form-control" formControlName="programType" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.programType,$event)">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.programType.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <td>
                                            <input mat-input class="form-control" formControlName="printQuality" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.printQuality,$event)">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.printQuality.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                        <!-- <td>
                                            <input mat-input class="form-control" formControlName="splNotes">
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.splNotes.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td> -->
                                        <td class="promoNotes">
                                            <textarea class="form-control" formControlName="splNotes" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.splNotes,$event)"></textarea>
                                            <mat-error *ngIf="promoSpotPrintTitle.controls.splNotes.invalid && submittedForm1">Please enter valid value</mat-error>
                                        </td>
                                    </tr>
                                </div>
                            </table>
                        </span>
                    </div>
                </div>
            </form>

            <!-- <i class="addnewIcon float-left" (click)="onAddspotRow()"></i> -->
            <i class="saveIcon" (click)="spotSave(formSpot)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            <!-- </form> -->
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 2 of 9 </span> Additional Attributes
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="spotNotSaved">No Data... Please Add Section1 and Save</div>
            <form [formGroup]="formAddAttr" class="form-horizontal" *ngIf="!spotNotSaved">
                <!-- <div class="container">
                    <button type="submit"> <i class="saveIcon"></i></button>
                </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader" *ngFor="let addAttr_Object of addAttributesControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let promoSpotPrintTitle of addAttr_Object.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.description,$event)">
                                <mat-icon (click)="deleteSections(promoSpotPrintTitle)">close</mat-icon>
                                <mat-error *ngIf="promoSpotPrintTitle.controls.description.invalid && submittedForm2">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowAfterAddAttribute()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <!-- <button type="submit"> <i class="saveIcon"></i></button> -->
                    <i class="saveIcon" (click)="onSection2Submit(formAddAttr.value)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
        </mat-expansion-panel>

        <!-- Section 3 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 3 of 9 </span> Statements_Indiv
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="spotNotSaved">No Data... Please Add Section1 and Save</div>
            <form [formGroup]="formStatIndiv" class="form-horizontal" *ngIf="!spotNotSaved">
                <!-- <div class="container">
                    <button type="submit"> <i class="saveIcon"></i></button>
                </div> -->
                <div formArrayName="inputSheetSection" class="adTitleWrapper">
                    <div class="inputTitleHeader"
                        *ngFor="let addAttr_Object of statementsIndivControls(); let i = index"
                        formGroupName="{{i}}">
                        <div class="row">
                            <div class="col-8">
                                {{displayedColumns[i]}}
                            </div>
                        </div>

                        <div formArrayName="descriptionList" class="inputTitleBody">
                            <div class="col-6 inputTitleContent"
                                *ngFor="let promoSpotPrintTitle of addAttr_Object.controls.descriptionList.controls; let j = index"
                                formGroupName="{{j}}">
                                <input mat-input class="form-control" formControlName="description" [readonly]="readonlyFlag" (keyup)="updateVal(promoSpotPrintTitle.controls.description,$event)">
                                <mat-icon (click)="deleteSections(promoSpotPrintTitle)">close</mat-icon>
                                <mat-error *ngIf="promoSpotPrintTitle.controls.description.invalid && submittedForm3">Please enter valid value</mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <i class="addnewIcon float-left" (click)="AddRowAfterAddStatIndivGroup()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                    <!-- <button type="submit"> <i class="saveIcon"></i></button> -->
                    <i class="saveIcon" (click)="onSection3Submit(formStatIndiv.value)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                </div>
            </form>
            <!-- <form [formGroup]="formSpot">
            <div formArrayName="spot_Objects">
                <div class="" *ngFor="let spot_Objects of formSpot.controls.spot_Objects.controls; let i = index"
                    formGroupName="{{i}}">
                    <span formArrayName="promoSet">
                        <table class="promoSetcol inputSheet-table-create">
                            <div *ngFor="let promoSpotPrintTitle of spot_Objects.controls.promoSet.controls; let j = index"
                                formGroupName="{{j}}">
                                <tr *ngIf="i===0 && j===0">
                                    <th>#</th>
                                    <th> Show Title </th>
                                    <th> Additional Attributes </th>
                                    
                                </tr>
                                <tr>
                                    <td><input readonly mat-input class="form-control" formControlName="spotPosition"></td>
                                    <td><input readonly mat-input class="form-control" formControlName="showTitle"></td>
                                    <td><input mat-input class="form-control" formControlName="promoSpotPrintTitle">
                                    </td>
                                </tr>
                            </div>
                        </table>
                    </span>
                </div>
            </div>
        </form> -->
        </mat-expansion-panel>
        <!-- Section 4 -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 4 of 9 </span>Statements_group: Standard
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section4Form">
                <table mat-table [dataSource]="dataSource4" class="mat-elevation-z8" formArrayName="section4Array">
                    <!-- Statements_group: Standard Definition -->
                    <ng-container matColumnDef="posstatstd">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Statements Definition -->
                    <ng-container matColumnDef="statstd">
                        <th mat-header-cell *matHeaderCellDef> Statements </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Statements" [value]="element.statstd" formControlName="statstd" (keyup)="updatestatstd(element,$event)" [readonly]="!element.metaDataFlag || readonlyFlag">
                            <!-- <mat-error *ngIf="section4Form.controls.section4Array.controls[in].invalid &&
                                submittedForm4">Please enter valid value</mat-error> -->
                                <mat-error *ngIf="hasErrorsection4Form(in,'pattern')  && submittedForm4">Please enter
                                    valid value
                                </mat-error>
                                <mat-error *ngIf="hasErrorsection4Form(in,'required')  && submittedForm4">Please enter
                                    required
                                    value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox"
                                (click)="deleteStmtGrp(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>

                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns4"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddstatstdRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="statstdSave(section4Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 5 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 5 of 9 </span> Statements_group: Variable
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section5Form">
                <table mat-table [dataSource]="dataSource5" class="mat-elevation-z8" formArrayName="section5Array">

                    <!-- Posotion Statements_group: Variable Definition -->
                    <ng-container matColumnDef="posstatvar">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Statements_group: Variable Definition -->
                    <ng-container matColumnDef="statgrpvar">
                        <th mat-header-cell *matHeaderCellDef> Statements_group: Variable </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Statements"
                                [value]="element.statgrpvar" formControlName="statgrpvar"
                                (keyup)="updateStatgrpVar(element,$event)" [readonly]="!element.metaDataFlag || readonlyFlag">
                            <!-- <mat-error *ngIf="section5Form.controls.section5Array.controls[in].invalid &&
                            submittedForm5">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection5Form(in,'pattern')  && submittedForm5">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection5Form(in,'required')  && submittedForm5">Please enter
                                required
                                value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox"
                                (click)="deleteStatgrpvar(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>


                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns5"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddStatgrpVarRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="statgrpVarSave(section5Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 6 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 6 of 9 </span> Elements_group: Standard
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section6Form">
                <table mat-table [dataSource]="dataSource6" class="mat-elevation-z8" formArrayName="section6Array">

                    <!-- Position Elements_group: Standard Definition -->
                    <ng-container matColumnDef="poselestd">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Elements_group: Standard Definition -->
                    <ng-container matColumnDef="elegrpStd">
                        <th mat-header-cell *matHeaderCellDef> Elements </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Elements" [value]="element.elegrpStd"
                                formControlName="elegrpStd" (keyup)="updateeleGrpstd(element,$event)" [readonly]="!element.metaDataFlag || readonlyFlag">
                            <!-- <mat-error *ngIf="section6Form.controls.section6Array.controls[in].invalid &&
                            submittedForm6">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection6Form(in,'pattern')  && submittedForm6">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection6Form(in,'required')  && submittedForm6">Please enter
                                required
                                value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox"
                                (click)="deleteeleGrpstd(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>

                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns6"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns6"></tr>
                </table>

                <i class="addnewIcon float-left" (click)="onaddeleGrpStdRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="eleStdSave(section6Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 7 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 7 of 9 </span> Elements_group: Variable
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="section7Form">
                <table mat-table [dataSource]="dataSource7" class="mat-elevation-z8" formArrayName="section7Array">
                    <!-- Position Elements_group: Variable Definition -->
                    <ng-container matColumnDef="poselegrpVar">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Elements_group: Variable Definition -->
                    <ng-container matColumnDef="elegrpVar">
                        <th mat-header-cell *matHeaderCellDef> Elements_group: Variable </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Elements" [value]="element.elegrpVar"
                                formControlName="elegrpVar" (keyup)="updateElegrpVar(element,$event)" [readonly]="!element.metaDataFlag || readonlyFlag">
                            <!-- <mat-error *ngIf="section7Form.controls.section7Array.controls[in].invalid &&
                            submittedForm7">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection7Form(in,'pattern')  && submittedForm7">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection7Form(in,'required')  && submittedForm7">Please enter
                                required
                                value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox"
                                (click)="deleteEleGrpvar(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>

                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns7"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns7"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddEleGrpVarRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="EleGrpVarSave(section7Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 8 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 8 of 9 </span>Sr Awareness
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                <p>
                    <label>Sr AWARENESS:</label>
                    <span>If not included, enter “Not Asked” in the space to the right. If included, enter each
                        program’s name below & to the right.</span>
                </p>
            </div>
            <form [formGroup]="section8Form">
                <table mat-table [dataSource]="dataSource8" class="mat-elevation-z8" formArrayName="section8Array">

                    <!-- Position Sr Awareness Definition -->
                    <ng-container matColumnDef="possraware">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Sr Awareness Definition -->
                    <ng-container matColumnDef="srawareness">
                        <th mat-header-cell *matHeaderCellDef> Sr Awareness </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Sr Awareness"
                                [value]="element.srawareness" formControlName="srawareness"
                                (keyup)="updatesrAware(element,$event)" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section8Form.controls.section8Array.controls[in].invalid &&
                            submittedForm8">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection8Form(in,'pattern')  && submittedForm8">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection8Form(in,'required')  && submittedForm8">Please enter
                                required
                                value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox"
                                (click)="deletesraware(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>


                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns8"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns8"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddsrawareRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="srawareSave(section8Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

        <!-- Section 9 -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="expansion_header_title">Section 9 of 9 </span>Question and stim changes
                </mat-panel-title>
                <!-- <span class="infoIconBgW">i</span> -->
            </mat-expansion-panel-header>
            <div class="InputInfoContent">
                <p>
                    <label>NBC ANALYST NOTE:</label>
                    <span>For each question or Stim to be deleted, type in its label and the first five words of that
                        question or stim.</span>
                </p>
                <p>
                    <label>PROGRAMMER'S NOTE:</label>
                    <span>Completely remove the following question(s) &/or stim(s) from questionnaire.</span>
                </p>
            </div>
            <form [formGroup]="section9Form">
                <table mat-table [dataSource]="dataSource9" class="mat-elevation-z8" formArrayName="section9Array">

                    <!-- Position Statement Definition -->
                    <ng-container matColumnDef="posques">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in"> {{in + 1}}
                        </td>
                    </ng-container>

                    <!-- Question and Stim Changes Definition -->
                    <ng-container matColumnDef="quesnstim">
                        <th mat-header-cell *matHeaderCellDef> Question and Stim Changes </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <input class="form-control" matInput placeholder="Add Question and Stim Changes"
                                [value]="element.quesnstim" formControlName="quesnstim"
                                (keyup)="updateQues(element,$event)" [readonly]="readonlyFlag">
                            <!-- <mat-error *ngIf="section9Form.controls.section9Array.controls[in].invalid &&
                            submittedForm9">Please enter valid value</mat-error> -->
                            <mat-error *ngIf="hasErrorsection9Form(in,'pattern')  && submittedForm9">Please enter
                                valid value
                            </mat-error>
                            <mat-error *ngIf="hasErrorsection9Form(in,'required')  && submittedForm9">Please enter
                                required
                                value</mat-error>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columndelete">
                        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let in=index" [formGroupName]="in">
                            <i class="float-right deleteIcon alignDelete-textbox" (click)="deleteQues(element, in)" [ngClass]="disButtons? 'disabled-button':'' "></i>
                        </td>
                    </ng-container>

                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns9"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns9"></tr>
                </table>
                <i class="addnewIcon float-left" (click)="onAddQuesRow()" [ngClass]="disButtons? 'disabled-button':'' "></i>
                <i class="saveIcon" (click)="quesSave(section9Form)" [ngClass]="disButtons? 'disabled-button':'' "></i>
            </form>
        </mat-expansion-panel>

    </mat-accordion>
</div>