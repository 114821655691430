<nav class="sidebar">
  <div class="sidebar-sticky">
    <ul class="nav flex-column text-center">
      <li class="nav-item inputsheetNav" [routerLinkActive]="['is-active']">
        <a class="nav-link">
          <i class="isheetIcon"></i>
          <p>Input Sheet </p>
        </a>
        <ul class="inputMultiMenu">
          <li routerLink='/inputsheet/pilot'>Pilot</li>
          <li routerLink='/inputsheet/print'>Print Ad </li>
          <li routerLink='/inputsheet/promo'>Promo </li>
          <li routerLink='/inputsheet/internet'>Internet </li>
          <li routerLink='/inputsheet/others'>Others </li>
        </ul>
      </li>
      <li class="nav-item" routerLink='/survey' [routerLinkActive]="['is-active']">
        <a class="nav-link">
          <i class="surveyIcon"></i>
          <p>Survey</p>
        </a>
      </li>
      <li class="nav-item" routerLink='/template' [routerLinkActive]="['is-active']">

        <a class="nav-link">

          <i class="templateIcon"></i>
          <p>Template</p>
        </a>
      </li>
      <li class="nav-item" routerLink='/question' [routerLinkActive]="['is-active']">
        <a class="nav-link">
          <i class="quesIcon"></i>
          <p>Question</p>
        </a>
      </li>
      <li class="nav-item" routerLink="/choice" [routerLinkActive]="['is-active']">
        <a class="nav-link">
          <i class="choiceIcon"></i>
          <p>Choice</p>
        </a>
      </li>
      <li class="nav-item" routerLink="/sharedList" [routerLinkActive]="['is-active']">
        <a class="nav-link">
          <i class="slistIcon"></i>
          <p>Shared List</p>
        </a>
      </li>
    </ul>
  </div>
</nav>