<div class="container primeNewModal createSharedQuestion">
    <span class="modalClose" mat-dialog-close routerLink="./survey/dashboard"><i></i></span>
    <mat-card>
        <mat-card-title></mat-card-title>
        <form class="example-form" novalidate>
            <mat-card-content>
                <div class="example-full-width">
                    <label class="font-13" style=" float: left; padding-bottom: 24px; font-weight: bold;"> Upload File </label>
                    <div>
                        <input type="file" size="60" class="form-control" (change)="selectFile($event)">
                    </div>
                </div>
               
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!selectedFiles" (click)="upload()">
                    Upload </button>
                <button type="button" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>