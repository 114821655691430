import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, OnChanges, AfterContentInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';
import { SharedQuestionModel } from '../../../../models/sharedquestion.model';
import { CommonService } from '../../../../services/common.service';
import { NotificationService } from '../../../../services/notification.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SharedListDashboardComponent } from '../shared-list-dashboard/shared-list-dashboard.component';
import { SharedlistService } from 'src/app/services/sharedlist.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, share } from 'rxjs/operators';
import { SharedListSidenavComponent } from '../shared-list-sidenav/shared-list-sidenav.component';
import { validateEmptyField } from 'src/app/shared/validators/validateemptyfield';
import { MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange, MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';
import { AwsCommonService } from 'src/app/services/aws-common.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-create-shared-question',
  templateUrl: './shared-question-create.component.html',
  styleUrls: ['./shared-question-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedQuestionCreateComponent implements OnInit, OnDestroy {

  appConstants = AppConfig.settings;
  dialogRef: any;
  SHAREDQUESTIONERVER_URL: any;
  SHAREDCHOICESERVER_URL: any;
  DASHBOARDSSERVER_URL: any;
  SHAREDQUESTIONSCREATE_URL: any;
  SHAREDCHOICELIST_URL: any;
  SHAREDLIST_URL: any;
  searchQuesModel: any;
  searchChoiModel: any;
  // tslint:disable-next-line: variable-name
  checkServerURL: any;
  public createSharedQuesForm: UntypedFormGroup;
  public label = AppConfig.settings.sharedquestionModel;
  public validationMsg = AppConfig.settings.validationMsg;
  public globalResponse: any;
  public url = 'sharedQuestion';
  sharedChoiceList;
  sharedQuestionList: any;
  selectedOptions = [];
  questionCategoryList: any;
  filteredSharedQues: Observable<any[]>; //any [];
  selectedChoices = [];
  choiceLibraryIds: any = [];
  filteredChoices: any[];
  sideNavReload: any;
  isLoadingSave: any;
  isLoading: boolean = true;
  isRequired: boolean;
  isCategorySelected: boolean;
  selectedIndex: number;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('sharedQuestions') private selectionList: MatSelectionList;
  @ViewChild('sharedChoices') private selectionChoiceList: MatSelectionList;
  filteredList: any;
  constructor(
    public dialog: MatDialog,
    private commonservice: CommonService,
    private toastorService: NotificationService,
    private sharedlistService: SharedlistService,
    private awsCommonService: AwsCommonService,
    //  private sidenav: SharedListSidenavComponent,
    private createSharedQuestionRef: MatDialogRef<SharedListDashboardComponent>,
    private sharedService: SharedService
  ) {
    this.checkServerURL = this.appConstants.checkServerURL;
    if (this.checkServerURL) {
      this.DASHBOARDSSERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.getQuestionCategoryParam}`;
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
      this.SHAREDQUESTIONERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedQuestionListParam}`;
      this.SHAREDQUESTIONSCREATE_URL = `${this.appConstants.sharedQuestionCreateParam}`;
      this.SHAREDCHOICELIST_URL = `${this.appConstants.serverUrl}/${this.appConstants.choiceDashboardParam}`;
      this.SHAREDLIST_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedListParam}`;
    } else {
      this.SHAREDQUESTIONERVER_URL = this.appConstants.questionDashboardLocalURL;
      this.SHAREDCHOICESERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.sharedChoiceListParam}`;
      this.DASHBOARDSSERVER_URL = `${this.appConstants.serverUrl}/${this.appConstants.getQuestionCategoryLocalURL}`;
    }
    //console.log('test label', this.label);
    this.getQuestionCategory();
  }
  ngOnInit() {
    this.getSharedChoiceList();
    this.createSharedQuesForm = new UntypedFormGroup({
      sharedListName: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(128)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      sharedListDescription: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(200)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      questionLibraryId: new UntypedFormControl(null, Validators.required),
      questionLongText: new UntypedFormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
        validateEmptyField,
        Validators.maxLength(200)
        //  Validators.pattern('^[a-zA-Z ]+$')
      ])),
      choiceSharedListId: new UntypedFormControl(null),
      questionType: new UntypedFormControl('Simple', Validators.required),
      questionCategory: new UntypedFormControl(null, Validators.required)
    });
    this.isRequired = true;
    this.onChanges();
  }

  // Validation Error 
  public hasError = (controlName: string, errorName: string) => {
    //   //console.log(controlName, this.createSharedQuesForm.controls[controlName].hasError(errorName));
    return this.createSharedQuesForm.controls[controlName].hasError(errorName);
  }


  onChanges() {
    //console.log('changes');
    this.createSharedQuesForm.get('questionType').valueChanges.subscribe(data => {
      if (data == 'Simple') {
        this.createSharedQuesForm.controls['questionCategory'].setValidators([Validators.required]);
        this.createSharedQuesForm.controls["questionCategory"].updateValueAndValidity();
        this.createSharedQuesForm.controls['questionLongText'].setValidators(Validators.compose([Validators.required,
        Validators.pattern(new RegExp(/^([a-zA-Z0-9 _!@#$%^&*()<>\[{\]}\\.,~`:;|/'"?+-=]{1,255})$/)),
          validateEmptyField,
        Validators.maxLength(200)]));
        this.createSharedQuesForm.controls["questionLongText"].updateValueAndValidity();
        this.isRequired = true;
      } else {
        this.createSharedQuesForm.controls['questionLongText'].setValidators(null);
        this.createSharedQuesForm.controls["questionLongText"].updateValueAndValidity();
        this.createSharedQuesForm.controls['questionCategory'].setValidators(null);
        this.createSharedQuesForm.controls["questionCategory"].updateValueAndValidity();
        this.isRequired = false;
      }
    });
    this.createSharedQuesForm.get('questionCategory').valueChanges.subscribe(data => {
      if (data == null || '') {
        this.createSharedQuesForm.controls['questionLibraryId'].setValidators(null);
        this.createSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = false;
      } else {
        this.createSharedQuesForm.controls['questionLibraryId'].setValidators([Validators.required]);
        this.createSharedQuesForm.controls["questionLibraryId"].updateValueAndValidity();
        this.isCategorySelected = true;
      }
    });
  }
  onClose(event: MouseEvent): void {
    this.createSharedQuestionRef.close();
    event.preventDefault();
  }

  public trimFunction(val) {
    val.target.value = val.target.value.trim();
  }

  // Form Save Functionality
  createQuestion() {
    if (this.createSharedQuesForm.invalid) {
      //console.log(this.createSharedQuesForm);
      this.isLoadingSave = false;

    } else {
      this.isLoadingSave = true;
      // console.log(this.createSharedQuesForm.value); return false;
      // this.commonservice.insert(this.SHAREDQUESTIONSCREATE_URL, this.createSharedQuesForm.value)
      this.awsCommonService.postRequest(`${this.appConstants.serverUrl}/${this.SHAREDQUESTIONSCREATE_URL}`, 'POST', this.createSharedQuesForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => {
            //console.log(res) ;
            this.sideNavReload = { sideNavReload: true, questionSharedListId: res.questionSharedListId, questionType: res.questionType };
            this.sharedService.announceEvent(this.sideNavReload);
          },
          (error) => {
            //console.log('error', error.message);
            this.isLoadingSave = false;
            if (error.status === 409) {
              this.createSharedQuesForm.controls.sharedListName.setErrors({
                notUnique: true
              });
            } else if (error.status === 417) {
              this.createSharedQuesForm.controls.questionLongText.setErrors({
                notUnique: true
              })
            }
            else {
              this.isLoadingSave = false;
              this.createSharedQuestionRef.close();
              this.toastorService.warn('Error' + error.message);
            }
          },
          () => {
            this.isLoadingSave = false;
            this.createSharedQuestionRef.close();
            this.toastorService.success('New Shared Question Created Successfully!');
            // this.sideNavReload = { sideNavReload: true };
            // this.sharedService.announceEvent(this.sideNavReload);
          }
        );

      //  this.sidenav.getSharedQuestionsList();

      event.preventDefault();
    }
  }
  // Auto Populate for each dropdown
  // Category API
  getQuestionCategory(): void {
    this.isLoadingSave = true;
    // this.sharedlistService.getQuestionCategory(this.DASHBOARDSSERVER_URL)
    this.awsCommonService.getRequest(this.DASHBOARDSSERVER_URL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isLoadingSave = false;
        //console.log(data);
        this.questionCategoryList = data;
        this.filteredList = this.questionCategoryList.slice();
        //console.log('this.questionCategoryList', this.questionCategoryList);
      });
  }
  onChangeQuesCat(event): void {
    let questionIds = [];
    questionIds = event.value;
    //console.log('event ', questionIds);
    this.getQuestionList(questionIds);
    //console.log('event ', questionIds);
  }

  getQuestionList(questionIds) {
    this.isLoadingSave = true;
    let sharedList = [];
    // this.sharedlistService.getQuestionList(this.SHAREDLIST_URL, questionIds)
    this.awsCommonService.getRequest(`${this.SHAREDLIST_URL}/${questionIds}/question`, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isLoadingSave = false;
        data.forEach((item) => {
          if (item.status === 'Y') {
            sharedList.push(item);
          }
        })
        this.sharedQuestionList = sharedList;
        //   this.sharedQuestionListSorted = this.sharedQuestionList.name.sort();
        //console.log('sharedlist', sharedList)
        //console.log('this.sharedQuestionList', this.sharedQuestionList);
        //   //console.log('this.sharedQuestionListSorted', this.sharedQuestionListSorted);
        this.isLoading = false;

      }
      );
  }

  getSharedChoiceList() {
    this.isLoadingSave = true;
    //  let url = `${this.appConstants.serverUrl}`;
    // this.sharedlistService.getSharedChoice(this.SHAREDCHOICESERVER_URL)
    this.awsCommonService.getRequest(this.SHAREDCHOICESERVER_URL, 'GET').pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isLoadingSave = false;
        this.sharedChoiceList = data;
      }
      );
  }

  categoryDisplayFn(filterBy): string {
    return filterBy ? filterBy.value1 : filterBy; // filterBy.questionCategoryDesc : filterBy;
  }

  handleSelection(category) {
    //console.log(category)
    this.selectedIndex = category.id;
    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);

  }
  _filterValue(val: any, ArrList: any, Type: any) {
    if (Type === 'sharedListQuestions') {
      const name = val.value1 || val; // val can be List or string
      const list = ArrList.filter(
        option => option.value1.toLowerCase().indexOf(name.toLowerCase()) === 0);
      return (list.length > 0 ? list : [{ id: null, value1: 'No record found' } as any]);
    }
  }
  //   onAreaListControlChanged(sharedChoices){
  //     this.selectedOptions = sharedChoices.selectedOptions.selected.map(item => {
  //       console.log('Item' +item)
  //       item.value.choiceLibrary.choiceLibraryDesc
  //     });

  //     console.log('selected choices',sharedChoices)
  //     console.log('selected options',this.selectedOptions)
  // }

  onAreaListControlChanged(sharedChoices, option) {
    // //console.log(this.createSharedQuesForm.value.choiceLibraryId);
    //console.log(sharedChoices);
    //console.log(option);
    //console.log(this.selectedChoices);
    this.selectionChoiceList.selectedOptions = new SelectionModel<MatListOption>(true);

    const found = this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
    if (!found) {
      //option.isSelected = true;
      this.selectedChoices.push(option);
      this.choiceLibraryIds.push(option.choiceSharedListId);
      this.createSharedQuesForm.patchValue({ sharedChoiceId: this.choiceLibraryIds });
    } else {
      // option.isSelected = false;
      //const index = this.selectedChoices.findIndex(el => el.choiceSharedListId === option.choiceSharedListId);
      this.onRemove(option);
    }
    //console.log(this.choiceLibraryIds);
    this.createSharedQuesForm.patchValue({ choiceSharedListId: this.choiceLibraryIds });
    return this.sharedChoiceList;
  }
  getSomeClass(option) {
    //console.log(option);
    const found = this.selectedChoices.some(el => el.choiceSharedListId === option.choiceSharedListId);
    if (found) {
      return { createQuesselected: found };
    }
    else {
      return { removeBg: !found };
    }
  }
  onRemove(item) {
    //console.log(item);
    //console.log(this.createSharedQuesForm);
    let index = this.selectedChoices.findIndex(x => x.choiceSharedListId === item.choiceSharedListId);
    this.selectedChoices.splice(index, 1);
    // this.selectedChoices=this.filteredChoices;
    //console.log(this.selectedChoices);
    let ChoiceIds = this.selectedChoices.map(x => x.choiceSharedListId);
    this.choiceLibraryIds = ChoiceIds;
    this.createSharedQuesForm.patchValue({ choiceSharedListId: ChoiceIds });
  }
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
