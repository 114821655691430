<div class="tableTitleHeader px-2 mt-3 mb-3 InputSheetHeader" *ngIf="!promoDeclaring.communication">
   <h5 class="float-left font-20">Input Sheet - {{promoDeclaring.inputSelecteName}}</h5>
   <mat-form-field appearance="outline">
      <mat-select [formControl]="inputSheetTypeControl" class="form-control">
         <mat-option *ngFor="let inputSheetType of inputSheetType" [value]="inputSheetType.value">
            {{inputSheetType.viewValue}}
         </mat-option>
      </mat-select>
      <i class="bottomArrow inputsheet-selectAlign"></i>
   </mat-form-field>
   <div class="float-right createSection" *ngIf="isAdmin">
      <span>
         <span *ngIf="sheetType === 1">
            <p class="float-left font-13 blue" (click)="sendMessage()" routerLink="../pilotadd">
               <span>Enter New Input Sheet</span>
               <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3">
               <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
         </span>
         <span *ngIf="sheetType === 2">
            <p class="float-left font-13 blue" routerLink="../printadd">
               <span>Enter New Input Sheet</span>
               <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3">
               <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
         </span>
         <span *ngIf="sheetType === 3">
            <p class="float-left font-13 blue" routerLink="../promoadd">
               <span>Enter New Input Sheet</span>
               <i class="addnewIcon"></i>
            </p>
            <p class="float-left ml-3">
               <i class="excelIcon" (click)="exportExcel()"></i>
            </p>
         </span>
      </span>
   </div>
</div>
<div class="inputsheet-table promoTable">
   <div class="spinnerOverlay"  *ngIf="isLoading"></div>
   <div class="spinner" *ngIf="isLoading">
      <div class="bounceone"></div>
      <div class="bouncetwo"></div>
      <div class="bouncethree"></div>
      <div class="bouncefour"></div>
      <div class="bouncefive"></div>
      <div class="bouncesix"></div>
   </div>
   <cdk-virtual-scroll-viewport class="demo-viewport demo-table-container" itemSize="10">

      <table mat-table [dataSource]="matTableDataSource" matSort matSortDisableClear [multiTemplateDataRows]="true">

         <ng-container matColumnDef="inputsheetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[0] | uppercase}}
               <i class="sortIcon"></i>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.inputsheetName}} </td>
            <td mat-footer-cell *matFooterCellDef colspan="11" class="sticky-header">
               <span *ngIf="promoDeclaring.gridTotalCount; else elseBlock">Showing {{promoDeclaring.gridTotalCount}}
                  Record(s)</span>
               <ng-template #elseBlock>{{promoDeclaring.tableErrorBlock}}</ng-template>
            </td>
         </ng-container>

         <ng-container matColumnDef="genreDesc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[1] | uppercase}} <i class="sortIcon"></i></th>
            <td mat-cell *matCellDef="let element"> {{element.genreDesc}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="showTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[2] | uppercase}}
               <i class="sortIcon"></i>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.showTitle}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
         </ng-container>

         <ng-container matColumnDef="surveyAnalyst">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[3] | uppercase}}
               <i class="sortIcon"></i>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.surveyAnalyst}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="episode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[4] | uppercase}} <i class="sortIcon"></i></th>
            <td mat-cell *matCellDef="let element"> {{element.episode}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="noOfPrints">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[5] | uppercase}}<i class="sortIcon"></i> </th>
            <td mat-cell *matCellDef="let element"> {{element.noOfPrints}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="programmerNotes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[6] | uppercase}}<i class="sortIcon"></i> </th>
            <td mat-cell *matCellDef="let element"> {{element.programmerNotes}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="createdDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[7] | uppercase}}<i class="sortIcon"></i> </th>
            <td mat-cell *matCellDef="let element"> {{element.createdDt | date: 'MM/dd/yyyy'}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="lastUpdatedDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[8] | uppercase}}<i class="sortIcon"></i> </th>
            <td mat-cell *matCellDef="let element"> {{element.lastUpdatedDt | date: 'MM/dd/yyyy'}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="inputSheetStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-header">
               {{promoDeclaring.promoTableHeaderColumns[9] | uppercase}} <i class="sortIcon"></i></th>
            <td mat-cell *matCellDef="let element"> {{element.inputSheetStatus}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{promoDeclaring.promoTableHeaderColumns[10] | uppercase}} </th>
            <td mat-cell *matCellDef="let element" class="StatusCopyIcon">
               <i class="makeCopyIcon" *ngIf="isAdmin" (click)="onCopy(element)"></i>
               <i class="" [ngClass]="!isAdmin ? 'isUser viewIcon':'editIcon'" (click)="edit(element)"></i>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
         </ng-container>

         <ng-container matColumnDef="filter_inputsheetName">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <mat-select [formControl]="promoDeclaring.inputSheetNameControl">
                     <mat-option value="">All</mat-option>
                     <mat-option *ngFor="let optionData of promoDeclaring.inputsheetNameData" [value]="optionData">
                        {{optionData}}
                     </mat-option>
                  </mat-select>
                  <i class="bottomArrow filter-selectAlign"></i>
               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_genreDesc">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <mat-select [formControl]="promoDeclaring.genreDescControl">
                     <mat-option value="">All</mat-option>
                     <mat-option *ngFor="let optionData of promoDeclaring.genreData" [value]="optionData">
                        {{optionData}}
                     </mat-option>
                  </mat-select>
                  <i class="bottomArrow filter-selectAlign"></i>
               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_showTitle">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <mat-select [formControl]="promoDeclaring.showTitleControl" placeholder="Question Category">
                     <mat-option value="">All</mat-option>
                     <mat-option *ngFor="let optionData of promoDeclaring.showtitleData" [value]="optionData">
                        {{optionData}}
                     </mat-option>
                  </mat-select>
                  <i class="bottomArrow filter-selectAlign"></i>
               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_surveyAnalyst">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Search Here"
                     [formControl]="promoDeclaring.surveyAnalystControl" autocomplete="off">
                  <i class="autocomplete-search"></i>

               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_episode">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Search Here" [formControl]="promoDeclaring.episodeControl"
                     autocomplete="off">
                  <i class="autocomplete-search"></i>
               </mat-form-field>
            </th>
         </ng-container>
         <ng-container matColumnDef="filter_noOfPrints">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Search Here" [formControl]="promoDeclaring.noOfPrintsControl"
                     autocomplete="off">
                  <i class="autocomplete-search"></i>

               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_programmerNotes">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Search Here"
                     [formControl]="promoDeclaring.programmerNotesControl" autocomplete="off">
                  <i class="autocomplete-search"></i>
                  <!-- <button mat-button *ngIf="questionText" matSuffix mat-icon-button aria-label="Clear"
                     (click)="questionText=''">
                     <mat-icon>close</mat-icon>
                  </button> -->
               </mat-form-field>
            </th>
         </ng-container>

         <ng-container matColumnDef="filter_createdate">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <span matTooltip="{{promoDeclaring.createdate}}"><app-daterange-picker-component (emitRange)="getDates($event, 'createdDt')">
               </app-daterange-picker-component></span>
            </th>
         </ng-container>
         <ng-container matColumnDef="filter_modifieddate">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <span matTooltip="{{promoDeclaring.lastdate}}"><app-daterange-picker-component (emitRange)="getDates($event, 'lastUpdatedDt')">
               </app-daterange-picker-component></span>
            </th>
         </ng-container>
         <ng-container matColumnDef="filter_status">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <mat-form-field appearance="outline">


                  <mat-select [formControl]="promoDeclaring.inputSheetStatusControl" placeholder="All">
                     <mat-option value="">All</mat-option>
                     <mat-option *ngFor="let StatusData of promoDeclaring.inputSheetStatusData" [value]="StatusData">
                        {{StatusData}}</mat-option>
                  </mat-select>

                  <i class="bottomArrow filter-selectAlign"></i>
               </mat-form-field>
            </th>
         </ng-container>
         <ng-container matColumnDef="filter_action">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">
               <!-- <button mat-button color="primary" >Clear</button> -->
               <button mat-stroked-button class="clearFilter ClearAllWtext" color="primary" (click)="clearGridTable()">Clear All
                  Filters</button>
               <span class="makeCopyText" *ngIf="isAdmin">Make a Copy</span> <span class="editText" [ngClass]="!isAdmin ? 'isUser':''">{{(isAdmin) ? 'Edit' : 'View'}}</span>
            </th>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="promoDeclaring.promoTableDisplayColumns;sticky: true; "
            class="table-header-section">
         </tr>
         <tr mat-header-row *matHeaderRowDef="promoDeclaring.promoTableFooterColumns;sticky: true; "
            class="table-filter-section">
         </tr>
         <tr mat-row *matRowDef="let row; columns: promoDeclaring.promoTableDisplayColumns;"></tr>
         <tr mat-footer-row *matFooterRowDef="promoDeclaring.promoTableDisplayColumns; sticky: true"
            class="table-footer-section"></tr>
      </table>

   </cdk-virtual-scroll-viewport>

</div>